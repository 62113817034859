import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { Utils } from "../../utils/utils";

@Component({
             selector: 'app-touchspin',
             templateUrl: './touchspin.component.html',
             styleUrls: ['./touchspin.component.scss']
           })
export class TouchspinComponent implements OnChanges {
  @Input() presetTouchspin: number;
  @Output() numberOutput = new EventEmitter<number>();
  touchspinForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.touchspinForm = this.formBuilder.group({
                                                  touchspinValue: [this.presetTouchspin]
                                                });
  }

  ngOnChanges(changes: SimpleChanges) {
    changes.presetTouchspin.currentValue !== undefined
      ? this.touchspinValue?.setValue(changes.presetTouchspin.currentValue, { emitEvent: false })
      : this.touchspinValue?.setValue('');
    this.watchTouchspin();
  }

  get touchspinValue() {
    return this.touchspinForm.get('touchspinValue');
  }

  private watchTouchspin(): void {
    this.touchspinForm.valueChanges
        .pipe(debounceTime(500))
        .subscribe((value) => {
          if (value.touchspinValue.length > 0) {
            if (!isNaN(value.touchspinValue)) {
              this.numberOutput.emit(+value.touchspinValue);
            } else {
              this.numberOutput.emit(value.touchspinValue);
            }
          } else {
            this.numberOutput.emit()
          }
        });
  }

  addValue() {
    let touchspin: number;
    typeof this.touchspinValue?.value === 'number'
      ? touchspin = this.touchspinValue?.value
      : touchspin = +Utils.formatPriceForBE(this.touchspinValue?.value);

    if (!isNaN(touchspin)) {
      const incrementedValue = ++touchspin;
      this.numberOutput.emit(Math.round((+incrementedValue + Number.EPSILON) * 100) / 100);
    }
  }

  reduceValue() {
    let touchspin: number;
    typeof this.touchspinValue?.value === 'number'
      ? touchspin = this.touchspinValue?.value
      : touchspin = +Utils.formatPriceForBE(this.touchspinValue?.value);

    if (!isNaN(touchspin)) {
      const decrementedValue = --touchspin;
      this.numberOutput.emit(Math.round((+decrementedValue + Number.EPSILON) * 100) / 100);
    }
  }
}
