import {Component, OnDestroy, OnInit} from '@angular/core';
import { ExportService } from "../../../services/export.service";
import { FilterUtils } from "../../utils/filter";
import { ActivatedRoute, Params } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { ExportResponse, SecureFile } from "../../dataModels/exportDataModels";
import { ResponseCode } from "../../enum/shared-enums";
import { AlertifyService } from "../../services/alertify-service.service";
import { Utils } from "../../utils/utils";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { SubscriptionComponent } from "../subscription/subscription.component";

@Component({
  selector: 'app-export-loading',
  templateUrl: './excel-export.component.html',
  styleUrls: ['./excel-export.component.scss']
})
export class ExcelExportComponent extends SubscriptionComponent implements OnInit, OnDestroy {
  requestParams: Params;
  downloadReady = false;
  errorCatch = false;
  file: SecureFile;
  exportFile: SafeUrl;

  constructor(private exportService: ExportService,
              private route: ActivatedRoute,
              private sanitizer: DomSanitizer,
              private alertifyService: AlertifyService) { super(); }

  ngOnInit(): void {
    this.requestParams = FilterUtils.getQueryParametersFromRoute(this.route);
    this.exportTable(this.requestParams.tableName);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private exportTable(tableName: string): void {
    this.exportService.exportTable(tableName, this.requestParams)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: ExportResponse) => {
        if (response.code == ResponseCode.SUCCESS) {
          this.file = Utils.snakeCaseToCamelCase(response.body);
          this.onDownload();
        } else {
          this.alertifyService.error(response.message);
        }
      }, () => {
          this.downloadReady = false;
          this.errorCatch = true;
      });
  }

  private onDownload(): void {
    this.exportService.downloadTable(this.file.fileUuid)
      .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: ArrayBuffer) => {
          const file = new File([response], this.file.fileName + '.xlsx', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = window.URL.createObjectURL(file);
          this.exportFile = this.sanitizer.bypassSecurityTrustUrl(url);
          this.downloadReady = true;
        }, () => {
          this.errorCatch = true;
        });
  }
}
