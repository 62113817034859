<h4 class="mb-3">Web Banner</h4>
<div *appLoading="!webBannerLoaded">
  <div class="col-lg-8 col-xl-6">
    <div class="row justify-content-around mb-2">
      <div class="card">
        <div class="card-img-top mt-2">
          <img class="card-img-top" [src]="selectedFileDesktopBlob" alt="desktop">
        </div>
        <div class="card-body">
          <h5 class="card-title"> Desktop Banner</h5>
          <app-file-upload buttonText="Bild hochladen" (file)="onDesktopFileUpload($event)"></app-file-upload>
          <div *ngIf="selectedFileDesktopBlob" class="row mt-1 mb-2">
            <a [href]="selectedFileDesktopBlob" target="_blank">
              <span class="me-2">Bild für {{ region?.name }} herunterladen</span>
              <i class="uil-external-link-alt"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="card">
        <img class="card-img-top mt-2" [src]="selectedFileMobileBlob" alt="mobile">
        <div class="card-body">
          <h5 class="card-title"> Mobile Banner</h5>
          <app-file-upload buttonText="Bild hochladen" (file)="onMobileFileUpload($event)"></app-file-upload>
          <div *ngIf="selectedFileMobileBlob" class="row mt-1 mb-2">
            <a [href]="selectedFileMobileBlob" target="_blank">
              <span class="me-2">Bild für {{ region?.name }} herunterladen</span>
              <i class="uil-external-link-alt"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <form [formGroup]="webBannerForm">
      <div class="row">
        <div class="col-md-6 mb-2">
          <label for="couponText" class="form-label fw-bolder">Titel</label>
          <input type="text" id="couponText" formControlName="couponText" class="form-control">
          <span *ngIf="couponText?.invalid && couponText?.touched"
            class="error-feedback mb-3">Der Titel darf maximal 10 Zeichen lang sein!
          </span>
        </div>
      </div>
      <div class="row">
        <div class="mb-2">
          <label for="text" class="form-label fw-bolder">Text</label>
          <textarea type="text" id="text" formControlName="text" class="form-control"></textarea>
          <span *ngIf="text?.invalid && text?.touched"
            class="error-feedback mb-3">Der Text darf maximal 65 Zeichen lang sein!
          </span>
        </div>
      </div>
      <div class="row">
        <div class="mb-2">
          <label for="footerText" class="form-label fw-bolder">Fußzeile</label>
          <textarea type="text" id="footerText" formControlName="footerText" class="form-control"></textarea>
          <span *ngIf="footerText?.invalid && footerText?.touched"
            class="error-feedback mb-3">Die Fußzeile darf maximal 120 Zeichen lang sein!
          </span>
        </div>
      </div>
    </form>
    <div class="d-flex justify-content-end mt-2 mt-md-3 mb-2 mb-md-3">
      <button type="button" class="cancel-button" (click)="clearData()">
        Abbrechen
      </button>
      <button type="submit"
              class="save-button ms-3"
              [disabled]="webBannerForm?.invalid || webBannerForm?.pristine"
              [class.active]="webBannerForm?.valid && webBannerForm?.touched"
              (click)="onPostBanner()">
                        <span *ngIf="pendingResponse"
                              class="spinner-border spinner-border-sm me-1"
                              role="status"
                              aria-hidden="true"></span>
        <span>Speichern</span>
      </button>
    </div>
  </div>
</div>
