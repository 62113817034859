<div class="accordion custom-accordion mb-3" [id]="'custom-accordion-' + statistic">
  <div class="card mb-0">
    <div class="card-header" [id]="'heading' + statistic">
      <h5 class="m-0">
        <i class="mdi mdi-chevron-down accordion-arrow custom-accordion-title me-3 mt-1"
           [ngClass]="{show: chevron, collapse: !chevron}"></i>
        <i class="mdi mdi-chevron-up accordion-arrow custom-accordion-title me-3 mt-1"
           [ngClass]="{show: !chevron, collapse: chevron}"></i>
        <a class="custom-accordion-title d-flex justify-content-between align-items-baseline py-1"
           data-bs-toggle="collapse" [href]="'#collapse' + statistic"
           (click)="chevron = !chevron"
           aria-expanded="true" [attr.aria-controls]="'collapse' + statistic">
          <div>{{ statistic }}</div>
        </a>
      </h5>
    </div>
  </div>
  <div [id]="'collapse' + statistic"
       class="collapse"
       [attr.aria-labelledby]="'heading' + statistic"
       [attr.data-bs-parent]="'#custom-accordion-' + statistic">
    <div class="card-body">
      <div class="loading-spinner" *ngIf="!error">
        <div *appLoading="!responseSuccessful">
          <div class="d-md-flex justify-content-start justify-content-xxxl-end align-items-center gap-2">
            <form [formGroup]="regionForm" class="d-md-flex justify-content-start col-md-6 gap-2">
              <div class="row col-xxxl-1 mb-3 mb-md-0">
                <div>
                  <label class="col-form-label" for="regionId">Region</label>
                  <select formControlName="regionId"
                          id="regionId"
                          class="form-select cursor-pointer me-3">
                    <option value="-1">Alle</option>
                    <option *ngFor="let region of regions" [value]="region.id">{{ region.name }}</option>
                  </select>
                </div>
              </div>
              <div class="col-xxxl-4">
                <button type="button" (click)="onRefreshList()" class="btn app-primary-button m-refresh-button">
                  <i class="mdi mdi-refresh cursor-pointer align-self-center mt-2"></i>
                  <span>Neu laden</span>
                </button>
              </div>
            </form>
            <div class="col-md-6 d-md-flex justify-content-end gap-4">
              <form [formGroup]="cardStatisticNameForm" class="col-lg-7 col-xxl-5 mb-2 mb-md-0">
                <div>
                  <label for="name" class="col-form-label">
                    Berichtname <span class="text-danger">*</span>
                  </label>
                  <input type="text" id="name" formControlName="name" class="form-control">
                  <span
                    *ngIf="!cardStatisticNameForm?.get('name')?.valid && cardStatisticNameForm?.get('name')?.touched"
                    class="error-feedback mb-3">Das Feld ist erforderlich!
                  </span>
                </div>
              </form>
              <app-filter #cardFilter
                          identifier="card-statistic-modal"
                          [initialClick]="initialClick"
                          triggerButton="Bericht erstellen"
                          [disableTriggerButton]="cardStatisticNameForm.get('name')?.invalid"
                          (openModal)="cardFilter.openModalWindow(); this.initialClick = false;">
                <app-card-statistic-filters #filtersComponent (filterValues)="onReceiveCardFilterData($event)">
                </app-card-statistic-filters>
              </app-filter>
            </div>
          </div>
          <!-- TABLE -->
          <div *ngIf="cardReports && cardReports.length > 0" class="table-responsive scroll-table mt-3">
            <table class="table table-striped table-centered mb-0">
              <thead class="table-light">
              <tr class="sticky-header-row">
                <th class="table-cell">ID</th>
                <th class="table-cell">Bericht</th>
                <th class="table-cell">Erstelldatum</th>
                <th class="table-cell">Erstellt von</th>
                <th class="table-cell">Kartenart</th>
                <th class="table-cell">Kartenzustand</th>
                <th class="table-cell">Kartenstatus</th>
                <th class="table-cell">Haptisch</th>
                <th class="table-cell">Kündigungsstatus</th>
                <th class="table-cell">Erstellt von</th>
                <th class="table-cell">Erstellt bis</th>
                <th class="table-cell">Startdatum von</th>
                <th class="table-cell">Startdatum bis</th>
                <th class="table-cell">Enddatum von</th>
                <th class="table-cell">Enddatum bis</th>
                <th class="table-cell">Kündigungseingang von</th>
                <th class="table-cell">Kündigungseingang bis</th>
                <th class="table-cell">Kündigungsrückname von</th>
                <th class="table-cell">Kündigungsrückname bis</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let cardReport of cardReports" class="bg-aqua">
                <td class="table-cell">{{ cardReport.id }}</td>
                <td class="table-cell text-nowrap">
                  <table class="w-100">
                    <tr class="d-flex justify-content-between">
                      <td>{{ cardReport.name }}</td>
                      <td>
                        <ng-container *ngIf="cardReport.fileUrl; else noLinkYet">
                          <a (click)="onDownloadReport(cardReport.fileUrl, cardReport.name)"
                             class="link-primary cursor-pointer">
                            <i class="mdi mdi-download-outline mdi-18px"></i>
                          </a>
                        </ng-container>
                        <a class="link-primary cursor-pointer ms-lg-2"
                           (click)="deleteReport.emit(cardReport)"
                           data-bs-toggle="modal"
                           data-bs-target="#danger-header-modal">
                          <i class="mdi mdi-trash-can mdi-18px"></i>
                        </a>
                        <ng-template #noLinkYet>
                          <span class="badge-in-progress p-1">In Bearbeitung...</span>
                        </ng-template>
                      </td>
                    </tr>
                  </table>
                </td>
                <td class="table-cell">{{ Utils.getLocalDateFromUtc(cardReport.createdAt) }}</td>
                <td class="table-cell">{{ cardReport.email }}</td>
                <td class="table-cell">
                  <ng-container *ngIf="cardReport.filters.cardType; else noCardType">
                    <ng-container *ngIf="cardReport.filters.duration">
                      {{ cardReport.filters.duration + ' ' + cardReport.filters.timeUnit }}
                    </ng-container>
                    <ng-container *ngIf="!cardReport.filters.duration && cardReport.filters.cardType !== 'Ersatzkarte'">
                      Abo-
                    </ng-container>
                    {{ cardReport.filters.cardType }}
                  </ng-container>
                  <ng-template #noCardType>&#8212;</ng-template>
                </td>
                <td class="table-cell">
                  <ng-container *ngIf="cardReport.filters.cardState; else noCardState">
                    {{ cardReport.filters.cardState }}
                  </ng-container>
                  <ng-template #noCardState>&#8212;</ng-template>
                </td>
                <td class="table-cell">
                  <ng-container *ngIf="cardReport.filters.cardStatus; else noCardStatus">
                    {{ cardReport.filters.cardStatus }}
                  </ng-container>
                  <ng-template #noCardStatus>&#8212;</ng-template>
                </td>
                <td class="table-cell">
                  <ng-container *ngIf="cardReport.filters.hasOwnProperty('isOnlyDigital'); else noIsOnlyDigital">
                    {{ !cardReport.filters.isOnlyDigital ? 'Ja' : 'Nein' }}
                  </ng-container>
                  <ng-template #noIsOnlyDigital>&#8212;</ng-template>
                </td>
                <td class="table-cell">
                  <ng-container *ngIf="cardReport.filters.cancellationStatus; else noCancellationStatus">
                    {{ cardReport.filters.cancellationStatus }}
                  </ng-container>
                  <ng-template #noCancellationStatus>&#8212;</ng-template>
                </td>
                <td class="table-cell">
                  <ng-container *ngIf="cardReport.filters.createdFrom; else noCreatedFrom">
                    {{ Utils.getLocalDateFromUtc(cardReport.filters.createdFrom) }}
                  </ng-container>
                  <ng-template #noCreatedFrom>&#8212;</ng-template>
                </td>
                <td class="table-cell">
                  <ng-container *ngIf="cardReport.filters.createdTo; else noCreatedTo">
                    {{ Utils.getLocalDateFromUtc(cardReport.filters.createdTo) }}
                  </ng-container>
                  <ng-template #noCreatedTo>&#8212;</ng-template>
                </td>
                <td class="table-cell">
                  <ng-container *ngIf="cardReport.filters.startDateFrom; else noStartDateFrom">
                    {{ Utils.getLocalDateFromUtc(cardReport.filters.startDateFrom) }}
                  </ng-container>
                  <ng-template #noStartDateFrom>&#8212;</ng-template>
                </td>
                <td class="table-cell">
                  <ng-container *ngIf="cardReport.filters.startDateTo; else noStartDateTo">
                    {{ Utils.getLocalDateFromUtc(cardReport.filters.startDateTo) }}
                  </ng-container>
                  <ng-template #noStartDateTo>&#8212;</ng-template>
                </td>
                <td class="table-cell">
                  <ng-container *ngIf="cardReport.filters.endDateFrom; else noEndDateFrom">
                    {{ Utils.getLocalDateFromUtc(cardReport.filters.endDateFrom) }}
                  </ng-container>
                  <ng-template #noEndDateFrom>&#8212;</ng-template>
                </td>
                <td class="table-cell">
                  <ng-container *ngIf="cardReport.filters.endDateTo; else noEndDateTo">
                    {{ Utils.getLocalDateFromUtc(cardReport.filters.endDateTo) }}
                  </ng-container>
                  <ng-template #noEndDateTo>&#8212;</ng-template>
                </td>
                <td class="table-cell">
                  <ng-container *ngIf="cardReport.filters.cancellationDateFrom; else noCancellationDateFrom">
                    {{ Utils.getLocalDateFromUtc(cardReport.filters.cancellationDateFrom) }}
                  </ng-container>
                  <ng-template #noCancellationDateFrom>&#8212;</ng-template>
                </td>
                <td class="table-cell">
                  <ng-container *ngIf="cardReport.filters.cancellationDateTo; else noCancellationDateTo">
                    {{ Utils.getLocalDateFromUtc(cardReport.filters.cancellationDateTo) }}
                  </ng-container>
                  <ng-template #noCancellationDateTo>&#8212;</ng-template>
                </td>
                <td class="table-cell">
                  <ng-container
                    *ngIf="cardReport.filters.cancellationRevokedDateFrom; else noCancellationRevokedDateFrom">
                    {{ Utils.getLocalDateFromUtc(cardReport.filters.cancellationRevokedDateFrom) }}
                  </ng-container>
                  <ng-template #noCancellationRevokedDateFrom>&#8212;</ng-template>
                </td>
                <td class="table-cell">
                  <ng-container *ngIf="cardReport.filters.cancellationRevokedDateTo; else noCancellationRevokedDateTo">
                    {{ Utils.getLocalDateFromUtc(cardReport.filters.cancellationRevokedDateTo) }}
                  </ng-container>
                  <ng-template #noCancellationRevokedDateTo>&#8212;</ng-template>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="cardReports && cardReports.length === 0" class="mt-3">Keine Daten vorhanden.</div>
        </div>
      </div>
    </div>
  </div>
</div>
