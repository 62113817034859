<app-embossments [embossmentData]="embossmentData"
                 [embossments]="embossments"
                 [requestParams]="requestParams"
                 tableTitle="Prägeliste Bestellungen"
                 [tableHeaders]="tableHeaders"
                 [responseSuccessful]="responseSuccessful"
                 (refreshList)="onRefreshList()"
                 (emitPaginationData)="onLoadPaginationData($event)"
                 [error]="error"
></app-embossments>
