<div class="accordion custom-accordion mb-3" id="custom-accordion-four">
  <div class="card mb-0">
    <div class="card-header" id="headingFour">
      <h5 class="m-0">
        <i class="mdi mdi-chevron-down accordion-arrow custom-accordion-title me-3 mt-1"
           [ngClass]="{show: chevron, collapse: !chevron}"></i>
        <i class="mdi mdi-chevron-up accordion-arrow custom-accordion-title me-3 mt-1"
           [ngClass]="{show: !chevron, collapse: chevron}"></i>
        <a class="custom-accordion-title d-flex justify-content-between align-items-baseline py-1"
           data-bs-toggle="collapse" href="#collapseFour"
           (click)="chevron = !chevron"
           aria-expanded="true" aria-controls="collapseFour">
          <div>
            <i class="mdi mdi-drag mdi-18px cursor-pointer me-3"></i>
            Inkasso Vorschlag
          </div>
          <div *ngIf="ordersData?.data?.length" class="items-length me-3">+ {{ ordersData?.data?.length }}</div>
        </a>
      </h5>
    </div>
  </div>
  <div id="collapseFour"
       class="collapse"
       aria-labelledby="headingFour"
       data-bs-parent="#custom-accordion-four">
    <div class="card-body">
      <div class="loading-spinner" *ngIf="!error">
        <div *appLoading="!responseSuccessful">
          <ng-container *ngIf="orders.length > 0">
            <div class="d-xxl-flex justify-content-between" [class.d-md-flex]="orders.length < this.perPage">
              <form [formGroup]="encashmentFilterForm" class="d-md-flex justify-content-start col-xxxl-6 gap-2">
                <div class="col-xxxl-4 mb-3">
                  <label class="col-form-label" for="regionId">Region</label>
                  <select formControlName="region"
                          id="regionId"
                          class="form-select cursor-pointer me-3">
                    <option value="-1">Alle</option>
                    <option *ngFor="let region of regions" [value]="region.id">{{ region?.name }}</option>
                  </select>
                </div>
                <div class="col-xxxl-4">
                  <button type="button" (click)="onRefreshList()" class="btn app-primary-button m-refresh-button">
                    <i class="mdi mdi-refresh cursor-pointer align-self-center mt-2"></i>
                    <span>Neu laden</span>
                  </button>
                </div>
              </form>
              <div class="d-md-flex justify-content-start justify-content-xxxl-end align-items-center gap-2">
                <div class="mt-2">
                  <button type="button"
                          class="btn save-button text-nowrap"
                          [class.m-create-button]="orders.length < perPage"
                          [class.active]="selectedOrders.length > 0 && !waitingForResponse"
                          [disabled]="selectedOrders.length === 0 || waitingForResponse"
                          (click)="onCreateEncashmentList()">
                <span *ngIf="waitingForResponse"
                      class="spinner-border spinner-border-sm me-1"
                      role="status"
                      aria-hidden="true"></span>
                    Inkasso Übergang
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- TABLE -->
          <div *ngIf="searchResults" class="table-responsive db-table-width mt-3">
            <table class="table table-striped table-centered mb-0">
              <thead class="table-light">
              <tr>
                <th class="all table-cell">
                  <input type="checkbox"
                         #selectAllCheckbox
                         class="form-check-input custom-xl-checkbox cursor-pointer"
                         (change)="onCheckAllOrders($event)"
                         [checked]="allOrdersSelected">
                </th>
                <th class="table-cell">Bestellung</th>
                <th class="table-cell">Bestellstatus</th>
                <th class="table-cell">Zahlstatus</th>
                <th class="table-cell">Zahlungsmethode</th>
                <th class="table-cell">Saldo</th>
                <th class="table-cell">Erstellt</th>
                <th class="table-cell">Region</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let order of orders; index as i" class="bg-aqua">
                <td class="table-cell">
                  <input type="checkbox"
                         [checked]="selectedOrders.includes(order.id)"
                         (change)="onSelectOrder(order.id)"
                         class="form-check-input custom-xl-checkbox cursor-pointer">
                </td>
                <td class="text-body fw-bold">
                  <a [routerLink]="['/dashboard/orders', order.id]"
                     class="neutral-anchor"
                     queryParamsHandling="merge">
                    {{ order.id }}
                  </a>
                </td>
                <td><a [routerLink]="['/dashboard/orders', order.id]"
                       class="neutral-anchor"
                       queryParamsHandling="merge">
                  {{ order?.orderStatus }}
                </a></td>
                <td>
                  <a [routerLink]="['/dashboard/orders', order.id]"
                     class="neutral-anchor"
                     queryParamsHandling="merge">
                    <div>
                      <span>{{ order?.paymentStatus }}</span>
                    </div>
                  </a>
                </td>
                <td><a [routerLink]="['/dashboard/orders', order.id]"
                       class="neutral-anchor"
                       queryParamsHandling="merge">
                  {{ order?.paymentMethod }}
                </a></td>
                <td><a [routerLink]="['/dashboard/orders', order.id]"
                       class="neutral-anchor"
                       queryParamsHandling="merge">
                  {{ order?.receivable }}
                </a></td>
                <td><a [routerLink]="['/dashboard/orders', order.id]"
                       class="neutral-anchor"
                       queryParamsHandling="merge">
                  {{ order?.createdAt | date:"dd.MM.yyyy" }}
                </a></td>
                <td><a [routerLink]="['/dashboard/orders', order.id]"
                       class="neutral-anchor"
                       queryParamsHandling="merge">
                  {{ order?.regionName }}
                </a></td>
              </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="!searchResults" class="mt-3">Keine Daten vorhanden.</div>
        </div>
      </div>
    </div>
  </div>
</div>
