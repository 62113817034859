import { LOCALE_ID, NgModule } from '@angular/core';
import localeDe from '@angular/common/locales/de';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MainDashboardComponent } from "./main-dashboard/main-dashboard.component";
import { DashboardRoutingModule } from "./main-dashboard/dashboard-routing.module";
import { LoginRoutingModule } from "./authentication/login/login-routing.module";
import { AuthenticationGuard } from "./authentication/authentication.guard";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AuthenticationInterceptor } from "./authentication/authentication.interceptor";
import { HomeDashboardComponent } from "./home-dashboard/home-dashboard.component";
import { SharedModule } from "./shared.module";
import { AuthenticationModule } from "./authentication/authentication.module";
import { VouchersComponent } from "./vouchers/vouchers.component";
import { SettingsComponent } from './settings/settings.component';
import { WebBannerComponent } from './settings/web-banner/web-banner.component';
import { DeploymentInfoComponent } from "./shared/components/deployment-info/deployment-info.component";
import { ClickElsewhereDirective } from "./shared/directives/click-elsewhere.directive";
import { DragulaModule } from "ng2-dragula";
import { DashboardItemComponent } from './home-dashboard/dashboard-item/dashboard-item.component';
import { OrdersForEncashmentComponent } from './home-dashboard/orders-for-encashment/orders-for-encashment.component';
import { EncashmentsComponent } from './home-dashboard/encashments/encashments.component';
import { TroubleshootingComponent } from './home-dashboard/troubleshooting/troubleshooting.component';
import { registerLocaleData } from "@angular/common";
import { ReportingComponent } from "./reporting/reporting.component";
import { SalesStatisticComponent } from './reporting/sales-statistic/sales-statistic.component';
import { CardStatisticComponent } from './reporting/card-statistic/card-statistic.component';
import { BlockingTimeSettingsComponent } from './settings/blocking-time-settings/blocking-time-settings.component';

registerLocaleData(localeDe);

@NgModule({
            declarations: [
              AppComponent,
              MainDashboardComponent,
              VouchersComponent,
              HomeDashboardComponent,
              SettingsComponent,
              WebBannerComponent,
              DeploymentInfoComponent,
              ClickElsewhereDirective,
              DashboardItemComponent,
              OrdersForEncashmentComponent,
              EncashmentsComponent,
              TroubleshootingComponent,
              ReportingComponent,
              SalesStatisticComponent,
              CardStatisticComponent,
              BlockingTimeSettingsComponent,
            ],
            imports: [
              BrowserModule,
              AppRoutingModule,
              AuthenticationModule,
              LoginRoutingModule,
              DashboardRoutingModule,
              FormsModule,
              ReactiveFormsModule,
              SharedModule,
              HttpClientModule,
              DragulaModule.forRoot()
            ],
            providers: [
              AuthenticationGuard,
              {
                provide: HTTP_INTERCEPTORS,
                useClass: AuthenticationInterceptor,
                multi: true
              },
              {
                provide: LOCALE_ID,
                useValue: 'de-DE'
              }
            ],
            exports: [ClickElsewhereDirective],
            bootstrap: [AppComponent]
          })
export class AppModule {
}
