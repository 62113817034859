<app-breadcrumbs (navigateTo)="navigateTo($event)"></app-breadcrumbs>
<div class="loading-spinner">
  <div *appLoading="!historyLoaded">
    <h3>Aktionshistorie</h3>

    <div class="table-responsive">
      <table class="table table-striped mb-0">
        <thead class="table-light">
        <tr>
          <th class="border-bottom-0 table-cell">Aktion</th>
          <th class="border-bottom-0 table-cell">Durchgeführt von</th>
          <th class="border-bottom-0 table-cell">Daten</th>
          <th class="border-bottom-0 table-cell">Datum</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let history of actionHistory"
            class="bg-aqua">
          <td *ngIf="history.description" class="table-cell">{{ history?.description }}</td>
          <td *ngIf="!history.description" class="table-cell">{{ history?.action }}</td>
          <td class="table-cell">
            <a *ngIf="history.changedBy; else autoChanged"
               [routerLink]="['/dashboard/users', history?.changedBy?.id ]"
               queryParamsHandling="merge">
              <span class="hover-underline">{{ history.changedBy.email }}</span>
              <span> - </span>
              <span>{{ history?.changedBy?.id }} <i class="uil-external-link-alt"></i></span>
            </a>
            <ng-template #autoChanged>
              <span class="text-decoration-none">&#8212;</span>
            </ng-template>
          </td>
          <td class="table-cell">
            <i class="mdi mdi-eye-outline mdi-18px cursor-pointer"
               (click)="onDisplayData(history.data)"
               data-bs-toggle="modal"
               data-bs-target="#bs-example-modal-lg"></i>
          </td>
          <td class="table-cell">{{ history?.createdAt | date: 'dd.MM.yyyy - HH:mm' }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div class="modal fade"
     id="bs-example-modal-lg"
     tabindex="-1"
     role="dialog"
     aria-labelledby="myLargeModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myLargeModalLabel">Daten</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
      </div>
      <div class="modal-body">
        <pre>{{ dataToDisplay | json }}</pre>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
