<div class="form-group row">
  <div class="col-md-3 align-self-center">
    <div class="form-check form-switch">
      <input type="checkbox"
             class="form-check-input"
             data-bs-toggle="modal"
             data-bs-target="#warning-header-modal-status"
             [checked]="status === Status.ACTIVE"
             (click)="onPreventSwitchToggle($event)">
      <label class="form-check-label">Aktiv</label>
    </div>
  </div>
</div>
<!-- M O D A L  B O D Y -->
<div id="warning-header-modal-status" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="warning-header-modalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header modal-colored-header bg-warning">
        <h4 class="modal-title" id="warning-header-modalLabel">
          <ng-content select="[header]"></ng-content>
        </h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
      </div>
      <div class="modal-body">
      <ng-content select="[body]"></ng-content>
      </div>
      <div class="modal-footer">
        <button type="button"
                class="btn btn-light"
                data-bs-dismiss="modal">
          <span>Nein, zurück</span>
        </button>
        <button type="button"
                class="btn btn-warning"
                data-bs-dismiss="modal"
                (click)="onSubmit()">
          <span>Ja, status {{ status === Status.ACTIVE ? 'deaktivieren' : 'aktivieren' }}</span>
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
