import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Observable } from "rxjs";
import { BreadcrumbService } from "../../../services/breadcrumb.service";
import { Params } from "@angular/router";

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  breadcrumbs$: Observable<BreadcrumbData[]>;
  @Output() navigateTo = new EventEmitter<BreadcrumbData>();

  constructor(private breadcrumbService: BreadcrumbService) { }

  ngOnInit(): void {
    this.breadcrumbs$ = this.breadcrumbService.emittedBreadcrumbs;
  }

  onNavigate(breadcrumb: BreadcrumbData) {
    this.navigateTo.emit(breadcrumb);
  }
}

export interface BreadcrumbData {
  pageName: string
  routerLink: string
  queryParams: Params
}
