import { Component, ComponentFactoryResolver, Input, OnInit, Type, ViewChild } from '@angular/core';
import { ComponentInjectorDirective } from "../../shared/directives/component-injector.directive";

@Component({
             selector: 'app-dashboard-item',
             templateUrl: './dashboard-item.component.html',
             styleUrls: ['./dashboard-item.component.scss']
           })
export class DashboardItemComponent implements OnInit {
  @Input() loadComponent: Type<any>;
  @ViewChild(ComponentInjectorDirective, { static: true }) injectHost: ComponentInjectorDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit(): void {
    this.initializeComponentFactory();
  }

  private initializeComponentFactory() {
    const compFactory = this.componentFactoryResolver.resolveComponentFactory(this.loadComponent);
    this.injectHost.viewContainerRef.createComponent(compFactory);
  }
}
