import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthenticationService } from "../../services/authentication.service";
import { take } from "rxjs/operators";
import { SimpleResponse } from "../../shared/dataModels/sharedDataModels";
import { ResponseCode } from "../../shared/enum/shared-enums";
import { AlertifyService } from "../../shared/services/alertify-service.service";
import { Router } from "@angular/router";

@Component({
             selector: 'app-forgot-password',
             templateUrl: './forgot-password.component.html',
             styleUrls: ['./forgot-password.component.scss']
           })
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  currentYear = new Date().getFullYear();

  constructor(private formBuilder: FormBuilder,
              private authenticationService: AuthenticationService,
              private alertifyService: AlertifyService,
              private router: Router) { }

  ngOnInit(): void {
    this.forgotPasswordForm = this.formBuilder.group({
                                                      email: ['', [Validators.required]]
                                                    });
  }

  onRequestPasswordChange(): void {
    this.authenticationService.sendChangePasswordRequest(this.forgotPasswordForm.get('email')?.value)
      .pipe(take(1))
      .subscribe((response: SimpleResponse) => {
        if (response.code === ResponseCode.SUCCESS) {
          this.authenticationService.emitEmail(this.forgotPasswordForm.get('email')?.value);
          this.router.navigate(['/confirm-email']);
        } else {
          this.alertifyService.warning(response.message);
        }
      });
  }
}
