import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { LoginRegion, UserResponseData } from "../../../dataModels/userDataModel";
import { Router } from "@angular/router";

@Component({
  selector: 'app-default-region-selection-modal',
  templateUrl: './default-region-selection-modal.component.html',
  styleUrls: ['./default-region-selection-modal.component.scss']
})
export class DefaultRegionSelectionModalComponent implements OnInit {
  @Input() showModal: boolean;
  @Input() user: UserResponseData;
  @Input() selectedDefaultRegion: LoginRegion;
  @Output() _onSelectDefaultRegion = new EventEmitter<LoginRegion>();
  @Output() closeModal = new EventEmitter();
  selectedRegion: LoginRegion;
  selectedRegionName: string;

  constructor(public router: Router) { }

  ngOnInit(): void {
    this.selectedRegion = this.selectedDefaultRegion;
  }

  onSaveSelectedDefaultRegion() {
    if (!this.selectedRegion) {
      return;
    }

    this._onSelectDefaultRegion.emit(this.selectedRegion)
  }

  onSelectDefaultRegion(regionName: string) {
    // @ts-ignore
    this.selectedRegion = this.user?.loginRegions?.find((defaultRegion: LoginRegion) => {
      return defaultRegion.name === regionName
    });
  }

  onClose() {
    this.closeModal.emit();
  }
}
