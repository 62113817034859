import {Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { PaginationData } from "../../dataModels/paginationDataModel";
import { MemosService } from "../../../services/memos.service";
import { Utils } from "../../utils/utils";
import { takeUntil } from "rxjs/operators";
import { AllMemosResponse, Memo } from "../../dataModels/memosDataModel";
import { FollowUp } from "../../enum/memos";
import { BreadcrumbData } from "../breadcrumbs/breadcrumbs.component";
import { HttpErrorResponse } from "@angular/common/http";
import { ErrorStatus } from "../../enum/error-status";
import { SubscriptionComponent } from "../subscription/subscription.component";
import { CRUDMode } from "../../enum/shared-enums";
import { AppGlobalService } from "../../services/app-global.service";

@Component({
             selector: 'app-detailed-memos',
             templateUrl: './detailed-memos.component.html',
             styleUrls: ['./detailed-memos.component.scss']
           })
export class DetailedMemosComponent extends SubscriptionComponent implements OnInit, OnDestroy {
  memosData: PaginationData;
  memos: Memo[] = [];
  id: number;
  orderId: number;
  routeOrigin: string;
  MemoModes = CRUDMode;
  FollowUp = FollowUp;
  memosLoaded = true;
  responseSuccessful = false;

  constructor(private memosService: MemosService,
              private globalService: AppGlobalService,
              private router: Router,
              private route: ActivatedRoute) { super() }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.routeOrigin = this.route.snapshot.queryParams['origin'];
    this.routeOrigin === 'customers' ? this.getCustomerMemos() : this.getOrderMemos();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private getCustomerMemos(): void {
    this.memosService.getCustomerMemos(this.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: AllMemosResponse) => {
        this.memosData = response.body;
        this.memos = Utils.snakeCaseToCamelCase(response.body?.pagination.data);
        this.showMemos(response);
      }, (error: HttpErrorResponse) => {
        if (error.status === ErrorStatus.STATUS_500) {
          this.globalService.emitServerError(true);
        }
      });
  }

  private getOrderMemos(): void {
    this.memosService.getOrderMemos(this.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: AllMemosResponse) => {
        this.memosData = response.body;
        this.memos = Utils.snakeCaseToCamelCase(response.body?.pagination.data);
        this.showMemos(response);
      }, (error: HttpErrorResponse) => {
         if (error.status === ErrorStatus.STATUS_500) {
           this.globalService.emitServerError(true);
        }
      });
  }

  private showMemos(response: AllMemosResponse) {
    this.responseSuccessful = true;
    this.memosLoaded = response.body.pagination.data.length > 0;
  }

  getPaginatedData(paginatedMemos: any): void {
    this.memosData = paginatedMemos.body;
    this.memos = paginatedMemos.body.pagination.data;
  }

  navigateTo(breadcrumb: BreadcrumbData) {
    this.router.navigate(['/' + breadcrumb.routerLink],
                         {queryParams: {'region': Utils.getRegionFromLocalStorage()}});
  }
}
