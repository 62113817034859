import { PaginationData } from "./paginationDataModel";
import { CurrencyCode } from "../enum/products";
import { Filter } from "./sharedDataModels";
import { Status } from "../enum/status";

export interface AllVouchersResponse {
  code: number;
  message: string;
  body: PaginationData;
}

export interface Voucher {
  id: number;
  orderId: number;
  orderItemId: number;
  productId: number;
  priceId: number;
  regionId: number;
  applicationOrderId: number;
  sendAsGift: boolean;
  code: string;
  amount: string;
  fee: string;
  status: Status;
  operationStatus: Status;
  currencyCode: CurrencyCode;
  startDate: Date;
  endDate: Date;
  appliedAt: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface VoucherPayload {
  customerId: number;
  productId: number;
  voucherCount: number;
  adjustedNetPrice: string;
  alsoPhysical: boolean;
  sendAsGift: boolean;
}

export const VoucherFilters: Filter[] = [
  {
    title: 'Gültigkeitsende',
    formName: 'endDate',
    inputType: 'text',
    placeholder: 'dd.mm.yyyy',
    isDate: true
  },
  {
    title: 'Wert von',
    formName: 'amountFrom',
    inputType: 'text',
    placeholder: '€29.99',
    isDate: false
  },
  {
    title: 'Wert bis',
    formName: 'amountTo',
    inputType: 'text',
    placeholder: '€49.99',
    isDate: false
  },
  {
    title: 'Erstellung von',
    formName: 'createdFrom',
    inputType: 'text',
    isDate: true
  },
  {
    title: 'Erstellung bis',
    formName: 'createdTo',
    inputType: 'text',
    isDate: true
  },
  {
    title: 'Einlösedatum von',
    formName: 'appliedFrom',
    inputType: 'text',
    isDate: true
  },
  {
    title: 'Einlösedatum bis',
    formName: 'appliedTo',
    inputType: 'text',
    isDate: true
  },
   {
    title: 'Status',
    formName: 'operationStatus',
    options: [
      {
        label: 'Alle',
        value: null
      },
      {
        label: 'Aktiv',
        value: Status.ACTIVE
      },
      {
        label: 'Inaktiv',
        value: Status.INACTIVE
      }
    ]
  },
];
