import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Order, OrdersData } from "../../shared/dataModels/ordersDataModel";
import { Region } from "../../shared/dataModels/regionDataModels";
import { Params } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Utils } from "../../shared/utils/utils";
import { LocalStorageKeys, ResponseCode } from "../../shared/enum/shared-enums";
import { OrdersService } from "../../services/orders.service";
import { SubscriptionComponent } from "../../shared/components/subscription/subscription.component";
import { filter, takeUntil, tap } from "rxjs/operators";
import { AppGlobalService } from "../../shared/services/app-global.service";
import { OrderStatus, PaymentStatus } from "../../shared/enum/orders";
import { SimpleResponse } from "../../shared/dataModels/sharedDataModels";
import { AlertifyService } from "../../shared/services/alertify-service.service";
import { combineLatest } from "rxjs";

@Component({
             selector: 'app-orders-for-encashment',
             templateUrl: './orders-for-encashment.component.html',
             styleUrls: ['./orders-for-encashment.component.scss']
           })
export class OrdersForEncashmentComponent extends SubscriptionComponent implements OnInit, OnDestroy {
  @ViewChild('selectAllCheckbox') headerCheckbox: ElementRef;
  ordersData: OrdersData;
  orders: Order[] = [];
  regions: Region[];
  regionId = JSON.parse(Utils.getItemFromLocalStorage(LocalStorageKeys.REGION)).id;
  encashmentFilterForm: FormGroup;
  selectedOrders: number[] = [];
  requestParams: Params;
  PaymentStatus = PaymentStatus;
  responseSuccessful = false;
  perPage = 10;
  waitingForResponse = false;
  searchResults = true;
  allOrdersSelected = false;
  chevron = true;
  error = false;

  constructor(private formBuilder: FormBuilder,
              private ordersService: OrdersService,
              private globalService: AppGlobalService,
              private alertifyService: AlertifyService) { super() }

  ngOnInit(): void {
    this.requestParams = {
      orderStatus: OrderStatus.COLLECTION_PROPOSAL,
      perPage: this.perPage,
      regionId: this.regionId,
      orderDirection: 'desc',
      orderBy: 'created_at',
      withoutPagination: 1
    };
    this.initializeEncashmentFiltersForm();
    this.getTableData();
    this.watchFilter();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private initializeEncashmentFiltersForm() {
    this.encashmentFilterForm = this.formBuilder.group({
                                                         region: [this.regionId]
                                                       });
  }

  private getTableData(): void {
    combineLatest([this.ordersService.getOrders(this.requestParams), this.globalService.regions$])
      .pipe(
        tap(([ordersResponse]) => {
          this.responseSuccessful = true;
          if (ordersResponse.code === ResponseCode.FAILED) {
            this.alertifyService.warning(ordersResponse.message);
          }
        }),
        filter(([, regionsResponse]) => Object.keys(regionsResponse).length > 0),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(([ordersResponse, regionsResponse]) => {
        if (ordersResponse.code === ResponseCode.SUCCESS) {
          if ('data' in ordersResponse.body) {
            this.ordersData = ordersResponse.body;
            this.orders = Utils.snakeCaseToCamelCase(ordersResponse.body.data);
          } else {
            this.ordersData = ordersResponse.body.pagination;
            this.orders = Utils.snakeCaseToCamelCase(ordersResponse.body?.pagination?.data);
          }
          this.regions = Utils.snakeCaseToCamelCase(regionsResponse.body?.pagination.data);
          this.searchResults = this.orders.length > 0;
          this.assignRegionName();
          this.checkIfAllCheckboxSelected();
          this.setCheckboxIndeterminateState();
        }
      }, () => this.error = true);
  }

  private assignRegionName() {
    this.orders.map((order: Order) => {
      const region = this.regions.find((region: Region) => {
        return region.id === order.regionId;
      });
      if (region) {
        order.regionName = region.name;
      }
      return order;
    });
  }

  private watchFilter(): void {
    this.encashmentFilterForm?.valueChanges.subscribe((value) => {
      this.requestParams.regionId = value.region;
      this.onRefreshList();
    });
  }

  private setCheckboxIndeterminateState() {
    if (this.headerCheckbox) {
      this.headerCheckbox.nativeElement.indeterminate = Utils.setCheckboxIndeterminateState(
        this.allOrdersSelected,
        this.orders,
        this.selectedOrders
      );
    }
  }

  private checkIfAllCheckboxSelected(): void {
    this.allOrdersSelected = Utils.areAllCheckboxesSelected(this.selectedOrders, this.orders);
  }

  onSelectOrder(id: number) {
    if (this.selectedOrders?.includes(id)) {
      const index = this.selectedOrders.indexOf(id);
      this.selectedOrders.splice(index, 1);
      this.allOrdersSelected = false;
      this.setCheckboxIndeterminateState();
    } else {
      this.selectedOrders?.push(id);
      this.checkIfAllCheckboxSelected();
      this.headerCheckbox.nativeElement.indeterminate = !this.allOrdersSelected;
    }
  }

  onCheckAllOrders(mainCheckbox: any) {
    if (mainCheckbox.target.checked) {
      this.orders.forEach((order: Order) => {
        if (!this.selectedOrders.includes(order.id)) {
          this.selectedOrders.push(order.id);
        }
        this.allOrdersSelected = true;
      });
    } else {
      this.orders.forEach((order: Order) => {
        const index = this.selectedOrders.indexOf(order.id);
        this.selectedOrders.splice(index, 1);
      });
    }
  }

  onCreateEncashmentList() {
    this.waitingForResponse = true;
    this.ordersService.transferInkassoOrders(this.selectedOrders)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: SimpleResponse) => {
          this.waitingForResponse = false;
          if (response.code === ResponseCode.SUCCESS) {
            this.ordersService.emitNewEncashment(true);
            this.alertifyService.success(response.message);
            this.onRefreshList();
          } else {
            this.alertifyService.warning(response.message);
          }
        }, () => {
          this.waitingForResponse = false;
        });
  }

  onRefreshList() {
    this.responseSuccessful = false;
    this.getTableData();
    this.selectedOrders = [];
  }
}
