<div class="d-flex gap-3 mb-3">
  <h3>{{ title }}</h3>
  <app-filter class="align-self-center"
              (click)="onLoadFilterData()"
              [filtersApplied]="filtersApplied"
              [initialClick]="initialClick">
    <ng-template appComponentInjector></ng-template>
  </app-filter>
  <i *ngIf="exportItemUrl"
     [routerLink]="exportItemUrl"
     [queryParams]="queryParams"
     queryParamsHandling="merge"
     class="mdi mdi-download-circle mdi-24px d-lg-none create-user-button align-self-center icon-color"></i>
  <i *ngIf="createItemUrl"
     [routerLink]="createItemUrl"
     [queryParams]="queryParams"
     queryParamsHandling="merge"
     class="mdi mdi-plus-circle mdi-24px d-lg-none create-user-button align-self-center icon-color"></i>
  <span *ngIf="totalItems" class="items-length d-lg-none fw-bold me-3 mt-2">
    Anzahl: {{ totalItems }}
  </span>
</div>

<form [formGroup]="searchForm" (ngSubmit)="onSearch()">
  <div class="app-search d-flex justify-content-between gap-1 search-background">
    <input type="text"
           class="form-control-custom"
           formControlName="searchInput"
           required
           [placeholder]="placeholder">
    <div class="align-self-center search-clear-button ms-1">
      <i class="mdi mdi-close-thick cursor-pointer" (click)="onClearSearch()"
         [hidden]="!searchInput?.value"></i>
    </div>
    <button type="button"
            class="btn cursor-pointer"
            [disabled]="!searchInput?.value"
            (click)="onSearch()">
      <i class="mdi mdi-magnify mdi-18px align-self-center"></i>
    </button>
  </div>
</form>
