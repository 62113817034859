import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItem } from "../../dataModels/contextMenuDataModel";
import { OrdersService } from "../../../services/orders.service";
import { SubscriptionComponent } from "../subscription/subscription.component";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent extends SubscriptionComponent {
  @Input() items: MenuItem[];
  @Output() emitItem = new EventEmitter<MenuItem>();
  hover = false;

  constructor(private ordersService: OrdersService) {
    super();
    this.items = [];
  }

  onEmitItem(value: MenuItem) {
    if (!value.subMenu) {
      this.emitItem.emit(value);
    }
  }

  onDownload(fileUrl: string, invoiceId: string) {
    if (fileUrl) {
      this.ordersService.downloadFile(fileUrl)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: ArrayBuffer) => {
          const file = new File([response], 'Rechnung', { type: 'application/pdf' });
          const url = window.URL.createObjectURL(file);
          let fileLink = document.createElement('a');
          fileLink.href = url;
          fileLink.download = invoiceId + '.pdf';
          fileLink.setAttribute('target', '_blank');
          fileLink.click();
        });
    }
  }
}
