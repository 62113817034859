import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EmbossmentsCreatedResponse, Order, StringArrayResponse } from "../../../shared/dataModels/ordersDataModel";
import { OrdersService } from "../../../services/orders.service";
import { filter, takeUntil, tap } from "rxjs/operators";
import { Utils } from "../../../shared/utils/utils";
import { Params } from "@angular/router";
import { LocalStorageKeys, ResponseCode } from "../../../shared/enum/shared-enums";
import { Region } from "../../../shared/dataModels/regionDataModels";
import { AlertifyService } from "../../../shared/services/alertify-service.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AppGlobalService } from "../../../shared/services/app-global.service";
import { SubscriptionComponent } from "../../../shared/components/subscription/subscription.component";
import { DisplayOrderType } from "../../../shared/enum/orders";
import { combineLatest } from "rxjs";

@Component({
             selector: 'app-orders-for-embossment',
             templateUrl: './orders-for-embossment.component.html',
             styleUrls: ['./orders-for-embossment.component.scss']
           })
export class OrdersForEmbossmentComponent extends SubscriptionComponent implements OnInit, OnDestroy {
  @ViewChild('selectAllCheckbox') headerCheckbox: ElementRef;
  orders: Order[] = [];
  embossmentFilterForm: FormGroup;
  regions: Region[];
  regionId: number;
  selectedOrders: number[] = [];
  embossmentValues: string[];
  requestParams: Params;
  allOrdersSelected = false;
  responseSuccessful = false;
  waitingForResponse = false;
  perPage = 5;
  chevron = true;
  error = false;
  OrderType = DisplayOrderType;
  orderTypes: any = {};

  constructor(private ordersService: OrdersService,
              private formBuilder: FormBuilder,
              private globalService: AppGlobalService,
              private alertifyService: AlertifyService) { super() }

  ngOnInit(): void {
    this.regionId = JSON.parse(Utils.getItemFromLocalStorage(LocalStorageKeys.REGION)).id;
    this.requestParams = {
      availableForEmbossment: 'Alle',
      orderDirection: 'desc',
      orderBy: 'created_at',
      withoutPagination: 1,
      regionId: this.regionId,
      perPage: this.perPage
    };
    this.initializeEmbossmentFiltersForm();
    this.getTableData();
    this.getOrderTypes();
    this.getEmbossmentValues();
    this.watchAvailableForEmbossment();
    this.watchRegion();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private getTableData(): void {
    combineLatest([this.ordersService.getOrders(this.requestParams), this.globalService.regions$])
      .pipe(
        tap(([ordersResponse]) => {
          this.responseSuccessful = true;
          if (ordersResponse.code === ResponseCode.FAILED) {
            this.alertifyService.warning(ordersResponse.message);
          }
        }),
        filter(([, regionsResponse]) => Object.keys(regionsResponse).length > 0),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(([ordersResponse, regionsResponse]) => {
        if (ordersResponse.code === ResponseCode.SUCCESS) {
          if ('data' in ordersResponse.body) {
            this.orders = Utils.snakeCaseToCamelCase(ordersResponse.body?.data);
          } else {
            this.orders = Utils.snakeCaseToCamelCase(ordersResponse.body?.pagination?.data);
          }
          this.regions = Utils.snakeCaseToCamelCase(regionsResponse.body?.pagination.data);
          this.assignRegionName();
          this.checkIfAllCheckboxSelected();
          this.setCheckboxIndeterminateState();
        }
      }, () => this.error = true);
  }

  private getOrderTypes() {
    Utils.enumKeys(DisplayOrderType).forEach((value) => {
      this.orderTypes[value] = DisplayOrderType[value];
    });
  }

  private initializeEmbossmentFiltersForm() {
    this.embossmentFilterForm = this.formBuilder.group({
                                                         availableForEmbossment: ['Alle'],
                                                         region: [this.regionId]
                                                       });
  }

  get availableForEmbossment() {
    return this.embossmentFilterForm.get('availableForEmbossment');
  }

  get region() {
    return this.embossmentFilterForm.get('region');
  }

  private getEmbossmentValues(): void {
    this.ordersService.getEmbossmentValues()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: StringArrayResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            this.embossmentValues = response.body.data;
          } else {
            this.alertifyService.warning(response.message);
          }
        });
  }

  private watchAvailableForEmbossment(): void {
    this.availableForEmbossment?.valueChanges.subscribe((value) => {
      this.requestParams.availableForEmbossment = value;
      this.onRefreshList();
    });
  }

  private watchRegion(): void {
    this.region?.valueChanges.subscribe((value) => {
      this.requestParams.regionId = value;
      this.onRefreshList();
    });
  }

  private assignRegionName() {
    this.orders.map((order: Order) => {
      const region = this.regions.find((region: Region) => {
        return region.id === order.regionId;
      });
      if (region) {
        order.regionName = region.name;
      }
      return order;
    });
  }
  onSelectOrder(orderId: number) {
    if (this.selectedOrders?.includes(orderId)) {
      const index = this.selectedOrders.indexOf(orderId);
      this.selectedOrders.splice(index, 1);
      this.allOrdersSelected = false;
      this.setCheckboxIndeterminateState();
    } else {
      this.selectedOrders.push(orderId);
      this.checkIfAllCheckboxSelected();
      this.headerCheckbox.nativeElement.indeterminate = !this.allOrdersSelected;
    }
  }

  onCheckAllOrders(event: any): void {
    if (event.target.checked) {
      // push only those orders that aren't already included
      this.orders.forEach((order: Order) => {
        if (!this.selectedOrders.includes(order.id)) {
          this.selectedOrders.push(order.id);
        }
        this.allOrdersSelected = true;
      });
    } else {
      // remove only those orders that are on the current page, keep all else in the array
      this.orders.forEach((order: Order) => {
        const index = this.selectedOrders.indexOf(order.id);
        this.selectedOrders.splice(index, 1);
      });
    }
  }

  private setCheckboxIndeterminateState() {
    if (this.headerCheckbox) {
      this.headerCheckbox.nativeElement.indeterminate = Utils.setCheckboxIndeterminateState(
        this.allOrdersSelected,
        this.orders,
        this.selectedOrders
      );
    }
  }

  private checkIfAllCheckboxSelected(): void {
    this.allOrdersSelected = Utils.areAllCheckboxesSelected(this.selectedOrders, this.orders);
  }

  onCreateEmbossmentList() {
    this.waitingForResponse = true;
    this.ordersService.generateEmbossmentList(this.selectedOrders)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: EmbossmentsCreatedResponse) => {
          this.waitingForResponse = false;
          if (response.code === ResponseCode.SUCCESS) {
            this.alertifyService.success(response.message);
            this.ordersService.emitNewEmbossment(true);
            this.onRefreshList();
          } else {
            this.alertifyService.warning(response.message);
          }
        }, () => {
          this.waitingForResponse = false;
        });
  }

  onRefreshList() {
    this.responseSuccessful = false;
    this.orders = [];
    this.getTableData();
    this.selectedOrders = [];
  }
}
