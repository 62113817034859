import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loading = new BehaviorSubject<boolean | null>(false);
  public readonly _isLoading$ = this.loading.asObservable();

  constructor() { }

  isLoading() {
    this.loading.next(true);
  }

  isLoaded() {
    this.loading.next(false);
  }
}
