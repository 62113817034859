import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { apiUrl, Utils } from '../shared/utils/utils';
import {
  AllRegionsResponse, CommercialOutletPayload,
  RegionPayload,
  RegionResponse,
  SetDefaultRegionResponse
} from '../shared/dataModels/regionDataModels';
import { SimpleResponse } from "../shared/dataModels/sharedDataModels";
import { tap } from "rxjs/operators";
import { AppGlobalService } from "../shared/services/app-global.service";

@Injectable({
              providedIn: 'root'
            })
export class RegionsService {
  constructor(private http: HttpClient, private globalService: AppGlobalService) {}

  getRegions(): Observable<AllRegionsResponse> {
    return this.http.get<AllRegionsResponse>(apiUrl + 'regions')
      .pipe(tap((response: AllRegionsResponse) => this.globalService.emitRegions(response)));
  }

  getRegion(id: number): Observable<RegionResponse> {
    return this.http.get<RegionResponse>(apiUrl + 'regions/' + id);
  }

  setRegion(region: RegionPayload): Observable<RegionResponse> {
    const convertedRegionPayload = Utils.camelCaseToSnakeCase(region);
    return this.http.post<RegionResponse>(apiUrl + 'regions', convertedRegionPayload);
  }

  updateRegion(id: number, payload: RegionPayload): Observable<RegionResponse> {
    const regionToUpdate = Utils.camelCaseToSnakeCase(payload);
    return this.http.put<RegionResponse>(apiUrl + 'regions/' + id, { ...regionToUpdate });
  }

  setDefaultLogin(regionId: number): Observable<SetDefaultRegionResponse>{
    return this.http.put<SetDefaultRegionResponse>(apiUrl + 'user/setDefaultLoginRegion', { region_id: regionId });
  }

  createCommercialOutlet(payload: CommercialOutletPayload): Observable<SimpleResponse> {
    const parsedPayload = Utils.camelCaseToSnakeCase(payload);
    return this.http.post<SimpleResponse>(apiUrl + 'buyingStation', { ...parsedPayload });
  }

  updateCommercialOutlet(payload: CommercialOutletPayload, outletId: number): Observable<SimpleResponse> {
    const parsedPayload = Utils.camelCaseToSnakeCase(payload);
    return this.http.put<SimpleResponse>(apiUrl + 'buyingStation/' + outletId, { ...parsedPayload });
  }
}
