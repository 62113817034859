import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ActivatedRoute, Router } from "@angular/router";
import { AlertifyService } from "../shared/services/alertify-service.service";
import { catchError, timeout } from "rxjs/operators";
import { ErrorStatus } from "../shared/enum/error-status";

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  timeoutLimit = 300000;

  constructor(private router: Router,
              private activeRouter: ActivatedRoute,
              private alertifyService: AlertifyService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const userToken = localStorage.getItem('token');
    if (userToken) {

      const clonedRequest = request.clone({
                                            headers: request.headers.set('Authorization', 'Bearer ' + userToken)
                                          });
      return next.handle(clonedRequest)
                 .pipe(
                   timeout(this.timeoutLimit),
                   catchError((error: HttpErrorResponse) => {
                     if (error.status === ErrorStatus.STATUS_401) {
                       localStorage.clear();
                       this.router.navigate(['/login']);
                     } else {
                       if (error.status) {
                         this.alertifyService.handleError(error);
                       } else {
                         this.alertifyService.error('Klientseitiges Zeitlimit überschritten.');
                       }
                     }
                     return throwError(error);
                   })
                 );
    } else {
      if (!this.router.url.includes('forgot-password') && !this.router.url.includes('reset-password')) {
        this.router.navigate(['/login']);
      }
      return next.handle(request)
                 .pipe(
                   catchError((error: HttpErrorResponse) => {
                     this.alertifyService.handleError(error);
                     return throwError(error);
                   }));
    }
  }
}
