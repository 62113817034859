import {
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Type,
  ViewChild
} from '@angular/core';
import { ComponentInjectorDirective } from "../../directives/component-injector.directive";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Utils } from "../../utils/utils";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AppGlobalService } from "../../services/app-global.service";

export const dateInputPattern = /^(\d{1,2}\.\d{1,2}\.\d{4})$/;
export const numberFilterValidators = [Validators.pattern(/^-?\d*[.,]?\d{0,2}$/), Validators.min(0.1)];

@Component({
             selector: 'app-filter-container',
             templateUrl: './filter-container.component.html',
             styleUrls: ['./filter-container.component.scss']
           })
export class FilterContainerComponent implements OnInit {
  @Input() loadComponent: Type<any>;
  @Input() requestParams: any;
  @Input() searchTerm: string;
  @Input() title: string;
  @Input() createItemUrl: string;
  @Input() exportItemUrl: string;
  @Input() queryParams: Params;
  @Input() totalItems: number | undefined;
  @Input() placeholder = 'Suche...';
  @Output() emitQueryParams = new EventEmitter<Params>();
  @ViewChild(ComponentInjectorDirective, { static: true }) injectHost: ComponentInjectorDirective;
  searchForm: FormGroup;
  filtersApplied = false;
  initialClick = true;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private formBuilder: FormBuilder,
              private globalService: AppGlobalService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.initializeSearchForm();
    this.watchSearchInput();
    if (this.searchTerm) {
      this.searchInput?.setValue(this.searchTerm);
    }
    this.watchQueryParams();
  }

  private watchQueryParams() {
    this.route.queryParams.subscribe((queryParams: Params) => {
      if (queryParams['filters_applied']) {
        this.filtersApplied = queryParams['filters_applied'];
      } else {
        this.filtersApplied = false;
      }
    });
  }

  private initializeComponentFactory() {
    const compFactory = this.componentFactoryResolver.resolveComponentFactory(this.loadComponent);
    const hostViewContainerRef = this.injectHost.viewContainerRef;
    const componentRef = hostViewContainerRef.createComponent(compFactory);
    componentRef.instance.filterValues.subscribe((event: any) => {
      this.onFilter(event);
    });
  }

  private initializeSearchForm(): void {
    this.searchForm = this.formBuilder.group({
                                               searchInput: ['', [Validators.required]],
                                             });
  }

  get searchInput() {
    return this.searchForm.get('searchInput');
  }

  private watchSearchInput(): void {
    this.searchInput?.valueChanges.subscribe(value => {
      if (value === '') {
        delete this.requestParams.searchTerms;
        this.setQueryParameters();
        this.prepareRequestParams();
      }
    });
  }

  private setQueryParameters(): void {
    const queryParams = Utils.camelCaseToSnakeCase(
      { region: Utils.getRegionFromLocalStorage().toLowerCase(), ...this.requestParams }
    );
    this.router.navigate([], { queryParams, relativeTo: this.route });
  }

  onFilter(filters: Params) {
    this.requestParams = filters;
    if (this.searchInput?.value) {
      this.requestParams = { ...this.requestParams, searchTerms: this.searchInput?.value };
    }
    this.filtersApplied = filters.filtersApplied ? filters.filtersApplied : false;
    this.setQueryParameters();
    this.prepareRequestParams();
  }

  onSearch(): void {
    this.requestParams = { ...this.requestParams, searchTerms: this.searchInput?.value };
    this.setQueryParameters();
    this.prepareRequestParams();
  }

  onClearSearch(): void {
    this.searchInput?.setValue('');
  }

  private prepareRequestParams(): void {
    if (this.requestParams.page) {
      delete this.requestParams.page;
    }
    this.emitQueryParams.emit(this.requestParams);
  }

  onLoadFilterData() {
    if (this.initialClick) {
      this.initializeComponentFactory();
    }
    this.initialClick = false;
  }
}
