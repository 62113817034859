import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import {
  AllMemosResponse,
  MemoPayload,
  MemoResponse,
} from "../shared/dataModels/memosDataModel";
import { apiUrl, Utils } from "../shared/utils/utils";
import { Params } from "@angular/router";
import { FilterUtils } from "../shared/utils/filter";
import { CustomUrlEncoder } from "../shared/utils/custom-url-encoder";

@Injectable({
  providedIn: 'root'
})
export class MemosService {
  constructor(private http: HttpClient) {}

  getMemos(filterParameters: Params): Observable<AllMemosResponse> {
    const queryParams = FilterUtils.trimRequestParams(filterParameters);
    const params = new HttpParams({ encoder: new CustomUrlEncoder() })
      .appendAll(Utils.camelCaseToSnakeCase(queryParams));
    return this.http.get<AllMemosResponse>(apiUrl + 'memos', { params });
  }

  getMemo(id: number): Observable<MemoResponse> {
    return this.http.get<MemoResponse>(apiUrl + 'memos/' + id);
  }

  getCustomerMemos(customerId: number): Observable<AllMemosResponse> {
    const params = new HttpParams().set('order_by', 'created_at').set('order_direction', 'desc');
    return this.http.get<AllMemosResponse>(apiUrl + 'customers/' + customerId + '/memos', { params });
  }

  getOrderMemos(orderId: number): Observable<AllMemosResponse> {
    return this.http.get<AllMemosResponse>(apiUrl + 'orders/' + orderId + '/memos');
  }

  createMemo(newMemo: MemoPayload): Observable<MemoResponse> {
    const memo = Utils.camelCaseToSnakeCase(newMemo);
    return this.http.post<MemoResponse>(apiUrl + 'memos', memo);
  }

  editMemo(memoId: number, editedMemo: MemoPayload): Observable<MemoResponse> {
    const memo = Utils.camelCaseToSnakeCase(editedMemo);
    return this.http.put<MemoResponse>(apiUrl + 'memos/' + memoId, memo);
  }
}
