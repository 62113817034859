import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserResponse } from '../shared/dataModels/userDataModel';
import { HttpClient } from '@angular/common/http';
import { apiUrl } from '../shared/utils/utils';

@Injectable({
              providedIn: 'root'
            })
export class MeService {
  constructor(private http: HttpClient) {}

  getMeData(): Observable<UserResponse> {
    return this.http.get<UserResponse>(apiUrl + 'auth/me');
  }
}
