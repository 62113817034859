import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Utils } from "../../shared/utils/utils";
import { WebBanner, WebBannerPayload, WebBannerResponse } from "../../shared/dataModels/settingsDataModels";
import { BannerPurpose } from "../../shared/enum/settings";
import { SettingsService } from "../../services/settings.service";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { LocalStorageKeys, ResponseCode } from "../../shared/enum/shared-enums";
import { takeUntil } from "rxjs/operators";
import { AlertifyService } from "../../shared/services/alertify-service.service";
import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { SuccessfulCreationResponse } from "../../shared/dataModels/sharedDataModels";
import { ErrorStatus } from "../../shared/enum/error-status";
import { SubscriptionComponent } from "../../shared/components/subscription/subscription.component";

@Component({
             selector: 'app-web-banner',
             templateUrl: './web-banner.component.html',
             styleUrls: ['./web-banner.component.scss']
           })
export class WebBannerComponent extends SubscriptionComponent implements OnInit {
  webBanner: WebBanner;
  webBannerForm: FormGroup;
  webBannerPath: string;
  pendingResponse = false;
  region = JSON.parse(Utils.getItemFromLocalStorage(LocalStorageKeys.REGION));
  desktopBannerImage: File | null;
  mobileBannerImage: File | null;
  selectedFileDesktopBlob: SafeUrl;
  selectedFileMobileBlob: SafeUrl;
  webBannerLoaded = false;

  constructor(private formBuilder: FormBuilder,
              private settingsService: SettingsService,
              private sanitizer: DomSanitizer,
              private alertifyService: AlertifyService) { super(); }

  ngOnInit(): void {
    this.getExistingBanner();
    this.initializeWebbannerForm();
  }

  private getExistingBanner(): void {
    const params = new HttpParams().set('region_id', this.region.id);
    this.settingsService.getBanner(params)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: WebBannerResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            if(response.body?.data) {
              this.webBanner = Utils.snakeCaseToCamelCase(response.body.data);
              this.prefillWebBannerForm();
              this.selectedFileDesktopBlob = this.webBanner.desktopFileUrl;
              this.selectedFileMobileBlob = this.webBanner.mobileFileUrl;
            }
          }
          this.webBannerLoaded = true;
        }, (error: HttpErrorResponse) => {
          this.webBannerLoaded = true;
        });
  }

  private initializeWebbannerForm(): void {
    this.webBannerForm = this.formBuilder.group({
                                                  couponText: ['', [Validators.maxLength(10)]],
                                                  text: ['', [Validators.maxLength(65)]],
                                                  footerText: ['', [Validators.maxLength(120)]]
                                                });
  }

  get couponText() {
    return this.webBannerForm.get('couponText');
  }

  get text() {
    return this.webBannerForm.get('text');
  }

  get footerText() {
    return this.webBannerForm.get('footerText');
  }

  private prefillWebBannerForm(): void {
    this.webBannerForm.patchValue(this.webBanner)
  }

  onDesktopFileUpload(files: FileList) {
    this.desktopBannerImage = files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.webBannerPath = reader.result as string;
      const blob = new Blob(files as unknown as BlobPart[], { type: files[0].type });
      const url = window.URL.createObjectURL(blob);
      this.selectedFileDesktopBlob = this.sanitizer.bypassSecurityTrustUrl(url);
    }
    reader.readAsDataURL(files[0]);
  }

  onMobileFileUpload(files: FileList) {
    this.mobileBannerImage = files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.webBannerPath = reader.result as string;
      const blob = new Blob(files as unknown as BlobPart[], { type: files[0].type });
      const url = window.URL.createObjectURL(blob);
      this.selectedFileMobileBlob = this.sanitizer.bypassSecurityTrustUrl(url);
    }
    reader.readAsDataURL(files[0]);
  }

  onPostBanner(): void {
    const payload: WebBannerPayload = {
      ...this.webBannerForm?.value,
      purpose: BannerPurpose.ENTRY_ORDER,
      regionId: this.region.id
    }
    this.pendingResponse = true;
    this.createBanner(payload);
  }

  private createBanner(payload: WebBannerPayload): void {
    this.settingsService.uploadBanner(payload, this.desktopBannerImage, this.mobileBannerImage)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: SuccessfulCreationResponse) => {
          this.pendingResponse = false;
          if (response.code === ResponseCode.FAILED) {
            this.alertifyService.warning(response.message);
          } else {
            this.alertifyService.success(response.message);
          }
        }, (error: HttpErrorResponse) => {
          this.pendingResponse = false;
          if (ErrorStatus.STATUS_413) {
            this.alertifyService.error('Die Bildgröße ist zu hoch! Wählen Sie bitte ein anderes Bild aus.');
          }
        });
  }

  clearData() {
    this.desktopBannerImage = null;
    this.mobileBannerImage = null;
    this.selectedFileDesktopBlob = this.webBanner.desktopFileUrl;
    this.selectedFileMobileBlob = this.webBanner.mobileFileUrl;
  }
}
