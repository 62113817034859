<div class="accordion custom-accordion mb-3" id="custom-accordion-seven">
  <div class="card mb-0">
    <div class="card-header" id="headingSeven">
      <h5 class="m-0">
        <i class="mdi mdi-chevron-down accordion-arrow custom-accordion-title me-3 mt-1"
           [ngClass]="{show: chevron, collapse: !chevron}"></i>
        <i class="mdi mdi-chevron-up accordion-arrow custom-accordion-title me-3 mt-1"
           [ngClass]="{show: !chevron, collapse: chevron}"></i>
        <a class="custom-accordion-title d-flex justify-content-between align-items-baseline py-1"
           data-bs-toggle="collapse" href="#collapseSeven"
           (click)="chevron = !chevron"
           aria-expanded="true" aria-controls="collapseSeven">
          <div class="dashboard-table-title-fs">
            <i class="mdi mdi-drag mdi-18px cursor-pointer me-3"></i>
            Verlängerungsbenachrichtigung
          </div>
          <div *ngIf="cards?.length" class="items-length me-3">+ {{ cards.length }}</div>
        </a>
      </h5>
    </div>
  </div>
  <div id="collapseSeven"
       class="collapse"
       aria-labelledby="headingSeven"
       data-bs-parent="#custom-accordion-seven">
    <div class="card-body">
      <div class="loading-spinner" *ngIf="!error">
        <div *appLoading="!responseSuccessful">
          <div class="d-md-flex justify-content-between">
            <form [formGroup]="embossmentFilterForm" class="d-md-flex justify-content-start col-xxxl-6 gap-2">
              <div class="col-xxxl-4 mb-3">
                <label class="col-form-label" for="regionId">Region</label>
                <select formControlName="region"
                        id="regionId"
                        class="form-select cursor-pointer me-3">
                  <option value="-1">Alle</option>
                  <option *ngFor="let region of regions" [value]="region.id">{{ region?.name }}</option>
                </select>
              </div>
              <div class="col-xxxl-4 mb-2">
                <button type="button" (click)="onRefreshList()" class="btn app-primary-button m-refresh-button">
                  <i class="mdi mdi-refresh cursor-pointer align-self-center mt-2"></i>
                  <span>Neu laden</span>
                </button>
              </div>
            </form>
            <div class="d-md-flex justify-content-start justify-content-xxxl-end align-items-center gap-2">
              <div>
                <button type="button"
                        class="btn save-button text-nowrap m-create-button"
                        [class.active]="selectedCards.length > 0"
                        [disabled]="selectedCards.length === 0"
                        (click)="onCreateEmbossmentList()">
                  <span *ngIf="waitingForResponse"
                        class="spinner-border spinner-border-sm me-1"
                        role="status"
                        aria-hidden="true"></span>
                  Prägeliste erstellen
                </button>
              </div>
            </div>
          </div>
          <!-- TABLE -->
          <div *ngIf="cards && cards.length > 0" class="table-responsive db-table-width scroll-embossments mt-3">
            <table class="table table-striped table-centered mb-0">
              <thead class="table-light">
              <tr class="sticky-header-row">
                <th class="all table-cell">
                  <input type="checkbox"
                         #selectAllCheckbox
                         class="form-check-input custom-xl-checkbox cursor-pointer"
                         (change)="onCheckAllCards($event)"
                         [checked]="allCardsSelected">
                </th>
                <th class="table-cell">ID</th>
                <th class="table-cell">Kunden-ID</th>
                <th class="table-cell">Bestellung-ID</th>
                <th class="table-cell">Erstellt am</th>
                <th class="table-cell">Region</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let card of cards; index as i" class="bg-aqua">
                <td class="table-cell">
                  <input type="checkbox"
                         [checked]="selectedCards.includes(card.id)"
                         (change)="onSelectCard(card.id)"
                         class="form-check-input custom-xl-checkbox cursor-pointer">
                </td>
                <td class="text-body fw-bold table-cell">{{ card.id }}</td>
                <td class="table-cell">{{ card?.customerId }}</td>
                <td class="table-cell">{{ card?.orderId }}</td>
                <td class="table-cell">{{ card?.createdAt | date:"dd.MM.yyyy" }}</td>
                <td class="table-cell">{{ card?.regionName }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="cards && cards.length === 0" class="mt-3">Keine Ergebnisse gefunden.</div>
        </div>
      </div>
    </div>
  </div>
</div>
