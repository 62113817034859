<app-breadcrumbs (navigateTo)="navigateTo($event)"></app-breadcrumbs>
<app-card-statistic #cardStatisticComponent
                    statistic="Kartenstatistik"
                    (deleteReport)="onMarkReportForDeletion($event)"></app-card-statistic>
<app-sales-statistic #salesStatisticComponent
                     statistic="Umsatzstatistik"
                     (deleteReport)="onMarkReportForDeletion($event)"></app-sales-statistic>

<app-modal
  modalType="danger"
  data-bs-target="modal"
  modalLabel="Bericht löschen"
  [modalContent]="modalBody"
  saveButtonLabel="Löschen"
  cancelButtonLabel="Abbrechen"
  saveButtonCssClass="btn-danger"
  modalHeaderCssClass="bg-danger"
  (emitConfirm)="onDeleteReport()"
></app-modal>
