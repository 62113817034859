<app-breadcrumbs (navigateTo)="navigateTo($event)"></app-breadcrumbs>
<div class="loading-spinner">
  <div *appLoading="!cardsLoaded">
    <div class="d-flex justify-content-between align-items-center mt-auto">
      <h3>Karten</h3>
      <div *ngIf="cardsData?.pagination?.total" class="items-length fw-bold me-3">
        Anzahl: {{ cardsData?.pagination?.total }}</div>
    </div>
    <div *ngIf="!noCards" class="table-responsive">
      <table class="table table-striped mb-0">
        <thead class="table-light">
        <tr>
          <th class="border-bottom-0 table-cell">Kartennummer</th>
          <th *ngIf="!isLinkedCardsResponse" class="border-bottom-0 table-cell">Produkt / Typ</th>
          <th *ngIf="isLinkedCardsResponse" class="border-bottom-0 table-cell">Verlinkter Kunde</th>
          <th class="border-bottom-0 table-cell">Inhaber</th>
          <th class="border-bottom-0 table-cell">Status</th>
          <th class="border-bottom-0 table-cell">Haptisch</th>
          <th class="border-bottom-0 table-cell">Zustand</th>
          <th class="border-bottom-0 table-cell">Begin</th>
          <th class="border-bottom-0 table-cell">Ende</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let card of cards">
          <td><a [routerLink]="['/dashboard', 'cards', card.id]"
                 [queryParams]="{ page: null }"
                 queryParamsHandling="merge"
                 class="neutral-anchor">{{ card.cardNumber }}</a></td>
          <td *ngIf="!isLinkedCardsResponse">
            <a [routerLink]="['/dashboard', 'cards', card.id]"
               [queryParams]="{ page: null }"
               queryParamsHandling="merge"
               class="neutral-anchor">
              {{ card.product?.name }} / {{ card.product?.type?.name }}
            </a>
          </td>
          <td *ngIf="isLinkedCardsResponse">
            <a *ngIf="card.customerId !== customerId; else noLink"
               [routerLink]="['/dashboard/customers', customerId]"
               queryParamsHandling="merge"
               class="neutral-anchor">
              {{ customerId }}
            </a>
            <ng-template #noLink>{{ customerId }}</ng-template>
          </td>
          <td><a [routerLink]="['/dashboard', 'cards', card.id]"
                 [queryParams]="{ page: null }"
                 queryParamsHandling="merge"
                 class="neutral-anchor">
            {{ card?.firstname }} {{ card?.lastname }}
          </a></td>
          <td *ngIf="card.deactivationReason; else deliveryStatus">
            <a [routerLink]="['/dashboard', 'cards', card.id]"
               [queryParams]="{ page: null }"
               queryParamsHandling="merge"
               class="neutral-anchor">
              {{ card.deactivationReason }}
            </a>
          </td>
          <ng-template #deliveryStatus>
            <td><a [routerLink]="['/dashboard', 'cards', card.id]"
                   [queryParams]="{ page: null }"
                   queryParamsHandling="merge"
                   class="neutral-anchor">{{ card.deliveryStatus }}
            </a></td>
          </ng-template>
          <td><a [routerLink]="['/dashboard', 'cards', card.id]"
                 [queryParams]="{ page: null }"
                 queryParamsHandling="merge"
                 class="neutral-anchor">
            {{card.isOnlyDigital ? 'Nein' : 'Ja' }}
          </a></td>
          <td><a [routerLink]="['/dashboard', 'cards', card.id]"
                 [queryParams]="{ page: null }"
                 queryParamsHandling="merge"
                 class="neutral-anchor">{{ card.state }}
          </a></td>
          <td><a [routerLink]="['/dashboard', 'cards', card.id]"
                 [queryParams]="{ page: null }"
                 queryParamsHandling="merge"
                 class="neutral-anchor">
            {{ card.startDate | date: 'dd.MM.yyyy' }}
          </a></td>
          <td><a [routerLink]="['/dashboard', 'cards', card.id]"
                 [queryParams]="{ page: null }"
                 queryParamsHandling="merge"
                 class="neutral-anchor">
            {{ card.endDate | date: 'dd.MM.yyyy' }}
          </a></td>
        </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="noCards" class="text-center">
      <span>Keine Karten</span>
    </div>
    <app-advanced-pagination *ngIf="cardsData && cardsData.pagination.total > cardsData.pagination.per_page"
                             [paginationData]="cardsData"
                             (emitPaginatedData)="loadPaginatedData($event)">
    </app-advanced-pagination>
  </div>
</div>
