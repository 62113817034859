<nav aria-label="breadcrumb" class="breadcrumb-grid mb-3">
    <ol *ngFor="let breadcrumb of (breadcrumbs$ | async); let last = last; let first = first" class="breadcrumb item">
        <li class="breadcrumb-item">
            <span *ngIf="first" class="cursor-default">
                <i class="uil-home-alt"></i>
                {{ breadcrumb.pageName }}
              <i class="mdi dripicons-chevron-right ps-md-1 pe-md-1"></i>
            </span>
            <a *ngIf="!first && !last" (click)="onNavigate(breadcrumb)">
                {{ breadcrumb.pageName }}
              <i class="mdi dripicons-chevron-right ps-md-1 pe-md-1"></i>
            </a>
            <span *ngIf="last" class="last-breadcrumb cursor-default">
              {{ breadcrumb.pageName }}
            </span>
        </li>
    </ol>
</nav>
