<div class="authentication-bg" data-layout-config='{"darkMode":false}'>
  <div class="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xxl-4 col-lg-5">
          <div class="card">
            <!-- Logo -->
            <div class="card-header pt-4 pb-4 text-center app-bg-primary"></div>
            <div class="card-body p-4">
              <div class="text-center w-75 m-auto">
                <h4 class="text-dark-50 text-center mt-0 fw-bold">Passwort zurücksetzen</h4>
                <p class="text-muted mb-4">Bitte geben Sie Ihre Email Adresse zum Zurücksetzen des Passwortes ein</p>
              </div>

              <form [formGroup]="forgotPasswordForm">
                <div class="mb-3">
                  <label for="email" class="form-label">Email Adresse</label>
                  <input class="form-control" type="email" id="email" formControlName="email" placeholder="Email">
                </div>

                <div class="mb-0 text-center">
                  <button class="btn app-primary-button" type="submit" (click)="onRequestPasswordChange()">
                    Passwort zurücksetzen
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 text-center">
              <p class="text-muted">zurück zum
                <a routerLink="/login" class="text-muted ms-1">
                  <b>Anmelden</b>
                </a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <footer class="footer footer-alt">
    2018 - {{ currentYear }} © DRESDEN FOR FRIENDS
  </footer>
</div>
