import { Injectable } from '@angular/core';
import { Params } from "@angular/router";
import { Observable, of } from "rxjs";
import { HttpParams } from "@angular/common/http";
import { Utils } from "../utils/utils";

@Injectable({
              providedIn: 'root'
            })
export class NavigationService {
  constructor() {}

  back(): Observable<Params> {
    const currentUrl = window.location.href;
    const currentQueryParams = new HttpParams({ fromString: currentUrl.split('?')[1] })
    const params = currentQueryParams.keys().map((key) => ({
      [key]: currentQueryParams.get(key)
    }));
    let paramsObject = Utils.snakeCaseToCamelCase(Object.assign({}, ...params));
    delete paramsObject.region;

    return of(paramsObject);
  }
}
