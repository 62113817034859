import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginationData } from '../../dataModels/paginationDataModel';

@Component({
             selector: 'app-advanced-pagination',
             templateUrl: './advanced-pagination.component.html',
             styleUrls: ['./advanced-pagination.component.scss']
           })
export class AdvancedPaginationComponent {
  @Input() paginationData: PaginationData;
  @Input() shortPagination: boolean;
  @Output() emitPaginatedData = new EventEmitter<string>();

  constructor() { }

  onLoadPaginatedPage(url: string): void {
    this.emitPaginatedData.emit(url.split('=').pop());
  }
}
