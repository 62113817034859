<div class="accordion custom-accordion mb-3" id="custom-accordion-ten">
  <div class="card mb-0">
    <div class="card-header" id="headingTen">
      <h5 class="m-0">
        <i class="mdi mdi-chevron-down accordion-arrow custom-accordion-title me-3"
           [class.mt-1]="!noHistory"
           [ngClass]="{show: actionChevron, collapse: !actionChevron}"></i>
        <i class="mdi mdi-chevron-up accordion-arrow custom-accordion-title me-3"
           [class.mt-1]="!noHistory"
           [ngClass]="{show: !actionChevron, collapse: actionChevron}"></i>
        <a class="custom-accordion-title d-flex justify-content-between align-items-baseline"
           [class.py-1]="noHistory"
           data-bs-toggle="collapse" href="#collapseTen"
           (click)="actionChevron = !actionChevron"
           aria-expanded="true" aria-controls="collapseTen">
          Aktionshistorie
          <div *ngIf="!noHistory" class="d-flex justify-content-end align-items-center gap-2 me-4 me-md-5">
            <button class="btn app-primary-button show-all-button d-none d-md-flex align-items-center gap-2"
                    type="button"
                    routerLink="action-history"
                    [queryParams]="{for: historyFor}"
                    queryParamsHandling="merge">
              Alles
            </button>
            <i *ngIf="!noHistory" class="mdi mdi-view-list-outline mdi-24px d-md-none icon-color me-md-3"
               routerLink="action-history"
               [queryParams]="{for: historyFor}"
               queryParamsHandling="merge"></i>
          </div>
        </a>
      </h5>
    </div>

    <div id="collapseTen"
         class="collapse"
         aria-labelledby="headingTen"
         data-bs-parent="#custom-accordion-ten">
      <div *appLoading="!historyLoaded" class="card-body">
        <div *ngIf="!noHistory; else noHistoryExists" [class.scroll-history]="actionHistory.length > 8"
             class="table-responsive">
          <table class="table table-striped mb-0">
            <thead class="table-light">
            <tr>
              <th class="border-bottom-0 table-cell">Aktion</th>
              <th class="border-bottom-0 table-cell">Durchgeführt von</th>
              <th class="border-bottom-0 table-cell">Daten</th>
              <th class="border-bottom-0 table-cell">Datum</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let history of actionHistory"
                class="bg-aqua">
              <td *ngIf="history.description" class="table-cell">{{ history?.description }}</td>
              <td *ngIf="!history.description" class="table-cell">{{ history?.action }}</td>
              <td class="table-cell">
                <a *ngIf="history.changedBy; else autoChanged"
                   [routerLink]="['/dashboard/users', history?.changedBy?.id ]"
                   queryParamsHandling="merge">
                  <span class="hover-underline">{{ history.changedBy.email }}</span>
                  <span> - </span>
                  <span>{{ history?.changedBy?.id }} <i class="uil-external-link-alt"></i></span>
                </a>
                <ng-template #autoChanged>
                  <span class="text-decoration-none">&#8212;</span>
                </ng-template>
              </td>
              <td class="table-cell">
                <i class="mdi mdi-eye-outline mdi-18px cursor-pointer"
                   (click)="onDisplayData(history.data)"
                   data-bs-toggle="modal"
                   data-bs-target="#bs-example-modal-lg"></i>
              </td>
              <td class="table-cell">{{ history?.createdAt | date: 'dd.MM.yyyy - HH:mm' }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <ng-template #noHistoryExists>
          <div class="text-center">Keine Historie</div>
        </ng-template>
        <app-advanced-pagination
          *ngIf="actionHistoryData && actionHistoryData.pagination.total > actionHistoryData.pagination.per_page"
          [paginationData]="actionHistoryData"
          [shortPagination]="true"
          (emitPaginatedData)="getPaginatedData($event)">
        </app-advanced-pagination>
      </div>
    </div>
  </div>
</div>
<div class="modal fade"
     id="bs-example-modal-lg"
     tabindex="-1"
     role="dialog"
     aria-labelledby="myLargeModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myLargeModalLabel">Daten</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
      </div>
      <div class="modal-body">
        <pre>{{ dataToDisplay | json }}</pre>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
