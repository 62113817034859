import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { AppGlobalService } from "../../services/app-global.service";
import { SubscriptionComponent } from "../subscription/subscription.component";
import { takeUntil } from "rxjs/operators";

@Component({
             selector: 'app-filter',
             templateUrl: './filter.component.html',
             styleUrls: ['./filter.component.scss']
           })
export class FilterComponent extends SubscriptionComponent implements OnInit, OnDestroy {
  @Input() filtersApplied: boolean;
  @Input() initialClick: boolean;
  @Input() triggerButton: string;
  @Input() disableTriggerButton: boolean | undefined;
  @Input() identifier: string;
  @ViewChild('triggerFilterModal') showModal: ElementRef;
  @ViewChild('errorModalTrigger') showErrorModal: ElementRef;
  @Output() openModal = new EventEmitter<boolean>();
  loadingSpinner = false;
  filtersLoaded = false;
  noFilterData = false;

  constructor(private globalService: AppGlobalService) { super() }

  ngOnInit() {
    this.listenForLoadedFilters();
    this.listenForFilterErrors();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private listenForLoadedFilters(): void {
    this.globalService.filtersLoaded$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((event: boolean) => {
          if (event && !this.filtersLoaded) {
            if (this.showModal) {
              if (!this.identifier) {
                this.openModalWindow();
              }
              this.filtersLoaded = event;
              this.loadingSpinner = false;
            }
          }
        });
  }

  openModalWindow() {
    let modal: HTMLElement = this.showModal?.nativeElement as HTMLElement;
    modal.click();
  }

  private listenForFilterErrors(): void {
    this.globalService.filterError$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((event: boolean) => {
          if (event) {
            if (this.showErrorModal) {
              let errorModal: HTMLElement = this.showErrorModal?.nativeElement as HTMLElement;
              errorModal.click();
              this.loadingSpinner = false;
            }
          }
        });
  }

  onSelect(): void {
    this.filtersApplied = true;
  }

  onShowModal() {
    if (this.filtersLoaded) {
      let modal: HTMLElement = this.showModal.nativeElement as HTMLElement;
      modal.click();
    } else {
      this.loadingSpinner = true;
      this.getFiltersData();
    }
    this.openModal.emit(true);
  }

  getFiltersData() {
    if (!this.initialClick) {
      this.loadingSpinner = true;
      this.globalService.reloadFilterData(true);
    }
  }
}
