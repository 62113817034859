import {Component, OnDestroy, OnInit} from '@angular/core';
import { PaginationData } from "../../../shared/dataModels/paginationDataModel";
import { Embossment, EmbossmentsResponse } from "../../../shared/dataModels/ordersDataModel";
import { Params } from "@angular/router";
import { OrdersService } from "../../../services/orders.service";
import { SubscriptionComponent } from "../../../shared/components/subscription/subscription.component";
import { Utils } from "../../../shared/utils/utils";
import { takeUntil } from "rxjs/operators";
import { EmbossmentType, ResponseCode } from "../../../shared/enum/shared-enums";
import { CardsService } from "../../../services/cards.service";
import { AlertifyService } from "../../../shared/services/alertify-service.service";

@Component({
  selector: 'app-extension-embossments',
  templateUrl: './extension-embossments.component.html',
  styleUrls: ['./extension-embossments.component.scss']
})
export class ExtensionEmbossmentsComponent extends SubscriptionComponent implements OnInit, OnDestroy {
  embossmentData: PaginationData;
  embossments: Embossment[];
  requestParams: Params = { perPage: 10 };
  tableHeaders = ['ID', 'Erstellt', 'Ersteller', '# Karten', 'Dateien'];
  responseSuccessful = false;
  error = false;

  constructor(private ordersService: OrdersService,
              private alertifyService: AlertifyService,
              private cardsService: CardsService) { super() }

  ngOnInit(): void {
    this.getEmbossments();
    this.cardsService.newEmbossment$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((value: EmbossmentType | null) => {
          if (value === EmbossmentType.EXTENSION) {
            this.getEmbossments();
          }
        });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private getEmbossments(): void {
    this.requestParams.orderDirection = 'desc';
    this.requestParams.orderBy = 'created_at';
    this.ordersService.getEmbossments(this.requestParams, EmbossmentType.EXTENSION)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: EmbossmentsResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            this.embossmentData = response.body;
            this.embossments = Utils.snakeCaseToCamelCase(response.body?.pagination.data);
          } else {
            this.alertifyService.warning(response.message);
          }
          this.responseSuccessful = true;
        }, () => this.error = true);
  }

  onLoadPaginationData(pageNumber: string) {
    this.responseSuccessful = false;
    this.requestParams = { ...this.requestParams, page: pageNumber };
    this.getEmbossments();
  }

  onRefreshList(): void {
    this.responseSuccessful = false;
    this.embossments = [];
    this.getEmbossments();
  }
}
