export enum ResponseCode {
  SUCCESS,
  FAILED
}

export enum LocalStorageKeys {
  USER_TOKEN = 'token',
  REGION = 'region',
  USER = 'user',
  ORDER = 'order',
  PRODUCTS = 'productsInOrder',
  CORPORATE_FORM_VALUES = 'corporate_form_values',
  CHECKOUT_INFO = 'checkoutInfo',
  CORPORATE_CHECKOUT_INFO = 'corporateCheckoutInfo',
  CORPORATE_OPTIONS = 'corpOptions',
  ORDER_PAYMENT_DATA = 'orderPayment',
  NEW_CUSTOMER = 'newCustomer',
  NEW_COUPON = 'newCoupon',
  REPLACEMENT_CHECKOUT_INFO = 'replacementCheckoutInfo',
  REPLACEMENT_PAYLOAD = 'replacementPayload',
  FIRST_COLUMN_ITEMS = 'firstColumn',
  SECOND_COLUMN_ITEMS = 'secondColumn'
}

export enum CouponAndVoucherCode {
  COUPON = 'coupon',
  VOUCHER = 'voucher',
  UNKNOWN = 'unknown'
}

export enum ContextMenuValues {
  SEND_REG_LINK = 'send_reg_link',
  MEMOS = 'memos',
  ORDERS = 'orders',
  DEPOSIT = 'deposit',
  CREATE_ORDER = 'create_order',
  CREATE_CORPORATE_ORDER = 'create_corporate_order',
  CREATE_MEMO = 'create_memo',
  CARDS = 'cards',
  CANCEL = 'cancel', // use to identify Stornieren
  REVOKE = 'revoke',
  REVOKE_CANCELLATION = 'revoke_cancellation',
  CONFIRM_REVOKE = 'confirm_revoke',
  REFUND = 'refund',
  INVOICES = 'invoices',
  DELETE = 'delete',
  TERMINATE = 'terminate', // use to identify Kündigen
  SET_PRICE = 'set_price',
  COLLECTION_COMPLETE = 'collection_complete',
  COLLECTION_ACTS = 'collection_acts',
  REPLACEMENT_CARD = 'replacement_card',
  PLASTIC_CARD = 'plastic_card',
  LINK_CARD = 'link_card',
  UNLINK_CARD = 'unlink_card',
  DEACTIVATE = 'deactivate',
  CREATE_COMMERCIAL_OUTLET = 'create_commercial_outlet',
  SYNCHRONIZE_CUSTOMER = 'synchronize_customer',
  EXTENSION_TYPE = 'extension_type'
}

export enum EmbossmentType {
  ORDERS = 'order',
  EXTENSION = 'extension',
  EXPIRATION = 'expiration'
}

export enum CRUDMode {
  CREATE,
  EDIT,
  READ,
  ARCHIVE,
  DELETE
}

export enum CommercialOutlets {
  FIRST,
  SECOND
}
