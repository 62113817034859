import { PaginationData } from "./paginationDataModel";
import { Customer } from "./customerDataModel";
import { Filter } from "./sharedDataModels";
import { Product } from "./productsDataModel";
import { PaymentMethods } from "../enum/customer";
import { CardDeliveryStatus } from "../enum/cards";

export interface AllCardsResponse {
  code: number;
  message: string;
  body: PaginationData;
}

export interface AllLinkedCardsResponse {
  code: number;
  message: string;
  body: Card[];
}

export interface CardResponse {
  code: number;
  message: string;
  body: Card;
}

export interface CardsWithoutPagination {
  code: number;
  message: string;
  body: Card[];
}

export interface LinkCardResponse {
  code: number;
  message: string;
  body: LinkCardData;
}

export interface LinkCardData {
  data: Card;
}

export interface Card {
  id: number;
  productId: number;
  customerId: number;
  regionId: number;
  orderId: number;
  orderItemId: number;
  predecessorCardId: number;
  successorCardId: number;
  replacesCardId: number;
  replacedByCardId: number;
  cardNumber: string;
  deliveryStatus: CardDeliveryStatus;
  state: string;
  isOnlyDigital: boolean;
  isGift: boolean;
  isSubscription: boolean;
  magicCode: string;
  firstname: string;
  lastname: string;
  startDate: string;
  endDate: string;
  product: Product;
  customer: Customer;
  cardLink: CardLink;
  deactivationReason: string;
  subscriptionStatus: string;
  cancellationStatus: string;
  isCancellable: boolean;
  isCancellableForAp: boolean;
  isCancellationRevocable: boolean;
  createdAt: Date;
  updatedAt: Date;
  hasExtension: boolean;
  selectedExtensionType: ExtensionType;
  regionName?: string;
  possibleExtensionTypes: PossibleExtensionType[];
  canBookPlasticCard: boolean;
}

export interface CardLink {
  id: number;
  customerId: number;
  cardId: number;
  createdAt: string;
  updatedAt: string;
}

export interface CardAccessories {
  code: number;
  message: string;
  body: CardAccessory
}

export interface CardAccessory {
  data: string[];
}

export interface ReembossmentPayload {
  firstname: string;
  lastname: string;
  startDate: string;
  endDate: string;
}

export interface EditCardPayload {
  firstname: string;
  lastname: string;
  startDate: string;
  endDate: string;
}

export interface CardReplacementPayload {
  cardId: number;
  adjustedGrossPrice: string | null;
}

export interface CardReplacementCheckoutPayload {
  cart: CardReplacementPayload;
  paymentMethod: PaymentMethods;
  productDelivery: string;
  _iban: string;
  _bic: string;
}

export interface PossibleExtensionType {
  name: string;
  value: ExtensionType
}
export enum ExtensionType {
  digital = 'digital',
  physical = 'physical',
  cost_free = 'cost-free'
}

export const CardFilters: Filter[] = [
  {
    title: 'Erstellt von',
    formName: 'createdFrom',
    inputType: 'text',
  },
  {
    title: 'Erstellt bis',
    formName: 'createdTo',
    inputType: 'text',
  },
  {
    title: 'Startdatum von',
    formName: 'startDateFrom',
    inputType: 'text',
  },
  {
    title: 'Startdatum bis',
    formName: 'startDateTo',
    inputType: 'text',
  },
  {
    title: 'Enddatum von',
    formName: 'endDateFrom',
    inputType: 'text',
  },
  {
    title: 'Enddatum bis',
    formName: 'endDateTo',
    inputType: 'text',
  },
];

export const CardStatisticFilters: Filter[] = [
  {
    title: 'Erstellt von',
    formName: 'createdFrom',
    inputType: 'text',
  },
  {
    title: 'Erstellt bis',
    formName: 'createdTo',
    inputType: 'text',
  },
  {
    title: 'Startdatum von',
    formName: 'startDateFrom',
    inputType: 'text',
  },
  {
    title: 'Startdatum bis',
    formName: 'startDateTo',
    inputType: 'text',
  },
  {
    title: 'Enddatum von',
    formName: 'endDateFrom',
    inputType: 'text',
  },
  {
    title: 'Enddatum bis',
    formName: 'endDateTo',
    inputType: 'text',
  },
  {
    title: 'Kündigungseingang von',
    formName: 'cancellationDateFrom',
    inputType: 'text',
  },
  {
    title: 'Kündigungseingang bis',
    formName: 'cancellationDateTo',
    inputType: 'text',
  },
  {
    title: 'Kündigungsrückname von',
    formName: 'cancellationRevokedDateFrom',
    inputType: 'text',
  },
  {
    title: 'Kündigungsrückname bis',
    formName: 'cancellationRevokedDateTo',
    inputType: 'text',
  }
];
