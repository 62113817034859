import { RouterModule, Routes } from "@angular/router";
import { ErrorMessageComponent } from "../shared/components/error-message/error-message.component";
import { LockScreenComponent } from "./lock-screen/lock-screen.component";
import { NgModule } from "@angular/core";

const authenticationRoutes: Routes = [
  {
    path: 'lock-screen',
    component: LockScreenComponent,
  },
  {
    path: 'error',
    component: ErrorMessageComponent
  },
];

@NgModule({
            imports: [RouterModule.forChild(authenticationRoutes)],
            exports: [RouterModule]
          })
export class AuthenticationRoutingModule {
}
