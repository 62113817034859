import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import {
  AllCardsResponse, AllLinkedCardsResponse,
  CardAccessories, CardReplacementCheckoutPayload,
  CardReplacementPayload,
  CardResponse, CardsWithoutPagination, EditCardPayload,
  LinkCardResponse,
} from "../shared/dataModels/cardDataModel";
import { HttpClient, HttpParams } from "@angular/common/http";
import { apiUrl, Utils } from "../shared/utils/utils";
import { Params } from "@angular/router";
import { FilterUtils } from "../shared/utils/filter";
import { CustomUrlEncoder } from "../shared/utils/custom-url-encoder";
import { SimpleResponse } from "../shared/dataModels/sharedDataModels";
import {
  CheckoutInfoResponse,
  CheckoutResponse,
  EmbossmentsCreatedResponse
} from "../shared/dataModels/ordersDataModel";
import { EmbossmentType } from "../shared/enum/shared-enums";

@Injectable({
              providedIn: 'root'
            })
export class CardsService {
  newEmbossment$: Observable<EmbossmentType | null>;
  newEmbossmentSub = new BehaviorSubject<EmbossmentType | null>(null);

  constructor(private http: HttpClient) {
    this.newEmbossment$ = this.newEmbossmentSub.asObservable();
  }

  getCards(requestParams: Params): Observable<AllCardsResponse> {
    let queryParams = FilterUtils.trimRequestParams(requestParams);
    const params = new HttpParams({ encoder: new CustomUrlEncoder() })
      .appendAll(Utils.camelCaseToSnakeCase(queryParams));

    return this.http.get<AllCardsResponse>(apiUrl + 'cards', { params });
  }

  getCard(id: number): Observable<CardResponse> {
    return this.http.get<CardResponse>(apiUrl + 'cards/' + id);
  }

  getCustomerCards(id: number, requestParams?: Params): Observable<AllCardsResponse> {
    let params = new HttpParams({ encoder: new CustomUrlEncoder() });
    if (requestParams) {
      params = params.appendAll(requestParams);
    }
    params = params.delete('for')
                   .delete('regionId')
                   .delete('region')
                   .append('order_direction', 'desc')
                   .append('order_by', 'created_at');
    return this.http.get<AllCardsResponse>(apiUrl + 'customers/' + id + '/cards', { params });
  }

  getOrderCards(id: number, requestParams?: Params): Observable<AllCardsResponse> {
    let params = new HttpParams({ encoder: new CustomUrlEncoder() });
    if (requestParams) {
      params = params.appendAll(requestParams);
    }
    params = params.delete('for')
                   .delete('regionId')
                   .delete('region')
                   .append('order_direction', 'desc')
                   .append('order_by', 'created_at');
    return this.http.get<AllCardsResponse>(apiUrl + 'orders/' + id + '/cards', { params });
  }

  getLinkedCards(id: number, regionId: number): Observable<AllLinkedCardsResponse> {
    const params = new HttpParams({ encoder: new CustomUrlEncoder() })
      .append('id', id)
      .append('region_id', regionId)
      .append('order_direction', 'desc')
      .append('order_by', 'created_at');
    return this.http.get<AllLinkedCardsResponse>(apiUrl + 'customerCardLinks', { params });
  }

  getAvailableForEmbossment(requestParams: Params, type: EmbossmentType): Observable<CardsWithoutPagination> {
    let queryParams = FilterUtils.trimRequestParams(requestParams);
    const params = new HttpParams({ encoder: new CustomUrlEncoder })
      .appendAll(Utils.camelCaseToSnakeCase(queryParams));
    return this.http.get<CardsWithoutPagination>(apiUrl + 'cards/availableForEmbossment/' + type, { params });
  }

  getCardStatuses(): Observable<CardAccessories> {
    return this.http.get<CardAccessories>(apiUrl + 'cards/deliveryStatus');
  }

  getCardCancellationStatuses(): Observable<CardAccessories> {
    return this.http.get<CardAccessories>(apiUrl + 'cards/cancellationStatus');
  }

  getCardStates(): Observable<CardAccessories> {
    return this.http.get<CardAccessories>(apiUrl + 'cards/cardStates');
  }

  getDeactivationReasons(): Observable<CardAccessories> {
    return this.http.get<CardAccessories>(apiUrl + 'cards/deactivationReasons');
  }

  createEmbossmentList(cardIds: number[], embossmentType: EmbossmentType): Observable<EmbossmentsCreatedResponse> {
    return this.http.post<EmbossmentsCreatedResponse>(apiUrl + 'cards/embossments/generate', {
      type: embossmentType,
      card_ids: cardIds
    });
  }

  editCard(id: number, payload: EditCardPayload): Observable<SimpleResponse> {
    const parsedPayload = Utils.camelCaseToSnakeCase(payload);
    return this.http.put<SimpleResponse>(apiUrl + 'cards/' + id + '/updateOwnerOrValidity', { ...parsedPayload });
  }

  cancelCard(id: number): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(apiUrl + 'cards/' + id + '/cancelSubscription', {});
  }

  revokeCardCancellation(id: number): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(apiUrl + 'cards/' + id + '/revokeCancelSubscription', {});
  }

  getReplacementCardCheckoutInfo(replacementPayload: CardReplacementPayload): Observable<CheckoutInfoResponse> {
    const parsedPayload = Utils.camelCaseToSnakeCase(replacementPayload);
    return this.http.post<CheckoutInfoResponse>(apiUrl + 'checkout-card-replacement-info', { ...parsedPayload });
  }

  checkoutCardReplacement(payload: CardReplacementCheckoutPayload): Observable<CheckoutResponse> {
    const parsedPayload = Utils.camelCaseToSnakeCase(payload);
    return this.http.post<CheckoutResponse>(apiUrl + 'checkout-card-replacement', { ...parsedPayload });
  }

  checkoutPlasticCardInfo(replacementPayload: CardReplacementPayload): Observable<CheckoutInfoResponse> {
    const parsedPayload = Utils.camelCaseToSnakeCase(replacementPayload);
    return this.http.post<CheckoutInfoResponse>(apiUrl + 'checkout-plastic-card-info', { ...parsedPayload });
  }

  checkoutPlasticCard(payload: CardReplacementCheckoutPayload): Observable<CheckoutResponse> {
    const parsedPayload = Utils.camelCaseToSnakeCase(payload);
    return this.http.post<CheckoutResponse>(apiUrl + 'checkout-plastic-card', { ...parsedPayload });
  }

  linkCardWithMagicCode(customerId: number, magicCode: string): Observable<LinkCardResponse> {
    return this.http.post<LinkCardResponse>(apiUrl + 'linkCard/' + customerId, { magic_code: magicCode });
  }

  unlinkCard(id: number): Observable<SimpleResponse> {
    return this.http.delete<SimpleResponse>(apiUrl + 'unlinkCard/' + id);
  }

  setCardExtension(id: number, extensionType: string): Observable<SimpleResponse> {
    return this.http.put<SimpleResponse>(apiUrl + `card/${id}/extension-type`, { extension_type: extensionType });
  }

  emitNewEmbossment(value: EmbossmentType): void {
    this.newEmbossmentSub.next(value);
  }

  deactivateCard(id: number): Observable<SimpleResponse> {
    return this.http.put<SimpleResponse>(apiUrl + `cards/${id}/deactivate`, {});
  }
}
