import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iban'
})
export class IbanPipe implements PipeTransform {
  transform(value: string): string {
    let iban = value.replace(/\s/g, "");
    iban = iban.replace(/(.{4})/g, "$1 ");
    return iban;
  }
}
