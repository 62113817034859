<div class="accordion custom-accordion mb-3" id="custom-accordion-nine">
  <div class="card mb-0">
    <div class="card-header" id="headingNine">
      <h5 class="m-0">
        <i class="mdi mdi-chevron-down accordion-arrow custom-accordion-title me-3"
           [class.mt-1]="!noMemos"
           [ngClass]="{show: memosChevron, collapse: !memosChevron}"></i>
        <i class="mdi mdi-chevron-up accordion-arrow custom-accordion-title me-3"
           [class.mt-1]="!noMemos"
           [ngClass]="{show: !memosChevron, collapse: memosChevron}"></i>
        <a class="custom-accordion-title d-flex justify-content-between align-items-baseline"
           [class.py-1]="noMemos"
           data-bs-toggle="collapse" href="#collapseNine"
           (click)="memosChevron = !memosChevron"
           aria-expanded="true" aria-controls="collapseNine">
          Memos
          <div class="d-flex justify-content-end align-items-center gap-2 me-4 me-md-5">
            <button *ngIf="!noMemos" class="btn app-primary-button show-all-button d-none d-md-flex align-items-center gap-2"
                    type="button"
                    (click)="onNavigateToAllMemos()">
              Alles
            </button>
            <i class="mdi mdi-view-list-outline mdi-24px d-md-none icon-color me-md-3"
               (click)="onNavigateToAllMemos()"></i>
          </div>
        </a>
      </h5>
    </div>

    <div id="collapseNine"
         class="collapse"
         aria-labelledby="headingNine"
         data-bs-parent="#custom-accordion-nine">
      <div *appLoading="!memosLoaded" class="card-body">
        <div *ngIf="!noMemos" class="table-responsive">
          <table class="table table-striped mb-0">
            <thead class="table-light">
            <tr>
              <th class="table-cell">Betreff</th>
              <th class="table-cell">Nachricht</th>
              <th class="table-cell">Wiedervorlage</th>
              <th class="table-cell">Erstellt am</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let memo of memos.slice(0, 3)">
              <td><a [routerLink]="['/dashboard/memos', memo.id]"
                [queryParams]="{ editMode: MemoModes.READ }"
                queryParamsHandling="merge"
                class="neutral-anchor">
                {{ memo?.subject }}
              </a></td>
              <td>
                <a [routerLink]="['/dashboard/memos', memo.id]"
                [queryParams]="{ editMode: MemoModes.READ }"
                queryParamsHandling="merge"
                class="neutral-anchor">
                  <div *ngFor="let entry of memo.entries?.slice(0, 1); let f = first">
                    <br *ngIf="!f">
                    <span *ngIf="!entry.editor.customer" class="fw-bold">{{ entry.editor.email }} -</span>
                    <span *ngIf="entry?.editor?.customer" class="fw-bold">
                          {{ entry.editor.customer.firstname + ' ' + entry.editor.customer.lastname }} -
                    </span>
                    <span class="fw-bold"> {{ entry.updatedAt | date: 'dd.MM.yyyy' }}</span>
                    <br>
                    <span [innerHTML]="entry.message"></span>
                  </div>
                </a>
              </td>
              <td>
                <a [routerLink]="['/dashboard/memos', memo.id]"
                [queryParams]="{ editMode: MemoModes.READ }"
                queryParamsHandling="merge"
                class="neutral-anchor">
                  <i *ngIf="memo.followUp === FollowUp.ON; else noFollowUp"
                     class="mdi mdi-check-circle fs-4 text-dark"></i>
                  <ng-template #noFollowUp>
                    <i class="mdi mdi-block-helper fs-4 text-dark"></i>
                  </ng-template>
                </a>
              </td>
              <td><a [routerLink]="['/dashboard/memos', memo.id]"
                [queryParams]="{ editMode: MemoModes.READ }"
                queryParamsHandling="merge"
                class="neutral-anchor">
                {{ memo.createdAt | date:'dd.MM.yyyy' }}
              </a></td>
              <td>
                <a [routerLink]="['memos', memo.id, 'edit']"
                   [queryParams]="{ origin: customerId ? 'customers' : 'orders', editMode: MemoModes.EDIT }"
                   queryParamsHandling="merge"
                   class="neutral-anchor">
                  <i class="mdi mdi-pencil icon-color action-icon"></i>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="noMemos" class="text-center">
          <span>Keine Memos</span>
        </div>
      </div>
    </div>
  </div>
</div>
