import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SettingsService } from "../services/settings.service";
import { takeUntil } from "rxjs/operators";
import {
  EmailForNotifications,
  EmailForNotificationsResponse,
  Links,
  LinksResponse,
  Setting
} from "../shared/dataModels/settingsDataModels";
import { NotificationSettings } from "../shared/enum/settings";
import { BreadcrumbData } from "../shared/components/breadcrumbs/breadcrumbs.component";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertifyService } from "../shared/services/alertify-service.service";
import { SimpleResponse, SuccessfulCreationResponse } from "../shared/dataModels/sharedDataModels";
import { SubscriptionComponent } from "../shared/components/subscription/subscription.component";
import { ResponseCode } from "../shared/enum/shared-enums";
import { Utils } from "../shared/utils/utils";

@Component({
             selector: 'app-settings',
             templateUrl: './settings.component.html',
             styleUrls: ['./settings.component.scss']
           })
export class SettingsComponent extends SubscriptionComponent implements OnInit, OnDestroy {
  @ViewChild('confirmModal') confirmationModal: ElementRef;
  settings: Setting[];
  expirationNotificationValue: string;
  extensionNotificationValue: string;
  extensionExecutionNotificationValue: string;
  subscriptionCancellationValue: string;
  thirdDunningValue: string;
  voucherFeeValue: string;
  googlePlayLinkValue: string;
  appleStoreLinkValue: string;
  emailForNotificationsValue: string;
  defaultVatPercentageValue: string;
  NotificationSettings = NotificationSettings;
  targetedSetting: NotificationSettings;
  notificationsForm: FormGroup;
  modalHeader: string;
  modalText: string;
  settingsLoaded = false;
  waitingForResponse = false;
  error = false;

  constructor(private settingsService: SettingsService,
              private router: Router,
              private formBuilder: FormBuilder,
              private alertifyService: AlertifyService) { super() }

  ngOnInit(): void {
    this.getSettings();
    this.getLinks();
    this.getEmailForNotifications();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private getSettings(): void {
    this.settingsService.getNotificationSettings()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response) => {
          this.settings = response.body.data;
          this.mapSettings();
          this.initializeNotificationForm();
          this.settingsLoaded = true;
        }, () => {
          this.error = true;
        });
  }

  private mapSettings() {
    // @ts-ignore
    this.expirationNotificationValue = this.settings.find((setting: Setting) => {
      return setting.name === NotificationSettings.EXPIRATION;
    })?.value;
    // @ts-ignore
    this.extensionNotificationValue = this.settings.find((setting: Setting) => {
      return setting.name === NotificationSettings.EXTENSION;
    })?.value;
    // @ts-ignore
    this.extensionExecutionNotificationValue = this.settings.find((setting: Setting) => {
      return setting.name === NotificationSettings.EXTENSION_EXECUTION;
    })?.value;
    // @ts-ignore
    this.subscriptionCancellationValue = this.settings.find((setting: Setting) => {
      return setting.name === NotificationSettings.SUB_CANCELLATION;
    })?.value;
    // @ts-ignore
    this.voucherFeeValue = this.settings.find((setting: Setting) => {
      return setting.name === NotificationSettings.VOUCHER_FEE;
    })?.value;
    // @ts-ignore
    this.thirdDunningValue = this.settings.find((setting: Setting) => {
      return setting.name === NotificationSettings.THIRD_DUNNING;
    })?.value;
    // @ts-ignore
    this.defaultVatPercentageValue = this.settings.find((setting: Setting) => {
      return setting.name === NotificationSettings.DEFAULT_VAT_PERCENTAGE;
    })?.value;
  }

  private getLinks(): void {
    this.settingsService.getLinks()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: LinksResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            const parsedLinks: Links = Utils.snakeCaseToCamelCase(response.body.data);
            this.googlePlayLinkValue = parsedLinks.googlePlayStoreLink;
            this.appleStoreLinkValue = parsedLinks.appStoreLink;
            this.googlePlayLink?.patchValue(parsedLinks.googlePlayStoreLink);
            this.appleStoreLink?.patchValue(parsedLinks.appStoreLink);
          } else {
            this.alertifyService.warning(response.message);
          }
        });
  }

  private getEmailForNotifications(): void {
    this.settingsService.getEmailForNotification()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: EmailForNotificationsResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            const parsedEmailForNotifications: EmailForNotifications = Utils.snakeCaseToCamelCase(response.body.data);
            this.emailForNotificationsValue = parsedEmailForNotifications.emailForNotifications;
            this.emailForNotifications?.patchValue(parsedEmailForNotifications.emailForNotifications);
          } else {
            this.alertifyService.warning(response.message);
          }
        });
  }

  private initializeNotificationForm(): void {
    this.notificationsForm = this.formBuilder.group({
                                                      expirationNotificationThreshold: [this.expirationNotificationValue, [Validators.required]],
                                                      extensionNotificationThreshold: [this.extensionNotificationValue, [Validators.required]],
                                                      extensionExecutionThreshold: [this.extensionExecutionNotificationValue, [Validators.required]],
                                                      subscriptionCancellationThreshold: [this.subscriptionCancellationValue, [Validators.required]],
                                                      defaultVatPercentageThreshold: [this.defaultVatPercentageValue, [Validators.required]],
                                                      thirdDunningThreshold: [this.thirdDunningValue, [Validators.required]],
                                                      physicalVoucherFee: [this.voucherFeeValue, [Validators.required]],
                                                      googlePlayLink: [this.googlePlayLinkValue, [Validators.required]],
                                                      appleStoreLink: [this.appleStoreLinkValue, [Validators.required]],
                                                      emailForNotifications: [this.emailForNotificationsValue, [Validators.required]]
                                                    });
  }

  get expirationNotificationThreshold() {
    return this.notificationsForm.get('expirationNotificationThreshold');
  }

  get extensionNotificationThreshold() {
    return this.notificationsForm.get('extensionNotificationThreshold');
  }

  get extensionExecutionThreshold() {
    return this.notificationsForm.get('extensionExecutionThreshold');
  }

  get subscriptionCancellationThreshold() {
    return this.notificationsForm.get('subscriptionCancellationThreshold');
  }

  get defaultVatPercentageThreshold() {
    return this.notificationsForm.get('defaultVatPercentageThreshold');
  }

  get thirdDunningThreshold() {
    return this.notificationsForm.get('thirdDunningThreshold');
  }

  get physicalVoucherFee() {
    return this.notificationsForm.get('physicalVoucherFee');
  }

  get googlePlayLink() {
    return this.notificationsForm.get('googlePlayLink');
  }

  get appleStoreLink() {
    return this.notificationsForm.get('appleStoreLink');
  }

  get emailForNotifications() {
    return this.notificationsForm.get('emailForNotifications');
  }


  onChangeExpirationNotification(): void {
    this.modalHeader = 'Auslaufeinstellung ändern';
    this.modalText = 'Sind Sie sicher, dass Sie die Auslaufeinstellung ändern wollen?';
    this.targetedSetting = NotificationSettings.EXPIRATION;
    this.callModal();
  }

  onChangeExtensionNotification(): void {
    this.modalHeader = 'Verlängerungseinstellung ändern';
    this.modalText = 'Sind Sie sicher, dass Sie die Verlängerungseinstellung ändern wollen?';
    this.targetedSetting = NotificationSettings.EXTENSION;
    this.callModal();
  }

  onChangeExtensionExecutionNotification(): void {
    this.modalHeader = 'Ausführung Karte-Verlängerung ändern';
    this.modalText = 'Sind Sie sicher, dass Sie die Ausführungseinstellung ändern wollen?';
    this.targetedSetting = NotificationSettings.EXTENSION_EXECUTION;
    this.callModal();
  }

  onChangeSubscriptionCancellationThreshold(): void {
    this.modalHeader = 'Abonnement-Aufhebung ändern';
    this.modalText = 'Sind Sie sicher, dass Sie diesen Wert ändern wollen?';
    this.targetedSetting = NotificationSettings.SUB_CANCELLATION;
    this.callModal();
  }

  onChangeThirdDunningNotification(): void {
    this.modalHeader = 'Zeitintervall der dritten Mahnung ändern';
    this.modalText = 'Sind Sie sicher, dass Sie das Zeitintervall der dritten Mahnung ändern wollen?';
    this.targetedSetting = NotificationSettings.THIRD_DUNNING;
    this.callModal();
  }

  onChangeDefaultVatPercentage(): void {
    this.modalHeader = 'Standard-MwSt.-Prozentsatz ändern';
    this.modalText = 'Sind Sie sicher, dass Sie den MwSt.-Prozentsatz ändern wollen?';
    this.targetedSetting = NotificationSettings.DEFAULT_VAT_PERCENTAGE;
    this.callModal();
  }

  onChangePhysicalVoucherFee(): void {
    this.modalHeader = 'Haptischer Gutschein Aufpreis';
    this.modalText = 'Sind Sie sicher, dass Sie den Aufpreis ändern wollen?';
    this.targetedSetting = NotificationSettings.VOUCHER_FEE;
    this.callModal();
  }

  onChangeGooglePlayLink(): void {
    this.modalHeader = 'Google Play Link';
    this.modalText = 'Sind Sie sicher, dass Sie den Link ändern wollen?';
    this.targetedSetting = NotificationSettings.GOOGLE_PLAY_LINK;
    this.callModal();
  }

  onChangeAppleStoreLink(): void {
    this.modalHeader = 'Apple Store Link';
    this.modalText = 'Sind Sie sicher, dass Sie den Link ändern wollen?';
    this.targetedSetting = NotificationSettings.APPLE_STORE_LINK;
    this.callModal();
  }

  onChangeEmailForNotifications(): void {
    this.modalHeader = 'Email für die Benachrichtigungen';
    this.modalText = 'Sind Sie sicher, dass Sie die Email-Adresse ändern wollen?';
    this.targetedSetting = NotificationSettings.EMAIL_FOR_NOTIFICATIONS;
    this.callModal();
  }

  private callModal() {
    const modal = this.confirmationModal.nativeElement as HTMLElement;
    modal.click();
  }

  onAssignNumericValues(value: number, inputName: string) {
    // @ts-ignore
    this[inputName]?.setValue(value);
    // @ts-ignore
    this[inputName]?.markAsTouched();
    // @ts-ignore
    this[inputName]?.markAsDirty();
  }

  onConfirmSettingChange(): void {
    switch (this.targetedSetting) {
      case NotificationSettings.EXPIRATION:
        this.changeSetting(NotificationSettings.EXPIRATION,
                           this.expirationNotificationThreshold?.value,
                           'Die Auslaufsbenachrichtigung wurde erfolgreich gespeichert!');
        break;
      case NotificationSettings.EXTENSION:
        this.changeSetting(NotificationSettings.EXTENSION,
                           this.extensionNotificationThreshold?.value,
                           'Die Verlängerungsbenachrichtigung wurde erfolgreich gespeichert!');
        break;
      case NotificationSettings.EXTENSION_EXECUTION:
        this.changeSetting(NotificationSettings.EXTENSION_EXECUTION,
                           this.extensionExecutionThreshold?.value,
                           'Die Ausführung Karte-Verlängerung wurde erfolgreich gespeichert');
        break;
      case NotificationSettings.SUB_CANCELLATION:
        this.changeSetting(NotificationSettings.SUB_CANCELLATION,
                           this.subscriptionCancellationThreshold?.value,
                           'Die Abonnement-Aufhebung wurde erfolgreich gespeichert!');
        break;
      case NotificationSettings.VOUCHER_FEE:
        this.changeSetting(NotificationSettings.VOUCHER_FEE,
                           this.physicalVoucherFee?.value,
                           'Der Gutschein-Aufpreis wurde aktualisiert.');
        break;
      case NotificationSettings.DEFAULT_VAT_PERCENTAGE:
        this.changeSetting(NotificationSettings.DEFAULT_VAT_PERCENTAGE,
                           this.defaultVatPercentageThreshold?.value,
                           'Der MwSt.-Prozentsatz wurde aktualisiert.');
        break;
      case NotificationSettings.THIRD_DUNNING:
        this.changeSetting(NotificationSettings.THIRD_DUNNING,
                           this.thirdDunningThreshold?.value,
                           'Die dritte Mahnung wurde aktualisiert.');
        break;
      case NotificationSettings.GOOGLE_PLAY_LINK:
        this.changeRegionSettings(NotificationSettings.GOOGLE_PLAY_LINK,
                                  this.googlePlayLink?.value,
                                  'Der "Google Play" Link wurde aktualisiert.');
        break;
      case NotificationSettings.APPLE_STORE_LINK:
        this.changeRegionSettings(NotificationSettings.APPLE_STORE_LINK,
                                  this.appleStoreLink?.value,
                                  'Der "App Store" Link wurde aktualisiert.');
        break;
      case NotificationSettings.EMAIL_FOR_NOTIFICATIONS:
        this.changeRegionSettings(NotificationSettings.EMAIL_FOR_NOTIFICATIONS,
                                  this.emailForNotifications?.value,
                                  'Die Email-Adresse wurde aktualisiert.');
        break;
    }
  }

  private changeSetting(setting: NotificationSettings, value: number, successMessage: string): void {
    this.waitingForResponse = true;
    this.settingsService.editNotificationSetting(setting, value)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: SuccessfulCreationResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            this.waitingForResponse = false;
            this.getSettings();
            this.alertifyService.success(successMessage);
          } else {
            this.alertifyService.warning(response.message);
          }
        }, () => this.waitingForResponse = false);
  }

  private changeRegionSettings(setting: NotificationSettings, value: string, successMessage: string): void {
    this.waitingForResponse = true;
    this.settingsService.editRegionSetting(setting, value)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: SimpleResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            this.waitingForResponse = false;
            this.getLinks();
            this.getEmailForNotifications();
            this.alertifyService.success(successMessage);
          } else {
            this.alertifyService.warning(response.message);
          }
        }, () => this.waitingForResponse = false);
  }

  onCancel() {
    switch (this.targetedSetting) {
      case NotificationSettings.EXPIRATION:
        this.expirationNotificationThreshold?.patchValue(this.expirationNotificationValue);
        this.expirationNotificationThreshold?.markAsPristine();
        break;
      case NotificationSettings.EXTENSION:
        this.extensionNotificationThreshold?.patchValue(this.extensionNotificationValue);
        this.extensionNotificationThreshold?.markAsPristine();
        break;
      case NotificationSettings.EXTENSION_EXECUTION:
        this.extensionExecutionThreshold?.patchValue(this.extensionExecutionNotificationValue);
        this.extensionExecutionThreshold?.markAsPristine();
        break;
      case NotificationSettings.SUB_CANCELLATION:
        this.subscriptionCancellationThreshold?.patchValue(this.subscriptionCancellationValue);
        this.subscriptionCancellationThreshold?.markAsPristine();
        break;
      case NotificationSettings.VOUCHER_FEE:
        this.physicalVoucherFee?.patchValue(this.voucherFeeValue);
        this.physicalVoucherFee?.markAsPristine();
        break;
      case NotificationSettings.THIRD_DUNNING:
        this.thirdDunningThreshold?.patchValue(this.thirdDunningValue);
        this.thirdDunningThreshold?.markAsPristine();
        break;
      case NotificationSettings.GOOGLE_PLAY_LINK:
        this.googlePlayLink?.patchValue(this.googlePlayLinkValue);
        this.googlePlayLink?.markAsPristine();
        break;
      case NotificationSettings.APPLE_STORE_LINK:
        this.appleStoreLink?.patchValue(this.appleStoreLinkValue);
        this.appleStoreLink?.markAsPristine();
        break;
      case NotificationSettings.EMAIL_FOR_NOTIFICATIONS:
        this.emailForNotifications?.patchValue(this.emailForNotificationsValue);
        this.emailForNotifications?.markAsPristine();
        break;
    }
  }

  navigateTo(breadcrumb: BreadcrumbData) {
    this.router.navigate(['/' + breadcrumb.routerLink]);
  }
}
