import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { TroubleshootingResponse } from "../shared/dataModels/troubleshootingDataModel";
import { apiUrl, Utils } from "../shared/utils/utils";
import { FilterUtils } from "../shared/utils/filter";
import { CustomUrlEncoder } from "../shared/utils/custom-url-encoder";
import { Params } from "@angular/router";
import { TroubleShootingCreatedResponse } from "../shared/dataModels/ordersDataModel";

@Injectable({
  providedIn: 'root'
})
export class TroubleshootingService {
  constructor(private http: HttpClient) { }

  getItemsToTroubleshoot(requestParameters: Params): Observable<TroubleshootingResponse> {
    let queryParams = FilterUtils.trimRequestParams(requestParameters);
    const params = new HttpParams({ encoder: new CustomUrlEncoder })
      .appendAll(Utils.camelCaseToSnakeCase(queryParams));
    return this.http.get<TroubleshootingResponse>(apiUrl + 'troubleshootOrders', { params });
  }

  setResolvedOnTroubleShootingList(items: number[]): Observable<TroubleShootingCreatedResponse> {
    return this.http.put<TroubleShootingCreatedResponse>(apiUrl + 'orders/troubleShooting/resolve', { troubleshoot_ids: items });
  }
}
