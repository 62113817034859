<div class="container">
  <div class="row justify-content-center">
      <div *appLoading="!deploymentLoaded" class="border border-5 p-3 text-xl-center">
        <div>Date: {{ deployment?.deploymentDate }}</div>
        <div>Commit ID: {{ deployment?.commitId }}</div>
        <div>Commit Message: {{ deployment?.commitMessage }}</div>
        <div>Commit Date: {{ deployment?.commitDate }}</div>
      </div>
  </div>
</div>
