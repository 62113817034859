import {Component, OnDestroy, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SubscriptionComponent } from "../../shared/components/subscription/subscription.component";
import { AuthenticationService } from "../../services/authentication.service";
import { ActivatedRoute, Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { SimpleResponse } from "../../shared/dataModels/sharedDataModels";
import { ResponseCode } from "../../shared/enum/shared-enums";
import { AlertifyService } from "../../shared/services/alertify-service.service";
import { Utils } from "../../shared/utils/utils";

@Component({
             selector: 'app-reset-password',
             templateUrl: './reset-password.component.html',
             styleUrls: ['./reset-password.component.scss']
           })
export class ResetPasswordComponent extends SubscriptionComponent implements OnInit, OnDestroy {
  token: string;
  resetPasswordForm: FormGroup;
  passwordType: `password` | `text` = 'password';
  confirmPasswordType: `password` | `text` = 'password';
  currentYear = new Date().getFullYear();

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private authenticationService: AuthenticationService,
              private alertifyService: AlertifyService) { super() }

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParams['token'];
    this.resetPasswordForm = this.formBuilder.group({
                                                      password: ['', [Validators.required, Validators.minLength(8)]],
                                                      confirmPassword: ['', [Validators.required, Validators.minLength(8)]]
                                                    }, { validators: Utils.comparePasswords('password', 'confirmPassword') });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  get password() {
    return this.resetPasswordForm.get('password');
  }

  get confirmPassword() {
    return this.resetPasswordForm.get('confirmPassword');
  }

  onResetPassword(): void {
    this.authenticationService.resetPassword(this.password?.value, this.token)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: SimpleResponse) => {
        if (response.code === ResponseCode.SUCCESS) {
          this.alertifyService.success('Sie haben Ihr Password erfolgreich zurückgesetzt.');
          this.router.navigate(['/login']);
        } else {
          this.alertifyService.warning(response.message);
        }
      });
  }

  hidePassword(confirm?: string) {
    if (confirm) {
      this.confirmPasswordType = 'password';
      return;
    }
    this.passwordType = 'password';
  }

  showPassword(confirm?: string) {
    if (confirm) {
      this.confirmPasswordType = 'text';
      return;
    }
    this.passwordType = 'text';
  }
}
