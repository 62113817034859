import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { LoginRegion, User, UserResponse, UserResponseData } from '../shared/dataModels/userDataModel';
import { takeUntil } from 'rxjs/operators';
import { AppGlobalService } from '../shared/services/app-global.service';
import { MeService } from '../services/me.service';
import { RegionsService } from "../services/regions.service";
import { AllRegionsResponse, SetDefaultRegionResponse } from "../shared/dataModels/regionDataModels";
import { AuthenticationService } from "../services/authentication.service";
import { Customer } from '../shared/dataModels/customerDataModel';
import { HttpErrorResponse } from "@angular/common/http";
import { Utils } from "../shared/utils/utils";
import { ErrorStatus } from "../shared/enum/error-status";
import { MessageTypes } from "../shared/enum/message-types";
import { LocalStorageKeys, ResponseCode } from "../shared/enum/shared-enums";
import { SubscriptionComponent } from "../shared/components/subscription/subscription.component";
import { AlertifyService } from "../shared/services/alertify-service.service";


@Component({
             selector: 'app-main-dashboard',
             templateUrl: './main-dashboard.component.html',
             styleUrls: ['./main-dashboard.component.scss'],
           })
export class MainDashboardComponent extends SubscriptionComponent implements OnInit {
  customer: Customer;
  user: User;
  meData: UserResponseData;
  defaultLoginRegion: LoginRegion;
  selectedRegionInUrl: string;
  showDefaultRegionSelectionModal: boolean = false;
  MessageType = MessageTypes;
  isSelectionDefaultRegionFailed: boolean = false;
  sideMenuToggle = false;


  constructor(public router: Router,
              public activeRoute: ActivatedRoute,
              private meService: MeService,
              private regionService: RegionsService,
              private appGlobalService: AppGlobalService,
              private authenticationService: AuthenticationService,
              private alertifyService: AlertifyService) { super() }

  ngOnInit(): void {
    this.getRegions();
    this.getMeData();
  }

  getMeData() {
    this.meService.getMeData()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((meData: UserResponse) => {
          if (meData.code === ResponseCode.SUCCESS) {
            this.meData = Utils.snakeCaseToCamelCase(meData.body);
            this.defaultLoginRegion = this.meData?.defaultLoginRegion;
            const { user, customerId, firstname, lastname } = this.meData;
            Utils.setItemInLocalStorage(LocalStorageKeys.USER, JSON.stringify(
              { user, customerId, firstname, lastname }));
            this.appGlobalService.emitGlobalData({ meData: meData });
            this.passRegionInUrl();
            this.openSelectDefaultRegionModal();
            this.user = this.getMeUserData();
          } else {
            this.alertifyService.warning(meData.message);
          }
        }, (error: HttpErrorResponse) => {
          if (error.status === ErrorStatus.STATUS_500) {
            this.router.navigate(['/500'], { queryParamsHandling: "merge" });
          }
        });
  }

  private getRegions(): void {
    this.regionService.getRegions().pipe(takeUntil(this.ngUnsubscribe)).subscribe();
  }

  onLogout() {
    this.authenticationService.logoutUser()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data: any) => {
          if (data.code === ResponseCode.FAILED) {
            this.alertifyService.warning(data.message);
          }
        })

    localStorage.clear();
    this.navigateTo('/login');
  }

  openSelectDefaultRegionModal() {
    if (!this.isDefaultRegionInLoginRegions() && !this.selectedRegionInUrl) {
      this.showDefaultRegionSelectionModal = true;
    }
  }

  private isDefaultRegionInLoginRegions(): boolean {
    return this.meData?.loginRegions.some((region: LoginRegion) => {
      return region.id === this.defaultLoginRegion.id;
    });
  }

  onSaveSelectedDefaultRegion(region: LoginRegion) {
    this.regionService.setDefaultLogin(region.id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data: SetDefaultRegionResponse) => {
          if (data.code === ResponseCode.SUCCESS) {
            this.showDefaultRegionSelectionModal = false;
            Utils.setItemInLocalStorage(LocalStorageKeys.REGION, JSON.stringify(region))
            this.passRegionInUrl();
            this.onChangeSelectedDefaultRegion(region);
          }
        }, (() => {
          this.isSelectionDefaultRegionFailed = true;
        }));
  }

  onChangeSelectedDefaultRegion(region: LoginRegion) {
    Utils.setItemInLocalStorage(LocalStorageKeys.REGION, JSON.stringify(region));
    this.activeRoute.params.subscribe(() => {
      let queryParams = this.activeRoute.snapshot.queryParams;
      queryParams = {
        ...queryParams,
        region: region.name.toLowerCase(),
      }
      if (queryParams.filters_applied && queryParams.region_id) {
        queryParams.region_id = region.id;
      }
      const currentUrl = this.router.url.split('?')[0];
      this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl], { queryParams });
      });
    })
  }

  getSelectedRegion(): string {
    const selectedRegion = JSON.parse(Utils.getItemFromLocalStorage(LocalStorageKeys.REGION));
    const loginDefaultRegion = this.meData?.defaultLoginRegion;
    if (!loginDefaultRegion && !selectedRegion) {
      return 'region';
    }

    if (loginDefaultRegion && !selectedRegion) {
      Utils.setItemInLocalStorage(LocalStorageKeys.REGION, JSON.stringify(loginDefaultRegion))
      return loginDefaultRegion.name.toLowerCase();
    }
    return selectedRegion.name.toLowerCase();
  }

  getMeUserData(): User {
    const { user } = JSON.parse(Utils.getItemFromLocalStorage(LocalStorageKeys.USER))
    return user;
  }

  saveSelectedRegionInLocalRegion(selectedRegion: LoginRegion) {
    const storedRegion = JSON.parse(Utils.getItemFromLocalStorage(LocalStorageKeys.REGION));
    const loginDefaultRegion = this.meData?.defaultLoginRegion;
    if (!storedRegion && !loginDefaultRegion) {
      Utils.setItemInLocalStorage(LocalStorageKeys.REGION, JSON.stringify(selectedRegion))
    }
  }

  navigateTo(name: string) {
    this.router.navigate([name]);
  }

  passRegionInUrl() {
    this.activeRoute.queryParams.subscribe((params) => {
      this.selectedRegionInUrl = params.region;
    });

    const selectedRegionFromLocalStorage = Utils.getItemFromLocalStorage(LocalStorageKeys.REGION);
    let nextSelectedRegion;

    this.meData?.loginRegions?.forEach((region: LoginRegion) => {
      if (this.defaultLoginRegion && (region.name?.toLowerCase() === this.selectedRegionInUrl)) {
        Utils.setItemInLocalStorage(LocalStorageKeys.REGION, JSON.stringify(region));
        nextSelectedRegion = region;
      }
      if (!this.defaultLoginRegion && (region.name?.toLowerCase() === this.selectedRegionInUrl)) {
        Utils.setItemInLocalStorage(LocalStorageKeys.REGION, JSON.stringify(region));
        this.regionService.setDefaultLogin(region.id).subscribe();
        this.defaultLoginRegion = region;
        nextSelectedRegion = region;
      }
    });

    if (!this.defaultLoginRegion && !nextSelectedRegion && !selectedRegionFromLocalStorage && this.selectedRegionInUrl) {
      const firstRegion = this.meData.loginRegions[0];
      Utils.setItemInLocalStorage(LocalStorageKeys.REGION, JSON.stringify(firstRegion));
      this.regionService.setDefaultLogin(firstRegion.id).subscribe();
      this.defaultLoginRegion = firstRegion;
    }
    const url = this.router.url.split('?')[0];
    this.router.navigate([url], { queryParams: { region: this.getSelectedRegion() }, queryParamsHandling: 'merge' });
  }
}
