import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ActionHistoryResponse } from "../dataModels/sharedDataModels";
import { apiUrl } from "../utils/utils";

@Injectable({
  providedIn: 'root'
})
export class ActionHistoryService {
  constructor(private http: HttpClient) { }

  fetchActionHistory(target: string, id: number): Observable<ActionHistoryResponse> {
    return this.http.get<ActionHistoryResponse>(apiUrl + target + '/' + id + '/actions');
  }
}
