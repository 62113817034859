<div class="accordion custom-accordion mb-3" [id]="'custom-accordion-' + statistic">
  <div class="card mb-0">
    <div class="card-header" [id]="'heading' + statistic">
      <h5 class="m-0">
        <i class="mdi mdi-chevron-down accordion-arrow custom-accordion-title me-3 mt-1"
           [ngClass]="{show: chevron, collapse: !chevron}"></i>
        <i class="mdi mdi-chevron-up accordion-arrow custom-accordion-title me-3 mt-1"
           [ngClass]="{show: !chevron, collapse: chevron}"></i>
        <a class="custom-accordion-title d-flex justify-content-between align-items-baseline py-1"
           data-bs-toggle="collapse" [href]="'#collapse' + statistic"
           (click)="chevron = !chevron"
           aria-expanded="true" [attr.aria-controls]="'collapse' + statistic">
          <div>{{ statistic }}</div>
          <div *ngIf="paginatedData?.pagination?.total" class="items-length me-3">
            + {{ paginatedData.pagination.total }}
          </div>
        </a>
      </h5>
    </div>
  </div>
  <div [id]="'collapse' + statistic"
       class="collapse"
       [attr.aria-labelledby]="'heading' + statistic"
       [attr.data-bs-parent]="'#custom-accordion-' + statistic">
    <div class="card-body">
      <div class="loading-spinner" *ngIf="!error">
        <div *appLoading="!responseSuccessful">
          <div class="d-md-flex justify-content-start justify-content-xxxl-end align-items-center gap-2">
            <form [formGroup]="regionForm" class="d-md-flex justify-content-start col-md-6 gap-2">
              <div class="row col-xxxl-1 mb-3 mb-md-0">
                <div>
                  <label class="col-form-label" for="regionId">Region</label>
                  <select formControlName="regionId"
                          id="regionId"
                          class="form-select cursor-pointer me-3">
                    <option value="-1">Alle</option>
                    <option *ngFor="let region of regions" [value]="region.id">{{ region.name }}</option>
                  </select>
                </div>
              </div>
               <div class="col-xxxl-4">
                <button type="button" (click)="onRefreshList()" class="btn app-primary-button m-refresh-button">
                  <i class="mdi mdi-refresh cursor-pointer align-self-center mt-2"></i>
                  <span>Neu laden</span>
                </button>
              </div>
            </form>
            <div class="col-md-6 d-md-flex justify-content-end gap-4">
              <form [formGroup]="salesStatisticNameForm" class="col-lg-7 col-xxl-5 mb-2 mb-md-0">
                <div>
                  <label for="name" class="col-form-label">
                    Berichtname <span class="text-danger">*</span>
                  </label>
                  <input type="text" id="name" formControlName="name" class="form-control">
                  <span
                    *ngIf="!salesStatisticNameForm?.get('name')?.valid && salesStatisticNameForm?.get('name')?.touched"
                    class="error-feedback mb-3">Das Feld ist erforderlich!</span>
                </div>
              </form>
              <app-filter #filter
                          identifier="sales-modal"
                          [initialClick]="initialClick"
                          triggerButton="Bericht erstellen"
                          [disableTriggerButton]="salesStatisticNameForm.get('name')?.invalid"
                          (openModal)="filter.openModalWindow(); this.initialClick = false;">
                <app-sales-statistic-filters #salesFiltersComponent (filterValues)="onFilterReports($event)">
                </app-sales-statistic-filters>
              </app-filter>
            </div>
          </div>
          <!-- TABLE -->
          <div *ngIf="salesReports && salesReports.length > 0"
               class="table-responsive db-table-width scroll-table mt-3">
            <table class="table table-striped table-centered mb-0">
              <thead class="table-light">
              <tr>
                <th class="table-cell">ID</th>
                <th class="table-cell">Bericht</th>
                <th class="table-cell">Erstelldatum</th>
                <th class="table-cell">Erstellt von</th>
                <th class="table-cell">Produkttyp</th>
                <th class="table-cell">Bestellart</th>
                <th class="table-cell">Bestellstatus</th>
                <th class="table-cell">Bestellzustand</th>
                <th class="table-cell">Zahlungsmethode</th>
                <th class="table-cell">Zahlstatus</th>
                <th class="table-cell">Bestelleingang von</th>
                <th class="table-cell">Bestelleingang bis</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let orderReport of salesReports; index as i" class="bg-aqua">
                <td class="table-cell">{{ orderReport.id }}</td>
                <td class="table-cell text-nowrap">
                  <table class="w-100">
                    <tr class="d-flex justify-content-between">
                      <td>{{ orderReport.name }}</td>
                      <td>
                        <ng-container *ngIf="orderReport.fileUrl; else noLinkYet">
                          <a (click)="onDownloadReport(orderReport.fileUrl, orderReport.name)"
                             class="link-primary cursor-pointer">
                            <i class="mdi mdi-download-outline mdi-18px"></i>
                          </a>
                        </ng-container>
                        <a class="link-primary cursor-pointer ms-lg-2"
                           (click)="deleteReport.emit(orderReport)"
                           data-bs-toggle="modal"
                           data-bs-target="#danger-header-modal">
                          <i class="mdi mdi-trash-can mdi-18px"></i>
                        </a>
                        <ng-template #noLinkYet>
                          <span class="badge-in-progress p-1">In Bearbeitung...</span>
                        </ng-template>
                      </td>
                    </tr>
                  </table>
                </td>
                <td class="table-cell">{{ Utils.getLocalDateFromUtc(orderReport.createdAt) }}</td>
                <td class="table-cell">{{ orderReport.email }}</td>
                <td class="table-cell">
                  <ng-container *ngIf="orderReport.filters.productType">
                    <ng-container *ngIf="orderReport.filters.duration">
                      {{ orderReport.filters.duration + ' ' + orderReport.filters.timeUnit }}
                    </ng-container>
                    <ng-container *ngIf="!orderReport.filters.duration && orderReport.filters.productType !== 'Ersatzkarte'">
                      Abo-
                    </ng-container>
                    {{ orderReport.filters.productType }}
                  </ng-container>
                  <ng-container *ngIf="orderReport.filters.voucherAmount">
                    Gutschein {{ orderReport.filters.voucherAmount | currency: 'EUR' }}
                  </ng-container>
                  <span *ngIf="!orderReport.filters.productType && !orderReport.filters.voucherAmount">&#8212;</span>
                </td>
                <td class="table-cell">
                  <ng-container *ngIf="orderReport.filters.orderType; else noOrderType">
                    {{ getOrderTypeDisplayValue(orderReport.filters.orderType) }}
                  </ng-container>
                  <ng-template #noOrderType>&#8212;</ng-template>
                </td>
                <td class="table-cell">
                  <ng-container *ngIf="orderReport.filters.orderStatus; else noOrderStatus">
                    {{ orderReport.filters.orderStatus }}
                  </ng-container>
                  <ng-template #noOrderStatus>&#8212;</ng-template>
                </td>
                <td class="table-cell">
                  <ng-container *ngIf="orderReport.filters.orderState; else noOrderState">
                    {{ orderReport.filters.orderState }}
                  </ng-container>
                  <ng-template #noOrderState>&#8212;</ng-template>
                </td>
                <td class="table-cell">
                  <ng-container *ngIf="orderReport.filters.paymentMethod; else noPaymentMethod">
                    {{ orderReport.filters.paymentMethod }}
                  </ng-container>
                  <ng-template #noPaymentMethod>&#8212;</ng-template>
                </td>
                <td class="table-cell">
                  <ng-container *ngIf="orderReport.filters.paymentStatus; else noPaymentStatus">
                    {{ orderReport.filters.paymentStatus }}
                  </ng-container>
                  <ng-template #noPaymentStatus>&#8212;</ng-template>
                </td>
                <td class="table-cell">
                  <ng-container *ngIf="orderReport.filters.createdFrom; else noCreatedFrom">
                    {{ Utils.getLocalDateFromUtc(orderReport.filters.createdFrom) }}
                  </ng-container>
                  <ng-template #noCreatedFrom>&#8212;</ng-template>
                </td>
                <td class="table-cell">
                  <ng-container *ngIf="orderReport.filters.createdTo; else noCreatedTo">
                    {{ Utils.getLocalDateFromUtc(orderReport.filters.createdTo) }}
                  </ng-container>
                  <ng-template #noCreatedTo>&#8212;</ng-template>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="models && models.length === 0" class="mt-3">Keine Daten vorhanden.</div>
        </div>
      </div>
    </div>
  </div>
</div>
