import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import {
  AllOrdersResponse,
  Checkout,
  CheckoutInfoResponse,
  CheckoutResponse,
  StringArrayResponse,
  OrderPayload,
  EmbossmentsCreatedResponse,
  EmbossmentsResponse,
  OrderResponse,
  CorporateOrderPayload,
  CorporateCheckout,
  CorporateCheckoutAddress,
  RefundPayload,
  OrderShippingAddressPayload, OrdersWithoutPaginationResponse, VoucherCheckout
} from "../shared/dataModels/ordersDataModel";
import { apiUrl, Utils } from "../shared/utils/utils";
import { Params } from "@angular/router";
import { FilterUtils } from "../shared/utils/filter";
import { CustomUrlEncoder } from "../shared/utils/custom-url-encoder";
import { SimpleResponse } from "../shared/dataModels/sharedDataModels";
import { VoucherPayload } from "../shared/dataModels/vouchersDataModels";
import { EmbossmentType, LocalStorageKeys } from "../shared/enum/shared-enums";
import { OrderPaymentTransactionsResponse } from "../shared/dataModels/paymentTransactionModel";

@Injectable({
              providedIn: 'root'
            })
export class OrdersService {
  newEmbossment$: Observable<boolean>;
  newEmbossmentSub = new BehaviorSubject<boolean>(false);
  newEncashment$: Observable<boolean>;
  newEncashmentSub = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    this.newEmbossment$ = this.newEmbossmentSub.asObservable();
    this.newEncashment$ = this.newEncashmentSub.asObservable();
  }

  getOrders(requestParameters: Params): Observable<AllOrdersResponse | OrdersWithoutPaginationResponse> {
    let queryParams = FilterUtils.trimRequestParams(requestParameters);
    const params = new HttpParams({ encoder: new CustomUrlEncoder })
      .appendAll(Utils.camelCaseToSnakeCase(queryParams));
    return this.http.get<AllOrdersResponse | OrdersWithoutPaginationResponse>(apiUrl + 'orders', { params });
  }

  getOrder(id: number): Observable<OrderResponse> {
    return this.http.get<OrderResponse>(apiUrl + 'orders/' + id);
  }

  getCustomersOrders(id: number, requestParams?: Params): Observable<AllOrdersResponse> {
    let params = new HttpParams({ encoder: new CustomUrlEncoder() });
    if (requestParams) {
      params = params.appendAll(requestParams);
    }
    params = params.delete('for')
                   .delete('regionId')
                   .delete('region')
                   .append('order_direction', 'desc')
                   .append('order_by', 'created_at');
    return this.http.get<AllOrdersResponse>(apiUrl + 'customers/' + id + '/orders', { params });
  }

  getPaymentMethods(): Observable<StringArrayResponse> {
    const params = new HttpParams().set('checkout_source', 'Alle');
    return this.http.get<StringArrayResponse>(apiUrl + 'orders/paymentMethods', { params });
  }

  getCompanyPaymentMethods(): Observable<StringArrayResponse> {
    return this.http.get<StringArrayResponse>(apiUrl + 'orders/companyPaymentMethods');
  }

  getPaymentStatuses(): Observable<StringArrayResponse> {
    return this.http.get<StringArrayResponse>(apiUrl + 'orders/paymentStatus');
  }

  getSources(): Observable<StringArrayResponse> {
    return this.http.get<StringArrayResponse>(apiUrl + 'orders/sources');
  }

  getStatuses(): Observable<StringArrayResponse> {
    return this.http.get<StringArrayResponse>(apiUrl + 'orders/status');
  }

  getStates(): Observable<StringArrayResponse> {
    return this.http.get<StringArrayResponse>(apiUrl + 'orders/states');
  }

  getDeliveryMethods(): Observable<StringArrayResponse> {
    return this.http.get<StringArrayResponse>(apiUrl + 'orders/productDelivery');
  }

  getRefundMethods(): Observable<StringArrayResponse> {
    return this.http.get<StringArrayResponse>(apiUrl + 'refund/refundMethods');
  }

  checkoutOrder(order: OrderPayload): Observable<CheckoutInfoResponse> {
    const payload = Utils.camelCaseToSnakeCase(order);
    return this.http.post<CheckoutInfoResponse>(apiUrl + 'checkoutInfo', { ...payload });
  }

  checkoutCorporateOrder(order: CorporateOrderPayload, file: File): Observable<CheckoutInfoResponse> {
    const payload = Utils.camelCaseToSnakeCase(order);
    let formData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    formData.append("file", file);

    return this.http.post<CheckoutInfoResponse>(apiUrl + 'checkout-company-info', formData);
  }

  createOrder(order: Checkout): Observable<CheckoutResponse> {
    const payload = Utils.camelCaseToSnakeCase(order);
    return this.http.post<CheckoutResponse>(apiUrl + 'checkout', { ...payload });
  }

  checkoutVouchers(payload: VoucherPayload): Observable<CheckoutInfoResponse> {
    const parsedPayload = Utils.camelCaseToSnakeCase(payload);
    return this.http.post<CheckoutInfoResponse>(apiUrl + 'checkout-company-voucher-info', { ...parsedPayload });
  }

  createCorporateOrder(order: CorporateOrderPayload,
                       orderDetails: CorporateCheckout,
                       shippingAddress: CorporateCheckoutAddress,
                       file: File): Observable<CheckoutResponse> {
    const parsedOrder = Utils.camelCaseToSnakeCase(order);
    const parsedDetails = Utils.camelCaseToSnakeCase(orderDetails);
    const parsedAddress = Utils.camelCaseToSnakeCase(shippingAddress);
    let formData = new FormData();
    for (const key in parsedOrder) {
      formData.append(key, parsedOrder[key]);
    }
    for (const key in parsedDetails) {
      formData.append(key, parsedDetails[key]);
    }
    for (const key in parsedAddress) {
      formData.append(`shipping_address[${key}]`, parsedAddress[key]);
    }
    formData.append("file", file);

    return this.http.post<CheckoutResponse>(apiUrl + 'checkout-company', formData);
  }

  createVoucherOrder(payload: VoucherCheckout): Observable<CheckoutResponse> {
    const parsedPayload = Utils.camelCaseToSnakeCase(payload);
    return this.http.post<CheckoutResponse>(apiUrl + 'checkout-company-voucher', { ...parsedPayload });
  }

  getEmbossments(requestParams: Params, type: EmbossmentType): Observable<EmbossmentsResponse> {
    let queryParams = FilterUtils.trimRequestParams(requestParams);
    const params = new HttpParams({ encoder: new CustomUrlEncoder })
      .appendAll(Utils.camelCaseToSnakeCase(queryParams));
    return this.http.get<EmbossmentsResponse>(apiUrl + 'embossments/' + type, { params });
  }

  getEmbossmentValues(): Observable<StringArrayResponse> {
    return this.http.get<StringArrayResponse>(apiUrl + 'orders/availableForEmbossment');
  }

  generateEmbossmentList(ordersForEmbossment: number[]): Observable<EmbossmentsCreatedResponse> {
    return this.http.post<EmbossmentsCreatedResponse>(apiUrl + 'orders/embossments/generate', { order_ids: ordersForEmbossment });
  }

  emitNewEmbossment(value: boolean): void {
    this.newEmbossmentSub.next(value);
  }

  emitNewEncashment(value: boolean): void {
    this.newEncashmentSub.next(value);
  }

  transferInkassoOrders(encashmentOrders: number[]): Observable<SimpleResponse> {
    const regionId = JSON.parse(Utils.getItemFromLocalStorage(LocalStorageKeys.REGION)).id;
    return this.http.post<SimpleResponse>(
      apiUrl + 'encashment/transfer',
      { order_ids: encashmentOrders, region_id: regionId }
    );
  }

  revokeOrder(orderId: number, value: boolean): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(apiUrl + 'refund/initializeRevocation/' + orderId, {
      confirm_revocation: value
    });
  }

  cancelOrder(id: number): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(apiUrl + 'refund/initializeReverse/' + id, {});
  }

  refundOrder(id: number, refundData: RefundPayload): Observable<SimpleResponse> {
    const parsedPayload = Utils.camelCaseToSnakeCase(refundData);
    return this.http.post<SimpleResponse>(apiUrl + 'refund/partialRefund/' + id, { ...parsedPayload });
  }

  editOrderShippingAddress(address: OrderShippingAddressPayload, orderId: number): Observable<SimpleResponse> {
    const parsedAddress = Utils.camelCaseToSnakeCase(address);
    return this.http.put<SimpleResponse>(apiUrl + 'orders/' + orderId + '/shippingAddress', { ...parsedAddress });
  }

  completeOrderCollection(id: number): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(apiUrl + 'encashment/complete/' + id, {});
  }

  activateOrderCollection(id: number): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(apiUrl + 'encashment/akte/' + id, {});
  }

  downloadFile(url: string): Observable<ArrayBuffer> {
    return this.http.get(url, { responseType: 'arraybuffer' });
  }

  getPaymentTransactionsOfOrder(id: number): Observable<OrderPaymentTransactionsResponse> {
    return this.http.get<OrderPaymentTransactionsResponse>(apiUrl + 'orders/' + id + '/payone-transactions', {});
  }

  getTroubleShootingTypes(): Observable<StringArrayResponse> {
    return this.http.get<StringArrayResponse>(apiUrl + 'orders/troubleShootingTypes');
  }
}
