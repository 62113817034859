import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-data-failed',
  templateUrl: './loading-data-failed.component.html',
  styleUrls: ['./loading-data-failed.component.scss']
})
export class LoadingDataFailedComponent {
  @Input() message: string;
}
