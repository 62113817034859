<app-breadcrumbs (navigateTo)="navigateTo($event)"></app-breadcrumbs>
<div class="loading-spinner">
  <div *appLoading="!responseSuccessful">
    <div class="d-flex justify-content-between mb-3">
      <h3 class="me-3">Memos</h3>
      <i class="mdi mdi-plus-circle mdi-24px icon-color cursor-pointer"
         title="Memo erstellen"
         [routerLink]="['/dashboard/customers', id, 'create-memo']"
         [queryParams]="{origin: routeOrigin, editMode: MemoModes.CREATE}"
         queryParamsHandling="merge"></i>
    </div>

    <div *ngIf="memosLoaded" class="table-responsive mt-3">
      <table class="table table-striped table-centered mb-0">
        <thead>
        <tr>
          <th class="border-bottom-0 table-cell">ID</th>
          <th class="border-bottom-0 table-cell">Betreff</th>
          <th class="border-bottom-0 table-cell">Nachricht</th>
          <th class="border-bottom-0 table-cell" title="Wiedervorlage">WdVl</th>
          <th class="border-bottom-0 table-cell">Erstellt</th>
          <th>Aktionen</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let memo of memos">
          <td><a [routerLink]="['/dashboard/memos', memo.id]"
                 [queryParams]="{editMode: MemoModes.READ}"
                 queryParamsHandling="merge"
                 class="neutral-anchor">
            {{ memo?.id }}
          </a></td>
          <td class="text-nowrap"><a [routerLink]="['/dashboard/memos', memo.id]"
                                     [queryParams]="{editMode: MemoModes.READ}"
                                     queryParamsHandling="merge"
                                     class="neutral-anchor">
            {{ memo?.subject }}
          </a></td>
          <td>
            <a [routerLink]="['/dashboard/memos', memo.id]"
               [queryParams]="{editMode: MemoModes.READ}"
               queryParamsHandling="merge"
               class="neutral-anchor">
              <div *ngFor="let entry of memo.entries.reverse().slice(0, 1); let f = first">
                <br *ngIf="!f">
                <span *ngIf="!entry.editor.customer" class="fw-bold">{{ entry.editor.email }} -</span>
                <span *ngIf="entry.editor.customer" class="fw-bold">
                  {{ entry.editor.customer.firstname + ' ' + entry.editor.customer.lastname }} -
                </span>
                <span class="fw-bold"> {{ entry?.updatedAt | date: 'dd.MM.yyyy' }}:</span>
                <br>
                <span [innerHTML]="entry?.message"></span>
              </div>
            </a>
          </td>
          <td>
            <a [routerLink]="['/dashboard/memos', memo.id]"
               [queryParams]="{editMode: MemoModes.READ}"
               queryParamsHandling="merge"
               class="neutral-anchor">
              <i *ngIf="memo.followUp === FollowUp.ON" class="mdi mdi-check-circle fs-4 text-dark"></i>
              <i *ngIf="memo.followUp === FollowUp.OFF" class="mdi mdi-block-helper fs-4 text-dark"></i>
            </a>
          </td>
          <td><a [routerLink]="['/dashboard/memos', memo.id]"
                 [queryParams]="{editMode: MemoModes.READ}"
                 queryParamsHandling="merge"
                 class="neutral-anchor">
            {{ memo?.createdAt | date: 'dd.MM.yyyy' }}
          </a></td>
          <td>
            <a [routerLink]="[memo.id, 'edit']"
               [queryParams]="{ editMode: MemoModes.EDIT, origin: routeOrigin }"
               queryParamsHandling="merge"
               class="neutral-anchor">
              <i class="mdi mdi-pencil action-icon text-dark"></i>
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="!memosLoaded" class="text-center fw-bold fs-3">
      <span class="me-3">Keine Memos noch</span>
      <i class="mdi mdi-note-remove-outline"></i>
    </div>
    <app-advanced-pagination *ngIf="memosData.pagination.total > memosData.pagination.per_page"
                             [paginationData]="memosData"
                             (emitPaginatedData)="getPaginatedData($event)">
    </app-advanced-pagination>
  </div>
</div>
