<input type="file"
       class="file-input"
       multiple
       [accept]="requiredFileType"
       (change)="onFileSelected($event)"
       #fileUpload>

<button type="button" class="file-upload-button" (click)="fileUpload.click()">
  <div class="col-form-label">{{ buttonText }}</div>
  <div>
    <i class="mdi mdi-attachment mdi-24px"></i>
  </div>
</button>
