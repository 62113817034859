<div class="accordion custom-accordion mb-3" id="custom-accordion-one">
  <div class="card mb-0">
    <div class="card-header" id="headingOne">
      <h5 class="m-0">
        <i class="mdi mdi-chevron-down accordion-arrow custom-accordion-title me-3 mt-1"
           [ngClass]="{show: chevron, collapse: !chevron}"></i>
        <i class="mdi mdi-chevron-up accordion-arrow custom-accordion-title me-3 mt-1"
           [ngClass]="{show: !chevron, collapse: chevron}"></i>
        <a class="custom-accordion-title d-flex justify-content-between align-items-baseline py-1"
           data-bs-toggle="collapse" href="#collapseOne"
           (click)="chevron = !chevron"
           aria-expanded="true" aria-controls="collapseOne">
          <div>
            <i class="mdi mdi-drag mdi-18px cursor-pointer me-3"></i>
            Prägbare Bestellungen
          </div>
          <div *ngIf="orders?.length" class="items-length me-3">+ {{ orders.length }}</div>
        </a>
      </h5>
    </div>
  </div>
  <div id="collapseOne"
       class="collapse"
       aria-labelledby="headingOne"
       data-bs-parent="#custom-accordion-one">
    <div class="card-body">
      <div class="loading-spinner" *ngIf="!error">
        <div *appLoading="!responseSuccessful">
          <div class="d-xxxl-flex justify-content-between">
            <form [formGroup]="embossmentFilterForm" class="d-md-flex justify-content-start col-xxxl-6 gap-2">
              <div class="col-xxxl-4 mb-3">
                <label class="col-form-label" for="category">Zur Prägung</label>
                <select formControlName="availableForEmbossment"
                        id="category"
                        class="form-select cursor-pointer">
                  <option *ngFor="let embossment of embossmentValues" [value]="embossment">{{ embossment }}</option>
                </select>
              </div>
              <div class="col-xxxl-4 mb-3">
                <label class="col-form-label" for="regionId">Region</label>
                <select formControlName="region"
                        id="regionId"
                        class="form-select cursor-pointer me-3">
                  <option value="-1">Alle</option>
                  <option *ngFor="let region of regions" [value]="region.id">{{ region.name }}</option>
                </select>
              </div>
              <div class="col-xxxl-4 mb-2">
                <button type="button" (click)="onRefreshList()" class="btn app-primary-button m-refresh-button">
                  <i class="mdi mdi-refresh cursor-pointer align-self-center mt-2"></i>
                  <span>Neu laden</span>
                </button>
              </div>
            </form>
            <div class="d-md-flex justify-content-start justify-content-xxxl-end align-items-center col-xxxl-6 gap-2">
              <div>
                <button type="button"
                        class="btn save-button text-nowrap m-create-button"
                        [class.active]="selectedOrders.length > 0 && !waitingForResponse"
                        [disabled]="selectedOrders.length === 0 || waitingForResponse"
                        (click)="onCreateEmbossmentList()">
                  <span *ngIf="waitingForResponse"
                        class="spinner-border spinner-border-sm me-1"
                        role="status"
                        aria-hidden="true"></span>
                  Prägeliste erstellen
                </button>
              </div>
            </div>
          </div>
          <!-- TABLE -->
          <div *ngIf="orders && orders.length > 0" [class.scroll-embossments]="orders.length > 10"
               class="table-responsive db-table-width scroll-embossments mt-3">
            <table class="table table-striped table-centered mb-0">
              <thead class="table-light">
              <tr class="sticky-header-row">
                <th class="all table-cell">
                  <input type="checkbox"
                         #selectAllCheckbox
                         class="form-check-input custom-xl-checkbox cursor-pointer"
                         (change)="onCheckAllOrders($event)"
                         [checked]="allOrdersSelected">
                </th>
                <th class="table-cell">ID</th>
                <th class="table-cell"># Karten</th>
                <th class="table-cell"># Gutscheine</th>
                <th class="table-cell">Typ</th>
                <th class="table-cell">Erstellt</th>
                <th class="table-cell">Region</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let order of orders; index as i" class="bg-aqua">
                <td class="table-cell">
                  <input type="checkbox"
                         [checked]="selectedOrders.includes(order.id)"
                         (change)="onSelectOrder(order.id)"
                         class="form-check-input custom-xl-checkbox cursor-pointer">
                </td>
                <td class="text-body fw-bold table-cell">
                  {{ order.idForEmbossment }}
                  <a [routerLink]="['/dashboard/orders', order?.idForEmbossment]" queryParamsHandling="merge">
                    <i class="uil-external-link-alt"></i>
                  </a>
                </td>
                <td class="table-cell">{{ order.numberOfEmbossmentCards }}</td>
                <td class="table-cell">{{ order.numberOfEmbossmentVouchers }}</td>
                <td class="table-cell">{{ orderTypes[order.orderType] }}</td>
                <td class="table-cell">{{ order.createdAt | date:"dd.MM.yyyy" }}</td>
                <td class="table-cell">{{ order.regionName }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="orders && orders.length === 0" class="mt-3">Keine Ergebnisse gefunden.</div>
        </div>
      </div>
    </div>
  </div>
</div>
