<div class="loading authentication-bg" data-layout-config='{"darkMode":false}'>

  <div class="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xxl-4 col-lg-5">
          <div class="card">

            <!-- Logo -->
            <div class="card-header pt-4 pb-4 text-center app-bg-primary text-white fw-bolder fs-4">
              <a>
                <!-- Logo here-->
                ADMIN PANEL
              </a>
            </div>

            <div class="card-body p-4">

              <div class="text-center m-auto">
                <h4 class="text-dark-50 text-center mt-0 fw-bold">Anmelden</h4>
                <p class="text-muted mb-4"> Bitte geben Sie Ihre Email und Passwort ein.</p>
              </div>

              <form action="#" [formGroup]="loginForm" (ngSubmit)="onLogin()">
                <div class="mb-3">
                  <label for="email" class="form-label">Email Adresse</label>
                  <input class="form-control"
                         type="email" id="email"
                         formControlName="email"
                         placeholder="Email">
                </div>

                <div class="mb-3">
                  <label for="password" class="form-label">Passwort</label>
                  <div class="input-group">
                    <input type="{{passwordType}}"
                           id="password"
                           class="form-control"
                           formControlName="password"
                           placeholder=" Passwort">
                    <div class="input-group-text" data-password="true">
                      <i class="mdi mdi-eye-off"
                         *ngIf="!isPasswordHidden" (click)="hidePassword()"></i>
                      <i class="mdi mdi-eye"
                         *ngIf="isPasswordHidden" (click)="showPassword()"></i>
                    </div>
                  </div>
                </div>

                <div class="mb-3 mb-3">
                  <a routerLink="/forgot-password" class="text-muted">
                    <small>Passwort vergessen?</small>
                  </a>
                </div>

                <div class="mb-3 mb-0 text-center">
                  <button class="btn app-primary-button" type="submit"> Anmelden</button>
                </div>
              </form>
            </div> <!-- end card-body -->
          </div><!-- end card -->
        </div>
      </div>
    </div>
  </div>
  <footer class="footer footer-alt">
    2018 - {{ currentYear }} © DRESDEN FOR FRIENDS
  </footer>
</div>
<app-default-region-selection-modal
  *ngIf="meData"
  [showModal]="showDefaultRegionSelectionModal"
  [user]="meData"
  [selectedDefaultRegion]="defaultLoginRegion"
  (_onSelectDefaultRegion)="onNavigateHome($event)">
</app-default-region-selection-modal>
