<div class="accordion custom-accordion mb-3" id="custom-accordion-ten">
  <div class="card mb-0">
    <div class="card-header" id="headingTen">
      <h5 class="m-0">
        <i class="mdi mdi-chevron-down accordion-arrow custom-accordion-title me-3 mt-1"
           [ngClass]="{show: chevron, collapse: !chevron}"></i>
        <i class="mdi mdi-chevron-up accordion-arrow custom-accordion-title me-3 mt-1"
           [ngClass]="{show: !chevron, collapse: chevron}"></i>
        <a class="custom-accordion-title d-flex justify-content-between align-items-baseline py-1"
           data-bs-toggle="collapse" href="#collapseTen"
           (click)="chevron = !chevron"
           aria-expanded="true" aria-controls="collapseTen">
          <div>
            <i class="mdi mdi-drag mdi-18px cursor-pointer me-3"></i>
            Karten zur Abholung bereit
          </div>
          <div *ngIf="cardsData?.pagination?.total" class="items-length me-3">
            + {{ cardsData.pagination.total }}
          </div>
        </a>
      </h5>
    </div>
  </div>
  <div id="collapseTen"
       class="collapse"
       aria-labelledby="headingTen"
       data-bs-parent="#custom-accordion-ten">
    <div class="card-body">
      <div class="loading-spinner" *ngIf="!error">
        <div *appLoading="!responseSuccessful">
          <div class="d-md-flex justify-content-start justify-content-xxxl-end align-items-center gap-2">
            <form [formGroup]="cardsFilterForm" class="d-md-flex justify-content-start col-xxxl-6 gap-2">
              <div class="col-xxxl-4 mb-3">
                <label class="col-form-label" for="regionId">Region</label>
                <select formControlName="region"
                        id="regionId"
                        class="form-select cursor-pointer me-3">
                  <option value="-1">Alle</option>
                  <option *ngFor="let region of regions" [value]="region.id">{{ region.name }}</option>
                </select>
              </div>
            </form>
          </div>
          <!-- TABLE -->
          <div *ngIf="cards && cards.length > 0" class="table-responsive db-table-width mt-3">
            <table class="table table-striped table-centered mb-0">
              <thead class="table-light">
              <tr>
                <th class="table-cell">Kartennummer</th>
                <th class="table-cell">Typ</th>
                <th class="table-cell">Inhaber</th>
                <th class="table-cell">Kunde</th>
                <th class="table-cell">Status</th>
                <th class="table-cell">erstellt/bearbeitet</th>
                <th class="table-cell">Region</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let card of cards; index as i" class="bg-aqua">
                <td><a [routerLink]="['/dashboard/cards', card.id]"
                       class="neutral-anchor"
                       [state]="{ breadcrumb: card.id }"
                       queryParamsHandling="merge">
                  {{ card.cardNumber }}
                </a></td>
                <td><a [routerLink]="['/dashboard/card', card.id]"
                       class="neutral-anchor"
                       [state]="{ breadcrumb: card.id }"
                       queryParamsHandling="merge">
                  {{ card?.product?.name  }} / {{ card?.product?.type?.name }}
                </a></td>
                <td>
                  <a [routerLink]="['/dashboard/cards', card.id]"
                     class="neutral-anchor"
                     [state]="{ breadcrumb: card.id }"
                     queryParamsHandling="merge">
                      {{ card?.firstname }} {{ card?.lastname }}
                  </a>
                </td>
                <td><a [routerLink]="['/dashboard/cards', card.id]"
                       class="neutral-anchor"
                       [state]="{ breadcrumb: card.id }"
                       queryParamsHandling="merge">
                  {{ card.customer?.firstname }} {{ card.customer?.lastname }}
                </a></td>
                <td><a [routerLink]="['/dashboard/cards', card.id]"
                       class="neutral-anchor"
                       [state]="{ breadcrumb: card.id }"
                       queryParamsHandling="merge">
                  {{ card.deliveryStatus }}
                </a></td>
                <td><a [routerLink]="['/dashboard/cards', card.id]"
                       class="neutral-anchor"
                       [state]="{ breadcrumb: card.id }"
                       queryParamsHandling="merge">
                  {{ card.createdAt | date: 'dd.MM.yyyy' }} - {{ card.updatedAt | date: 'dd.MM.yyyy' }}
                </a></td>
                <td><a [routerLink]="['/dashboard/cards', card.id]"
                       class="neutral-anchor"
                       [state]="{ breadcrumb: card.id }"
                       queryParamsHandling="merge">
                  {{ card.regionName }}
                </a></td>
              </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="cards && cards.length === 0" class="mt-3">Keine Daten vorhanden.</div>
          <app-advanced-pagination *ngIf="cardsData
              && cardsData.pagination.total > cardsData.pagination.per_page"
                                   [paginationData]="cardsData"
                                   (emitPaginatedData)="loadPaginatedData($event)">
          </app-advanced-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
