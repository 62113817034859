import {Component, EventEmitter, HostListener, OnDestroy, OnInit, Output} from '@angular/core';
import { ActivatedRoute, Params } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AllRegionsResponse, Region } from "../../../dataModels/regionDataModels";
import { LocalStorageKeys } from "../../../enum/shared-enums";
import { NavigationService } from "../../../services/navigation.service";
import { Utils } from "../../../utils/utils";
import { takeUntil } from "rxjs/operators";
import { Status } from "../../../enum/status";
import { FilterUtils } from "../../../utils/filter";
import { VoucherFilters } from "../../../dataModels/vouchersDataModels";
import { AppGlobalService } from "../../../services/app-global.service";
import { SubscriptionComponent } from "../../subscription/subscription.component";
import { dateInputPattern, numberFilterValidators } from "../../filter-container/filter-container.component";

@Component({
             selector: 'app-vouchers-filters',
             templateUrl: './vouchers-filters.component.html',
             styleUrls: ['./vouchers-filters.component.scss']
           })
export class VouchersFiltersComponent extends SubscriptionComponent implements OnInit, OnDestroy {
  @Output() filterValues = new EventEmitter<Params>();
  voucherFiltersForm: FormGroup;
  VoucherFilters = VoucherFilters;
  regions: Region[];
  regionId = JSON.parse(Utils.getItemFromLocalStorage(LocalStorageKeys.REGION)).id;

  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private navigationService: NavigationService,
              private globalService: AppGlobalService) {
    super();
    this.initializeVoucherFiltersForm();
  }

  ngOnInit(): void {
    this.globalService.regions$
        .pipe((takeUntil(this.ngUnsubscribe)))
        .subscribe((response: AllRegionsResponse) => {
          this.regions = Utils.snakeCaseToCamelCase(response.body?.pagination.data);
          this.globalService.emitFiltersLoadedEvent(true);
        });
    this.prefillFilterForm();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private initializeVoucherFiltersForm(): void {
    this.voucherFiltersForm = this.formBuilder.group({
                                                       amountFrom: [null, numberFilterValidators],
                                                       amountTo: [null, numberFilterValidators],
                                                       endDate: [null, [Validators.pattern(dateInputPattern)]],
                                                       createdFrom: [null, [Validators.pattern(dateInputPattern)]],
                                                       createdTo: [null, [Validators.pattern(dateInputPattern)]],
                                                       appliedFrom: [null, [Validators.pattern(dateInputPattern)]],
                                                       appliedTo: [null, [Validators.pattern(dateInputPattern)]],
                                                       operationStatus: [null],
                                                       regionId: [this.regionId]
                                                     });
  }

  private prefillFilterForm(): void {
    this.route.queryParams.subscribe((queryParams: Params) => {
      if (queryParams['filters_applied']) {
        const queryParameters: Params = Utils.snakeCaseToCamelCase(queryParams);
        this.voucherFiltersForm.patchValue(queryParameters);
        this.prefillStartDateFields(queryParameters, ['createdFrom', 'appliedFrom'])
        this.prefillEndDateFields(queryParameters, ['endDate', 'createdTo', 'appliedTo']);
        if (queryParameters.sendAsGift) {
          if (queryParameters.sendAsGift === Status.ACTIVE) {
            this.voucherFiltersForm.get('sendAsGift')?.setValue(Status.ACTIVE);
          } else {
            this.voucherFiltersForm.get('sendAsGift')?.setValue(Status.INACTIVE);
          }
        }
      }
    });
  }

  private prefillStartDateFields(queryParams: Params, filters: string[]): void {
    filters.forEach((filter) => {
      if (queryParams[filter]) {
        this.voucherFiltersForm.get(filter)?.setValue(Utils.getDateWithTimezoneOffset(queryParams[filter]));
      }
    });
  }

  private prefillEndDateFields(queryParams: Params, filters: string[]): void {
    filters.forEach((filter) => {
      if (queryParams[filter]) {
        this.voucherFiltersForm.get(filter)?.setValue(Utils.getLocalDateFromUtc(queryParams[filter]));
      }
    });
  }

  onFilter(): void {
    let sanitizedFilters = this.sanitizeVoucherFilterForm();
    sanitizedFilters = FilterUtils.formatPriceFields(sanitizedFilters, ['amountFrom', 'amountTo']);
    sanitizedFilters = { ...sanitizedFilters, filtersApplied: true };
    this.filterValues.emit(sanitizedFilters);
  }

  private sanitizeVoucherFilterForm(): Params {
    let filterForm = FilterUtils.sanitizeFilterForm(this.voucherFiltersForm);
    filterForm = FilterUtils.formatStartDates(filterForm, ['createdFrom', 'appliedFrom']);
    filterForm = FilterUtils.formatEndDates(filterForm, ['endDate', 'createdTo', 'appliedTo']);

    return filterForm;
  }

  onResetFilters(): void {
    this.initializeVoucherFiltersForm();
    const sanitizedFilters = this.sanitizeVoucherFilterForm();
    this.filterValues.emit(sanitizedFilters);
  }

  @HostListener('window:popstate', ['$event'])
  goBack(event: Event) {
    this.navigationService.back().pipe(takeUntil(this.ngUnsubscribe)).subscribe((params: Params) => {
      this.initializeVoucherFiltersForm();
      this.voucherFiltersForm.patchValue(params);
    });
  }
}
