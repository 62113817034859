import { Component, OnDestroy } from '@angular/core';
import { Subject } from "rxjs";

@Component({
             selector: 'app-subscription',
             templateUrl: './subscription.component.html',
             styleUrls: ['./subscription.component.scss']
           })
export class SubscriptionComponent implements OnDestroy {
  ngUnsubscribe = new Subject<void>();

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
