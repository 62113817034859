<form [formGroup]="cardStatisticForm">
  <div class="dropdown-grid">
    <ng-container *ngFor="let field of CardStatisticFilters; let f = first">
      <div class="item mb-3">
        <label class="col-form-label">{{ field?.title }}</label>
        <div class="col-md-11">
          <ng-container *ngIf="!field.inputType">
            <select [formControlName]="field.formName"
                    class="form-select cursor-pointer me-3">
              <option *ngFor="let option of field.options" [value]="option.value">{{ option?.label }}</option>
            </select>
          </ng-container>
          <ng-container *ngIf="field.inputType">
            <app-date-picker [formName]="field.formName"
                             [inputType]="field.inputType"
                             [formGroup]="cardStatisticForm"
                             [value]="cardStatisticForm.get(field.formName)?.value"
            ></app-date-picker>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <div class="item mb-3">
      <label class="col-form-label" for="selectedProduct">Kartenart</label>
      <div class="col-md-11">
        <select formControlName="selectedProduct"
                id="selectedProduct"
                class="form-select cursor-pointer me-3">
         <option [value]="null">Alle</option>
          <option *ngFor="let product of products" [ngValue]="product">
            {{ product.name }}
          </option>
          <option [value]="CardType.FIRST_CARD">Abo-{{ CardType.FIRST_CARD }}</option>
          <option [value]="CardType.FOLLOW_CARD">Abo-{{ CardType.FOLLOW_CARD }}</option>
        </select>
      </div>
    </div>
    <div class="item mb-3">
      <label class="col-form-label" for="state">Kartenzustand</label>
      <div class="col-md-11">
        <select formControlName="cardState"
                id="state"
                class="form-select cursor-pointer me-3">
          <option [value]="null">Alle</option>
          <option *ngFor="let cardState of cardStates" [value]="cardState">{{ cardState }}</option>
        </select>
      </div>
    </div>
    <div class="item mb-3">
      <label class="col-form-label" for="cardStatus">Kartenstatus</label>
      <div class="col-md-11">
        <select formControlName="cardStatus"
                id="cardStatus"
                class="form-select cursor-pointer me-3">
          <option [value]="null">Alle</option>
          <option *ngFor="let cardStatus of cardStatuses" [value]="cardStatus">{{ cardStatus }}</option>
        </select>
      </div>
    </div>
    <div class="item mb-3">
      <label class="col-form-label" for="isOnlyDigital">Haptisch</label>
      <div class="col-md-11">
        <select formControlName="isOnlyDigital"
                id="isOnlyDigital"
                class="form-select cursor-pointer me-3">
          <option [value]="null">Alle</option>
          <option [value]="false">Ja</option>
          <option [value]="true">Nein</option>
        </select>
      </div>
    </div>
    <div class="item mb-3">
      <label class="col-form-label" for="cardCancellationStatus">Kündigungsstatus</label>
      <div class="col-md-11">
        <select formControlName="cancellationStatus"
                id="cardCancellationStatus"
                class="form-select cursor-pointer me-3">
          <option [value]="null">Alle</option>
          <option *ngFor="let cardStatus of cardCancellationStatuses" [value]="cardStatus">{{ cardStatus }}</option>
        </select>
      </div>
    </div>
    <div class="item mb-3">
      <label class="col-form-label" for="region">Region</label>
      <div class="col-md-11">
        <select id="region"
                formControlName="regionId"
                class="form-select cursor-pointer me-3">
          <option value="-1">Alle</option>
          <option *ngFor="let region of regions" [value]="region.id">{{ region?.name }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-end me-md-filter-buttons">
    <button class="cancel-button me-3"
            (click)="onInitializeCardStatisticForm()"
            data-bs-toggle="modal"
            data-bs-target="#card-statistic-modal">
      Abbrechen
    </button>
    <button class="app-primary-button"
            (click)="onEmitFilters()"
            [disabled]="cardStatisticForm?.invalid"
            data-bs-toggle="modal"
            data-bs-target="#card-statistic-modal">
      Erstellen
    </button>
  </div>
</form>
