import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { BreadcrumbData } from "../shared/components/breadcrumbs/breadcrumbs.component";
import { ActivatedRouteSnapshot, Data, NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

@Injectable({
              providedIn: 'root'
            })
export class BreadcrumbService {
  private readonly breadcrumbs$ = new BehaviorSubject<BreadcrumbData[]>([]);
  readonly emittedBreadcrumbs = this.breadcrumbs$.asObservable();
  dynamicValue: { [p: string]: any } | undefined;

  constructor(private router: Router) {
    this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(event => {
          this.dynamicValue = this.router.getCurrentNavigation()?.extras.state;
          const root = this.router.routerState.snapshot.root;
          const breadcrumbs: BreadcrumbData[] = [];
          this.addBreadcrumb(root, [], breadcrumbs);

          this.breadcrumbs$.next(breadcrumbs);
        });
  }

  private addBreadcrumb(route: ActivatedRouteSnapshot | null, parentUrl: string[], breadcrumbs: BreadcrumbData[]): void {
    if (route) {
      const routeUrl = parentUrl.concat(route.url.map(url => url.path));
      if (route.data.breadcrumb) {
        const breadcrumb: BreadcrumbData = {
          pageName: this.getLabel(route.data),
          routerLink: '/' + routeUrl.join('/'),
          queryParams: route.queryParams
        };
        breadcrumbs.push(breadcrumb);
      }

      if (!route.data.dynamicRoute || route.data.dynamicRoute === DynamicRoute.CONTINUE) {
        this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
      }
    }
  }

  private getLabel(data: Data): string {
    if (data.breadcrumb && !data.dynamicRoute) {
      return data.breadcrumb;
    } else if (data.dynamicRoute && !this.dynamicValue) {
      return data.breadcrumb;
    } else {
      return this.dynamicValue?.breadcrumb;
    }
  }
}

export enum DynamicRoute {
  SET = 'isDynamic',
  CONTINUE = 'continue'
}
