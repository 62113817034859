import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserResponse } from '../dataModels/userDataModel';
import { AllRegionsResponse } from "../dataModels/regionDataModels";
import { CreateCustomerTabs } from "../enum/customer";
import { HttpClient } from "@angular/common/http";
import { apiUrl, Utils } from "../utils/utils";
import { InputValidationResponse } from "../dataModels/customerDataModel";
import { DeploymentInformation, FileOrErrorJsonModel } from "../dataModels/sharedDataModels";
import { first } from "rxjs/operators";
import { ResponseCode } from "../enum/shared-enums";

@Injectable({
              providedIn: 'root'
            })
export class AppGlobalService {
  globalData$: Observable<GlobalData>;
  regions$: Observable<AllRegionsResponse>;
  emittedGlobalData = new BehaviorSubject<GlobalData>({});
  emittedRegionData = new BehaviorSubject<AllRegionsResponse>(<AllRegionsResponse>{});
  validatePhoneInput$: Observable<CreateCustomerTabs | null>;
  emitValidatePhoneInput = new BehaviorSubject<CreateCustomerTabs | null>(null);
  tabNavigation$: Observable<CreateCustomerTabs | null>;
  emitTabNavigation = new BehaviorSubject<CreateCustomerTabs | null>(null);
  reloadFilters$: Observable<boolean>;
  emitReloadFilters = new BehaviorSubject<boolean>(false);
  filterError$: Observable<boolean>;
  emitFilterError = new BehaviorSubject<boolean>(false);
  filtersLoaded$: Observable<boolean>;
  emitFiltersLoaded = new BehaviorSubject<boolean>(false);
  serverError$: Observable<boolean>;
  _serverError = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    this.globalData$ = this.emittedGlobalData.asObservable();
    this.regions$ = this.emittedRegionData.asObservable();
    this.validatePhoneInput$ = this.emitValidatePhoneInput.asObservable();
    this.tabNavigation$ = this.emitTabNavigation.asObservable();
    this.reloadFilters$ = this.emitReloadFilters.asObservable();
    this.filterError$ = this.emitFilterError.asObservable();
    this.filtersLoaded$ = this.emitFiltersLoaded.asObservable();
    this.serverError$ = this._serverError.asObservable();
  }

  emitGlobalData(globalData: GlobalData): void {
    this.emittedGlobalData.next(globalData);
  }

  emitRegions(regionData: AllRegionsResponse): void {
    this.emittedRegionData.next(regionData);
  }

  emitValidateInputField(data: CreateCustomerTabs | null): void {
    this.emitValidatePhoneInput.next(data);
  }

  emitNavigationTab(tab: CreateCustomerTabs | null): void {
    this.emitTabNavigation.next(tab);
  }

  reloadFilterData(value: boolean): void {
    this.emitReloadFilters.next(value);
  }

  emitFilterErrorEvent(value: boolean): void {
    this.emitFilterError.next(value);
  }

  emitFiltersLoadedEvent(value: boolean): void {
    this.emitFiltersLoaded.next(value);
  }

  validateInput(type: string, value: string): Observable<InputValidationResponse> {
    return this.http.post<InputValidationResponse>(apiUrl + 'validateInput', { type, value });
  }

  getDeploymentInfo(): Observable<DeploymentInformation> {
    return this.http.get<DeploymentInformation>(apiUrl + 'deployment-status');
  }

  downloadFile(url: string, fileName: string, mimeType: string): string | undefined {
    this.http.get(url, { responseType: 'arraybuffer' })
        .pipe(first())
        .subscribe((response: ArrayBuffer | FileOrErrorJsonModel) => {
          if ("code" in response && response.code && "message" in response && response.message) {
            if (response.code === ResponseCode.FAILED) {
              return response.message;
            }
          } else {
            Utils.downloadFile(response, fileName, mimeType);
          }
          return undefined;
        });
    return undefined;
  }

  emitServerError(value: boolean): void {
    this._serverError.next(value);
  }
}

export interface GlobalData {
  meData?: UserResponse;
}
