import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import {
  AllProductsResponse,
  PricePayload,
  PriceTypesResponse,
  ProductResponse,
  ProductPayload,
  ProductTypesResponse,
  EditProductPayload,
  PhysicalStartingDateResponse,
} from "../shared/dataModels/productsDataModel";
import { apiUrl, Utils } from "../shared/utils/utils";
import { Params } from "@angular/router";
import { Status } from "../shared/enum/status";
import { FilterUtils } from "../shared/utils/filter";
import { CustomUrlEncoder } from "../shared/utils/custom-url-encoder";
import { SimpleResponse, SuccessfulCreationResponse } from "../shared/dataModels/sharedDataModels";

@Injectable({
              providedIn: 'root'
            })
export class ProductsService {
  constructor(private http: HttpClient) { }

  getProducts(requestParameters?: Params): Observable<AllProductsResponse> {
    if (requestParameters) {
      let queryParams = FilterUtils.trimRequestParams(requestParameters);
      const params = new HttpParams({ encoder: new CustomUrlEncoder() })
        .appendAll(Utils.camelCaseToSnakeCase(queryParams))
      return this.http.get<AllProductsResponse>(apiUrl + 'products', { params });
    }
    return this.http.get<AllProductsResponse>(apiUrl + 'products');
  }

  getProduct(id: number): Observable<ProductResponse> {
    return this.http.get<ProductResponse>(apiUrl + 'products/' + id);
  }

  getPriceTypes(): Observable<PriceTypesResponse> {
    return this.http.get<PriceTypesResponse>(apiUrl + 'prices/types');
  }

  getTypes(withoutPagination: boolean): Observable<ProductTypesResponse> {
    if (withoutPagination) {
      const params = new HttpParams().set('without_pagination', 1)
      return this.http.get<ProductTypesResponse>(apiUrl + 'productTypes', { params });
    } else {
      return this.http.get<ProductTypesResponse>(apiUrl + 'productTypes');
    }
  }

  // It will be deleted if it's certain that the call is no longer necessary
  getPhysicalProductsEndDate(): Observable<PhysicalStartingDateResponse> {
    return this.http.get<PhysicalStartingDateResponse>(apiUrl + 'cards/physicalDates');
  }

  createProduct(payload: ProductPayload): Observable<SuccessfulCreationResponse> {
    const convertedPayload = Utils.camelCaseToSnakeCase(payload);
    return this.http.post<SuccessfulCreationResponse>(apiUrl + 'products', { ...convertedPayload });
  }

  editProduct(id: number, payload: EditProductPayload): Observable<SuccessfulCreationResponse> {
    const convertedPayload = Utils.camelCaseToSnakeCase(payload);
    return this.http.put<SuccessfulCreationResponse>(apiUrl + 'products/' + id, { ...convertedPayload });
  }

  setProductPrice(priceData: PricePayload): Observable<SuccessfulCreationResponse> {
    const convertedPayload = Utils.camelCaseToSnakeCase(priceData);
    return this.http.post<SuccessfulCreationResponse>(apiUrl + 'prices', convertedPayload);
  }

  changeStatus(id: number, status: Status): Observable<SuccessfulCreationResponse> {
    return this.http.put<SuccessfulCreationResponse>(apiUrl + 'products/' + id, { status });
  }

  changeVisibilityStatus(id: number, visibilityStatus: Status): Observable<SuccessfulCreationResponse> {
    return this.http.put<SuccessfulCreationResponse>(
      apiUrl + 'products/' + id,
      { visibility_status: visibilityStatus }
    );
  }

  deleteProduct(id: number): Observable<SimpleResponse> {
    return this.http.delete<SimpleResponse>(apiUrl + 'products/' + id);
  }
}
