<div class="accordion custom-accordion mb-3" id="custom-accordion-three">
  <div class="card mb-0">
    <div class="card-header" id="headingThree">
      <h5 class="m-0">
        <i class="mdi mdi-chevron-down accordion-arrow custom-accordion-title me-3 mt-1"
           [ngClass]="{show: chevron, collapse: !chevron}"></i>
        <i class="mdi mdi-chevron-up accordion-arrow custom-accordion-title me-3 mt-1"
           [ngClass]="{show: !chevron, collapse: chevron}"></i>
        <a class="custom-accordion-title d-flex justify-content-between align-items-baseline py-1"
           data-bs-toggle="collapse" href="#collapseThree"
           (click)="chevron = !chevron"
           aria-expanded="true" aria-controls="collapseThree">
          <div>
            <i class="mdi mdi-drag mdi-18px cursor-pointer me-3"></i>
            Memo Wiedervorlage
          </div>
          <div *ngIf="memosData?.pagination?.total" class="items-length me-3">+ {{ memosData.pagination.total }}</div>
        </a>
      </h5>
    </div>
  </div>

  <div id="collapseThree"
       class="collapse"
       aria-labelledby="headingThree"
       data-bs-parent="#custom-accordion-three">
    <div class="card-body">
      <div class="loading-spinner">
        <div *appLoading="!memosLoaded" >
          <div class="d-md-flex justify-content-between">
            <div class="col-12 mb-2 float-end">
              <button type="button" (click)="onRefreshList()" class="btn app-primary-button">
                <i class="mdi mdi-refresh cursor-pointer align-self-center"></i>
                <span>Neu laden</span>
              </button>
            </div>
          </div>
          <div class="table-responsive db-table-width mt-3">
            <!-- TABLE -->
            <table *ngIf="memos && memos.length !== 0" class="table table-striped table-centered mb-0">
              <thead>
              <tr>
                <th class="border-bottom-0 table-cell">Wiedervorlage</th>
                <th class="border-bottom-0 table-cell">Kontext</th>
                <th class="border-bottom-0 table-cell">Betreff</th>
                <th class="border-bottom-0 table-cell">Inhalt</th>
                <th class="border-bottom-0 table-cell">Initiator</th>
                <th class="border-bottom-0 table-cell">Aktualisiert</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let memo of memos">
                <td><a [routerLink]="getEditUrl(memo)"
                       [queryParams]="{ editMode: MemoModes.EDIT, origin: memo.orderId ? 'orders' : 'customers' }"
                       queryParamsHandling="merge"
                       class="neutral-anchor">
                  {{ memo?.followUpDate | date: 'dd.MM.yyyy' }}
                </a></td>
                <td *ngIf="memo.orderId; else customer">
                  <a [routerLink]="getEditUrl(memo)"
                     [queryParams]="{ editMode: MemoModes.EDIT, origin: memo.orderId ? 'orders' : 'customers' }"
                     queryParamsHandling="merge"
                     class="neutral-anchor">
                    Bestellung
                  </a>
                </td>
                <ng-template #customer>
                  <td><a [routerLink]="getEditUrl(memo)"
                         [queryParams]="{ editMode: MemoModes.EDIT, origin: memo.orderId ? 'orders' : 'customers' }"
                         queryParamsHandling="merge"
                         class="neutral-anchor">
                    Kunde
                  </a></td>
                </ng-template>
                <td><a [routerLink]="getEditUrl(memo)"
                       [queryParams]="{ editMode: MemoModes.EDIT, origin: memo.orderId ? 'orders' : 'customers' }"
                       [innerHTML]="memo?.subject"
                       queryParamsHandling="merge"
                       class="neutral-anchor">
                </a></td>
                <td>
                  <a [routerLink]="getEditUrl(memo)"
                     [queryParams]="{ editMode: MemoModes.EDIT, origin: memo.orderId ? 'orders' : 'customers' }"
                     queryParamsHandling="merge"
                     class="neutral-anchor">
                    <div *ngFor="let entry of memo.entries.slice(0, 1); let f = first">
                      <br *ngIf="!f">
                      <span *ngIf="!entry.editor.customer" class="fw-bold">{{ entry.editor.email }} -</span>
                      <span *ngIf="entry.editor.customer" class="fw-bold">
                    {{ entry.editor.customer.firstname + ' ' + entry.editor.customer.lastname }} -
                  </span>
                      <span class="fw-bold"> {{ entry?.updatedAt | date: 'dd.MM.yyyy' }}:</span>
                      <br>
                      <span [innerHTML]="entry?.message"></span>
                    </div>
                  </a>
                </td>
                <td *ngIf="!memo.creator.customer; else name">
                  <a [routerLink]="getEditUrl(memo)"
                     [queryParams]="{ editMode: MemoModes.EDIT, origin: memo.orderId ? 'orders' : 'customers' }"
                     queryParamsHandling="merge"
                     class="neutral-anchor">
                    {{ memo.creator.email }}
                  </a>
                </td>
                <ng-template #name>
                  <td><a [routerLink]="getEditUrl(memo)"
                         [queryParams]="{ editMode: MemoModes.EDIT, origin: memo.orderId ? 'orders' : 'customers' }"
                         queryParamsHandling="merge"
                         class="neutral-anchor">
                    {{ memo.creator.customer.firstname + ' ' + memo.creator.customer.lastname }}
                  </a></td>
                </ng-template>
                <td><a [routerLink]="getEditUrl(memo)"
                       [queryParams]="{ editMode: MemoModes.EDIT, origin: memo.orderId ? 'orders' : 'customers' }"
                       queryParamsHandling="merge"
                       class="neutral-anchor">
                  {{ memo.updatedAt | date: 'dd.MM.yyyy - HH:mm' }}
                </a></td>
              </tr>
              </tbody>
            </table>
            <app-advanced-pagination *ngIf="memosData && memosData.pagination.total > memosData.pagination.per_page"
                                     [paginationData]="memosData"
                                     (emitPaginatedData)="getPaginatedData($event)">
            </app-advanced-pagination>
            <div *ngIf="memos && memos.length === 0">Keine Ergebnisse gefunden.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
