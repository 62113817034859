import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Params } from "@angular/router";
import { Utils } from "./utils";
import { LocalStorageKeys } from "../enum/shared-enums";

export class FilterUtils {
  static sanitizeFilterForm(form: FormGroup): any {
    let filterForm = JSON.parse(JSON.stringify(form?.value));
    for (const propertyName in filterForm) {
      // @ts-ignore
      if (filterForm[propertyName] === null || filterForm[propertyName] === 'null' || filterForm[propertyName] === '') {
        // @ts-ignore
        delete filterForm[propertyName];
      }
    }

    return filterForm;
  }

  static trimRequestParams(requestParams: Params): Params {
    let queryParams = JSON.parse(JSON.stringify(requestParams));
    if (queryParams.regionId === '-1') {
      delete queryParams.regionId;
    }
    return queryParams;
  }

  static getQueryParametersFromRoute(route: ActivatedRoute): Params {
    let requestParams = Utils.snakeCaseToCamelCase(JSON.parse(JSON.stringify(route.snapshot.queryParams)));
    if (!requestParams.regionId && !requestParams.searchTerms && !requestParams.filtersApplied) {
      requestParams.regionId = JSON.parse(Utils.getItemFromLocalStorage(LocalStorageKeys.REGION)).id;
    }

    return requestParams;
  }

  static formatStartDates(filterForm: Params, startDates: string[]): Params {
    startDates.forEach((startDate) => {
      if (filterForm[startDate]) {
        filterForm[startDate] = Utils.convertLocalDateToISOString(filterForm[startDate]);
      }
    });

    return filterForm;
  }

  static formatEndDates(filterForm: Params, endDates: string[]): Params {
    endDates.forEach((endDate) => {
      if (filterForm[endDate]) {
        const [day, month, year] = filterForm[endDate].split('.');
        let formattedEndDate = new Date(+year, +month - 1, +day);
        // @ts-ignore
        formattedEndDate.setHours(
          formattedEndDate.getHours() + 23,
          formattedEndDate.getMinutes() + 59,
          formattedEndDate.getSeconds() + 59);
        filterForm[endDate] = formattedEndDate.toISOString();
      }
    });

    return filterForm;
  }

  static formatPriceFields(filters: any, priceFilters: string[]): any {
    priceFilters.forEach(priceFilter => {
      if (filters[priceFilter]) {
        filters[priceFilter] = Utils.formatPriceForBE(filters[priceFilter]);
      }
    });
    return filters;
  }
}
