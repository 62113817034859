import { Component, OnInit } from '@angular/core';
import { PaginationData } from "../../shared/dataModels/paginationDataModel";
import { Utils } from "../../shared/utils/utils";
import { AllMemosResponse, Memo } from "../../shared/dataModels/memosDataModel";
import { Params } from "@angular/router";
import { FollowUp } from "../../shared/enum/memos";
import { takeUntil } from "rxjs/operators";
import { SubscriptionComponent } from "../../shared/components/subscription/subscription.component";
import { MemosService } from "../../services/memos.service";
import { AlertifyService } from "../../shared/services/alertify-service.service";
import { CRUDMode, ResponseCode } from "../../shared/enum/shared-enums";

@Component({
             selector: 'app-dashboard-memos',
             templateUrl: './dashboard-memos.component.html',
             styleUrls: ['./dashboard-memos.component.scss']
           })
export class DashboardMemosComponent extends SubscriptionComponent implements OnInit {
  memos: Memo[] = [];
  memosData: PaginationData;
  requestParams: Params;
  MemoModes = CRUDMode;
  FollowUp = FollowUp;
  memosLoaded = false;
  chevron = true;

  constructor(private memosService: MemosService, private alertifyService: AlertifyService) { super() }

  ngOnInit(): void {
    this.requestParams = {
      regionId: JSON.parse(Utils.getItemFromLocalStorage('region')).id,
      followUpOccurred: '1',
      perPage: '5'
    }
    this.getMemos();
  }

  private getMemos(): void {
    this.memosService.getMemos(this.requestParams)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: AllMemosResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            this.memosData = response.body;
            this.memos = Utils.snakeCaseToCamelCase(response.body?.pagination.data);
          } else {
            this.alertifyService.warning(response.message);
          }
          this.memosLoaded = true;
        }, () => {
          this.memosLoaded = true;
        });
  }

  getPaginatedData(paginatedMemos: any): void {
    this.memosLoaded = false;
    this.requestParams = { ...this.requestParams, page: paginatedMemos };
    this.getMemos();
  }

   getEditUrl(memo: Memo): (string | number | null)[] {
    return memo.orderId
      ? ['/dashboard/orders', memo.orderId, 'memos', memo.id, 'edit']
      : ['/dashboard/customers', memo.customerId, 'memos', memo.id, 'edit'];
  }

  onRefreshList(): void {
    this.memosLoaded = false;
    this.memos = [];
    this.getMemos();
  }
}
