import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { LoginResponse } from '../shared/dataModels/loginDataModel';
import { apiUrl } from '../shared/utils/utils';
import { SimpleResponse } from "../shared/dataModels/sharedDataModels";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  forgotPassEmail$: Observable<string>;
  forgotPassEmail = new BehaviorSubject<string>('');

  constructor(private http: HttpClient) {
    this.forgotPassEmail$ = this.forgotPassEmail.asObservable();
  }

  getUserLoginData(email: string, password: string): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(apiUrl + 'auth/adminLogin',{
     email, password
    })
      .pipe(
        map((data: LoginResponse) => data)
      );
  }

  logoutUser(): Observable<any> {
    return this.http.post<any>(apiUrl + "auth/logout", {});
  }

  sendChangePasswordRequest(email: string): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(apiUrl + 'auth/resetAdminPassword', { email });
  }

  resetPassword(password: string, token: string): Observable<SimpleResponse> {
    return this.http.post<SimpleResponse>(apiUrl + 'auth/confirmResetPassword', { token, password });
  }

  emitEmail(email: string): void {
    this.forgotPassEmail.next(email);
  }
}

