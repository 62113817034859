import { environment } from '../../../environments/environment';
import { LocalStorageKeys } from "../enum/shared-enums";
import { Router } from "@angular/router";
import { NewCustomer } from "../dataModels/customerDataModel";
import { AbstractControl, ValidatorFn, Validators } from "@angular/forms";
import { FormOfAddress } from "../enum/form-of-address";
import { HttpErrorResponse } from "@angular/common/http";
import { CurrencyCodeLetters } from "../enum/products";
import { EditPricePayload, Product } from "../dataModels/productsDataModel";
import { Region } from "../dataModels/regionDataModels";
import { FileOrErrorJsonModel } from "../dataModels/sharedDataModels";

export const apiUrl = environment.apiUrl + "/api/";

export const ButtonsMode = {
  NAVIGATE: 'previousAndNext',
  SAVE: 'saveOrCancel',
}

export class Utils {
  static handleError(httpErrorResponse: HttpErrorResponse, router?: Router): string {
    let errorMessage = '';

    switch (httpErrorResponse.status) {
      case 404:
        errorMessage = 'Der gewünschte Inhalt ist nicht verfügbar!';
        break;
      case 401:
        // @ts-ignore
        if (router.url?.includes('login')) {
          errorMessage = 'Bei der Anmeldung ist ein Problem aufgetreten.' +
            'Überprüfen Sie bitte, ob Sie Ihre Email-Adresse oder ' +
            'Passwort korrekt eingegeben haben.';
          break;
        }
        // @ts-ignore
        if (router.url?.includes('change-password')) {
          errorMessage = 'Das angegebene Passwort ist falsch!';
          break;
        }
        console.log(router?.url)
        errorMessage = 'Etwas ist schiefgelaufen. Bitte versuchen Sie es noch einmal!'
        break;
      case 403:
        errorMessage = 'Ihnen fehlt die Berechtigung dieser Seite zu besuchen!';
        break;
      case 400:
        errorMessage = httpErrorResponse.error.message ?? 'Bitte kontaktieren Sie den Web-Administrator.';
        break;
      case 405:
        errorMessage = 'Etwas ist schiefgelaufen. Bitte kontaktieren Sie den Web-Administrator.';
        break;
      case 500:
        errorMessage = 'Etwas ist schiefgelaufen. Bitte versuchen Sie es noch einmal!';
        break;
      default:
        errorMessage = 'Ups! Ein unbekanntes Problem ist aufgetreten.';
    }

    return errorMessage;
  }

  static convertUtcToLocalTime(date: Date): Date {
    return new Date(date + ' UTC');
  }

  static enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
    return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
  }

  static setItemInLocalStorage(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  static getItemFromLocalStorage(key: string): any {
    return localStorage.getItem(key);
  }

  static removeItemFromLocalStorage(key: string) {
    localStorage.removeItem(key);
  }

  static getRegionFromLocalStorage(): string {
    const region = JSON.parse(this.getItemFromLocalStorage(LocalStorageKeys.REGION));
    return region.name.toString();
  }

  static camelCaseToSnakeCase(myObject: object): any {
    let obj = JSON.parse(JSON.stringify(myObject));
    if (typeof (obj) !== "object") return obj;

    for (const oldName in obj) {
      let newName = oldName.replace(/([A-Z])/g, $1 => { return "_" + $1.toLowerCase() });

      if (newName !== oldName) {
        if (obj.hasOwnProperty(oldName)) {
          // @ts-ignore
          obj[newName] = obj[oldName];
          // @ts-ignore
          delete obj[oldName];
        }
      }

      // @ts-ignore
      if (typeof (obj[newName]) === "object") {
        // @ts-ignore
        obj[newName] = Utils.camelCaseToSnakeCase(obj[newName]);
      }
    }
    return obj;
  }

  static snakeCaseToCamelCase(myObject: object): any {
    let obj = JSON.parse(JSON.stringify(myObject));
    if (typeof (obj) !== "object") return obj;

    for (const oldName in obj) {
      let newName = oldName.replace(/_[a-z]/g,
                                    group => {
                                      return group.toUpperCase().replace('_', '')
                                    });

      if (newName !== oldName) {
        if (obj.hasOwnProperty(oldName)) {
          // @ts-ignore
          obj[newName] = obj[oldName];
          // @ts-ignore
          delete obj[oldName];
        }
      }

      // @ts-ignore
      if (typeof (obj[newName]) === "object") {
        // @ts-ignore
        obj[newName] = Utils.snakeCaseToCamelCase(obj[newName]);
      }
    }
    return obj;
  }

  static generatePassword(): string {
    return Math.random().toString(36).slice(2);
  }

  static getDateFromUTC(date: string): string {
    return date.split('T')[0];
  }

  static getLocalDateFromUtc(date: string): string {
    return new Date(date.split('T')[0])
      .toLocaleDateString('de-DE', { year: "numeric", month: "2-digit", day: "2-digit" });
  }

  static getDateWithTimezoneOffset(date: string): string {
    const dateInstance = new Date(date);
    dateInstance.setMinutes(dateInstance.getMinutes() - dateInstance.getTimezoneOffset())
    return dateInstance.toLocaleDateString('de-DE', { year: "numeric", month: "2-digit", day: "2-digit" });
  }

  static convertLocalDateToUTC(date: string): string {
    const [day, month, year] = date.split('.');
    const createdDate = new Date(+year, +month - 1, +day);
    const timezoneOffset = createdDate.getTimezoneOffset() * 60000;
    // @ts-ignore
    return (new Date(createdDate - timezoneOffset)).toISOString();
  }

  static formatStartDateToISOString(inputDate: string): string {
    const [day, month, year] = inputDate.split('.').map(Number); // Parse the date components
    return new Date(Date.UTC(year, month - 1, day, 0, 0, 0, 0)).toISOString();
  }

  static formatEndDateToISOString(inputDate: string): string {
    const [day, month, year] = inputDate.split('.').map(Number); // Parse the date components
    return new Date(Date.UTC(year, month - 1, day, 23, 59, 59, 0)).toISOString();
  }

  static convertLocalDateToUTCDate(date: string): string {
    const dateAndTime = date.split('T');
    if (dateAndTime.length > 1) {
      return new Date(dateAndTime[0]).toISOString();
    }
    return Utils.convertLocalDateToUTC(date);
  }

  static convertLocalDateToISOString(date: string): string {
    const [day, month, year] = date.split('.');
    const createdDate = new Date(+year, +month - 1, +day);
    return createdDate.toISOString();
  }

  static getSavedCustomerDataFromLocalStorage(): NewCustomer {
    return JSON.parse(Utils.getItemFromLocalStorage(LocalStorageKeys.NEW_CUSTOMER));
  }

  static disallowPastDates(date: string): Function {
    return (formGroup: AbstractControl) => {
      const control = formGroup.get(date);
      if (control?.value) {
        const [day, month, year] = control?.value.split('.');
        const today = new Date(new Date().setHours(0, 0, 0, 0))

        if (control?.value && today > new Date(+year, +month - 1, +day)) {
          control.setErrors({ wrongDate: true });
        } else {
          control?.markAsDirty();
        }
      }
    }
  }

  static endDateValidation(startDate: string, endDate: string): Function {
    return (formGroup: AbstractControl) => {
      const startDateControl = formGroup.get(startDate);
      const endDateControl = formGroup.get(endDate);
      const [startDay, startMonth, startYear] = startDateControl?.value.split('.');
      const [endDay, endMonth, endYear] = endDateControl?.value.split('.');

      if (endDateControl?.value
        && new Date(+endYear, +endMonth - 1, +endDay)
        < new Date(+startYear, +startMonth - 1, +startDay)) {
        endDateControl.setErrors({ pastDate: true });
      } else if (endDateControl?.errors?.pastDate) {
        endDateControl.updateValueAndValidity();
      }
    }
  }

  static comparePasswords(controlName: string, matchingControlName: string): Function {
    return (formGroup: AbstractControl) => {
      const control = formGroup.get(controlName);
      const matchingControl = formGroup.get(matchingControlName);

      control?.value === matchingControl?.value
        ? matchingControl?.setErrors(null)
        : matchingControl?.setErrors({ mustMatch: true });
    }
  }

  static getPriceValidators(): ValidatorFn[] {
    return [Validators.required, Validators.min(1), Validators.pattern(/^-?\d*[.,]?\d{0,2}$/)];
  }

  static getFormOfAddress(formOfAddress: FormOfAddress): string {
    let translatedValue = '';
    switch (formOfAddress) {
      case FormOfAddress.MALE:
        translatedValue = 'Herr';
        break;
      case FormOfAddress.FEMALE:
        translatedValue = 'Frau';
        break;
      case FormOfAddress.DIVERSE:
        translatedValue = 'Divers';
        break;
    }
    return translatedValue;
  }

  static areAllCheckboxesSelected(checkboxes: any[], list: any[]): boolean {
    return list.every((item: any) => {
      return checkboxes.includes(item.id);
    });
  }

  static setCheckboxIndeterminateState(allItemsSelected: boolean, items: any[], selectedItems: any[]): boolean {
    if (!allItemsSelected) {
      return items.some((item: any) => {
        return selectedItems.includes(item.id);
      });
    }
    return false;
  }

  static copyToClipboard(content: string) {
    let listener = (event: ClipboardEvent) => {
      event.clipboardData?.setData('text/plain', (content));
      event.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand("copy");
    document.removeEventListener('copy', listener);
  }

  static formatPhoneNumber(num: string | undefined): string | undefined {
    if (num?.substring(0, 3) === '+49') {
      return num.replace("+49", "0");
    }
    if ((num?.substring(0, 4) === '0049')) {
      return num.replace("0049", "0");
    }
    return num;
  }

  static formatPriceForBE(price: string): string {
    return price.replace(',', '.');
  }

  static createPricePayload(priceInput: string | number): EditPricePayload {
    return {
      price: typeof priceInput === 'number' ? priceInput : +Utils.formatPriceForBE(priceInput),
      currencyCode: CurrencyCodeLetters.EUR
    }
  }

  static preparePhoneNumberInput(phone: string, countryCode: string): string {
    return phone ? countryCode + phone : '';
  }

  static assignRegionName(items: any[], regions: Region[]): any[] {
    items.map((item) => {
      const region = regions.find((region: Region) => {
        return region.id === item.regionId;
      });
      if (region) {
        item.regionName = region.name;
      }
      return item;
    });
    return items;
  }

  static downloadFile(rawFileData: ArrayBuffer | FileOrErrorJsonModel, fileName: string, mimeType: string): void {
    let fileExtension = this.determineFileExtension(mimeType);
    // @ts-ignore
    const blob = new Blob([rawFileData], { type: mimeType });
    const url = window.URL.createObjectURL(blob);
    let fileLink = document.createElement('a');
    fileLink.href = url;
    fileLink.setAttribute('download', fileName ? `${fileName}.${fileExtension}` : `File.${fileExtension}`);
    fileLink.setAttribute('target', '_blank');
    fileLink.click();
  }

  private static determineFileExtension(mimeType: string) {
    if (mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      return 'xlsx';
    } else {
      const matches = /\/([a-zA-Z]+)$/.exec(mimeType);
      return matches ? matches[1] : '';
    }
  }

  static transformProductNames(products: Product[]): Product[] {
    let transformedProducts: Product[] = [];
    const currentRegion = JSON.parse(Utils.getItemFromLocalStorage(LocalStorageKeys.REGION));
    const parsedProducts = Utils.snakeCaseToCamelCase(products);
    parsedProducts.forEach((product: Product) => {
      if (product.regionId === currentRegion.id) {
        transformedProducts.push(product);
      }
    });
    transformedProducts.map((product: Product) => {
      return product.name = `${product.duration} ${product.timeUnit} ${product.type?.name}`;
    });
    return transformedProducts
      .sort((product1: Product, product2: Product) => product1.duration - product2.duration);
  }

  static transformSnakeCaseToCamelCaseForString(value: string): string {
    return value.replace(/_[a-z]/g,
                         group => {
                           return group.toUpperCase().replace('_', '')
                         });
  }

  static transformCamelCaseToSnakeCaseForString(value: string): string {
    return value.replace(/([A-Z])/g, $1 => { return "_" + $1.toLowerCase() });
  }
}
