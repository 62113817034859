import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Utils } from "../../shared/utils/utils";
import { PaginationData } from "../../shared/dataModels/paginationDataModel";
import { UsersService } from "../../services/users.service";
import { takeUntil } from "rxjs/operators";
import { AllUsersResponse } from "../../shared/dataModels/userDataModel";
import { Permission } from "../../shared/dataModels/roleDataModel";
import { MessageTypes } from "../../shared/enum/message-types";
import { BreadcrumbData } from "../../shared/components/breadcrumbs/breadcrumbs.component";
import { ErrorStatus } from "../../shared/enum/error-status";
import { HttpErrorResponse } from "@angular/common/http";
import { SubscriptionComponent } from "../../shared/components/subscription/subscription.component";
import { AlertifyService } from "../../shared/services/alertify-service.service";
import { ResponseCode } from "../../shared/enum/shared-enums";
import { AppGlobalService } from "../../shared/services/app-global.service";
import { NavigationService } from "../../shared/services/navigation.service";

@Component({
             selector: 'app-permissions',
             templateUrl: './permissions.component.html',
             styleUrls: ['./permissions.component.scss']
           })
export class PermissionsComponent extends SubscriptionComponent implements OnInit {
  @Input() asChild: boolean;
  @Input() permissionsToPreselect: Permission[];
  @Output() selectedPermissions = new EventEmitter();
  allSelectedPermissions: number[] = [];
  permissionSearchForm: FormGroup;
  requestParams: Params = { searchTerms: '' };
  utils = Utils;
  permissionsData: PaginationData;
  messageTypes = MessageTypes;
  permissionsLoaded = false;
  searchResults = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private usersService: UsersService,
              private globalService: AppGlobalService,
              private alertifyService: AlertifyService,
              private navigationService: NavigationService,
              private formBuilder: FormBuilder) { super() }

  ngOnInit(): void {
    this.getPermissions();
    this.permissionSearchForm = this.formBuilder.group({
                                                         searchInput: ['', [Validators.required]],
                                                       });
    this.onChangeSearchInputValue();
  }

  get searchInput() {
    return this.permissionSearchForm.get('searchInput');
  }

  getPermissions(): void {
    this.usersService.getPermissions(this.requestParams)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: AllUsersResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            this.permissionsData = response.body;
            this.permissionsLoaded = true;

            response.body?.pagination?.data?.length === 0
              ? this.searchResults = false
              : this.searchResults = true;

            this.preselectPermissions();
          } else {
            this.alertifyService.warning(response.message);
          }
        }, (error: HttpErrorResponse) => {
          if (error.status === ErrorStatus.STATUS_500) {
            this.globalService.emitServerError(true);
          }
        });
  }

  preselectPermissions(): void {
    if (this.permissionsData && this.permissionsToPreselect) {
      this.permissionsData.pagination?.data?.map(permission => {
        this.permissionsToPreselect?.forEach(savedPermission => {
          if (permission.id === savedPermission.id) {
            permission.selected = true;
            this.allSelectedPermissions.push(permission.id);
          }
        })
      });

      this.selectedPermissions.emit(this.allSelectedPermissions);
    }
  }

  onSearchPermissions(): void {
    this.permissionsLoaded = false;
    this.requestParams.searchTerms = this.searchInput?.value;
    this.getPermissions();
  }

  onSelectPermission(permission: Permission): void {
    const permissionID = this.allSelectedPermissions
                             .findIndex(savedPermissionId => savedPermissionId === permission.id);
    permissionID !== -1
      ? this.allSelectedPermissions.splice(permissionID, 1)
      : this.allSelectedPermissions.push(permission.id);

    this.selectedPermissions.emit(this.allSelectedPermissions);
  }

  getPaginatedData(page: string): void {
    this.permissionsLoaded = false;
    this.requestParams.page = +page;
    const queryParams = Utils.camelCaseToSnakeCase(this.requestParams);
    this.router.navigate([], { queryParams, relativeTo: this.route });
    this.getPermissions();
  }

  onChangeSearchInputValue(): void {
    this.searchInput?.valueChanges.subscribe(value => {
      if (value === '') {
        this.requestParams.searchTerms = '';
        this.getPermissions();
      }
    })
  }

  onClearSearch(): void {
    this.requestParams.searchTerms = '';
    this.searchInput?.setValue('');
  }

  navigateTo(breadcrumb: BreadcrumbData): void {
    this.router.navigate(['/' + breadcrumb.routerLink]);
  }

  @HostListener('window:popstate', ['$event'])
  goBack() {
    this.navigationService.back().subscribe((params: Params) => {
      this.requestParams = params;
      this.requestParams.searchTerms = params['searchTerms'] ?? '';
      this.getPermissions();
    });
  }
}
