<form [formGroup]="salesStatisticsFilterForm">
  <div class="dropdown-grid">
    <div *ngFor="let field of SalesStatisticFilters" class="item mb-3">
      <label class="col-form-label">{{ field?.title }}</label>
      <div class="col-md-11">
        <ng-container *ngIf="!field.inputType">
          <select class="form-select cursor-pointer me-3"
                  [formControlName]="field.formName">
            <option *ngFor="let option of field.options" [value]="option.value">{{ option?.label }}</option>
          </select>
        </ng-container>
        <ng-container *ngIf="field.inputType">
          <app-date-picker [formName]="field.formName"
                           [inputType]="field.inputType"
                           [formGroup]="salesStatisticsFilterForm"
                           [value]="salesStatisticsFilterForm.get(field.formName)?.value"
          ></app-date-picker>
        </ng-container>
      </div>
    </div>
    <div class="item mb-3">
      <label class="col-form-label" for="selectedProduct">Produkttyp</label>
      <div class="col-md-11">
        <select formControlName="selectedProduct"
                id="selectedProduct"
                class="form-select cursor-pointer me-3">
          <option [value]="null">Alle</option>
          <option *ngFor="let product of products" [ngValue]="product">
            {{ product.name }}
          </option>
          <option [value]="CardType.FIRST_CARD">Abo-{{ CardType.FIRST_CARD }}</option>
          <option [value]="CardType.FOLLOW_CARD">Abo-{{ CardType.FOLLOW_CARD }}</option>
          <option *ngFor="let amount of voucherAmounts" [value]="amount">
            Gutschein {{ amount | currency: 'EUR' }}
          </option>
        </select>
      </div>
    </div>
    <div class="item mb-3">
      <label class="col-form-label" for="payment_status">Zahlstatus</label>
      <div class="col-md-11">
        <select formControlName="paymentStatus"
                id="payment_status"
                class="form-select cursor-pointer me-3">
          <option [value]="null">Alle</option>
          <option *ngFor="let paymentStatus of paymentStatusFilters"
                  [value]="paymentStatus">{{ paymentStatus }}</option>
        </select>
      </div>
    </div>
    <div class="item mb-3">
      <label class="col-form-label" for="paymentMethod">Zahlungsmethode</label>
      <div class="col-md-11">
        <select id="paymentMethod"
                formControlName="paymentMethod"
                class="form-select cursor-pointer me-3">
          <option [value]="null">Alle</option>
          <option *ngFor="let paymentMethod of paymentMethodFilters"
                  [value]="paymentMethod">{{ paymentMethod }}</option>
        </select>
      </div>
    </div>
    <div class="item mb-3">
      <label class="col-form-label" for="order_status">Bestellstatus</label>
      <div class="col-md-11">
        <select id="order_status"
                formControlName="orderStatus"
                class="form-select cursor-pointer me-3">
          <option [value]="null">Alle</option>
          <option *ngFor="let status of statusFilters" [value]="status">{{ status }}</option>
        </select>
      </div>
    </div>
    <div class="item mb-3">
      <label class="col-form-label" for="order_state">Bestellzustand</label>
      <div class="col-md-11">
        <select id="order_state"
                formControlName="orderState"
                class="form-select cursor-pointer me-3">
          <option [value]="null">Alle</option>
          <option *ngFor="let state of stateFilters" [value]="state">{{ state }}</option>
        </select>
      </div>
    </div>
    <div class="item mb-3">
      <label class="col-form-label" for="region">Region</label>
      <div class="col-md-11">
        <select id="region"
                formControlName="regionId"
                class="form-select cursor-pointer me-3">
          <option value="-1">Alle</option>
          <option *ngFor="let region of regions" [value]="region.id">{{ region?.name }}</option>
        </select>
      </div>
    </div>
  </div>
</form>
<div class="d-flex justify-content-end me-md-filter-buttons">
  <button class="cancel-button me-3"
          (click)="onInitializeOrderFilterForm()"
          data-bs-toggle="modal"
          data-bs-target="#sales-modal">
    Abbrechen
  </button>
  <button class="app-primary-button"
          (click)="onEmitFilters()"
          [disabled]="salesStatisticsFilterForm?.invalid"
          data-bs-toggle="modal"
          data-bs-target="#sales-modal">
    Erstellen
  </button>
</div>
