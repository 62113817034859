import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ProductVariant } from "../../../enum/products";
import { dateInputPattern } from "../../filter-container/filter-container.component";
import { AppGlobalService } from "../../../services/app-global.service";
import { CardsService } from "../../../../services/cards.service";
import { SubscriptionComponent } from "../../subscription/subscription.component";
import { takeUntil } from "rxjs/operators";
import { CardAccessories, CardStatisticFilters } from "../../../dataModels/cardDataModel";
import { FilterUtils } from "../../../utils/filter";
import { CardReportFilters } from "../../../dataModels/reportsDataModel";
import { Utils } from "../../../utils/utils";
import { LocalStorageKeys, ResponseCode } from "../../../enum/shared-enums";
import { AllRegionsResponse, Region } from "../../../dataModels/regionDataModels";
import { AllProductsResponse, Product } from "../../../dataModels/productsDataModel";
import { ProductsService } from "../../../../services/products.service";
import { Status } from "../../../enum/status";

@Component({
             selector: 'app-card-statistic-filters',
             templateUrl: './card-statistic-filters.component.html',
             styleUrls: ['./card-statistic-filters.component.scss']
           })
export class CardStatisticFiltersComponent extends SubscriptionComponent implements OnInit {
  @Output() filterValues = new EventEmitter<CardReportFilters>();
  cardStatisticForm: FormGroup;
  CardStatisticFilters = CardStatisticFilters;
  CardType = ProductVariant;
  products: Product[] = [];
  regions: Region[];
  regionId: number;
  cardStatuses: string[];
  cardStates: string[];
  cardCancellationStatuses: string[];

  constructor(private formBuilder: FormBuilder,
              private globalService: AppGlobalService,
              private cardsService: CardsService,
              private productsService: ProductsService) {
    super();
  }

  ngOnInit(): void {
    this.getCardStatusData();
    this.getCancellationStatuses();
    this.getCardStateData();
    this.getProductsData();
    this.getRegions();
    this.regionId = JSON.parse(Utils.getItemFromLocalStorage(LocalStorageKeys.REGION)).id;
    this.onInitializeCardStatisticForm();
    this.setDurationAndTimeUnit();
  }

  onInitializeCardStatisticForm(): void {
    this.cardStatisticForm = this.formBuilder.group({
                                                      regionId: [this.regionId],
                                                      selectedProduct: [null],
                                                      cardType: [null],
                                                      duration: [null],
                                                      timeUnit: [null],
                                                      cardStatus: [null],
                                                      cancellationStatus: [null],
                                                      cardState: [null],
                                                      isOnlyDigital: [null],
                                                      startDateFrom: [null, [Validators.pattern(dateInputPattern)]],
                                                      startDateTo: [null, [Validators.pattern(dateInputPattern)]],
                                                      endDateFrom: [null, [Validators.pattern(dateInputPattern)]],
                                                      endDateTo: [null, [Validators.pattern(dateInputPattern)]],
                                                      createdFrom: [null, [Validators.pattern(dateInputPattern)]],
                                                      createdTo: [null, [Validators.pattern(dateInputPattern)]],
                                                      cancellationDateFrom: [null, [Validators.pattern(dateInputPattern)]],
                                                      cancellationDateTo: [null, [Validators.pattern(dateInputPattern)]],
                                                      cancellationRevokedDateFrom: [null, [Validators.pattern(dateInputPattern)]],
                                                      cancellationRevokedDateTo: [null, [Validators.pattern(dateInputPattern)]]
                                                    });
  }

  private getCardStatusData(): void {
    this.cardsService.getCardStatuses()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: CardAccessories) => {
          if (response.code === ResponseCode.SUCCESS) {
            this.cardStatuses = response.body.data;
          }
        });
  }

  private getCancellationStatuses(): void {
    this.cardsService.getCardCancellationStatuses()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: CardAccessories) => {
          if (response.code === ResponseCode.SUCCESS) {
            this.cardCancellationStatuses = response.body.data;
          }
        });
  }

  private getCardStateData(): void {
    this.cardsService.getCardStates()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: CardAccessories) => {
          if (response.code === ResponseCode.SUCCESS) {
            this.cardStates = response.body.data;
          }
        });
  }

  private getProductsData(): void {
    this.productsService.getProducts({ status: Status.ACTIVE })
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: AllProductsResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            this.products = Utils.transformProductNames(response.body.pagination.data);
          }
        });
  }

  private getRegions(): void {
    this.globalService.regions$.pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((regionsResponse: AllRegionsResponse) => {
          if (regionsResponse) {
            this.regions = regionsResponse.body?.pagination.data;
          }
        });
  }

  private setDurationAndTimeUnit(): void {
    this.cardStatisticForm.get('selectedProduct')?.valueChanges.subscribe(product => {
      if (typeof product === 'object') {
        if (product.duration > 0) {
          this.cardStatisticForm.get('duration')?.patchValue(product.duration);
          this.cardStatisticForm.get('timeUnit')?.patchValue(product.timeUnit);
        } else {
          this.cardStatisticForm.get('duration')?.reset();
          this.cardStatisticForm.get('timeUnit')?.reset();
        }
        this.cardStatisticForm.get('cardType')?.setValue(product.type.name);
      } else {
        this.cardStatisticForm.get('cardType')?.setValue(product);
        this.cardStatisticForm.get('duration')?.reset();
        this.cardStatisticForm.get('timeUnit')?.reset();
      }
    });
  }

  onEmitFilters(): void {
    let sanitizedForm = FilterUtils.sanitizeFilterForm(this.cardStatisticForm);
    this.removeUnnecessaryFields(sanitizedForm);
    sanitizedForm = this.parseDates(sanitizedForm,
                                    ['startDateFrom',
                                      'startDateTo',
                                      'endDateFrom',
                                      'endDateTo',
                                      'createdFrom',
                                      'createdTo',
                                      'cancellationDateFrom',
                                      'cancellationDateTo',
                                      'cancellationRevokedDateFrom',
                                      'cancellationRevokedDateTo']);
   sanitizedForm = this.setHoursToEndDates(sanitizedForm, [
     'startDateTo',
     'endDateTo',
     'createdTo',
     'cancellationDateTo',
     'cancellationRevokedDateTo'
   ]);
    this.filterValues.emit(sanitizedForm);
  }

  private removeUnnecessaryFields(sanitizedForm: Record<string, any>): void {
    if (sanitizedForm.regionId === '-1') {
      delete sanitizedForm.regionId;
    }
    if (sanitizedForm.isOnlyDigital) {
      sanitizedForm.isOnlyDigital = sanitizedForm.isOnlyDigital === 'true';
    }
    delete sanitizedForm.selectedProduct;
  }

  private parseDates(sanitizedForm: CardReportFilters, dateFields: string[]): CardReportFilters {
    dateFields.forEach((dateField: string) => {
      if (dateField in sanitizedForm) {
        // @ts-ignore
        sanitizedForm[dateField] = Utils.convertLocalDateToUTC(sanitizedForm[dateField]);
      }
    });
    return sanitizedForm;
  }

  private setHoursToEndDates(sanitizedForm: CardReportFilters, endDates: string[]): CardReportFilters {
    endDates.forEach(endDate => {
      let utcEndDate;
      if (endDate in sanitizedForm) {
        // @ts-ignore
        utcEndDate = new Date(sanitizedForm[endDate]);
        utcEndDate.setHours(utcEndDate.getHours() + 23);
        utcEndDate.setMinutes(utcEndDate.getMinutes() + 59);
        utcEndDate.setSeconds(utcEndDate.getSeconds() + 59);
        // @ts-ignore
        sanitizedForm[endDate] = utcEndDate.toISOString();
      }
      utcEndDate = undefined;
    });
    return sanitizedForm;
  }
}
