import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { BreadcrumbData } from "../breadcrumbs/breadcrumbs.component";
import { Utils } from "../../utils/utils";
import { AllCardsResponse, AllLinkedCardsResponse, Card } from "../../dataModels/cardDataModel";
import { CardsService } from "../../../services/cards.service";
import { takeUntil } from "rxjs/operators";
import { SubscriptionComponent } from "../subscription/subscription.component";
import { PaginationData } from "../../dataModels/paginationDataModel";
import { LocalStorageKeys, ResponseCode } from "../../enum/shared-enums";
import { AlertifyService } from "../../services/alertify-service.service";
import { GetCards } from "../../enum/cards";
import { FilterUtils } from "../../utils/filter";
import { NavigationService } from "../../services/navigation.service";
import { AppGlobalService } from "../../services/app-global.service";

@Component({
             selector: 'app-detailed-cards',
             templateUrl: './detailed-cards.component.html',
             styleUrls: ['./detailed-cards.component.scss']
           })
export class DetailedCardsComponent extends SubscriptionComponent implements OnInit {
  cardsData: PaginationData;
  cards: Card[];
  cardsLoaded = false;
  requestParams: Params;
  noCards = false;
  isLinkedCardsResponse = false;
  customerId: number;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private cardsService: CardsService,
              private globalService: AppGlobalService,
              private navigationService: NavigationService,
              private alertifyService: AlertifyService) {super(); }

  ngOnInit(): void {
    this.requestParams = FilterUtils.getQueryParametersFromRoute(this.route);
    this.getCards();
  }

  private getCards() {
    this.route.params.pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((params: Params) => {
          if (params?.id) {
            if (+this.requestParams?.cardType === GetCards.CUSTOMER) {
              this.customerId = params.id;
              this.getCustomerCards(params.id);
            } else if (+this.requestParams?.cardType === GetCards.LINKED_CARDS) {
              this.isLinkedCardsResponse = true;
              this.customerId = params.id;
              this.getLinkedCards(params.id);
            } else {
              this.getOrderCards(params.id);
            }
          } else {
            this.noCards = true;
          }
        });
  }

  private getOrderCards(id: number) {
    this.cardsService.getOrderCards(id, this.requestParams).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: AllCardsResponse) => {
          this.handleResponse(response);
        }, () => {
          this.globalService.emitServerError(true);
        });
  }

  private getCustomerCards(id: number) {
    this.cardsService.getCustomerCards(id, this.requestParams).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: AllCardsResponse) => {
          this.handleResponse(response);
        }, () => {
          this.globalService.emitServerError(true);
        });
  }

  private getLinkedCards(id: number): void {
    const regionId = JSON.parse(Utils.getItemFromLocalStorage(LocalStorageKeys.REGION)).id;
    this.cardsService.getLinkedCards(id, regionId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: AllLinkedCardsResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            this.cardsLoaded = true;
            if (response.body.length > 0) {
              this.noCards = false;
            }
            this.cards = Utils.snakeCaseToCamelCase(response.body);
          } else {
            this.alertifyService.warning(response.message);
          }
        }, () => {
          this.globalService.emitServerError(true);
        });
  }

  private handleResponse(response: AllCardsResponse) {
    if (response.code === ResponseCode.SUCCESS) {
      this.cards = Utils.snakeCaseToCamelCase(response.body?.pagination?.data);
      this.cardsData = response.body;
      if (this.cards.length === 0) {
        this.noCards = true;
      }
      this.cardsLoaded = true;
    } else {
      this.alertifyService.warning(response.message);
      this.noCards = true;
    }
  }

  loadPaginatedData(pageNumber: string) {
    this.cardsLoaded = false;
    this.requestParams = { ...this.requestParams, page: pageNumber };
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { page: pageNumber },
        queryParamsHandling: 'merge'
      });
    this.getCards();
  }

  navigateTo(breadcrumb: BreadcrumbData) {
    this.router.navigate(['/' + breadcrumb.routerLink],
                         { queryParams: { 'region': Utils.getRegionFromLocalStorage() } });
  }

  @HostListener('window:popstate', ['$event'])
  goBack(event: Event) {
    this.navigationService.back().subscribe((params: Params) => {
      this.requestParams = params;
      this.getCards();
    });
  }
}
