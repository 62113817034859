import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from "rxjs";
import { debounceTime, tap } from "rxjs/operators";

@Directive({
             selector: '[appDebounceClick]'
           })
export class DebounceClickDirective implements OnInit, OnDestroy {

  @Input() debounceTime = 500;
  @Input() maxClickCount = 5;
  @Output() debounceClick = new EventEmitter();
  @Output() callback = new EventEmitter();
  private clicks = new Subject();
  private clickCount = 0;

  constructor() { }

  ngOnInit(): void {
    this.clicks.pipe(
      tap(() => {
        this.clickCount++;
        if (this.clickCount > this.maxClickCount) {
          this.clickCount = 0;
        } else if (this.clickCount === this.maxClickCount) {
          this.callback.emit();
        }
      }),
      debounceTime(this.debounceTime)
    )
        .subscribe(event => {
          this.clickCount = 0;
          this.debounceClick.emit(event);
        });
  }

  ngOnDestroy(): void {
    this.clicks.unsubscribe();
  }

  @HostListener('click', ['$event'])
  clickEvent(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }

}
