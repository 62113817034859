import {Component, OnDestroy, OnInit} from '@angular/core';
import { DeploymentInformation } from "../../dataModels/sharedDataModels";
import { AppGlobalService } from "../../services/app-global.service";
import { takeUntil } from "rxjs/operators";
import { Utils } from "../../utils/utils";
import { HttpErrorResponse } from "@angular/common/http";
import { SubscriptionComponent } from "../subscription/subscription.component";

@Component({
  selector: 'app-deployment-info',
  templateUrl: './deployment-info.component.html',
  styleUrls: ['./deployment-info.component.scss']
})
export class DeploymentInfoComponent extends SubscriptionComponent implements OnInit, OnDestroy {
  deployment: DeploymentInformation;
  deploymentLoaded = false;

  constructor(private globalService: AppGlobalService) { super() }

  ngOnInit(): void {
    this.getDeploymentInfo();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private getDeploymentInfo(): void {
    this.globalService.getDeploymentInfo()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: DeploymentInformation) => {
        this.deployment = Utils.snakeCaseToCamelCase(response);
        this.deploymentLoaded = true;
      }, (error: HttpErrorResponse) => {
        this.deploymentLoaded = true;
      });
  }
}
