import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  TroubleshootingAreas,
  TroubleShootingDisplay,
  TroubleshootingItem,
  TroubleshootingResponse
} from "../../shared/dataModels/troubleshootingDataModel";
import { TroubleshootingService } from "../../services/troubleshooting.service";
import { SubscriptionComponent } from "../../shared/components/subscription/subscription.component";
import { takeUntil } from "rxjs/operators";
import { Utils } from "../../shared/utils/utils";
import { StringArrayResponse, TroubleShootingCreatedResponse } from "../../shared/dataModels/ordersDataModel";
import { OrdersService } from "../../services/orders.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Params } from "@angular/router";
import { AllRegionsResponse, Region } from "../../shared/dataModels/regionDataModels";
import { AppGlobalService } from "../../shared/services/app-global.service";
import { LocalStorageKeys } from "../../shared/enum/shared-enums";
import { ResponseCode } from "../../shared/enum/shared-enums";
import { AlertifyService } from "../../shared/services/alertify-service.service";
import { TroubleShootingTypes } from "../../shared/enum/orders";

@Component({
             selector: 'app-troubleshooting',
             templateUrl: './troubleshooting.component.html',
             styleUrls: ['./troubleshooting.component.scss']
           })
export class TroubleshootingComponent extends SubscriptionComponent implements OnInit, OnDestroy {
  @ViewChild('selectAllCheckbox') headerCheckbox: ElementRef;
  troubleShootingFilterForm: FormGroup;
  itemsToTroubleshoot: TroubleshootingAreas;
  troubleshootingVM: TroubleshootingItem[] = [];
  troubleShootingTypes: TroubleShootingDisplay[] = [];
  requestParams: Params;
  selectedTroubleShoots: number[] = [];
  allTroubleShootsSelected = false;
  chevron = true;
  responseSuccessful = false;
  nothingToTroubleshoot = false;
  error = false;
  regions: Region[];
  regionId: number;

  constructor(private troubleshootingService: TroubleshootingService,
              private ordersService: OrdersService,
              private formBuilder: FormBuilder,
              private alertifyService: AlertifyService,
              private globalService: AppGlobalService) { super() }

  ngOnInit(): void {
    this.regionId = JSON.parse(Utils.getItemFromLocalStorage(LocalStorageKeys.REGION)).id;
    this.requestParams = {
      orderDirection: 'desc',
      orderBy: 'created_at',
      type: 'Alle',
      regionId: this.regionId
    };
    this.initializeTroubleShootingFiltersForm();
    this.getTroubleshootItems();
    this.getTroubleShootingTypes();
    this.getRegions();
    this.watchTroubleShootingTypes();
    this.watchRegion();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  getTroubleshootItems(): void {
    this.troubleshootingService.getItemsToTroubleshoot(this.requestParams)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: TroubleshootingResponse) => {
                     this.itemsToTroubleshoot = Utils.snakeCaseToCamelCase(response.body?.data);
                     this.iterateToTroubleshootingArray(this.itemsToTroubleshoot.overpaid);
                     this.iterateToTroubleshootingArray(this.itemsToTroubleshoot.underpaid);
                     this.iterateToTroubleshootingArray(this.itemsToTroubleshoot.postReturn);
                     this.iterateToTroubleshootingArray(this.itemsToTroubleshoot.stuckInProcess);
                     this.iterateToTroubleshootingArray(this.itemsToTroubleshoot.companyOrderWithoutEmail);
                     this.iterateToTroubleshootingArray(this.itemsToTroubleshoot.paidWithoutInvoice);
                     this.nothingToTroubleshoot = (this.itemsToTroubleshoot.overpaid?.length === 0 || !this.itemsToTroubleshoot.overpaid)
                       && (this.itemsToTroubleshoot.postReturn?.length === 0 || !this.itemsToTroubleshoot.postReturn)
                       && (this.itemsToTroubleshoot.underpaid?.length === 0 || !this.itemsToTroubleshoot.underpaid)
                       && (this.itemsToTroubleshoot.stuckInProcess?.length === 0 || !this.itemsToTroubleshoot.stuckInProcess)
                       && (this.itemsToTroubleshoot.companyOrderWithoutEmail?.length === 0 || !this.itemsToTroubleshoot.companyOrderWithoutEmail)
                       && (this.itemsToTroubleshoot.paidWithoutInvoice?.length === 0 || !this.itemsToTroubleshoot.paidWithoutInvoice);
                     this.responseSuccessful = true;
                   }, () => this.responseSuccessful = true);
  }

  private getRegions(): void {
    this.globalService.regions$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data: AllRegionsResponse) => {
          if (data.body) {
            this.regions = Utils.snakeCaseToCamelCase(data.body?.pagination.data);
          }
        });
  }

  private initializeTroubleShootingFiltersForm() {
    this.troubleShootingFilterForm = this.formBuilder.group({
                                                              type: ['-1'], // for type "Alle"
                                                              region: [this.regionId]
                                                            });
  }

  get type() {
    return this.troubleShootingFilterForm.get('type');
  }

  get region() {
    return this.troubleShootingFilterForm.get('region');
  }

  private getTroubleShootingTypes(): void {
    this.ordersService.getTroubleShootingTypes()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: StringArrayResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            const types = response.body.data;
            types.forEach((type: string) => {
              this.troubleShootingTypes.push({
                                               displayName: this.getValueOfEnumByStringKey(type),
                                               type: type
                                             });
            });
          } else {
            this.error = true;
          }
        });
  }

  getValueOfEnumByStringKey(type: string): string {
    const key = Utils.enumKeys(TroubleShootingTypes).find((key) => key === type.toUpperCase());
    if (typeof key !== "undefined") {
      const res = Utils.enumKeys(TroubleShootingTypes).indexOf(key);
      return Object.values(TroubleShootingTypes)[res];
    }
    return "";
  }

  private watchTroubleShootingTypes(): void {
    this.type?.valueChanges.subscribe((value) => {
      this.requestParams.type = value;
      this.onRefreshList();
    });
  }

  private watchRegion(): void {
    this.region?.valueChanges.subscribe((value) => {
      this.requestParams.regionId = value;
      this.onRefreshList();
    });
  }

  private iterateToTroubleshootingArray(items: TroubleshootingItem[]): void {
    if (items) {
      items.forEach((item: TroubleshootingItem) => {
        this.troubleshootingVM.push(item);
      });
    }
  }

  onSelectTroubleShoot(id: number) {
    if (this.selectedTroubleShoots?.includes(id)) {
      const index = this.selectedTroubleShoots.indexOf(id);
      this.selectedTroubleShoots.splice(index, 1);
      this.allTroubleShootsSelected = false;
      this.setCheckboxIndeterminateState();
    } else {
      this.selectedTroubleShoots.push(id);
      this.checkIfAllCheckboxSelected();
      this.headerCheckbox.nativeElement.indeterminate = !this.allTroubleShootsSelected;
    }
  }

  onCheckAllTroubleShoots(event: any): void {
    if (event.target.checked) {
      this.troubleshootingVM.forEach((item: TroubleshootingItem) => {
        if (!this.selectedTroubleShoots.includes(item.id)) {
          this.selectedTroubleShoots.push(item.id);
        }
        this.allTroubleShootsSelected = true;
      });
    } else {
      this.troubleshootingVM.forEach((item: TroubleshootingItem) => {
        const index = this.selectedTroubleShoots.indexOf(item.id);
        this.selectedTroubleShoots.splice(index, 1);
      });
    }
  }

  private setCheckboxIndeterminateState() {
    if (this.headerCheckbox) {
      this.headerCheckbox.nativeElement.indeterminate = Utils.setCheckboxIndeterminateState(
        this.allTroubleShootsSelected,
        this.troubleshootingVM,
        this.selectedTroubleShoots
      );
    }
  }

  private checkIfAllCheckboxSelected(): void {
    this.allTroubleShootsSelected = Utils.areAllCheckboxesSelected(this.selectedTroubleShoots, this.troubleshootingVM);
  }

  onSetResolveForTroubleShoots() {
    this.troubleshootingService.setResolvedOnTroubleShootingList(this.selectedTroubleShoots)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: TroubleShootingCreatedResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            this.alertifyService.success(response.message);
            this.onRefreshList();
          } else {
            this.alertifyService.warning(response.message);
          }
        });
  }

  onRefreshList() {
    this.responseSuccessful = false;
    this.troubleshootingVM = [];
    this.getTroubleshootItems();
  }
}
