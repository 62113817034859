import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent  {
  @Input() modalLabel: string;
  @Input() modalContent: string;
  @Input() saveButtonLabel: string;
  @Input() cancelButtonLabel: string;
  @Input() saveButtonCssClass: string;
  @Input() modalHeaderCssClass: string;
  @Input() modalType: string;
  @Input() allowClickElsewhere: boolean;
  @Output() emitDismiss = new EventEmitter<any>();
  @Output() emitConfirm = new EventEmitter<any>();
  @Output() emitCancel = new EventEmitter<any>();

  constructor() { }

  onDismiss(): void {
    this.emitDismiss.emit(true);
  }

  onConfirm(): void {
    this.emitConfirm.emit(true)
  }

  onCancel(): void {
    this.emitCancel.emit(true);
  }
}
