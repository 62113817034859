import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthenticationService } from "../../services/authentication.service";
import { ActivatedRoute, Router } from "@angular/router";
import { LoginResponse } from '../../shared/dataModels/loginDataModel';
import { Utils } from "../../shared/utils/utils";
import { ErrorStatus } from "../../shared/enum/error-status";
import { LoginRegion, UserResponse, UserResponseData } from "../../shared/dataModels/userDataModel";
import { LocalStorageKeys, ResponseCode } from "../../shared/enum/shared-enums";
import { RegionsService } from "../../services/regions.service";
import { MeService } from "../../services/me.service";
import { take } from "rxjs/operators";
import { AlertifyService } from "../../shared/services/alertify-service.service";

@Component({
             selector: 'app-login',
             templateUrl: './login.component.html',
             styleUrls: ['./login.component.scss']
           })
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  isLoginFailed: boolean;
  isPasswordHidden = true;
  passwordType = 'password';
  defaultLoginRegion: LoginRegion;
  selectedRegionInUrl: string;
  showDefaultRegionSelectionModal = false;
  meData: UserResponseData;
  errorMessage: string;
  currentYear = new Date().getFullYear();
  utils = Utils;
  errorStatus = ErrorStatus;

  constructor(private formBuilder: FormBuilder,
              private loginService: AuthenticationService,
              private regionService: RegionsService,
              private meService: MeService,
              private route: ActivatedRoute,
              private router: Router,
              private alertifyService: AlertifyService) {
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
                                              email: new FormControl('', Validators.required),
                                              password: new FormControl('', Validators.required),
                                            }
    );
  }

  get email() {
    return this.loginForm.get('email')?.value as FormControl;
  }

  get password() {
    return this.loginForm.get('password')?.value as FormControl;
  }

  onLogin() {
    const { email, password } = this.loginForm.value;
    this.loginService.getUserLoginData(email, password)
        .subscribe((loginData: LoginResponse) => {
          if (loginData.code === ResponseCode.SUCCESS) {
            const token = loginData.body?.token;
            if (token) {
              this.utils.setItemInLocalStorage('token', token);
              this.getMeData();
            }
          } else {
            this.alertifyService.warning(loginData.message);
          }
        });
  }

  getMeData() {
    this.meService.getMeData().pipe(take(1))
        .subscribe((meData: UserResponse) => {
          if (meData.code === ResponseCode.SUCCESS) {
            this.meData = Utils.snakeCaseToCamelCase(meData.body);
            this.defaultLoginRegion = this.meData.defaultLoginRegion;
            this.saveLoginRegion();
          } else {
            this.alertifyService.warning(meData.message);
          }
        });
  }

  private isDefaultRegionInLoginRegions(): boolean {
    return this.meData?.loginRegions.some((region: LoginRegion) => {
      return region.id === this.defaultLoginRegion.id;
    });
  }

  private saveLoginRegion() {
    if (this.defaultLoginRegion && this.isDefaultRegionInLoginRegions()) {
      this.utils.setItemInLocalStorage(LocalStorageKeys.REGION, JSON.stringify(this.defaultLoginRegion));
      this.router.navigate(['/dashboard/home'], { queryParams: { region: this.defaultLoginRegion.name } });
      return;
    }
    this.showDefaultRegionSelectionModal = true;
  }

  onNavigateHome(region: LoginRegion): void {
    if (region.code !== 'ROOT') {
      this.utils.setItemInLocalStorage(LocalStorageKeys.REGION, JSON.stringify(region));
      this.router.navigate(['/dashboard/home'], { queryParams: { region: region.name } });
      this.regionService.setDefaultLogin(region.id).subscribe();
    }
  }

  hidePassword() {
    this.passwordType = 'password';
    this.isPasswordHidden = true;
  }

  showPassword() {
    this.passwordType = 'text';
    this.isPasswordHidden = false;
  }
}
