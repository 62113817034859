import {
  Customer,
  CustomerAddress,
  CustomerInvoiceAddress,
  CustomerShippingAddress,
  LinkedUser
} from "./customerDataModel";
import { PaginationData } from "./paginationDataModel";
import { Coupon, Giveaway } from "./couponDataModel";
import { PersonalizedProduct, Price } from "./productsDataModel";
import { Filter } from "./sharedDataModels";
import { CheckoutSource, DisplayOrderType, OrderSource, OrderStatus, OrderType } from "../enum/orders";
import { Voucher } from "./vouchersDataModels";
import { FormOfAddress } from "../enum/form-of-address";
import { AddressType, PaymentMethods } from "../enum/customer";
import { CurrencyCodeLetters } from "../enum/products";
import { Card } from "./cardDataModel";
import { AcademicTitle } from "../enum/academic-title";
import { ContextMenuValues } from "../enum/shared-enums";

export interface AllOrdersResponse {
  code: number;
  message: string;
  body: PaginationData;
}

export interface OrdersWithoutPaginationResponse {
  code: number;
  message: string;
  body: OrdersData;
}

export interface OrderResponse {
  code: number;
  message: string;
  body: Order;
}

export interface OrdersData {
  data: Order[];
}

export interface StringArrayResponse {
  code: number;
  message: string;
  body: StringArrayData;
}

export interface EmbossmentsCreatedResponse {
  code: number;
  message: string;
  body: EmbossmentResult;
}

export interface EmbossmentsResponse {
  code: number;
  message: string;
  body: PaginationData;
}

export interface EmbossmentResult {
  data: boolean;
}

export interface TroubleShootingCreatedResponse {
  code: number;
  message: string;
  body: TroubleShootingResult;
}

export interface TroubleShootingResult {
  data: number | boolean;
}

export interface CheckoutInfoResponse {
  code: number;
  message: string;
  body: CheckoutInfoData;
}

export interface CheckoutResponse {
  code: number;
  message: string;
  body: CheckoutResponseData;
}

export interface CheckoutInfoData {
  data: CheckoutInfo;
}

export interface StringArrayData {
  data: string[];
}

export interface CheckoutResponseData {
  data: CheckoutSuccess;
}

export interface CheckoutSuccess {
  status: string;
  redirectLink: string;
}

export interface CheckoutInfo {
  products: PersonalizedProduct[];
  originalPrice: string;
  grossPrice: string;
  netPrice: string;
  vat: string;
  vatAmount: string;
  totalDeposit: string;
  remainingDeposit: string;
  giveaways: Giveaway[];
  possibleProductDeliveries: string[];
  couponCode: string;
  withDeposit: boolean;
  voucherCodes: string[];
  couponReduction: string;
  voucherReduction: string;
  depositReduction: string;
  balance: string;
  checkoutSource: CheckoutSource;
  timestamp: string;
}

export interface Checkout {
  cart: Cart;
  invoiceAddress: CustomerInvoiceAddress;
  shippingAddress: CustomerShippingAddress;
  regionId: number;
  paymentMethod: PaymentMethods;
  productDelivery: string;
  iban: string | null;
  bic: string | null;
  orderSource: OrderSource;
}

export interface CorporateCheckout {
  orderSource: OrderSource;
  paymentMethod: string;
  productDelivery: string;
}

export interface VoucherCheckout {
  customerId: number;
  productId: number;
  voucherCount: number;
  adjustedNetPrice: string;
  alsoPhysical: boolean;
  sendAsGift: boolean;
  invoiceAddress: CorporateCheckoutAddress;
  shippingAddress: CorporateCheckoutAddress;
  orderSource: OrderSource;
  paymentMethod: PaymentMethods;
  productDelivery: string;
}

export interface CorporateCheckoutAddress {
  formOfAddress: FormOfAddress;
  firstname: string;
  lastname: string;
  street: string;
  streetNumber: string;
  city: string;
  zipCode: string;
  country?: string;
  companyName?: string | null;
  companyVatNumber?: string | null;
  extension?: string;
}

export interface Cart {
  products: ProductInCart[];
  backofficeCustomerId: number;
  checkoutSource: CheckoutSource;
  withDeposit: boolean;
  couponCode: string | null;
  voucherCodes: string[];
}

export interface ProductInCart {
  id: number;
  intendedStartDate: string;
  alsoPhysical: boolean;
  asVoucher: boolean;
  sendAsGift: boolean;
  adjustedGrossPrice?: string;
  ownerFormOfAddress?: FormOfAddress;
  ownerFirstname?: string;
  ownerLastname?: string;
}

export interface Order {
  id: number;
  idForEmbossment: number;
  regionId: number;
  invoiceFileId: number;
  predecessorOrderId: number;
  directDebitMandateId: number;
  cartId: number;
  payments: Payment[];
  invoices: Invoice[];
  txid: number;
  reference: string;
  orderStatus: OrderStatus;
  orderType: DisplayOrderType;
  orderState: string;
  orderSource: string;
  orderedByCompany: boolean;
  orderItems: OrderItem[];
  embossmentOrder: number;
  customer: Customer;
  customerId: number;
  invoiceAddress: CustomerAddress;
  shippingAddress: CustomerAddress;
  paymentStatus: string;
  paymentMethod: string;
  productDelivery: string;
  totalNetPrice: string;
  netPaymentAmountWithPackaging: string;
  totalGrossPrice: string;
  totalGrossPriceWithoutPackaging: string;
  totalVoucherFee: string;
  vatAmount: string;
  vatAmountWithPackaging: string;
  vatPercentage: string;
  balance: string;
  calculatedBalance: string;
  grossTotalPaymentAmount: string;
  netTotalPaymentAmount: string;
  receivable: string;
  isRevocable: boolean;
  revokeInitialized: string;
  revokeCanBeConfirmed: boolean;
  mandateIdentification: string;
  isCancellable: boolean;
  coupons: Coupon[];
  couponCode: string;
  couponReduction: string;
  couponExtension: number;
  depositReduction: string;
  vouchers: Voucher[];
  createdAt: string;
  voucherCodes: string[];
  voucherReduction: string;
  updatedAt: string;
  regionName?: string;
  date?: string;

  numberOfEmbossmentCards?: number;
  numberOfEmbossmentVouchers?: number;
}

export interface OrderItem {
  id: number;
  orderId: number;
  productId: number;
  customerId: number;
  priceId: number;
  voucherId: number;
  orgId: number;
  intendedStartDate: Date;
  durationInMonths: number;
  firstname: string;
  lastname: string;
  isOnlyDigital: boolean;
  isSubscription: boolean;
  isGift: boolean;
  shallowProduct: OrderProduct;
  card: Card;
  price: Price;
  vatPrice: string;
  voucherFee: string;
  coupons: Coupon[];
  voucher: Voucher;
  voucherAmount: string;
  createdAt: string;
  updatedAt: string;
}

export interface OrderProduct {
  id: number;
  typeId: number;
  defaultCouponId: number;
  regionId: number;
  name: string;
  durationInMonths: number;
  extDurationInMonths: number;
  visibilityStatus: string;
  status: string;
  type: ProductType;
  createdAt: string;
  updatedAt: string;
}

export interface ProductType {
  id: number;
  name: string;
  isCard: boolean;
  isDigital: boolean;
  isPhysical: boolean;
  isGift: boolean;
  isSubscription: boolean;
  description: string;
}

export interface Payment {
  id: number;
  orderId: number;
  customerId: number;
  couponId: number;
  voucherId: number;
  txid: string;
  amount: string;
  currencyCode: CurrencyCodeLetters;
  type: string;
  reason: string;
  comment: string;
  expirationDate: string;
  createdAt: string;
  updatedAt: string;
  remainingBalance?: string;
}

export interface Invoice {
  id: number;
  orderId: number;
  customerId: number;
  textId: string;
  fileUuid: string;
  type: string;
  fileUrl: string;
  createdAt: string;
  updatedAt: string;
}

export interface OrderPayload {
  backofficeCustomerId: number;
  checkoutSource: CheckoutSource;
  products: OrderProductPayload[];
  withDeposit: boolean;
  couponCode: string | null;
  voucherCodes: string[];
}

export interface OrderProductPayload {
  id: number;
  intendedStartDate?: string;
  alsoPhysical: boolean;
  ownerFormOfAddress?: FormOfAddress;
  ownerFirstname?: string;
  ownerLastname?: string;
  asVoucher: boolean;
  sendAsGift: boolean;
  adjustedGrossPrice?: string;
  checkoutId?: number;
}

export interface CorporateOrderPayload {
  customerId: number;
  productId: number;
  alsoPhysical: string;
  adjustedNetPrice?: string;
}

export interface OrderShippingAddressPayload {
  firstname: string;
  lastname: string;
  addressType: AddressType;
  street: string;
  city: string;
  formOfAddress: string;
  streetNumber: string;
  zipCode: string;
  companyName?: string;
  extension?: string | null;
  companyVatNumber?: string;
  academicTitle?: AcademicTitle;
}

export interface Embossment {
  id: number;
  creatorId: number;
  user: LinkedUser;
  embossmentFiles: EmbossmentFile[];
  embossmentOrders: EmbossmentOrder[];
  embossmentCards: EmbossmentCard[];
  createdAt: string;
  updatedAt: string;
}

export interface EmbossmentFile {
  id: number;
  embossmentId: number;
  regionId: number;
  fileUuid: string;
  fileName: string;
  fileExtension: string;
  fileMimetype: string;
  fileUrl: string;
  purpose: string;
  createdAt: string;
  updatedAt: string;
}

export interface EmbossmentOrder {
  id: number;
  embossmentId: number;
  orderId: number;
  createdAt: string;
  updatedAt: string;
}

export interface EmbossmentCard {
  id: number;
  embossmentId: number;
  cardId: number;
  createdAt: string;
  updatedAt: string;
}

export interface RefundPayload {
  amount: string;
  refundMethod: string;
  reason: string;
}

export const OrdersFilters: Filter[] = [
  {
    title: 'Preis von',
    formName: 'balanceFrom',
    inputType: 'text',
    placeholder: '€29.99',
    isDate: false
  },
  {
    title: 'Preis bis',
    formName: 'balanceTo',
    inputType: 'text',
    placeholder: '€49.99',
    isDate: false
  },
  {
    title: 'Erstellt von',
    formName: 'createdFrom',
    inputType: 'text',
    isDate: true
  },
  {
    title: 'Erstellt bis',
    formName: 'createdTo',
    inputType: 'text',
    isDate: true
  },
  {
    title: 'TxId',
    formName: 'txid',
    inputType: 'text',
    isDate: false
  },
  {
    title: 'Bestellart',
    formName: 'orderedByCompany',
    options: [
      {
        label: 'Alle',
        value: null
      },
      {
        label: 'Privatkunde',
        value: '0'
      },
      {
        label: 'Firmenkunde',
        value: '1'
      }
    ]
  }
];

export const SalesStatisticFilters: Filter[] = [
  {
    title: 'Bestelleingang von',
    formName: 'orderCreatedFrom',
    inputType: 'text',
    isDate: true
  },
  {
    title: 'Bestelleingang bis',
    formName: 'orderCreatedTo',
    inputType: 'text',
    isDate: true
  },
  {
    title: 'Bestellart',
    formName: 'orderType',
    options: [
      {
        label: 'Alle',
        value: null
      },
      {
        label: 'Privatbestellung',
        value: OrderType.REGULAR
      },
      {
        label: 'Firmenbestellung',
        value: OrderType.COMPANY
      },
      {
        label: 'Verlängerungsbestellung',
        value: OrderType.EXTENSION
      },
    ]
  }
];

export const OrderConfirmationModal = [
  {
    id: ContextMenuValues.REVOKE,
    modalHeader: 'Bestellung widerrufen',
    modalBody: 'Sind Sie sicher, dass Sie diese Bestellung widerrufen möchten?'
  },
  {
    id: ContextMenuValues.CONFIRM_REVOKE,
    modalHeader: 'Widerruf bestätigen',
    modalBody: 'Bitte bestätigen Sie den Widerruf der Bestellung.'
  },
  {
    id: ContextMenuValues.CANCEL,
    modalHeader: 'Bestellung stornieren',
    modalBody: 'Möchten Sie tatsächlich die Bestellung stornieren?'
  },
  {
    id: ContextMenuValues.COLLECTION_COMPLETE,
    modalHeader: 'Inkasso abschließen',
    modalBody: 'Sind Sie sicher, dass Sie das Inkasso abschließen möchten?'
  },
  {
    id: ContextMenuValues.COLLECTION_ACTS,
    modalHeader: 'Inkasso Akte',
    modalBody: 'Möchten Sie tatsächlich die Bestellung auf Inkasso Akte stellen?'
  }
];
