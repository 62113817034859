<i *ngIf="!filtersApplied && !triggerButton"
   [hidden]="!initialClick && loadingSpinner"
   (click)="onShowModal()"
   class="mdi mdi-filter-variant mdi-24px icon-color cursor-pointer"></i>
<i *ngIf="filtersApplied && !triggerButton"
   (click)="onShowModal()"
   [hidden]="!initialClick && loadingSpinner"
   class="mdi mdi-filter-variant-plus mdi-24px icon-color cursor-pointer"></i>
<div class="text-end me-2">
  <button *ngIf="triggerButton"
          type="button"
          [disabled]="disableTriggerButton"
          (click)="onShowModal()"
          class="btn app-primary-button m-refresh-button">
    <i class="mdi mdi-plus-circle-outline mdi-18px cursor-pointer align-self-center me-xxl-1"></i>
    <span class="d-none d-xxl-inline-block">{{ triggerButton }}</span>
  </button>
</div>
<div #triggerFilterModal
     data-bs-toggle="modal"
     [attr.data-bs-target]="identifier ? '#' + identifier : '#standard-modal'"></div>
<div *ngIf="loadingSpinner && !triggerButton" class="d-block spinner-border spinner-border-sm text-dark-purple" role="status">
  <span class="visually-hidden">Loading...</span>
</div>
<div [id]="identifier ? identifier : 'standard-modal'" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="standard-modalLabel"
     aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header bg-light">
        <h4 class="modal-title" id="standard-modalLabel">Filter</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
      </div>
      <div class="modal-body overflow-scroll">
        <ng-content></ng-content>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<button type="button"
        class="d-none"
        #errorModalTrigger
        data-bs-toggle="modal"
        data-bs-target="#info-header-modal">
</button>
<app-modal
  modalType="info"
  data-bs-target="modal"
  modalLabel="HOPPLA!"
  modalContent="Etwas ist schiefgelaufen. Bitte probieren Sie es noch einmal."
  saveButtonLabel="Erneut versuchen"
  cancelButtonLabel="Abbrechen"
  [allowClickElsewhere]="false"
  saveButtonCssClass="btn-info"
  modalHeaderCssClass="bg-info"
  (emitConfirm)="getFiltersData()"
></app-modal>
