import {Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ActionHistoryService } from "../../services/action-history.service";
import { ActionHistory, ActionHistoryResponse } from "../../dataModels/sharedDataModels";
import { Utils } from "../../utils/utils";
import { takeUntil } from "rxjs/operators";
import { SubscriptionComponent } from "../subscription/subscription.component";
import { PaginationData } from "../../dataModels/paginationDataModel";
import { BreadcrumbData } from "../breadcrumbs/breadcrumbs.component";
import { ResponseCode } from "../../enum/shared-enums";
import { AppGlobalService } from "../../services/app-global.service";

@Component({
             selector: 'app-detailed-history',
             templateUrl: './detailed-history.component.html',
             styleUrls: ['./detailed-history.component.scss']
           })
export class DetailedHistoryComponent extends SubscriptionComponent implements OnInit, OnDestroy {
  historyFor: string;
  actionHistoryData: PaginationData;
  actionHistory: ActionHistory[] = [];
  dataToDisplay: any;
  historyLoaded = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private globalService: AppGlobalService,
              private actionHistoryService: ActionHistoryService) { super() }

  ngOnInit(): void {
    const id = +this.route.snapshot.params['id'];
    this.historyFor = this.route.snapshot.queryParams['for'];
    this.getActionHistory(id);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private getActionHistory(id: number) {
    this.actionHistoryService.fetchActionHistory(this.historyFor, id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: ActionHistoryResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            this.actionHistoryData = response.body;
            this.actionHistory = Utils.snakeCaseToCamelCase(response.body?.pagination.data);
          }
          this.historyLoaded = true;
        }, () => {
          this.globalService.emitServerError(true);
        });
  }

  onDisplayData(data: any): void {
    this.dataToDisplay = data;
  }

  navigateTo(breadcrumb: BreadcrumbData) {
    this.router.navigate(['/' + breadcrumb.routerLink],
                         { queryParams: { 'region': Utils.getRegionFromLocalStorage() } });
  }
}
