<form [formGroup]="voucherFiltersForm">
  <div class="dropdown-grid">
    <div class="item mb-3">
      <label class="col-form-label" for="region">Region</label>
      <div class="col-md-11">
        <select formControlName="regionId"
                id="region"
                class="form-select cursor-pointer me-3">
          <option value="-1">Alle</option>
          <option *ngFor="let region of regions" [value]="region.id">{{ region?.name }}</option>
        </select>
      </div>
    </div>
    <ng-container *ngFor="let field of VoucherFilters; let f = first">
      <div class="item mb-3">
        <label class="col-form-label">{{ field?.title }}</label>
        <div class="col-md-11">
          <ng-container *ngIf="!field.inputType">
            <select [formControlName]="field.formName"
                    class="form-select cursor-pointer me-3">
              <option *ngFor="let option of field.options" [value]="option.value">{{ option?.label }}</option>
            </select>
          </ng-container>
          <ng-container *ngIf="field.inputType">
            <app-date-picker *ngIf="field.isDate"
                             [formName]="field.formName"
                             [inputType]="field.inputType"
                             [formGroup]="voucherFiltersForm"
                             [value]="voucherFiltersForm.get(field.formName)?.value"
            ></app-date-picker>
            <div *ngIf="!field.isDate">
              <input type="{{ field.inputType }}"
                     min="1"
                     placeholder="{{ field.placeholder }}"
                     [formControlName]="field.formName"
                     class="form-control">
              <small
                *ngIf="voucherFiltersForm.get(field.formName)?.invalid && voucherFiltersForm.get(field.formName)?.touched"
                class="error-feedback">
                Ungültiger Wert
              </small>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</form>
<div class="d-flex justify-content-end column-placement">
  <button class="cancel-button me-3"
          (click)="onResetFilters()"
          data-bs-toggle="modal"
          data-bs-target="#standard-modal">Filter zurücksetzen
  </button>
  <button class="app-primary-button"
          (click)="onFilter()"
          [disabled]="voucherFiltersForm?.invalid"
          data-bs-toggle="modal"
          data-bs-target="#standard-modal">Filter
  </button>
</div>
