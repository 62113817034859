<div *ngIf="showError">
  <div class="account-pages">
    <div class="container">
      <div class="row justify-content-center" [class.card-position]="showError">
        <div class="col-lg-5">
          <div class="modal-content">
            <!-- Logo -->
            <div class="modal-header modal-colored-header header-height text-center bg-info">
              <h4>HOPPLA!</h4>
            </div>

            <div class="card-error-padding">
              <div>
                <p class="fw-bold">Etwas ist schiefgelaufen. Bitte versuchen Sie es noch einmal.</p>
              </div>
              <div class="d-flex justify-content-end">
                <a *ngIf="showError" class="btn cancel-button mt-3 me-1" (click)="back()">
                  <i class="mdi mdi-reply d-md-none"></i>
                  <span class="d-xs-none">Zurück</span>
                </a>
                <a class="btn btn-info mt-3"
                   (click)="onReload()">
                  <i class="mdi mdi-refresh d-md-none"></i>
                  <span class="d-xs-none">Erneut versuchen</span>
                </a>
              </div>
            </div> <!-- end card-body-->
          </div>
          <!-- end card -->
        </div> <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </div>
</div>
