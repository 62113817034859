import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { PaginationData } from "../../shared/dataModels/paginationDataModel";
import { Embossment } from "../../shared/dataModels/ordersDataModel";
import { Params } from "@angular/router";
import { OrdersService } from "../../services/orders.service";
import { takeUntil } from "rxjs/operators";
import { SubscriptionComponent } from "../../shared/components/subscription/subscription.component";

@Component({
             selector: 'app-embossments',
             templateUrl: './embossments.component.html',
             styleUrls: ['./embossments.component.scss']
           })
export class EmbossmentsComponent extends SubscriptionComponent implements OnInit, OnDestroy {
  @Input() tableTitle: string;
  @Input() tableHeaders: string[];
  @Input() embossmentData: PaginationData;
  @Input() embossments: Embossment[];
  @Input() requestParams: Params;
  @Input() error: boolean;
  @Input() responseSuccessful: boolean;
  @Output() emitPaginationData = new EventEmitter();
  @Output() refreshList = new EventEmitter();
  componentId: string;
  chevron = true;

  constructor(private ordersService: OrdersService) { super() }

  ngOnInit() {
    this.componentId = this.tableTitle.split(" ")[1];
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  onDownload(fileName: string, fileUrl: string, fileExtension: string, fileMimetype: string): void {
    this.ordersService.downloadFile(fileUrl)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: ArrayBuffer) => {
          const file = new File([response], fileName + '.' + fileExtension, { type: fileMimetype });
          const url = window.URL.createObjectURL(file);
          let fileLink = document.createElement('a');
          fileLink.href = url;
          fileLink.download = fileName + '.' + fileExtension;
          fileLink.setAttribute('target', '_blank');
          fileLink.click();
        });
  }

  loadPaginatedData(pageNumber: string) {
    this.emitPaginationData.emit(pageNumber);
    this.responseSuccessful = false;
  }

  onRefresh(): void {
    this.refreshList.emit();
  }
}
