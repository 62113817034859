import { Component, OnInit } from '@angular/core';
import { PaginationData } from "../shared/dataModels/paginationDataModel";
import { Utils } from "../shared/utils/utils";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { RolesService } from "../services/roles.service";
import { takeUntil } from "rxjs/operators";
import {
  AllRolesResponse,
  Role,
  RoleResponse,
  UserRole
} from "../shared/dataModels/roleDataModel";
import { HttpErrorResponse } from "@angular/common/http";
import { AllRegionsResponse, Region } from "../shared/dataModels/regionDataModels";
import { RegionsService } from "../services/regions.service";
import { MessageTypes } from "../shared/enum/message-types";
import { BreadcrumbData } from "../shared/components/breadcrumbs/breadcrumbs.component";
import { AlertifyService } from "../shared/services/alertify-service.service";
import { ErrorStatus } from "../shared/enum/error-status";
import { SubscriptionComponent } from "../shared/components/subscription/subscription.component";
import { ResponseCode } from "../shared/enum/shared-enums";
import { AppGlobalService } from "../shared/services/app-global.service";

@Component({
             selector: 'app-roles',
             templateUrl: './roles.component.html',
             styleUrls: ['./roles.component.scss']
           })
export class RolesComponent extends SubscriptionComponent implements OnInit {
  rolesSearchForm: FormGroup;
  regions: Region[];
  assignedRoles: Role[] = [];
  rolesData: PaginationData;
  requestParams: Params = { searchTerms: '' }
  userId: number;
  selectedRoleForDeletion: Role;
  spinnerIndex: number;
  messageTypes = MessageTypes;
  defaultRegion = JSON.parse(Utils.getItemFromLocalStorage('region'));
  rolesLoaded = false;
  regionsLoaded = false;
  pendingResponse = false;
  writeMode = false;
  regionId: number;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private regionsService: RegionsService,
              private rolesService: RolesService,
              private globalService: AppGlobalService,
              private alertifyService: AlertifyService) { super() }

  ngOnInit(): void {
    this.handleRouteParameters();
    this.getRegions();
    this.initializeSearchForm();
    this.onChangeSearchInputValue();
    this.regionId = this.defaultRegion.id;
  }

  private handleRouteParameters() {
    let mode = this.route.snapshot.queryParams['write'];
    mode ? this.writeMode = true : this.writeMode = false;
    this.userId = this.route.snapshot.params['id'];
  }

  private initializeSearchForm() {
    this.rolesSearchForm = this.formBuilder.group({
                                                    searchInput: ['', [Validators.required]],
                                                  });
  }

  get searchInput() {
    return this.rolesSearchForm.get('searchInput');
  }

  getRegions(): void {
    this.regionsService.getRegions()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: AllRegionsResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            this.regions = response.body?.pagination?.data;
            this.getRoles('');
          } else {
            this.alertifyService.warning(response.message);
          }
          this.regionsLoaded = true;
        }, (error: HttpErrorResponse) => {
          if (error.status === ErrorStatus.STATUS_500) {
            this.globalService.emitServerError(true);
          }
        });
  }

  getRoles(searchInput: string): void {
    this.rolesService.getAllRoles(searchInput)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: AllRolesResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            if (this.writeMode) {
              this.assignedRoles = response.body?.pagination?.data;
            } else {
              this.rolesData = response.body;
            }
            this.getUserRoles(this.regionId);
          } else {
            this.alertifyService.warning(response.message);
          }
          this.rolesLoaded = true;
        }, (error: HttpErrorResponse) => {
          if (error.status === ErrorStatus.STATUS_500) {
            this.globalService.emitServerError(true);
          }
        });
  }

  getUserRoles(regionId: number): void {
    if (this.userId) {
      this.rolesService.getUserRoles(this.userId, regionId)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((response: AllRolesResponse) => {
            if (response.code === ResponseCode.FAILED) {
              this.alertifyService.warning(response.message);
            } else {
              let userRoles: UserRole[] = Utils.snakeCaseToCamelCase(response.body?.pagination.data);
              this.markUserRolesAsSelected(userRoles);
            }
          }, (error: HttpErrorResponse) => {
            if (error.status === ErrorStatus.STATUS_500) {
              this.globalService.emitServerError(true);
            }
          });
    }
  }

  onChangeRegion() {
    this.rolesLoaded = false;
    this.getRoles('');
  }

  markUserRolesAsSelected(userRoles: UserRole[]): void {
    this.assignedRoles?.forEach((role: Role) => {
      userRoles.forEach(userRole => {
        if (+this.regionId === userRole.regionId && role.name === userRole.roleName) {
          role.selected = true;
        }
      });
    });
    this.rolesLoaded = true;
  }

  formatPermissions(permissions: string): string[] {
    return permissions.split(' ');
  }

  onSelectRole(role: Role, index: number): void {
    role.selected = !role.selected;
    role.selected ? this.assignRole(role) : this.withdrawRole(role);
    this.spinnerIndex = index;
  }

  private assignRole(role: Role): void {
    this.rolesService.assignRoleToUser(this.userId, role.id, +this.regionId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: RoleResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            this.alertifyService.success('Die Rolle wurde erfolgreich zugewiesen!');
            this.spinnerIndex = -1;
          } else {
            role.selected = false;
            this.alertifyService.warning(response.message);
            this.spinnerIndex = -1;
          }
        }, () => {
          this.spinnerIndex = -1;
        });
  }

  private withdrawRole(role: Role): void {
    this.rolesService.withdrawRoleFromUser(this.userId, role.id, +this.regionId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: RoleResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            this.alertifyService.success('Die Rolle wurde erfolgreich entfernt!');
            this.spinnerIndex = -1;
          } else {
            role.selected = true;
            this.alertifyService.warning(response.message);
            this.spinnerIndex = -1;
          }
        }, () => {
          this.spinnerIndex = -1;
        });
  }

  // TODO: later implement pagination
  getPaginatedData(paginatedRoles: any): void {
    this.rolesLoaded = false;
    this.rolesData = paginatedRoles.body;
    this.rolesLoaded = true;
  }

  onMarkRoleForDeletion(role: Role) {
    this.selectedRoleForDeletion = role;
  }

  onDeleteRole(): void {
    this.rolesService.deleteRole(this.selectedRoleForDeletion.id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: RoleResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            this.alertifyService.success('Die Rolle wurde erfolgreich gelöscht!');
            this.getRoles('');
          } else {
            this.alertifyService.warning(response.message);
          }
        });
  }

  onSearchRoles(): void {
    this.rolesLoaded = false;
    this.requestParams.searchTerms = this.searchInput?.value;
    this.getRoles(this.searchInput?.value);
  }

  onChangeSearchInputValue(): void {
    this.searchInput?.valueChanges.subscribe(value => {
      if (value === '') {
        this.requestParams.searchTerms = '';
        this.getRoles('');
      }
    })
  }

  onClearSearch(): void {
    this.requestParams.searchTerms = '';
    this.searchInput?.setValue('');
  }

  navigateTo(breadcrumb: BreadcrumbData): void {
    this.router.navigate(['/' + breadcrumb.routerLink]);
  }
}
