import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Status } from "../../enum/status";

@Component({
             selector: 'app-edit-status',
             templateUrl: './edit-status.component.html',
             styleUrls: ['./edit-status.component.scss']
           })
export class EditStatusComponent {
  @Input() status: Status;
  @Output() newStatus = new EventEmitter<string>();
  Status = Status;
  editStatus: Status;

  constructor() { }

  onPreventSwitchToggle(event: Event): void {
    event.preventDefault();
  }

  onSubmit(): void {
    this.editStatus = this.status === Status.ACTIVE ? Status.INACTIVE : Status.ACTIVE;
    this.newStatus.emit(this.editStatus);
  }
}
