<div [id]="modalType + '-header-modal'"
     class="modal fade"
     tabindex="-1"
     role="dialog"
     [attr.aria-labelledby]="modalType + '-header-modal'"
     aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header modal-colored-header {{ modalHeaderCssClass }}">
        <h4 class="modal-title" id="primary-header-modalLabel">{{ modalLabel }}</h4>
        <button type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                (click)="onDismiss()"
                aria-hidden="true"></button>
      </div>
      <div class="modal-body modal-body-bold">
        <ng-content></ng-content>
      </div>
      <div class="modal-footer">
        <button type="button"
                class="btn btn-light"
                (click)="onCancel()"
                data-bs-dismiss="modal">
          {{ cancelButtonLabel }}
        </button>
        <button type="button"
                (click)="onConfirm()"
                [disabled]="disableConfirmationButton"
                class="btn {{ saveButtonCssClass }}">
                  <span *ngIf="showSpinner"
                        class="spinner-border spinner-border-sm me-1"
                        role="status"
                        aria-hidden="true"></span>
          {{ saveButtonLabel }}
        </button>
        <button #closeButton type="button" class="d-none" data-bs-dismiss="modal">Close Modal</button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>
