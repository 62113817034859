import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import { PaginationData } from "../shared/dataModels/paginationDataModel";
import { AllVouchersResponse, Voucher } from "../shared/dataModels/vouchersDataModels";
import { LoadingService } from "../shared/services/loading.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { BreadcrumbData } from "../shared/components/breadcrumbs/breadcrumbs.component";
import { AlertifyService } from "../shared/services/alertify-service.service";
import { VouchersService } from "../services/vouchers.service";
import { takeUntil } from "rxjs/operators";
import { Utils } from "../shared/utils/utils";
import { VouchersFiltersComponent } from "../shared/components/filter/vouchers-filters/vouchers-filters.component";
import { FilterUtils } from "../shared/utils/filter";
import { NavigationService } from "../shared/services/navigation.service";
import { ErrorStatus } from "../shared/enum/error-status";
import { HttpErrorResponse } from "@angular/common/http";
import { SubscriptionComponent } from "../shared/components/subscription/subscription.component";
import { FormBuilder, FormGroup } from "@angular/forms";
import { SimpleResponse } from "../shared/dataModels/sharedDataModels";
import { LocalStorageKeys, ResponseCode } from "../shared/enum/shared-enums";
import { AppGlobalService } from "../shared/services/app-global.service";
import { Status } from "../shared/enum/status";

@Component({
             selector: 'app-vouchers',
             templateUrl: './vouchers.component.html',
             styleUrls: ['./vouchers.component.scss']
           })
export class VouchersComponent extends SubscriptionComponent implements OnInit, OnDestroy {
  vouchersData: PaginationData;
  vouchers: Voucher[];
  selectedVoucher: Voucher;
  endDateForm: FormGroup;
  VoucherFiltersComponent = VouchersFiltersComponent;
  requestParams: Params;
  Utils = Utils;
  Status = Status;
  editedVoucherId: number;
  searchInput: string;
  vouchersLoading = false;
  searchResults = false;
  pendingResponse = false;
  closeModal = false;

  constructor(private loadingService: LoadingService,
              private router: Router,
              private route: ActivatedRoute,
              private vouchersService: VouchersService,
              private globalService: AppGlobalService,
              private navigationService: NavigationService,
              private formBuilder: FormBuilder,
              private alertifyService: AlertifyService) { super() }

  ngOnInit(): void {
    this.getRequestParametersFromActivatedRoute();
    this.getVouchers();
    this.initializeEndDateForm();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private getVouchers(): void {
    this.requestParams.orderDirection = 'desc';
    this.requestParams.orderBy = 'created_at';
    this.vouchersService.getVouchers(this.requestParams)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: AllVouchersResponse) => {
          if(response.code === ResponseCode.SUCCESS) {
            this.toCamelCase(response);
            this.vouchersLoading = true;
            this.searchResults = response.body?.pagination?.data?.length > 0;
          } else {
            this.vouchersLoading = true;
            this.alertifyService.warning(response.message);
          }
        }, (error: HttpErrorResponse) => {
          if (error.status === ErrorStatus.STATUS_500) {
            this.globalService.emitServerError(true);
          }
        });
  }

  private getRequestParametersFromActivatedRoute(): void {
    this.requestParams = FilterUtils.getQueryParametersFromRoute(this.route);
    this.searchInput = this.requestParams.searchTerms;
  }

  private initializeEndDateForm(): void {
    this.endDateForm = this.formBuilder.group({
                                                endDate: ['']
                                              }, { validators: Utils.disallowPastDates('endDate') });
  }

  private toCamelCase(response: AllVouchersResponse) {
    this.vouchersData = response.body;
    this.vouchers = Utils.snakeCaseToCamelCase(response.body?.pagination.data);
  }

  getPaginatedData(pageNumber: string): void {
    this.vouchersLoading = false;
    this.requestParams = { ...this.requestParams, page: pageNumber };
    const queryParams = Utils.camelCaseToSnakeCase(this.requestParams);
    this.router.navigate([], { queryParams, relativeTo: this.route });
    this.getVouchers();
  }

  onSearchAndFilterVouchers(queryParameters: Params): void {
    this.vouchersLoading = false;
    this.requestParams = queryParameters;
    this.getVouchers();
  }

  onPrefillEndDateForm(currentDate: Date, index: number): void {
    this.endDateForm.get('endDate')?.patchValue(Utils.getLocalDateFromUtc(currentDate.toString()));
    this.editedVoucherId = index;
  }

  onCopyCode(codeToCopy: string): void {
    let listener = (event: ClipboardEvent) => {
      event.clipboardData?.setData('text/plain', (codeToCopy));
      event.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand("copy");
    document.removeEventListener('copy', listener);

    this.alertifyService.success('Code kopiert!');
  }

  onSetNewEndDate(): void {
    const parsedDate = Utils.convertLocalDateToUTC(this.endDateForm.get('endDate')?.value);
    this.pendingResponse = true;
    this.vouchersService.changeVoucherEndDate(this.editedVoucherId, parsedDate)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: SimpleResponse) => {
        this.closeModal = true;
        this.pendingResponse = false;
        if (response.code === ResponseCode.SUCCESS) {
          this.alertifyService.success(response.message);
          this.getVouchers();
        } else {
          this.alertifyService.warning(response.message);
        }
      }, () => {
        this.pendingResponse = false;
      });
    this.closeModal = false;
  }

  onPreventSwitchToggle(event: Event, voucher: Voucher): void {
    event.preventDefault();
    this.selectedVoucher = voucher;
  }

  onChangeVoucherStatus(): void {
    const newStatus = this.selectedVoucher.status === Status.ACTIVE ? Status.INACTIVE : Status.ACTIVE;
    this.vouchersService.changeVoucherStatus(this.selectedVoucher.id, newStatus)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: SimpleResponse) => {
        if (response.code === ResponseCode.SUCCESS) {
          this.alertifyService.success(response.message);
          this.getVouchers();
        } else {
          this.alertifyService.warning(response.message);
        }
      });
  }

  navigateTo(breadcrumb: BreadcrumbData) {
    this.router.navigate(['/' + breadcrumb.routerLink]);
  }

  @HostListener('window:popstate', ['$event'])
  goBack(event: Event) {
    this.navigationService.back().subscribe((params: Params) => {
      if (!params.regionId) {
        params.regionId = JSON.parse(Utils.getItemFromLocalStorage(LocalStorageKeys.REGION)).id;
      }
      this.requestParams = params;
      this.getVouchers();
    });
  }
}
