import { Component, ViewChild } from '@angular/core';
import { BreadcrumbData } from "../shared/components/breadcrumbs/breadcrumbs.component";
import { Router } from "@angular/router";
import { Report } from "../shared/dataModels/reportsDataModel";
import { ReportingService } from "../services/reporting.service";
import { SubscriptionComponent } from "../shared/components/subscription/subscription.component";
import { takeUntil } from "rxjs/operators";
import { SimpleResponse } from "../shared/dataModels/sharedDataModels";
import { ResponseCode } from "../shared/enum/shared-enums";
import { AlertifyService } from "../shared/services/alertify-service.service";
import { CardStatisticComponent } from "./card-statistic/card-statistic.component";
import { SalesStatisticComponent } from "./sales-statistic/sales-statistic.component";

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss']
})
export class ReportingComponent extends SubscriptionComponent {
  @ViewChild('cardStatisticComponent') cardStatisticComponent: CardStatisticComponent;
  @ViewChild('salesStatisticComponent') salesStatisticComponent: SalesStatisticComponent;
  modalBody: string;
  reportToBeDeleted: Report;

  constructor(private router: Router,
              private reportingService: ReportingService,
              private alertifyService: AlertifyService) { super() }

  onMarkReportForDeletion(report: Report): void {
    this.reportToBeDeleted = report;
    this.modalBody = `Sind Sie sicher, dass Sie den Bericht ${report.name} löschen möchten?`;
  }

  onDeleteReport(): void {
    this.reportingService.deleteReport(this.reportToBeDeleted.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: SimpleResponse) => {
        if (response.code === ResponseCode.SUCCESS) {
          this.alertifyService.success(response.message);
          this.cardStatisticComponent.getCardReports();
          this.salesStatisticComponent.getSalesReports();
        } else {
          this.alertifyService.warning(response.message);
        }
      });
  }

  navigateTo(breadcrumb: BreadcrumbData) {
    this.router.navigate(['/' + breadcrumb.routerLink]);
  }
}
