<div class="loading authentication-bg" data-layout-config='{"darkMode":false}'>
  <div class="account-pages mt-5 mb-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-5">
          <div class="card">
            <!-- Logo -->
            <div class="card-header pt-4 pb-4 text-center app-bg-primary">
            </div>

            <div class="card-body p-4">

              <div class="text-center m-auto">
                <img src="assets/images/mail_sent.svg" alt="mail sent image" height="64"/>
                <h4 class="text-dark-50 text-center mt-4 font-weight-bold">Bitte überprüfen Sie Ihre E-Mail</h4>
                <p class="text-muted mb-4">
                  Wir haben eine E-Mail <b *ngIf="forgotPassEmail">{{ 'zu ' + forgotPassEmail }}</b> geschickt.
                  Um Ihr Password neu zu setzen, klicken Sie auf dem darin angegebenen Link.
                </p>
              </div>

              <form action="index.html">
                <div class="form-group mb-0 text-center">
                  <button class="btn app-primary-button" type="submit" routerLink="/login">
                    <i class="mdi mdi-home mr-1"></i>
                    Zurück zum Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
