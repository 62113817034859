import { Component, Input } from '@angular/core';
import { FormGroup } from "@angular/forms";

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent {

  @Input() formName: string;
  @Input() inputType: string;
  @Input() formGroup: FormGroup;
  @Input() value: string;
  @Input() placeHolder: string = 'dd.mm.yyyy';
  @Input() dateFormat: string = "dd.mm.yyyy";

  constructor() { }
}
