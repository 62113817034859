<div *ngIf="!allowClickElsewhere"
     [id]="modalType + '-header-modal'"
     data-bs-backdrop="static"
     data-bs-keyborad="false"
     class="modal fade"
     tabindex="-1"
     role="dialog"
     [attr.aria-labelledby]="modalType + '-header-modal'"
     aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header modal-colored-header {{ modalHeaderCssClass }}">
        <h4 class="modal-title" id="info-header-modal-Label">{{ modalLabel }}</h4>
        <button type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                (click)="onDismiss()"
                aria-hidden="true"></button>
      </div>
      <div class="modal-body modal-body-bold">
        {{ modalContent }}
      </div>
      <div class="modal-footer">
        <button type="button"
                class="btn btn-light"
                (click)="onCancel()"
                data-bs-dismiss="modal">
          {{ cancelButtonLabel }}
        </button>
        <button type="button"
                data-bs-dismiss="modal"
                (click)="onConfirm()"
                class="btn {{ saveButtonCssClass }}">
          {{ saveButtonLabel }}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>

<div *ngIf="allowClickElsewhere"
     [id]="modalType + '-header-modal'"
     class="modal fade"
     tabindex="-1"
     role="dialog"
     [attr.aria-labelledby]="modalType + '-header-modal'"
     aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header modal-colored-header {{ modalHeaderCssClass }}">
        <h4 class="modal-title" id="danger-header-modalLabel">{{ modalLabel }}</h4>
        <button type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                (click)="onDismiss()"
                aria-hidden="true"></button>
      </div>
      <div class="modal-body modal-body-bold">
        {{ modalContent }}
      </div>
      <div class="modal-footer">
        <button type="button"
                class="btn btn-light"
                (click)="onCancel()"
                data-bs-dismiss="modal">
          {{ cancelButtonLabel }}
        </button>
        <button type="button"
                data-bs-dismiss="modal"
                (click)="onConfirm()"
                class="btn {{ saveButtonCssClass }}">
          {{ saveButtonLabel }}
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>

