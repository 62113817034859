import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import {
  CardReportFilters,
  CreateReportResponse,
  ReportResponse,
  SalesReportFilters
} from "../shared/dataModels/reportsDataModel";
import { Observable } from "rxjs";
import { apiUrl, Utils } from "../shared/utils/utils";
import { SimpleResponse } from "../shared/dataModels/sharedDataModels";

@Injectable({
              providedIn: 'root'
            })
export class ReportingService {
  constructor(private http: HttpClient) { }

  getCardReports(regionId: string): Observable<ReportResponse> {
    const params = new HttpParams().set('region_id', regionId);
    return this.http.get<ReportResponse>(apiUrl + 'card/reports', { params });
  }

  getSalesReports(regionId: string): Observable<ReportResponse> {
    const params = new HttpParams().set('region_id', regionId);
    return this.http.get<ReportResponse>(apiUrl + 'volume/reports', { params });
  }

  createReportForCards(filters: CardReportFilters): Observable<CreateReportResponse> {
    const parsedFilters = Utils.camelCaseToSnakeCase(filters);
    return this.http.post<CreateReportResponse>(apiUrl + 'card/report/generate', { ...parsedFilters });
  }

  createSalesReport(filters: SalesReportFilters): Observable<CreateReportResponse> {
    const parsedFilters = Utils.camelCaseToSnakeCase(filters);
    return this.http.post<CreateReportResponse>(apiUrl + 'volume/report/generate', { ...parsedFilters });
  }

  deleteReport(id: number): Observable<SimpleResponse> {
    return this.http.delete<SimpleResponse>(apiUrl + 'report/' + id);
  }
}
