import { Component, ComponentRef, EventEmitter, Input, OnInit, Output, Type, ViewChild, } from '@angular/core';
import { PaginationData } from "../../shared/dataModels/paginationDataModel";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AllRegionsResponse, Region } from "../../shared/dataModels/regionDataModels";
import { AppGlobalService } from "../../shared/services/app-global.service";
import { SubscriptionComponent } from "../../shared/components/subscription/subscription.component";
import { takeUntil } from "rxjs/operators";
import { LocalStorageKeys, ResponseCode } from "../../shared/enum/shared-enums";
import { Utils } from "../../shared/utils/utils";
import {
  CreateReportResponse, Report,
  ReportResponse,
  SalesReport,
  SalesReportFilters
} from "../../shared/dataModels/reportsDataModel";
import { ReportingService } from "../../services/reporting.service";
import { AlertifyService } from "../../shared/services/alertify-service.service";
import { OrderType } from "../../shared/enum/orders";
import {
  SalesStatisticFiltersComponent
} from "../../shared/components/filter/sales-statistic-filters/sales-statistic-filters.component";

@Component({
             selector: 'app-sales-statistic',
             templateUrl: './sales-statistic.component.html',
             styleUrls: ['./sales-statistic.component.scss']
           })
export class SalesStatisticComponent extends SubscriptionComponent implements OnInit {
  @Input() paginatedData: PaginationData;
  @Input() models: any[];
  @Input() statisticsFilterComponent: Type<any>;
  @Input() statistic: string;
  @Output() deleteReport = new EventEmitter<Report>();
  @ViewChild('salesFiltersComponent') filtersComponent: SalesStatisticFiltersComponent;
  regions: Region[];
  salesReports: SalesReport[];
  regionForm: FormGroup;
  salesStatisticNameForm: FormGroup;
  Utils = Utils;
  regionId: number;
  initialClick = true;
  chevron = false;
  responseSuccessful = false;
  error = false;
  componentRef: ComponentRef<any>;

  constructor(private globalService: AppGlobalService,
              private reportingService: ReportingService,
              private alertifyService: AlertifyService,
              private formBuilder: FormBuilder) { super() }

  ngOnInit(): void {
    this.regionId = JSON.parse(Utils.getItemFromLocalStorage(LocalStorageKeys.REGION)).id;
    this.getSalesReports();
    this.getRegions();
    this.initializeRegionForm();
    this.initializeStatisticNameForm();
    this.watchRegionId();
  }

  getSalesReports(): void {
    this.reportingService.getSalesReports(this.regionId.toString())
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: ReportResponse) => {
          this.responseSuccessful = true;
          if (response.code === ResponseCode.SUCCESS) {
            this.salesReports = Utils.snakeCaseToCamelCase(response.body.data);
          } else {
            this.alertifyService.warning(response.message);
          }
        }, () => this.responseSuccessful = true);
  }

  private getRegions(): void {
    this.globalService.regions$.pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((regionsResponse: AllRegionsResponse) => {
          if (regionsResponse) {
            this.regions = regionsResponse.body?.pagination.data;
          }
        });
  }

  private initializeRegionForm(): void {
    this.regionForm = this.formBuilder.group({
                                               regionId: [this.regionId],
                                             });
  }

  private initializeStatisticNameForm(): void {
    this.salesStatisticNameForm = this.formBuilder.group({
                                                           name: ['', [Validators.required]]
                                                         });
  }

  private watchRegionId(): void {
    this.regionForm.get('regionId')?.valueChanges.subscribe(value => {
      this.responseSuccessful = false;
      this.regionId = value;
      this.getSalesReports();
    });
  }

  onFilterReports(filterData: SalesReportFilters): void {
    let filters: SalesReportFilters = {
      ...filterData,
      name: this.salesStatisticNameForm.get('name')?.value
    };
    this.createSalesReport(filters);
  }

  private createSalesReport(filters: SalesReportFilters): void {
    this.reportingService.createSalesReport(filters)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: CreateReportResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            this.getSalesReports();
            this.filtersComponent.onInitializeOrderFilterForm();
            this.alertifyService.success(response.message);
          } else {
            this.alertifyService.warning(response.message);
          }
        });
  }

  onDownloadReport(fileUrl: string | null, fileName: string): void {
    if (fileUrl) {
      const errorMessage = this.globalService.downloadFile(
        fileUrl,
        fileName,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      );
      if (errorMessage) {
        this.alertifyService.error(errorMessage);
      }
    }
  }

  onRefreshList(): void {
    this.responseSuccessful = false;
    this.getSalesReports();
  }

  getOrderTypeDisplayValue(orderType: OrderType): string {
    let displayValue = '';
    switch (orderType) {
      case OrderType.REGULAR:
        displayValue = 'Privatbestellung';
        break;
      case OrderType.COMPANY:
        displayValue = 'Firmenbestellung';
        break;
    }
    return displayValue;
  }
}
