<nav class="d-lg-flex justify-content-center overflow-auto mt-4">
  <ul class="pagination pagination-rounded flex-lg-wrap mb-0">
    <li *ngIf="shortPagination" class="page-item me-lg-2">
      <button (click)="onLoadPaginatedPage(paginationData.pagination.first_page_url)"
              [disabled]="paginationData.pagination.current_page === 1"
              type="button"
              class="btn previous-and-next-button"
              aria-label="First Page">
        <span aria-hidden="true">&laquo;</span>
        <span class="sr-only text-nowrap">First Page</span>
      </button>
    </li>
    <li class="page-item me-lg-2">
      <button (click)="onLoadPaginatedPage(paginationData.pagination.prev_page_url)"
              [disabled]="!paginationData?.pagination?.prev_page_url"
              type="button"
              class="btn previous-and-next-button"
              aria-label="Previous">
        <span aria-hidden="true">&laquo;</span>
        <span class="sr-only">Previous</span>
      </button>
    </li>
    <ng-container *ngIf="!shortPagination">
      <ng-container *ngFor="let link of paginationData.pagination.links; let firstLink = first; let lastLink = last">
        <li *ngIf="!firstLink && !lastLink"
            [class.mt-1]="link.url === null"
            class="pagination-page-number page-item me-1">
          <a *ngIf="link.url !== null" (click)="onLoadPaginatedPage(link.url)"
             [class.active]="link.active"
             class="page-link">
            {{ link.label }}
          </a>
          <span style="cursor: default;" *ngIf="link.url === null">{{link.label}}</span>
        </li>
      </ng-container>
    </ng-container>
    <li class="page-item ms-lg-2">
      <button (click)="onLoadPaginatedPage(paginationData.pagination.next_page_url)"
              [disabled]="!paginationData.pagination.next_page_url"
              type="button"
              aria-label="next"
              class="btn previous-and-next-button">
        <span class="sr-only">Next</span>
        <span aria-hidden="true">&raquo;</span>
      </button>
    </li>
    <li *ngIf="shortPagination" class="page-item me-lg-2">
      <button (click)="onLoadPaginatedPage(paginationData.pagination.last_page_url)"
              [disabled]="!paginationData.pagination.next_page_url"
              type="button"
              class="btn previous-and-next-button"
              aria-label="Previous">
        <span class="sr-only text-nowrap">Last Page</span>
        <span aria-hidden="true">&raquo;</span>
      </button>
    </li>
  </ul>
</nav>
