import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import {
  AllSettingsResponse,
  BlockingTimeSettingsResponse,
  EmailForNotificationsResponse,
  LinksResponse,
  WebBannerPayload,
  WebBannerResponse
} from "../shared/dataModels/settingsDataModels";
import { apiUrl, Utils } from "../shared/utils/utils";
import { NotificationSettings } from "../shared/enum/settings";
import { SimpleResponse, SuccessfulCreationResponse } from "../shared/dataModels/sharedDataModels";
import { LocalStorageKeys } from "../shared/enum/shared-enums";

type SettingsType = {
  [key: string]: string | boolean;
};

@Injectable({
              providedIn: 'root'
            })
export class SettingsService {
  constructor(private http: HttpClient) { }

  getNotificationSettings(): Observable<AllSettingsResponse> {
    return this.http.get<AllSettingsResponse>(apiUrl + 'settings');
  }

  getEmailForNotification(): Observable<EmailForNotificationsResponse> {
    const regionId = JSON.parse(Utils.getItemFromLocalStorage(LocalStorageKeys.REGION)).id;
    return this.http.get<EmailForNotificationsResponse>(apiUrl + 'emailForNotification/' + regionId);
  }

  getLinks(): Observable<LinksResponse> {
    const regionId = JSON.parse(Utils.getItemFromLocalStorage(LocalStorageKeys.REGION)).id;
    return this.http.get<LinksResponse>(apiUrl + 'appLinks/' + regionId);
  }

  getBlockingTimeSettings(): Observable<BlockingTimeSettingsResponse> {
    const regionId = JSON.parse(Utils.getItemFromLocalStorage(LocalStorageKeys.REGION)).id;
    return this.http.get<BlockingTimeSettingsResponse>(apiUrl + 'region-setting/' + regionId + '/blocking-time');
  }

  editNotificationSetting(notificationType: NotificationSettings, value: number): Observable<SuccessfulCreationResponse> {
    return this.http.put<SuccessfulCreationResponse>(apiUrl + 'settings/' + notificationType, { value });
  }

  editRegionSetting(setting: NotificationSettings | string, value: string): Observable<SimpleResponse> {
    const regionId = JSON.parse(Utils.getItemFromLocalStorage(LocalStorageKeys.REGION)).id;
    return this.http.put<SimpleResponse>(apiUrl + 'regionSetting/' + setting, { value, region_id: regionId });
  }

  editBlockingTimeSettings(settings: SettingsType): Observable<SimpleResponse> {
    const regionId = JSON.parse(Utils.getItemFromLocalStorage(LocalStorageKeys.REGION)).id;
    return this.http.put<SimpleResponse>(apiUrl + 'blocking-time-settings', {
      region_id: regionId,
      ...settings
    });
  }

  getFormUrlEncoded(toConvert: any) {
    const formBody = [];
    for (const property in toConvert) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(toConvert[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    return formBody.join('&');
  }

  getBanner(params: HttpParams): Observable<WebBannerResponse> {
    return this.http.get<WebBannerResponse>(apiUrl + 'webbanner/entry_order', { params });
  }

  uploadBanner(payload: WebBannerPayload, desktopBanner: File | null, mobileBanner: File | null): Observable<SuccessfulCreationResponse> {
    const formData: FormData = new FormData();
    if (desktopBanner) {
      formData.append('desktop_file', desktopBanner, desktopBanner.name);
    }

    if (mobileBanner) {
      formData.append('mobile_file', mobileBanner, mobileBanner.name);
    }

    formData.append('purpose', payload.purpose);
    formData.append('region_id', payload.regionId.toString());
    if (payload.couponText) {
      formData.append('coupon_text', payload.couponText);
    }
    if (payload.text) {
      formData.append('text', payload.text);
    }
    if (payload.footerText) {
      formData.append('footer_text', payload.footerText);
    }
    return this.http.post<SuccessfulCreationResponse>(apiUrl + 'webbanner/entry_order', formData);
  }
}
