<app-breadcrumbs (navigateTo)="navigateTo($event)"></app-breadcrumbs>
<div class="loading-spinner">
  <div *appLoading="!rolesLoaded">
    <div *ngIf="writeMode">
      <h3 class="mb-3">Rollen zuweisen/entfernen</h3>
      <div class="col-lg-2 mb-3">
        <label for="regions" class="mb-1">Region:</label>
        <select name="regions"
                [(ngModel)]="regionId"
                (change)="onChangeRegion()"
                id="regions"
                class="form-select cursor-pointer me-3">
          <option *ngFor="let region of regions"
                  [value]="region.id"
                  [selected]="regionId === region.id">
            {{ region?.name }}
          </option>
        </select>
      </div>
    </div>
    <div *ngIf="!writeMode" class="d-flex gap-3 mb-3">
      <h3>Rollen</h3>
      <i routerLink="create"
         queryParamsHandling="merge"
         class="mdi mdi-plus-circle mdi-24px d-lg-none create-user-button align-self-center icon-color"></i>
    </div>

    <div class="row">
      <div class="col-lg-8">
        <form [formGroup]="rolesSearchForm" (ngSubmit)="onSearchRoles()">
          <div class="app-search d-flex justify-content-between gap-1 search-background">
            <input type="text"
                   class="form-control-custom"
                   formControlName="searchInput"
                   required
                   placeholder="Suche...">
            <div class="align-self-center search-clear-button ms-1">
              <i class="mdi mdi-close-thick cursor-pointer" (click)="onClearSearch()"
                 [hidden]="!searchInput?.value"></i>
            </div>
            <button type="button"
                    class="btn cursor-pointer"
                    [disabled]="!searchInput?.value"
                    (click)="onSearchRoles()">
              <i class="mdi mdi-magnify mdi-18px align-self-center"></i>
            </button>
          </div>
        </form>
      </div>
      <div *ngIf="!writeMode" class="col-lg-4 d-none d-lg-block text-end">
        <button type="button"
                [routerLink]="['create']"
                [queryParamsHandling]="'merge'"
                class="app-primary-button">
          <i class="mdi mdi-plus-circle text-white me-2 create-user-button"></i>
          <span>Neue Rolle anlegen</span>
        </button>
      </div>
    </div>
    <!-- T A B L E -->
    <div class="table-responsive mt-3">
      <table class="table table-striped table-centered mb-0">
        <thead>
        <tr>
          <th *ngIf="writeMode; else id" class="border-bottom-0 table-cell">Auswählen</th>
          <ng-template #id>
            <th class="border-bottom-0 table-cell">ID</th>
          </ng-template>
          <th class="border-bottom-0 table-cell">Name</th>
          <th class="border-bottom-0 table-cell">Berechtigungen</th>
          <th class="border-bottom-0 table-cell">Beschreibung</th>
          <th *ngIf="!writeMode">Aktionen</th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngIf="writeMode">
          <tr *ngFor="let role of assignedRoles; let i = index">
            <td class="table-cell fw-bold">
              <span *ngIf="spinnerIndex === i" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span>
              <input type="checkbox"
                     class="form-check-input custom-xl-checkbox cursor-pointer"
                     [class.d-none]="spinnerIndex === i"
                     [checked]="role.selected"
                     (click)="onSelectRole(role, i)">
            </td>
            <td>{{ role?.name }}</td>
            <td>
              <ng-container *ngFor="let permission of formatPermissions(role.permissions.toString());
                let odd = odd;
                let last = last;
                let even = even">
                <span *ngIf="!last && odd">{{ permission }}, <br></span>
                <span *ngIf="!last && even">{{ permission }}, </span>
                <span *ngIf="last">{{ permission }}</span>
              </ng-container>
            </td>
            <td>{{ role?.description }}</td>
          </tr>
        </ng-container>
        <ng-container *ngIf="!writeMode">
          <tr *ngFor="let role of rolesData.pagination.data">
            <td class="fw-bold table-cell">{{ role?.id }}</td>
            <td class="table-cell">{{ role?.name }}</td>
            <td class="table-cell">
              <ng-container *ngFor="let permission of formatPermissions(role.permissions);
                let odd = odd;
                let last = last;
                let even = even">
                <span *ngIf="!last && odd">{{ permission }}, <br></span>
                <span *ngIf="!last && even">{{ permission }}, </span>
                <span *ngIf="last">{{ permission }}</span>
              </ng-container>
            </td>
            <td class="table-cell">{{ role?.description }}</td>
            <td class="table-cell">
              <a [routerLink]="[role.id, 'manage']" queryParamsHandling="merge" class="action-icon">
                <i class="mdi mdi-pencil icon-color"></i>
              </a>
              <a href="#" class="action-icon" data-bs-toggle="modal" (click)="onMarkRoleForDeletion(role)"
                 data-bs-target="#danger-header-modal">
                <i class="mdi mdi-trash-can-outline icon-color"></i>
              </a>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>
    <div *ngIf="rolesData?.pagination?.data?.length === 0" class="fw-bold mt-3 fs-4">Keine Daten vorhanden.</div>
    <app-advanced-pagination *ngIf="rolesData && rolesData.pagination.total > rolesData.pagination.per_page"
                             [paginationData]="rolesData"
                             (emitPaginatedData)="getPaginatedData($event)">
    </app-advanced-pagination>
    <div *ngIf="writeMode" class="d-flex justify-content-end mt-2">
      <button type="submit"
              class="save-button active ms-3"
              queryParamsHandling="merge"
              [routerLink]="['/dashboard/users', userId]">
        <span>Zurück</span>
      </button>
    </div>
  </div>
</div>
<!-- M O D A L  B O D Y -->
<div id="danger-header-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="danger-header-modalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header modal-colored-header bg-danger">
        <h4 class="modal-title" id="danger-header-modalLabel">Rolle löschen</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-hidden="true"></button>
      </div>
      <div class="modal-body">
        Sind Sie sicher, dass Sie die Rolle entfernen wollen?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Abbrechen</button>
        <button type="button" (click)="onDeleteRole()" data-bs-dismiss="modal" class="btn btn-danger">
          Löschen
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
