import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {RegistrationComponent} from "../registration/registration.component";
import {ForgotPasswordComponent} from "../forgot-password/forgot-password.component";
import {ConfirmMailComponent} from "../confirm-mail/confirm-mail.component";
import {MainDashboardComponent} from "../../main-dashboard/main-dashboard.component";
import {AuthenticationGuard} from "../authentication.guard";


const routes: Routes = [
  {
    path: 'dashboard',
    canActivate: [AuthenticationGuard],
    component: MainDashboardComponent,
  },
  {
    path: 'registration',
    component: RegistrationComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'confirm-email',
    component: ConfirmMailComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule {
}
