import { Component, OnInit } from '@angular/core';
import { PaginationData } from "../../shared/dataModels/paginationDataModel";
import { Card } from "../../shared/dataModels/cardDataModel";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Region } from "../../shared/dataModels/regionDataModels";
import { CardsService } from "../../services/cards.service";
import { AppGlobalService } from "../../shared/services/app-global.service";
import { combineLatest } from "rxjs";
import { Params } from "@angular/router";
import { Utils } from "../../shared/utils/utils";
import { LocalStorageKeys, ResponseCode } from "../../shared/enum/shared-enums";
import { AlertifyService } from "../../shared/services/alertify-service.service";
import { filter, takeUntil, tap } from "rxjs/operators";
import { SubscriptionComponent } from "../../shared/components/subscription/subscription.component";

@Component({
             selector: 'app-cards-for-pick-up',
             templateUrl: './cards-for-pick-up.component.html',
             styleUrls: ['./cards-for-pick-up.component.scss']
           })
export class CardsForPickUpComponent extends SubscriptionComponent implements OnInit {
  cardsData: PaginationData;
  cards: Card[];
  regions: Region[];
  cardsFilterForm: FormGroup;
  requestParams: Params;
  perPage = 5;
  regionId: number;
  chevron = true;
  error = false;
  responseSuccessful = false;

  constructor(private cardsService: CardsService,
              private globalService: AppGlobalService,
              private formBuilder: FormBuilder,
              private alertifyService: AlertifyService) { super() }

  ngOnInit(): void {
    this.regionId = JSON.parse(Utils.getItemFromLocalStorage(LocalStorageKeys.REGION)).id;
    this.requestParams = {
      cardStatus: 'Zur Abholung bereit',
      orderDirection: 'desc',
      orderBy: 'created_at',
      perPage: this.perPage,
      regionId: this.regionId
    }
    this.getData();
    this.initializePickUpFilterForm();
    this.watchRegion();
  }

  private getData(): void {
    combineLatest([this.cardsService.getCards(this.requestParams), this.globalService.regions$])
      .pipe(
        tap(([cardsResponse]) => {
          if (cardsResponse.code === ResponseCode.FAILED) {
            this.alertifyService.warning(cardsResponse.message);
          }
          this.responseSuccessful = true;
        }),
        filter(([, regionsResponse]) => Object.keys(regionsResponse).length > 0),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(([cardsResponse, regionsResponse]) => {
        if (cardsResponse.code === ResponseCode.SUCCESS) {
          this.cardsData = cardsResponse.body as PaginationData;
          this.cards = Utils.snakeCaseToCamelCase(cardsResponse.body?.pagination.data);
        }
        this.regions = Utils.snakeCaseToCamelCase(regionsResponse.body?.pagination.data);
        this.cards = Utils.assignRegionName(this.cards, this.regions);
      }, () => this.error = true);
  }

  private initializePickUpFilterForm(): void {
    this.cardsFilterForm = this.formBuilder.group({
                                                    region: [this.regionId]
                                                  });
  }

  loadPaginatedData(pageNumber: string): void {
    this.responseSuccessful = false;
    this.requestParams = { ...this.requestParams, page: pageNumber };
    this.getData();
  }

  private watchRegion(): void {
    this.cardsFilterForm?.valueChanges.subscribe((value) => {
      this.requestParams.regionId = value.region;
      this.responseSuccessful = false;
      this.getData();
    });
  }
}
