<div class="accordion custom-accordion mb-3" id="custom-accordion-two">
  <div class="card mb-0">
    <div class="card-header" [id]="'heading' + componentId">
      <h5 class="m-0">
        <i class="mdi mdi-chevron-down accordion-arrow custom-accordion-title me-3 mt-1"
           [ngClass]="{show: chevron, collapse: !chevron}"></i>
        <i class="mdi mdi-chevron-up accordion-arrow custom-accordion-title me-3 mt-1"
           [ngClass]="{show: !chevron, collapse: chevron}"></i>
        <a class="custom-accordion-title d-flex justify-content-between align-items-baseline py-1"
           data-bs-toggle="collapse" [href]="'#collapse' + componentId"
           (click)="chevron = !chevron"
           aria-expanded="true" [attr.aria-controls]="'collapse' + componentId">
          <div class="dashboard-table-title-fs">
            <i class="mdi mdi-drag mdi-18px cursor-pointer me-3"></i>
            {{ tableTitle }}
          </div>
        </a>
      </h5>
    </div>
  </div>

  <div [id]="'collapse' + componentId"
       class="collapse"
       [attr.aria-labelledby]="'heading' + componentId"
       data-bs-parent="#custom-accordion-two">
    <div class="card-body">
      <div class="loading-spinner" *ngIf="!error">
        <div *appLoading="!responseSuccessful"
             class="table-responsive db-table-width"
             [class.mt-3]="embossments.length > 0">
          <app-advanced-pagination *ngIf="embossmentData.pagination.total > embossmentData.pagination.per_page"
                                   [paginationData]="embossmentData"
                                   (emitPaginatedData)="loadPaginatedData($event)">
          </app-advanced-pagination>
          <table *ngIf="embossments.length > 0" class="table table-striped table-centered mb-0 mt-1">
            <thead class="table-light">
            <tr class="sticky-header-row">
              <th *ngFor="let title of tableHeaders" class="table-cell">{{ title }}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let embossment of embossments" class="bg-aqua">
              <td class="text-body fw-bold table-cell">{{ embossment.id }}</td>
              <td class="table-cell">{{ embossment.createdAt | date:'dd.MM.yyyy' }}</td>
              <td class="table-cell">{{ embossment.user?.email }}</td>
              <td *ngIf="embossment.embossmentOrders" class="table-cell">{{ embossment.embossmentOrders.length }}</td>
              <td *ngIf="embossment.embossmentCards" class="table-cell">{{ embossment.embossmentCards.length }}</td>
              <td *ngIf="embossment.embossmentFiles.length > 0; else noFiles" class="table-cell">
                <div *ngFor="let file of embossment.embossmentFiles">
                  <span class="me-3">{{ file.fileName }}</span>
                  <a (click)="onDownload(file.fileName, file.fileUrl, file.fileExtension, file.fileMimetype)"
                     [download]="file.fileName"
                     class="link-info cursor-pointer" title="Herunterladen">
                    <i class="mdi mdi-download-outline mdi-18px"></i>
                  </a>
                </div>
              </td>
              <ng-template #noFiles>
                <td class="table-cell">
                  <div class="d-flex align-items-center gap-2">
                    <span>wird erzeugt...</span>
                    <i class="mdi mdi-refresh mdi-24px cursor-pointer" (click)="onRefresh()"></i>
                  </div>
                </td>
              </ng-template>
            </tr>
            </tbody>
          </table>
          <div *ngIf="embossments.length === 0" class="d-flex align-items-center">
            <span>Keine Prägungen</span>
            <i class="mdi mdi-refresh mdi-24px ms-2 cursor-pointer" (click)="onRefresh()"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
