<div class="loading"
     [class.screen-background-color]="showDefaultRegionSelectionModal"
     data-layout-config='{"leftSideBarTheme":"dark","layoutBoxed":false, "leftSidebarCondensed":false, "leftSidebarScrollable":false,"darkMode":false, "showRightSidebarOnStart": true}'>
  <!-- Begin page -->
  <div class="wrapper">

    <!-- ========== Left Sidebar Start ========== -->

    <div class="leftside-menu d-md-block" [class.d-none]="!sideMenuToggle">

      <!-- LOGO -->
      <a class="logo text-center logo-dark">
                    <span class="logo-lg">
                        <img src="assets/images/logo-dark.png" alt="" height="16">
                    </span>
        <span class="logo-sm">
      <img src="https://www.dresdenforfriends.de/sites/ddff/files/DDFF_Logo450.png" alt="" height="18">
                    </span>
      </a>

      <div class="h-100" id="leftside-menu-container" data-simplebar>

        <!--- Sidemenu -->
        <ul class="metismenu navigation-color side-nav">

          <li class="side-nav-title side-nav-item">Navigation</li>

          <li class="side-nav-item">
            <a routerLink="home"
               [class.active]="router.url.includes('/dashboard/home')"
               [queryParams]="{'region': getSelectedRegion()}"
               role="button"
               class="side-nav-link">
              <i class="uil-home-alt"></i>
              <span>Dashboard</span>
            </a>
          </li>

          <!-- <li class="side-nav-title side-nav-item">Apps</li>-->

          <li class="side-nav-item">
            <a class="side-nav-link"
               [class.active]="router.url.includes('/dashboard/customers')"
               role="button"
               routerLink="customers"
               [queryParams]="{'region': getSelectedRegion()}">
              <i class="mdi mdi-account-cash"></i>
              <span>Kunden</span>
            </a>
          </li>


          <li class="side-nav-item">
            <a routerLink="orders"
               [class.active]="router.url.includes('/dashboard/orders')"
               [queryParams]="{'region': getSelectedRegion()}"
               role="button"
               class="side-nav-link">
              <i class="mdi mdi-package-variant-closed"></i>
              <span>Bestellungen</span>
            </a>
          </li>

          <li class="side-nav-item">
            <a routerLink="coupons"
               [class.active]="router.url.includes('/dashboard/coupons')"
               [queryParams]="{'region': getSelectedRegion()}"
               role="button"
               class="side-nav-link">
              <i class="mdi mdi-sack-percent"></i>
              <span>Coupons</span>
            </a>
          </li>

          <li class="side-nav-item">
            <a routerLink="vouchers"
               [class.active]="router.url.includes('/dashboard/vouchers')"
               [queryParams]="{'region': getSelectedRegion()}"
               role="button"
               class="side-nav-link">
              <i class="mdi mdi-inbox-full"></i>
              <span>Gutscheine</span>
            </a>
          </li>

          <li class="side-nav-item">
            <a routerLink="products"
               [queryParams]="{'region': getSelectedRegion()}"
               [class.active]="router.url.includes('/dashboard/products')"
               role="button"
               class="side-nav-link">
              <i class="mdi mdi-shape-outline"></i>
              <span>Produkte</span>
            </a>
          </li>

          <li class="side-nav-item">
            <a routerLink="cards"
               [class.active]="router.url.includes('/dashboard/cards')"
               [queryParams]="{'region': getSelectedRegion()}"
               class="side-nav-link"
               role="button">
              <i class="mdi mdi-credit-card-outline"></i>
              <span>Karten</span>
            </a>
          </li>

          <li class="side-nav-item">
            <a class="side-nav-link"
               role="button"
               [class.active]="router.url.includes('/dashboard/users')"
               [queryParams]="{'region': getSelectedRegion()}"
               routerLink="users">
              <i class="mdi mdi-account-group"></i>
              <span>Nutzer</span>
            </a>
          </li>

          <li class="side-nav-item">
            <a routerLink="roles"
               role="button"
               [class.active]="router.url.includes('/dashboard/roles')"
               [queryParams]="{'region': getSelectedRegion()}"
               class="side-nav-link">
              <i class="mdi mdi-account-tie-outline"></i>
              <span class="ms-1">Rollen</span>
            </a>
          </li>
          <li class="side-nav-item">
            <a routerLink="permissions"
               [class.active]="router.url.includes('/dashboard/permissions')"
               [queryParams]="{'region': getSelectedRegion()}"
               role="button"
               class="side-nav-link">
              <i class="mdi mdi-key"></i>
              <span class="ms-1">Berechtigungen</span>
            </a>
          </li>


          <li class="side-nav-item">
            <a routerLink="regions"
               [class.active]="router.url.includes('/dashboard/regions')"
               [queryParams]="{'region': getSelectedRegion()}"
               class="side-nav-link"
               role="button">
              <i class="mdi mdi-map-marker-radius"></i>
              <span>Regionen</span>
            </a>
          </li>

          <li class="side-nav-item">
            <a routerLink="memos"
               [class.active]="router.url.includes('/dashboard/memos')"
               [queryParams]="{'region': getSelectedRegion()}"
               role="button"
               class="side-nav-link">
              <i class="mdi mdi-note-text-outline"></i>
              <span>Memos</span>
            </a>
          </li>

          <li class="side-nav-item">
            <a routerLink="reporting"
               [class.active]="router.url.includes('/dashboard/reporting')"
               [queryParams]="{'region': getSelectedRegion()}"
               role="button"
               class="side-nav-link">
              <i class="mdi mdi-book-outline"></i>
              <span>Reporting</span>
            </a>
          </li>

          <li class="side-nav-item">
            <a routerLink="settings"
               [class.active]="router.url.includes('/dashboard/settings')"
               [queryParams]="{'region': getSelectedRegion()}"
               role="button"
               class="side-nav-link">
              <i class="mdi mdi-cog-outline"></i>
              <span>Einstellungen</span>
            </a>
          </li>
        </ul>

        <div class="clearfix"></div>

      </div>
      <!-- Sidebar -left -->

    </div>

    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->

    <div class="content-page" id="component-container">
      <div class="content">
        <!-- Topbar Start -->
        <div class="navbar-custom pe-0" (clickElsewhere)="sideMenuToggle = false">

          <ul class="list-unstyled topbar-menu float-end mb-0">
            <li class="dropdown notification-list topbar-dropdown">
              <a class="nav-link dropdown-toggle arrow-none" data-bs-toggle="dropdown"
                 href="#" role="button" aria-haspopup="false" aria-expanded="false">
                <span class="me-0 me-sm-1">{{ getSelectedRegion() | titlecase }}</span>
                <i class="mdi mdi-chevron-down d-sm-inline-block align-middle"></i>
              </a>
              <div class="dropdown-menu cursor-pointer dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu">
                <a *ngFor="let region of meData?.loginRegions"
                   (click)="onChangeSelectedDefaultRegion(region)" class="dropdown-item notify-item">
                  <span class="align-middle">{{region.name}}</span>
                </a>
              </div>
            </li>

            <li class="dropdown notification-list">
              <a class="nav-link dropdown-toggle nav-user arrow-none me-0 d-flex align-items-center"
                 data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                                    <span class="account-user-avatar">
                                        <img src="assets/images/users/Portrait_Placeholder.png" alt="user-image"
                                             class="rounded-circle">
                                    </span>
                <span *ngIf="user" class="account-user-name"> {{user?.email}}</span>
              </a>
              <div class="dropdown-menu dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
                <a [routerLink]="['/dashboard/users/me']" [queryParams]="{'region': getSelectedRegion()}"
                   class="dropdown-item notify-item">
                  <i class="mdi mdi-account-circle me-1"></i>
                  <span>Mein Konto</span>
                </a>

                <a [routerLink]="['/dashboard/users/me/change-password']"
                   [queryParams]="{'region': getSelectedRegion()}"
                   class="dropdown-item notify-item">
                  <i class="mdi mdi-account-edit me-1"></i>
                  <span>Passwort ändern</span>
                </a>

                <a class="dropdown-item notify-item cursor-pointer"
                   (click)="showDefaultRegionSelectionModal = true">
                  <i class="mdi mdi-map-marker-radius me-1"></i>
                  <span>Standardregion ändern</span>
                </a>

                <a class="cursor-pointer
                                dropdown-item notify-item" (click)="onLogout()">
                  <i class="mdi mdi-logout me-1"></i>
                  <span>Abmelden</span>
                </a>

              </div>
            </li>
          </ul>

          <button class="button-menu-mobile open-left" (click)="sideMenuToggle = !sideMenuToggle">
            <i class="mdi mdi-menu"></i>
          </button>

        </div>
        <!-- end Topbar -->

        <!-- Start Content-->
        <div class="container-fluid">

          <!-- start page title -->
          <div class="row">
            <div class="col-12">
              <div class="position-relative pt-4">
                <router-outlet></router-outlet>
              </div>
            </div>
          </div>

        </div>
        <!-- container -->

      </div>
      <!-- content -->

      <!-- Footer Start -->
      <footer (click)="sideMenuToggle = false" class="footer">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6">
              © DD<span routerLink="deployment-info" queryParamsHandling="merge">F</span>F - Dresden
            </div>
          </div>
        </div>
      </footer>
    </div>

    <!-- ============================================================== -->
    <!-- End Page content -->
    <!-- ============================================================== -->

  </div>
  <!-- END wrapper -->

  <!-- Right Sidebar -->
  <div class="end-bar">

    <div class="rightbar-title">
      <a href="javascript:void(0);" class="end-bar-toggle float-end">
        <i class="dripicons-cross noti-icon"></i>
      </a>
      <h5 class="m-0">Settings</h5>
    </div>

    <div class="rightbar-content h-100" data-simplebar>

      <div class="p-3">
        <div class="alert alert-warning" role="alert">
          <strong>Customize </strong> the overall color scheme, layout width, etc.
        </div>

        <!-- Settings -->
        <h5 class="mt-3">Color Scheme</h5>
        <hr class="mt-1"/>

        <div class="form-check form-switch mb-1">
          <input type="checkbox" class="form-check-input" name="color-scheme-mode" value="light"
                 id="light-mode-check" checked/>
          <label class="form-check-label" for="light-mode-check">Light Mode</label>
        </div>

        <div class="form-check form-switch mb-1">
          <input type="checkbox" class="form-check-input" name="color-scheme-mode" value="dark"
                 id="dark-mode-check"/>
          <label class="form-check-label" for="dark-mode-check">Dark Mode</label>
        </div>

        <!-- Width -->
        <h5 class="mt-4">Width</h5>
        <hr class="mt-1"/>
        <div class="form-check form-switch mb-1">
          <input type="checkbox" class="form-check-input" name="width" value="fluid" id="fluid-check"
                 checked/>
          <label class="form-check-label" for="fluid-check">Fluid</label>
        </div>
        <div class="form-check form-switch mb-1">
          <input type="checkbox" class="form-check-input" name="width" value="boxed" id="boxed-check"/>
          <label class="form-check-label" for="boxed-check">Boxed</label>
        </div>


        <div class="d-grid mt-4">
          <button class="btn btn-primary" id="resetBtn">Reset to Default</button>

          <a href="https://themes.getbootstrap.com/product/hyper-responsive-admin-dashboard-template/"
             class="btn btn-danger mt-3" target="_blank"><i class="mdi mdi-basket me-1"></i> Purchase Now</a>
        </div>
      </div> <!-- end padding-->

    </div>
  </div>

  <div class="rightbar-overlay"></div>
  <!-- /End-bar -->
</div>

<app-default-region-selection-modal
  *ngIf="meData"
  [showModal]="showDefaultRegionSelectionModal"
  [user]="meData"
  [selectedDefaultRegion]="defaultLoginRegion"
  (_onSelectDefaultRegion)="onSaveSelectedDefaultRegion($event)"
  (closeModal)="showDefaultRegionSelectionModal = false">
</app-default-region-selection-modal>
