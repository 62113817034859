import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AppGlobalService } from "../../services/app-global.service";
import { SubscriptionComponent } from "../subscription/subscription.component";
import { takeUntil } from "rxjs/operators";

@Component({
             selector: 'app-error-message',
             templateUrl: './error-message.component.html',
             styleUrls: ['./error-message.component.scss']
           })
export class ErrorMessageComponent extends SubscriptionComponent implements OnInit, OnDestroy {
  @Input() toggleBackground: boolean;
  showError: boolean;
  body = document.getElementsByTagName('body')[0];

  constructor(private route: ActivatedRoute, private location: Location, private globalService: AppGlobalService) {
    super();
  }

  ngOnInit(): void {
    this.globalService._serverError.pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((error: boolean) => {
          this.showError = error;
        });
    this.showError
      ? this.body?.classList.add('error-background')
      : this.body?.classList.add('authentication-bg');
  }

  ngOnDestroy() {
    this.body.classList.remove('error-background');
    this.body.classList.remove('authentication-bg');
  }

  back(): void {
    this.location.back();
    this.globalService.emitServerError(false);
    this.body.classList.remove('error-background');
  }

  onReload(): void {
    this.showError ? window.location.reload() : this.back();
  }

  @HostListener('window:popstate', ['$event'])
  goBack() {
    this.globalService.emitServerError(false);
    this.body.classList.remove('error-background');
  }
}
