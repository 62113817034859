import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { MessageResponse } from "../shared/dataModels/messageDataModel";
import { apiUrl } from "../shared/utils/utils";

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  constructor(private http: HttpClient) { }

  getMessages(id: number, purpose: string): Observable<MessageResponse> {
    return this.http.get<MessageResponse>(apiUrl + 'messages/' + purpose + '/' + id);
  }

  sendMessage(id: number, email?: string): Observable<MessageResponse> {
    return this.http.post<MessageResponse>(apiUrl + 'messages/send/' + id,  email);
  }

  generateMessage(id: number): Observable<MessageResponse> {
    return this.http.post<MessageResponse>(apiUrl + 'messages/generate/' + id, {});
  }
}
