<div *appLoading="!btSettingsLoaded">
  <h4 class="mb-3 mt-2">Sperrzeitraum im Webshop</h4>
  <div class="mb-5 pb-5">
    <div class="col-lg-8 col-xl-6">
      <form [formGroup]="btSettingsForm">
        <div class="row">
          <div class="mb-2">
            <label for="blockingTimeText" class="form-label fw-bolder">{{ btLabels['blockingTimeText'] }}</label>
            <textarea
              type="text"
              id="blockingTimeText"
              formControlName="blockingTimeText"
              class="form-control"></textarea>
            <span *ngIf="blockingTimeText?.invalid && blockingTimeText?.touched"
                  class="error-feedback mb-3">Der Text darf maximal 100 Zeichen lang sein!
              </span>
          </div>
          <div class="mb-2">
            <input type="checkbox"
                   formControlName="blockingTimePublished"
                   [value]="btSettingsForm.get('blockingTimePublished')?.value"
                   id="blockingTimePublished"
                   class="form-check-input custom-xl-checkbox cursor-pointer">
            <label for="blockingTimePublished" class="form-label mt-1 fw-bolder ms-2">{{ btLabels['blockingTimePublished'] }}</label>
          </div>
        </div>
        <div class="row">
          <h5 class="mb-2 mt-2">Sperrzeitraum Prägelauf</h5>
        </div>
        <div class="row">
          <div class="mb-2 col-12 col-sm-6">
            <label for="blocking_time_start" class="form-label fw-bolder">{{ btLabels['blockingTimeStart'] }}</label>
            <div id="1" class="position-relative">
              <input type="text"
                     id="blocking_time_start"
                     class="form-control"
                     #datepicker1
                     data-date-format="dd.mm.yyyy"
                     (blur)="btSettingsForm?.get('blockingTimeStart')?.patchValue(datepicker1?.value)"
                     formControlName="blockingTimeStart"
                     data-provide="datepicker"
                     [attr.data-date-container]="'#' + 1">
            </div>
            <div *ngIf="!btSettingsForm.get('blockingTimeStart')?.valid && btSettingsForm.get('blockingTimeStart')?.value">
                <span *ngIf="btSettingsForm.get('blockingTimeStart')?.errors?.wrongDate"
                      class="error-feedback mb-3">
                      Das Startdatum darf nicht in der Vergangenheit liegen!
                </span>
            </div>
          </div>
          <div class="mb-2 col-12 col-sm-6">
            <label for="blocking_time_end" class="form-label fw-bolder">{{ btLabels['blockingTimeEnd'] }}</label>
            <div id="2" class="position-relative">
              <input type="text"
                     id="blocking_time_end"
                     class="form-control"
                     #datepicker2
                     data-date-format="dd.mm.yyyy"
                     (blur)="btSettingsForm?.get('blockingTimeEnd')?.patchValue(datepicker2?.value)"
                     formControlName="blockingTimeEnd"
                     data-provide="datepicker"
                     [attr.data-date-container]="'#' + 2">
            </div>
            <div *ngIf="!btSettingsForm.get('blockingTimeEnd')?.valid && btSettingsForm.get('blockingTimeEnd')?.value">
                <span class="error-feedback mb-3">
                      Das Enddatum ist falsch!
                </span>
            </div>
          </div>
        </div>
      </form>
      <div class="d-flex justify-content-end mt-2 mt-md-3 mb-2 mb-md-3">
        <button type="submit"
                class="save-button ms-3"
                [class.active]="btSettingsForm?.valid && btSettingsForm?.touched"
                (click)="onChangeSettings()">
                                    <span *ngIf="waitingForResponse"
                                          class="spinner-border spinner-border-sm me-1"
                                          role="status"
                                          aria-hidden="true"></span>
          <span>Speichern</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- M O D A L  B O D Y -->
<button type="button"
        class="d-none sr-only"
        #confirmModal
        data-bs-toggle="modal"
        data-bs-target="#info-header-modal">
  Confirm
</button>
<app-modal
  data-bs-target="modal"
  [modalLabel]="modalHeader"
  [modalType]="'info'"
  [modalContent]="modalText"
  saveButtonLabel="Bestätigen"
  cancelButtonLabel="Abbrechen"
  [allowClickElsewhere]="false"
  saveButtonCssClass="btn-warning"
  modalHeaderCssClass="bg-warning"
  (emitConfirm)="onConfirmSettingChange()"
  (emitCancel)="onCancel()"
  (emitDismiss)="onCancel()"
></app-modal>
