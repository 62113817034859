<app-breadcrumbs (navigateTo)="navigateTo($event)"></app-breadcrumbs>
<div *appLoading="!ordersLoaded" class="card-body">
  <div *ngIf="!noOrders" class="table-responsive">
    <table class="table table-striped mb-0">
      <thead class="table-light">
      <tr>
        <th class="table-cell">Bestellnummer</th>
        <th class="table-cell">Bestellstatus</th>
        <th class="table-cell">Bestellursprung</th>
        <th class="table-cell">Zahlungsstatus</th>
        <th class="table-cell">Saldo €</th>
        <th class="table-cell">Erstellt/bearbeitet</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let order of orders">
        <td><a [routerLink]="['/dashboard', 'orders', order.id]"
               [queryParams]="{page: null}"
               queryParamsHandling="merge"
               class="neutral-anchor">
          {{ order.id }}
        </a></td>
        <td><a [routerLink]="['/dashboard', 'orders', order.id]"
               [queryParams]="{page: null}"
               queryParamsHandling="merge"
               class="neutral-anchor">
          {{ order.orderStatus }}
        </a></td>
        <td><a [routerLink]="['/dashboard', 'orders', order.id]"
               [queryParams]="{page: null}"
               queryParamsHandling="merge"
               class="neutral-anchor">
          {{ order.orderSource }}
        </a></td>
        <td>
          <a [routerLink]="['/dashboard', 'orders', order.id]"
             [queryParams]="{page: null}"
             queryParamsHandling="merge"
             class="neutral-anchor">
            <span>{{ order.paymentStatus }}</span>
          </a>
        </td>
        <td><a [routerLink]="['/dashboard', 'orders', order.id]"
               [queryParams]="{page: null}"
               queryParamsHandling="merge"
               class="neutral-anchor">
          {{ order.balance | currency: 'EUR' }}
        </a></td>
        <td>
          <a [routerLink]="['/dashboard', 'orders', order.id]"
             [queryParams]="{page: null}"
             queryParamsHandling="merge"
             class="neutral-anchor">
            {{ order.createdAt | date: 'dd.MM.yyyy - HH:mm' }} /
            {{ order.updatedAt | date: 'dd.MM.yyyy - HH:mm' }}
          </a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="noOrders" class="text-center">
    <span>Keine Bestellungen</span>
  </div>
  <app-advanced-pagination *ngIf="ordersData && ordersData.pagination.total > ordersData.pagination.per_page"
                           [paginationData]="ordersData"
                           (emitPaginatedData)="loadPaginatedData($event)">
  </app-advanced-pagination>
</div>
