<div class="accordion custom-accordion mb-3" id="custom-accordion-six">
  <div class="card mb-0">
    <div class="card-header" id="headingSix">
      <h5 class="m-0">
        <i class="mdi mdi-chevron-down accordion-arrow custom-accordion-title me-3 mt-1"
           [ngClass]="{show: chevron, collapse: !chevron}"></i>
        <i class="mdi mdi-chevron-up accordion-arrow custom-accordion-title me-3 mt-1"
           [ngClass]="{show: !chevron, collapse: chevron}"></i>
        <a class="custom-accordion-title d-flex justify-content-between align-items-baseline py-1"
           data-bs-toggle="collapse" href="#collapseSix"
           (click)="chevron = !chevron"
           aria-expanded="true" aria-controls="collapseSix">
          <div>
            <i class="mdi mdi-drag mdi-18px cursor-pointer me-3"></i>
            Fehlerbehebung
          </div>
          <div *ngIf="troubleshootingVM?.length" class="items-length me-3">+ {{ troubleshootingVM?.length }}</div>
        </a>
      </h5>
    </div>
  </div>
  <div id="collapseSix"
       class="collapse"
       aria-labelledby="headingSix"
       data-bs-parent="#custom-accordion-six">
    <div class="card-body">
      <div class="loading-spinner" *ngIf="!error">
        <div *appLoading="!responseSuccessful">
          <div class="d-xxxl-flex justify-content-between">
            <form [formGroup]="troubleShootingFilterForm" class="d-md-flex justify-content-start col-xxxl-6 gap-2">
              <div class="col-xxxl-4 mb-3">
                <label class="col-form-label" for="type">Fehlertypen</label>
                <select formControlName="type"
                        id="type"
                        class="form-select cursor-pointer">
                  <option value="-1">Alle</option>
                  <option *ngFor="let type of troubleShootingTypes" [value]="type.type">{{ type.displayName }}</option>
                </select>
              </div>
              <div class="col-xxxl-4 mb-3">
                <label class="col-form-label" for="regionId">Region</label>
                <select formControlName="region"
                        id="regionId"
                        class="form-select cursor-pointer me-3">
                  <option value="-1">Alle</option>
                  <option *ngFor="let region of regions" [value]="region.id">{{ region.name }}</option>
                </select>
              </div>
              <div class="col-xxxl-4 mb-2">
                <button type="button" (click)="onRefreshList()" class="btn app-primary-button m-refresh-button">
                  <i class="mdi mdi-refresh cursor-pointer align-self-center mt-2"></i>
                  <span>Neu laden</span>
                </button>
              </div>
            </form>
            <div class="d-md-flex justify-content-start justify-content-xxxl-end align-items-center col-xxxl-6 gap-2">
              <div>
                <button type="button"
                        class="btn save-button text-nowrap m-create-button"
                        [class.active]="selectedTroubleShoots.length > 0"
                        [disabled]="selectedTroubleShoots.length === 0 || troubleshootingVM.length === 0"
                        (click)="onSetResolveForTroubleShoots()">
                  Fehlerbehebung lösen
                </button>
              </div>
            </div>
          </div>
          <!-- TABLE -->
          <div *ngIf="!nothingToTroubleshoot" [class.scroll-allow]="troubleshootingVM.length > 10"
               class="table-responsive mt-3">
            <table class="table table-striped table-centered db-table-width mb-0">
              <thead class="table-light">
              <tr>
                <th class="all table-cell">
                  <input type="checkbox"
                         #selectAllCheckbox
                         class="form-check-input custom-xl-checkbox cursor-pointer"
                         (change)="onCheckAllTroubleShoots($event)"
                         [checked]="allTroubleShootsSelected">
                </th>
                <th class="table-cell">Fehlertyp</th>
                <th class="table-cell">ID</th>
                <th class="table-cell">Kunde</th>
                <th class="table-cell">Bestelländerung</th>
                <th class="table-cell">Erstellt</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of troubleshootingVM" class="bg-aqua">
                <td class="table-cell">
                  <input type="checkbox"
                         [checked]="selectedTroubleShoots.includes(item.id)"
                         (change)="onSelectTroubleShoot(item.id)"
                         class="form-check-input custom-xl-checkbox cursor-pointer">
                </td>
                <td class="text-body fw-bold table-cell">{{ getValueOfEnumByStringKey(item.type) }}</td>
                <td class="table-cell">
                  {{ item?.orderId }}
                  <a [routerLink]="['/dashboard/orders', item?.orderId]" queryParamsHandling="merge">
                    <i class="uil-external-link-alt"></i>
                  </a>
                </td>
                <td class="table-cell">
                  {{ item?.customerId }}
                  <a [routerLink]="['/dashboard/customers', item?.customerId]" queryParamsHandling="merge">
                    <i class="uil-external-link-alt"></i>
                  </a>
                </td>
                <td class="table-cell">{{ item?.orderChangeDate | date: 'dd.MM.yyyy - HH:mm'  }}</td>
                <td class="table-cell">{{ item?.createdAt | date: 'dd.MM.yyyy - HH:mm'  }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="nothingToTroubleshoot" class="mt-3">Keine Daten vorhanden.</div>
        </div>
      </div>
    </div>
  </div>
</div>
