<app-breadcrumbs *ngIf="!asChild" (navigateTo)="navigateTo($event)"></app-breadcrumbs>
<div class="loading-spinner">

  <h3 *ngIf="!asChild; else zuweisen" class="mb-3">Berechtigungen</h3>
  <ng-template #zuweisen>
    <h5 class="mb-3">Berechtigungen zuweisen</h5>
  </ng-template>

  <div class="row">
    <div class="col-lg-8">
      <form [formGroup]="permissionSearchForm" (ngSubmit)="onSearchPermissions()">
        <div class="app-search d-flex justify-content-between gap-1 search-background">
          <input type="text"
                 class="form-control-custom"
                 formControlName="searchInput"
                 required
                 placeholder="Suche...">
          <div class="align-self-center search-clear-button ms-1">
            <i class="mdi mdi-close-thick cursor-pointer" (click)="onClearSearch()"
               [hidden]="!searchInput?.value"></i>
          </div>
          <button type="button"
                  class="btn cursor-pointer"
                  [disabled]="!searchInput?.value"
                  (click)="onSearchPermissions()">
            <i class="mdi mdi-magnify mdi-18px align-self-center"></i>
          </button>
        </div>
      </form>
    </div>
    <div *appLoading="!permissionsLoaded">
      <!-- T A B L E -->
      <div *ngIf="searchResults" class="table-responsive mt-3">
        <table class="table table-striped table-centered mb-0">
          <thead>
          <tr>
            <th *ngIf="asChild; else id" class="border-bottom-0 table-cell">Auswählen</th>
            <ng-template #id>
              <th class="border-bottom-0 table-cell">ID</th>
            </ng-template>
            <th class="border-bottom-0 table-cell">Gruppe</th>
            <th class="border-bottom-0 table-cell">Name</th>
            <th class="border-bottom-0 table-cell">Beschreibung</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let permission of permissionsData?.pagination?.data">
            <td *ngIf="asChild; else showId" class="table-cell fw-bold">
              <input type="checkbox" [checked]="permission.selected" (click)="onSelectPermission(permission)">
            </td>
            <ng-template #showId>
              <td class="table-cell fw-bold">{{ permission?.id }}</td>
            </ng-template>
            <td class="table-cell">{{ permission?.group }}</td>
            <td class="table-cell">{{ permission?.name }}</td>
            <td class="table-cell">{{ permission?.description }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="!searchResults" class="fw-bold mt-3 fs-3">Keine Ergebnisse gefunden.</div>
      <app-advanced-pagination *ngIf="permissionsData.pagination.total > permissionsData.pagination.per_page"
                               [paginationData]="permissionsData"
                               (emitPaginatedData)="getPaginatedData($event)">
      </app-advanced-pagination>
    </div>
  </div>
</div>
