<div class="authentication-bg" data-layout-config='{"darkMode":false}'>
  <div class="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xxl-4 col-lg-5">
          <div class="card">
            <!-- Logo -->
            <div class="card-header pt-4 pb-4 text-center app-bg-primary"></div>
            <div class="card-body p-4">
              <div class="text-center m-auto">
                <h4 class="text-dark-50 text-center mt-0 fw-bold">Passwort zurücksetzen</h4>
                <p class="text-muted mb-4">Bitte geben Sie Ihre Email Adresse zum Zurücksetzen des Passwortes ein</p>
              </div>

              <form [formGroup]="resetPasswordForm">
                <div class="mb-3">
                  <label for="password" class="form-label">Passwort</label>
                  <div class="input-group">
                    <input class="form-control"
                           [type]="passwordType"
                           id="password"
                           minlength="8"
                           formControlName="password"
                           placeholder="Passwort">
                    <div class="input-group-text" data-password="true">
                      <i class="mdi mdi-eye-off"
                         *ngIf="passwordType === 'text'" (click)="hidePassword()"></i>
                      <i class="mdi mdi-eye"
                         *ngIf="passwordType === 'password'" (click)="showPassword()"></i>
                    </div>
                  </div>
                  <span *ngIf="!password?.valid && password?.touched" class="error-feedback">
                    Das neue Passwort muss mindestens 8 Zeichen sein!
                  </span>
                </div>
                <div class="mb-3">
                  <label for="confirmPassword" class="form-label">Password Wiederholung</label>
                  <div class="input-group">
                    <input class="form-control"
                           [type]="confirmPasswordType"
                           id="confirmPassword"
                           minlength="8"
                           formControlName="confirmPassword"
                           placeholder="Passwort">
                    <div class="input-group-text" data-password="true">
                      <i class="mdi mdi-eye-off"
                         *ngIf="confirmPasswordType === 'text'" (click)="hidePassword('confirm')"></i>
                      <i class="mdi mdi-eye"
                         *ngIf="confirmPasswordType === 'password'" (click)="showPassword('confirm')"></i>
                    </div>
                  </div>
                   <span *ngIf="confirmPassword?.errors?.mustMatch && confirmPassword?.touched" class="error-feedback">
                     Die Angaben stimmen nicht überein!
                   </span>
                </div>
                <div class="mb-0 text-center">
                  <button class="btn app-primary-button"
                          type="submit"
                          [disabled]="resetPasswordForm?.invalid"
                          (click)="onResetPassword()">
                    Passwort ändern
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 text-center">
              <p class="text-muted">zurück zum
                <a routerLink="/login" class="text-muted ms-1">
                  <b>Anmelden</b>
                </a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <footer class="footer footer-alt">
    2018 - {{ currentYear }} © DRESDEN FOR FRIENDS
  </footer>
</div>
