import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AllUsersResponse, NewUser, UserResponse } from '../shared/dataModels/userDataModel';
import { apiUrl, Utils } from '../shared/utils/utils';
import { Status } from "../shared/enum/status";
import { Params } from "@angular/router";
import { CustomUrlEncoder } from "../shared/utils/custom-url-encoder";

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private http: HttpClient) {}

  getUser(id: number): Observable<UserResponse> {
      return this.http.get<UserResponse>(apiUrl + 'users/' + id);
  }

  getUsers(requestParameters: Params): Observable<AllUsersResponse> {
    const params = new HttpParams({ encoder: new CustomUrlEncoder })
      .appendAll(Utils.camelCaseToSnakeCase(requestParameters));
    return this.http.get<AllUsersResponse>(apiUrl + 'users', {params});
  }

  getUnlinkedUsers(searchTerm: string): Observable<AllUsersResponse> {
    const params = new HttpParams()
      .set('search_terms', searchTerm)
      .set('status', 0)
      .set('has_customer', 0)
      .set('per_page', 5);

    return this.http.get<AllUsersResponse>(apiUrl + 'users', {params});
  }

  getPermissions(requestParameters: Params): Observable<AllUsersResponse> {
    if (requestParameters.searchTerms !== '') {
      const params = new HttpParams({ encoder: new CustomUrlEncoder })
        .set('search_terms', requestParameters.searchTerms)
        .set('page', requestParameters.page ? requestParameters.page : '');
      return this.http.get<AllUsersResponse>(apiUrl + 'permissions', {params});
    }
    const params = new HttpParams().set('page', requestParameters.page ? requestParameters.page : '');
    return this.http.get<AllUsersResponse>(apiUrl + 'permissions', { params });
  }

  createUser(user: NewUser): Observable<UserResponse> {
    return this.http.post<UserResponse>(apiUrl + 'users', user);
  }

  updateUser(userId: number, status: Status): Observable<UserResponse> {
    return this.http.put<UserResponse>(apiUrl + 'users/' + userId, { status: status });
  }
}
