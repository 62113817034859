<div class="accordion custom-accordion mb-3" id="custom-accordion-three">
  <div class="card mb-0">
    <div class="card-header" id="headingSix">
      <h5 class="m-0">
        <i class="mdi mdi-chevron-down accordion-arrow custom-accordion-title me-3 mt-1"
           [ngClass]="{show: messagesChevron, collapse: !messagesChevron}"></i>
        <i class="mdi mdi-chevron-up accordion-arrow custom-accordion-title me-3 mt-1"
           [ngClass]="{show: !messagesChevron, collapse: messagesChevron}"></i>
        <a class="custom-accordion-title d-block py-1"
           data-bs-toggle="collapse" href="#collapseSix"
           (click)="messagesChevron = !messagesChevron"
           aria-expanded="true" aria-controls="collapseSix">
          Nachrichten
        </a>
      </h5>
    </div>

    <div id="collapseSix"
         class="collapse"
         aria-labelledby="headingSix"
         data-bs-parent="#custom-accordion-three">
      <div *appLoading="!messagesLoaded" class="card-body">
        <div *ngIf="!noMessages" data-simplebar class="table-responsive max-height">
          <table class="table table-striped mb-0">
            <thead class="table-light">
            <tr>
              <th class="table-cell">Erstelldatum</th>
              <th class="table-cell">Versanddatum</th>
              <th class="table-cell">Nachrichtentyp</th>
              <th class="table-cell">Kanal</th>
              <th class="table-cell">Titel</th>
              <th class="table-cell">Status</th>
              <th class="table-cell">Datei (Anhang)</th>
              <th class="table-cell">Aktion</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let message of messages">
              <td class="table-cell">{{ message?.createdAt | date: 'dd.MM.yyyy - HH:mm' }}</td>
              <td class="table-cell">{{ message?.sentAt | date: 'dd.MM.yyyy'}}</td>
              <td class="table-cell">{{ message?.type }}</td>
              <td class="table-cell">{{ message?.channel }}</td>
              <td class="table-cell">{{ message?.subject ?? 'Ohne Titel' }}</td>
              <td class="table-cell">{{ message?.status }}</td>
              <td class="text-center table-cell">
                <a *ngIf="message.previewUrl" class="link-primary cursor-pointer" title="{{ message.subject }}" (click)="onPreview(message.previewUrl)">
                  <i class="mdi mdi-eye-outline mdi-18px"></i>
                </a>
                <div *ngFor="let file of message.files">
                  <a *ngIf="message.channel !== getMessageChannel().EMAIL" target="_blank"
                     class="link-primary cursor-pointer"
                     (click)="onDownload(file.secureFileUrl, file.name)"
                     title="{{ file.name + '.' + file.extension }}">
                    <i class="mdi mdi-download-outline mdi-18px"></i>
                  </a>
                </div>
              </td>
              <td class="table-cell">
                <div *ngIf="!noMessages && message.channel === getMessageChannel().EMAIL" class="dropdown">
                  <button class="btn btn-dark dropdown-toggle" type="button" id="dropdownMenuButton"
                          data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Aktion
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item d-none d-md-flex align-items-center gap-2 w-100"
                       type="button"
                       (click)="onChangeEmailMessageSharing(message.channel, message.id)">
                      Teilen
                    </a>
                    <a class="dropdown-item d-none d-md-flex align-items-center gap-2 w-100"
                       type="button"
                       (click)="onChangeEmailMessage(message.channel, message.id)">
                      Senden
                    </a>
                  </div>
                </div>
                <div
                  *ngIf="!noMessages && (message.channel === getMessageChannel().MAIL || message.channel === getMessageChannel().EMAIL_FILE)"
                  class="dropdown">
                  <button class="btn btn-dark dropdown-toggle" type="button" id="dropdownPostMenuButton"
                          data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Aktion
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownPostMenuButton">
                    <a class="dropdown-item d-none btn-hover d-md-flex align-items-center gap-2 w-100"
                       type="button"
                       (click)="onChangePdfMessage(message.channel, message.id)">
                      Generieren
                    </a>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="noMessages" class="text-center">
          <span>Keine Nachrichten</span>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- M O D A L  B O D Y-->
<button type="button"
        class="d-none sr-only"
        #confirmMessagesModal
        data-bs-toggle="modal"
        data-bs-target="#warning-header-modal">
  Confirm
</button>
<div id="warning-header-modal"
     data-bs-backdrop="static"
     data-bs-keyborad="false"
     class="modal fade"
     tabindex="-1"
     role="dialog"
     aria-labelledby="info-header-modalLabel"
     aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header modal-colored-header bg-info">
        <h4 class="modal-title" id="info-header-modalLabel">{{ modalHeader }}</h4>
        <button type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-hidden="true"></button>
      </div>
      <div class="modal-body modal-body-bold">
        {{ modalText }}
      </div>
      <div class="modal-footer">
        <button type="button"
                class="btn btn-light"
                data-bs-dismiss="modal">
          Abbrechen
        </button>
        <button type="button"
                data-bs-dismiss="modal"
                (click)="onConfirmMessageSendOrGenerate()"
                class="btn btn-info">
          Bestätigen
        </button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div>

<button type="button"
        class="d-none sr-only"
        #confirmSharingModal
        data-bs-toggle="modal"
        data-bs-target="#share-header-modal">
  Confirm
</button>
<app-form-modal
  modalType="share"
  [modalLabel]="modalHeader"
  [saveButtonLabel]="'Bestätigen'"
  [cancelButtonLabel]="'Abbrechen'"
  [showSpinner]="waitingForResponse"
  [closeModal]="closeFormModal"
  [disableConfirmationButton]="email?.invalid"
  saveButtonCssClass="btn-info"
  modalHeaderCssClass="bg-info"
  (emitConfirm)="onConfirmMessageSendOrGenerate()"
>
  <form [formGroup]="emailForm">
    <div class="mb-2 mt-3">
      <label for="email" class="form-label">Email Adresse <span
        class="text-danger">*</span></label>
      <input class="form-control"
             type="email" id="email"
             formControlName="email"
             placeholder="Email">
      <span
        *ngIf="!email?.valid && email?.touched"
        class="error-feedback mb-3">Eine korrekte E-Mail muss angegeben werden!</span>
    </div>
  </form>
</app-form-modal>
