import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { AllVouchersResponse } from "../shared/dataModels/vouchersDataModels";
import { apiUrl, Utils } from "../shared/utils/utils";
import { Params } from "@angular/router";
import { FilterUtils } from "../shared/utils/filter";
import { CustomUrlEncoder } from "../shared/utils/custom-url-encoder";
import { SimpleResponse } from "../shared/dataModels/sharedDataModels";
import { Status } from "../shared/enum/status";
import { CardAccessories } from "../shared/dataModels/cardDataModel";

@Injectable({
  providedIn: 'root'
})
export class VouchersService {
  constructor(private http: HttpClient) { }

  getVouchers(requestParameters: Params): Observable<AllVouchersResponse> {
    let queryParams = FilterUtils.trimRequestParams(requestParameters);
    const params = new HttpParams({ encoder: new CustomUrlEncoder })
      .appendAll(Utils.camelCaseToSnakeCase(queryParams));
    return this.http.get<AllVouchersResponse>(apiUrl + 'vouchers', { params });
  }

  getVoucherAmounts(regionId: number): Observable<CardAccessories> {
    const params = new HttpParams().set('region_id', regionId);
    return this.http.get<CardAccessories>(apiUrl + 'voucherAmounts', { params });
  }

  changeVoucherEndDate(id: number, endDate: string): Observable<SimpleResponse> {
    return this.http.put<SimpleResponse>(apiUrl + `vouchers/${id}`, { end_date: endDate });
  }

  changeVoucherStatus(id: number, status: Status): Observable<SimpleResponse> {
    return this.http.put<SimpleResponse>(apiUrl + 'vouchers/' + id + '/status', { status });
  }
}
