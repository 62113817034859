<app-breadcrumbs class="col-md-6" (navigateTo)="navigateTo($event)"></app-breadcrumbs>
<div id="page" class="dashboard-layout" #layout (mouseup)="onEndDrag()" (mousemove)="onDrag($event)">
  <div id="firstColumn" dragula="accordions" [(dragulaModel)]="firstColumnComponents">
    <ng-container *ngFor="let component of firstColumnComponents">
      <app-dashboard-item [loadComponent]="component.component"
                          [id]="component.id">
      </app-dashboard-item>
    </ng-container>
    <div *ngIf="firstColumnComponents.length === 0" class="empty-dragula-container"></div>
  </div>
  <div class="column-divider" (mousedown)="onStartDrag()"></div>
  <div id="secondColumn" dragula="accordions" [(dragulaModel)]="secondColumnComponents">
    <ng-container *ngFor="let component of secondColumnComponents">
      <app-dashboard-item [loadComponent]="component.component"
                          [id]="component.id">
      </app-dashboard-item>
    </ng-container>
    <div *ngIf="secondColumnComponents.length === 0" class="empty-dragula-container"></div>
  </div>
</div>
