import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmMailComponent } from "./confirm-mail/confirm-mail.component";
import { LockScreenComponent } from "./lock-screen/lock-screen.component";
import { LoginComponent } from "./login/login.component";
import { RegistrationComponent } from "./registration/registration.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { SharedModule } from "../shared.module";
import { ReactiveFormsModule } from "@angular/forms";
import { AuthenticationRoutingModule } from "./authentication-routing.module";
import { ResetPasswordComponent } from './reset-password/reset-password.component';



@NgModule({
  declarations: [
    ConfirmMailComponent,
    LockScreenComponent,
    LoginComponent,
    RegistrationComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent
  ],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    SharedModule,
    ReactiveFormsModule
  ]
})
export class AuthenticationModule { }
