import {Component, OnDestroy, OnInit} from '@angular/core';
import { PaginationData } from "../../../shared/dataModels/paginationDataModel";
import { OrdersService } from "../../../services/orders.service";
import { Embossment, EmbossmentsResponse } from "../../../shared/dataModels/ordersDataModel";
import { Params } from "@angular/router";
import { Utils } from "../../../shared/utils/utils";
import { takeUntil } from "rxjs/operators";
import { SubscriptionComponent } from "../../../shared/components/subscription/subscription.component";
import { EmbossmentType, ResponseCode } from "../../../shared/enum/shared-enums";
import { AlertifyService } from "../../../shared/services/alertify-service.service";

@Component({
  selector: 'app-order-embossments',
  templateUrl: './order-embossments.component.html',
  styleUrls: ['./order-embossments.component.scss']
})
export class OrderEmbossmentsComponent extends SubscriptionComponent implements OnInit, OnDestroy {
  embossmentData: PaginationData;
  embossments: Embossment[];
  requestParams: Params = { perPage: 10 };
  tableHeaders = ['ID', 'Erstellt', 'Ersteller', '# Bestellungen', 'Dateien'];
  responseSuccessful = false;
  error = false;

  constructor(private ordersService: OrdersService, private alertifyService: AlertifyService) { super() }

  ngOnInit(): void {
    this.getEmbossments();
    this.ordersService.newEmbossment$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((value) => {
          if (value) {
            this.getEmbossments();
          }
        });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private getEmbossments(): void {
    this.requestParams.orderDirection = 'desc';
    this.requestParams.orderBy = 'created_at';
    this.ordersService.getEmbossments(this.requestParams, EmbossmentType.ORDERS)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: EmbossmentsResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            this.embossmentData = response.body;
            this.embossments = Utils.snakeCaseToCamelCase(response.body?.pagination.data);
          } else {
            this.alertifyService.warning(response.message);
          }
          this.responseSuccessful = true;
        }, () => this.error = true);
  }

  onLoadPaginationData(pageNumber: string) {
    this.responseSuccessful = false;
    this.requestParams = { ...this.requestParams, page: pageNumber };
    this.getEmbossments();
  }

  onRefreshList(): void {
    this.responseSuccessful = false;
    this.embossments = [];
    this.getEmbossments();
  }
}
