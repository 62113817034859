export enum ProductType {
  HAPTIC = 'physical',
  DIGITAL = 'digital',
  DIGITAL_EXT = 'digital_ext',
  HAPTIC_EXT = 'physical_ext'
}

export enum ProductVariant {
  STANDARD = 'Auslaufend',
  GIFT = 'Geschenk',
  SUBSCRIPTION = 'Abonnement',
  FIRST_CARD = 'Erstkarte',
  FOLLOW_CARD = 'Folgekarte',
  OTHER = 'Sonstiges'
}

export enum CurrencyCode {
  EUR = '€',
  USD = '$'
}

export enum CurrencyCodeLetters {
  EUR = 'EUR',
  USD = 'USD'
}

export enum ProductStatusType {
  PRIMARY = 'primary',
  VISIBILITY = 'visibility'
}

export enum TimeUnits {
  DAYS = 'Tage',
  MONTHS = 'Monate'
}

export enum IsOnlyDigital {
  YES = '1',
  NO = '0'
}
