import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AllRolesResponse, RolePayload, RoleResponse } from '../shared/dataModels/roleDataModel';
import { apiUrl, Utils } from '../shared/utils/utils';
import { CustomUrlEncoder } from "../shared/utils/custom-url-encoder";

@Injectable({
              providedIn: 'root'
            })
export class RolesService {
  constructor(private http: HttpClient) {}

  getRole(roleId: number): Observable<RoleResponse> {
    return this.http.get<RoleResponse>(apiUrl + 'roles/' + roleId);
  }

  getUserRoles(user_id: number, region_id: number): Observable<AllRolesResponse> {
    const params = new HttpParams()
      .set('user_id', user_id)
      .set('region_id', region_id);

    return this.http.get<AllRolesResponse>(apiUrl + 'userRoles', { params });
  }

  getAllRoles(searchTerms: string): Observable<AllRolesResponse> {
    if (searchTerms !== '') {
      const params = new HttpParams({ encoder: new CustomUrlEncoder }).set('search_terms', searchTerms);
      return this.http.get<AllRolesResponse>(apiUrl + 'rolesOverview', { params });
    }
    return this.http.get<AllRolesResponse>(apiUrl + 'rolesOverview');
  }

  createRole(role: RolePayload): Observable<RoleResponse> {
    return this.http.post<RoleResponse>(apiUrl + 'roles', { ...Utils.camelCaseToSnakeCase(role) });
  }

  updateRole(role: RolePayload, roleId: number): Observable<RoleResponse> {
    return this.http.put<RoleResponse>(apiUrl + 'roles/' + roleId,
                                       { ...Utils.camelCaseToSnakeCase(role) });
  }

  assignRoleToUser(userId: number, roleId: number, regionId: number): Observable<RoleResponse> {
    let payload = Utils.camelCaseToSnakeCase({ userId, roleId, regionId });
    return this.http.post<RoleResponse>(apiUrl + 'assignUserRole', payload);
  }

  withdrawRoleFromUser(userId: number, roleId: number, regionId: number): Observable<RoleResponse> {
    let payload = Utils.camelCaseToSnakeCase({ userId, roleId, regionId });
    return this.http.post<RoleResponse>(apiUrl + 'withdrawUserRole', payload);
  }

  deleteRole(roleId: number): Observable<RoleResponse> {
    return this.http.delete<RoleResponse>(apiUrl + 'roles/' + roleId);
  }
}
