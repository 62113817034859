export enum PaymentStatus {
  PENDING = 'Ausstehend',
  CLOSED = 'Abgeschlossen',
  FAILED = 'Fehler',
  UNDERPAID = 'Unterzahlung',
  OVERPAID = 'Überzahlung'
}

export enum OrderStatus {
  UNCOMPLETED = 'Abgebrochen',
  REVOKED = 'Widerrufen',
  CANCELLED = 'Storniert',
  PENDING = 'Ausstehend',
  COMPLETED = 'Abgeschlossen',
  BEING_PROCESSED = 'In Verarbeitung',
  DUNNING = 'Mahnlauf',
  DUNNING_1 = 'Mahnstufe 1',
  DUNNING_2 = 'Mahnstufe 2',
  DUNNING_3 = 'Mahnstufe 3',
  COLLECTION_PROPOSAL = 'Inkasso Vorschlag',
  COLLECTION_HANDOVER = 'Inkasso Übergabe',
  COLLECTION_FILE = 'Inkasso Akte'
}

export enum DisplayOrderType {
  REGULAR = 'Neubestellung',
  EXTENSION = 'Verlängerung',
  COMPANY = 'Firmenbestellung',
  REPLACEMENT = 'Ersatzkarte',

  PLASTIC_CARD = 'Plastikkarte'
}

export enum OrderType {
  REGULAR = 'REGULAR',
  EXTENSION = 'EXTENSION',
  COMPANY = 'COMPANY',
  REPLACEMENT = 'REPLACEMENT',

  PLASTIC_CARD = 'PLASTIC_CARD'
}

export enum CreateOrderTabs {
  DETAILS = 'details',
  OVERVIEW = 'overview'
}

export enum CheckoutSource {
  BACKOFFICE = 'Backoffice',
  WEBSHOP = 'Webshop'
}

export enum OrderSource {
  BACKEND = 'Backend',
  STORE = 'Store',
  TERMINAL = 'Terminal',
  WEBSHOP = 'Webshop',
  APP = 'App',
  SHOP_IN_SHOP = 'Shop-In-Shop'
}

export enum IbanAvailability {
  EXISTS,
  SET_NEW_IBAN,
  NOT_REQUIRED
}

export enum OrderActions {
  REVOKE,
  CANCEL
}

export enum TroubleShootingTypes {
  UNDERPAID = 'Unterzahlung',
  OVERPAID = 'Überzahlung',
  POST_RETURN = 'Rückläufervermerk',
  STUCK_IN_PROCESS = 'Fehler Prozessübergang',
  COMPANY_ORDER_WITHOUT_EMAIL = 'Firmenbestellung ohne E-Mail',
  PAID_WITHOUT_INVOICE = 'Abgeschlossene Bestellungen ohne Rechnung',
}
