<div class="modal bg-dark-lighten"
     [ngClass]="{'show': showModal}"
     id="staticBackdrop"
     data-bs-backdrop="static"
     data-bs-keyboard="false"
     tabindex="-1"
     aria-labelledby="staticBackdropLabel"
     aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">In welcher Stadt wollen Sie sich anmelden</h5>
      </div>
      <div class="modal-body">
        <select class="form-select"
                #selectedRegionName
                (change)="onSelectDefaultRegion(selectedRegionName.value)"
                aria-label="Default select">
          <option selected disabled>Bitte eine Stadt auswählen</option>
          <option *ngFor="let region of user.loginRegions"
                  [value]="region.name">
            {{ region?.name }}
          </option>
        </select>

      </div>
      <div class="modal-footer">
        <button *ngIf="!router.url.includes('login')"
                type="button"
                (click)="onClose()"
                class="btn btn-secondary me-3">
          Abbrechen
        </button>
        <button type="button"
                class="save-region"
                [class.active]="selectedRegion?.id"
                data-bs-dismiss="modal"
                (click)="onSaveSelectedDefaultRegion()">Auswählen
        </button>
      </div>
    </div>
  </div>
</div>

