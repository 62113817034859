<div class="container-xxl">
  <div class="row justify-content-center">
    <app-breadcrumbs [hidden]="error" (navigateTo)="navigateTo($event)"></app-breadcrumbs>
    <ng-container *ngIf="!error">
      <h4 class="mb-3">Einstellungen</h4>
      <form *ngIf="settingsLoaded" [formGroup]="notificationsForm">
        <div class="row col-xl-8 mb-3">
          <div class="d-md-flex justify-content-between justify-content-lg-start align-items-center">
            <label class="col-md-7 col-xl-5 fw-bold mb-1">
              Auslaufbenachrichtigung (in Tagen):
            </label>
            <div class="d-md-flex justify-content-lg-start">
              <div class="col-lg-5 me-3">
                <app-touchspin [presetTouchspin]="expirationNotificationThreshold?.value"
                               (numberOutput)="onAssignNumericValues($event, 'expirationNotificationThreshold')">
                </app-touchspin>
              </div>
              <button type="submit"
                      class="btn app-primary-button mt-2 mt-md-0"
                      [disabled]="!expirationNotificationThreshold?.valid || expirationNotificationThreshold?.pristine"
                      (click)="onChangeExpirationNotification()">
                 <span *ngIf="waitingForResponse && targetedSetting === NotificationSettings.EXPIRATION"
                       class="spinner-border spinner-border-sm me-1" role="status"
                       aria-hidden="true"></span>
                <span>Speichern</span>
              </button>
            </div>
          </div>
        </div>
        <div class="row col-xl-8 mb-3">
          <div class="d-md-flex justify-content-between justify-content-lg-start align-items-center">
            <label class="col-md-7 col-xl-5 fw-bold mb-1">
              Verlängerungbenachrichtigung (in Tagen):
            </label>
            <div class="d-md-flex justify-content-lg-start">
              <div class="col-lg-5 me-3">
                <app-touchspin [presetTouchspin]="extensionNotificationThreshold?.value"
                               (numberOutput)="onAssignNumericValues($event, 'extensionNotificationThreshold')">
                </app-touchspin>
              </div>
              <button type="submit"
                      class="btn app-primary-button mt-2 mt-md-0"
                      [disabled]="!extensionNotificationThreshold?.valid || extensionNotificationThreshold?.pristine"
                      (click)="onChangeExtensionNotification()">
                 <span *ngIf="waitingForResponse && targetedSetting === NotificationSettings.EXTENSION"
                       class="spinner-border spinner-border-sm me-1" role="status"
                       aria-hidden="true"></span>
                <span>Speichern</span>
              </button>
            </div>
          </div>
        </div>
        <div class="row col-xl-8 mb-3">
          <div class="d-md-flex justify-content-between justify-content-lg-start align-items-center">
            <label class="col-md-7 col-xl-5 fw-bold mb-1">
              Mehrwertsteuer (in %):
            </label>
            <div class="d-md-flex justify-content-lg-start">
              <div class="col-lg-5 me-3">
                <app-touchspin [presetTouchspin]="defaultVatPercentageThreshold?.value"
                               (numberOutput)="onAssignNumericValues($event, 'defaultVatPercentageThreshold')">
                </app-touchspin>
              </div>
              <button type="submit"
                      class="btn app-primary-button mt-2 mt-md-0"
                      [disabled]="!defaultVatPercentageThreshold?.valid || defaultVatPercentageThreshold?.pristine"
                      (click)="onChangeDefaultVatPercentage()">
                 <span *ngIf="waitingForResponse && targetedSetting === NotificationSettings.DEFAULT_VAT_PERCENTAGE"
                       class="spinner-border spinner-border-sm me-1" role="status"
                       aria-hidden="true"></span>
                <span>Speichern</span>
              </button>
            </div>
          </div>
        </div>
        <div class="row col-xl-8 mb-3">
          <div class="d-md-flex justify-content-between justify-content-lg-start align-items-center">
            <label class="col-md-7 col-xl-5 fw-bold mb-1">
              Dritte Mahnung (in Tagen):
            </label>
            <div class="d-md-flex justify-content-lg-start">
              <div class="col-lg-5 me-3">
                <app-touchspin [presetTouchspin]="thirdDunningThreshold?.value"
                               (numberOutput)="onAssignNumericValues($event, 'thirdDunningThreshold')">
                </app-touchspin>
              </div>
              <button type="submit"
                      class="btn app-primary-button mt-2 mt-md-0"
                      [disabled]="!thirdDunningThreshold?.valid || thirdDunningThreshold?.pristine"
                      (click)="onChangeThirdDunningNotification()">
                 <span *ngIf="waitingForResponse && targetedSetting === NotificationSettings.THIRD_DUNNING"
                       class="spinner-border spinner-border-sm me-1" role="status"
                       aria-hidden="true"></span>
                <span>Speichern</span>
              </button>
            </div>
          </div>
        </div>
        <div class="row col-xl-8 mb-3">
          <div class="d-md-flex justify-content-between justify-content-lg-start align-items-center">
            <label class="col-md-7 col-xl-5 fw-bold mb-1">
              Ausführung Karte-Verlängerung (in Tagen):
            </label>
            <div class="d-md-flex justify-content-lg-start">
              <div class="col-lg-5 me-3">
                <app-touchspin [presetTouchspin]="extensionExecutionThreshold?.value"
                               (numberOutput)="onAssignNumericValues($event, 'extensionExecutionThreshold')">
                </app-touchspin>
              </div>
              <button type="submit"
                      class="btn app-primary-button mt-2 mt-md-0"
                      [disabled]="!extensionExecutionThreshold?.valid || extensionExecutionThreshold?.pristine"
                      (click)="onChangeExtensionExecutionNotification()">
                 <span *ngIf="waitingForResponse && targetedSetting === NotificationSettings.EXTENSION_EXECUTION"
                       class="spinner-border spinner-border-sm me-1" role="status"
                       aria-hidden="true"></span>
                <span>Speichern</span>
              </button>
            </div>
          </div>
        </div>
        <div class="row col-xl-8 mb-3">
          <div class="d-md-flex justify-content-between justify-content-lg-start align-items-center">
            <label class="col-md-7 col-xl-5 fw-bold mb-1">
              Abonnement-Aufhebung (in Tagen):
            </label>
            <div class="d-md-flex justify-content-lg-start">
              <div class="col-lg-5 me-3">
                <app-touchspin [presetTouchspin]="subscriptionCancellationThreshold?.value"
                               (numberOutput)="onAssignNumericValues($event, 'subscriptionCancellationThreshold')">
                </app-touchspin>
              </div>
              <button type="submit"
                      class="btn app-primary-button mt-2 mt-md-0"
                      [disabled]="!subscriptionCancellationThreshold?.valid || subscriptionCancellationThreshold?.pristine"
                      (click)="onChangeSubscriptionCancellationThreshold()">
                 <span *ngIf="waitingForResponse && targetedSetting === NotificationSettings.SUB_CANCELLATION"
                       class="spinner-border spinner-border-sm me-1" role="status"
                       aria-hidden="true"></span>
                <span>Speichern</span>
              </button>
            </div>
          </div>
        </div>
        <div class="row col-xl-8 mb-3">
          <div class="d-md-flex justify-content-between justify-content-lg-start align-items-center">
            <label class="col-md-7 col-xl-5 fw-bold mb-1">
              Aufpreis Haptischer Gutschein:
            </label>
            <div class="d-md-flex justify-content-lg-start">
              <div class="col-lg-5 me-3">
                <app-touchspin [presetTouchspin]="physicalVoucherFee?.value"
                               (numberOutput)="onAssignNumericValues($event, 'physicalVoucherFee')">
                </app-touchspin>
              </div>
              <button type="submit"
                      class="btn app-primary-button mt-2 mt-md-0"
                      [disabled]="!physicalVoucherFee?.valid || physicalVoucherFee?.pristine"
                      (click)="onChangePhysicalVoucherFee()">
                 <span *ngIf="waitingForResponse && targetedSetting === NotificationSettings.SUB_CANCELLATION"
                       class="spinner-border spinner-border-sm me-1" role="status"
                       aria-hidden="true"></span>
                <span>Speichern</span>
              </button>
            </div>
          </div>
        </div>
        <hr>
        <h4 class="mb-3">App Links</h4>
        <div class="row col-xl-8 mb-3">
          <div class="col-md-8 mb-2 mb-md-0">
            <label for="google" class="col-md-7 col-xl-5 fw-bold mb-1">
              Google Play Link:
            </label>
            <div class="col-md-12 d-md-flex justify-content-lg-start">
              <div class="col-md-9 me-3">
                <input type="text"
                       class="form-control"
                       id="google"
                       formControlName="googlePlayLink">
                <span *ngIf="!googlePlayLink?.valid && googlePlayLink?.touched" class="error-feedback">
                      Der App-Link ist erforderlich
                  </span>
              </div>
              <button type="submit"
                      class="btn app-primary-button mt-2 mt-md-0 d-flex"
                      [disabled]="!googlePlayLink?.valid || googlePlayLink?.pristine"
                      (click)="onChangeGooglePlayLink()">
                 <span *ngIf="waitingForResponse && targetedSetting === NotificationSettings.GOOGLE_PLAY_LINK"
                       class="spinner-border spinner-border-sm me-1" role="status"
                       aria-hidden="true"></span>
                <span>Speichern</span>
              </button>
            </div>
          </div>
        </div>
        <div class="row col-xl-8 mb-3">
          <div class="col-md-8 mb-2 mb-md-0">
            <label for="apple" class="col-md-7 col-xl-5 fw-bold mb-1">
              App Store Link:
            </label>
            <div class="col-md-12 d-md-flex justify-content-lg-start">
              <div class="col-md-9 me-3">
                <input type="text"
                       class="form-control"
                       id="apple"
                       formControlName="appleStoreLink">
                <span *ngIf="!appleStoreLink?.valid && appleStoreLink?.touched" class="error-feedback">
                      Der App-Link ist erforderlich
                  </span>
              </div>
              <button type="submit"
                      class="btn app-primary-button mt-2 mt-md-0 d-flex"
                      [disabled]="!appleStoreLink?.valid || appleStoreLink?.pristine"
                      (click)="onChangeAppleStoreLink()">
                 <span *ngIf="waitingForResponse && targetedSetting === NotificationSettings.APPLE_STORE_LINK"
                       class="spinner-border spinner-border-sm me-1" role="status"
                       aria-hidden="true"></span>
                <span>Speichern</span>
              </button>
            </div>
          </div>
        </div>
        <hr>
        <h4 class="mb-3">Email für Benachrichtigungen</h4>
        <div class="row col-xl-8 mb-3">
          <div class="col-md-8 mb-2 mb-md-0">
            <label for="email" class="col-md-7 col-xl-5 fw-bold mb-1">
              Email:
            </label>
            <div class="col-md-12 d-md-flex justify-content-lg-start">
              <div class="col-md-9 me-3">
                <input type="text"
                       class="form-control"
                       id="email"
                       formControlName="emailForNotifications">
                <span *ngIf="!emailForNotifications?.valid && emailForNotifications?.touched" class="error-feedback">
                      Die Email-Adresse ist erforderlich
                  </span>
              </div>
              <button type="submit"
                      class="btn app-primary-button mt-2 mt-md-0 d-flex"
                      [disabled]="!emailForNotifications?.valid || emailForNotifications?.pristine"
                      (click)="onChangeEmailForNotifications()">
                 <span *ngIf="waitingForResponse && targetedSetting === NotificationSettings.EMAIL_FOR_NOTIFICATIONS"
                       class="spinner-border spinner-border-sm me-1" role="status"
                       aria-hidden="true"></span>
                <span>Speichern</span>
              </button>
            </div>
          </div>
        </div>
      </form>
      <span *ngIf="!settingsLoaded"
            class="d-block m-auto spinner-border text-dark-purple mb-3"
            role="status"
            aria-hidden="true"></span>
    </ng-container>
    <hr>
    <app-web-banner></app-web-banner>
    <hr>
    <app-blocking-time-settings></app-blocking-time-settings>
  </div>
</div>
<!-- M O D A L  B O D Y -->
<button type="button"
        class="d-none sr-only"
        #confirmModal
        data-bs-toggle="modal"
        data-bs-target="#info-header-modal">
  Confirm
</button>
<app-modal
  data-bs-target="modal"
  [modalLabel]="modalHeader"
  [modalType]="'info'"
  [modalContent]="modalText"
  saveButtonLabel="Bestätigen"
  cancelButtonLabel="Abbrechen"
  [allowClickElsewhere]="false"
  saveButtonCssClass="btn-warning"
  modalHeaderCssClass="bg-warning"
  (emitConfirm)="onConfirmSettingChange()"
  (emitCancel)="onCancel()"
  (emitDismiss)="onCancel()"
></app-modal>
