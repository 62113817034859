import {Component, OnDestroy, OnInit} from '@angular/core';
import { PaginationData } from "../../shared/dataModels/paginationDataModel";
import { Order } from "../../shared/dataModels/ordersDataModel";
import { Params } from "@angular/router";
import { Utils } from "../../shared/utils/utils";
import { OrderStatus, PaymentStatus } from "../../shared/enum/orders";
import { AppGlobalService } from "../../shared/services/app-global.service";
import { SubscriptionComponent } from "../../shared/components/subscription/subscription.component";
import { Region } from "../../shared/dataModels/regionDataModels";
import { filter, takeUntil, tap } from "rxjs/operators";
import { OrdersService } from "../../services/orders.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { LocalStorageKeys, ResponseCode } from "../../shared/enum/shared-enums";
import { AlertifyService } from "../../shared/services/alertify-service.service";
import { combineLatest } from "rxjs";

@Component({
             selector: 'app-encashments',
             templateUrl: './encashments.component.html',
             styleUrls: ['./encashments.component.scss']
           })
export class EncashmentsComponent extends SubscriptionComponent implements OnInit, OnDestroy {
  encashmentFilterForm: FormGroup;
  encashmentsData: PaginationData;
  encashments: Order[];
  regions: Region[];
  requestParams: Params = { orderStatus: OrderStatus.COLLECTION_HANDOVER };
  PaymentStatus = PaymentStatus;
  responseSuccessful = false;
  perPage = 5;
  chevron = true;
  error = false;
  regionId: number;

  constructor(private globalService: AppGlobalService,
              private ordersService: OrdersService,
              private alertifyService: AlertifyService,
              private formBuilder: FormBuilder) { super() }

  ngOnInit(): void {
    this.regionId = JSON.parse(Utils.getItemFromLocalStorage(LocalStorageKeys.REGION)).id;
    this.requestParams = {
      orderStatus: OrderStatus.COLLECTION_HANDOVER,
      orderDirection: 'desc',
      orderBy: 'created_at',
      perPage: this.perPage,
      regionId: this.regionId
    }
    this.ordersService.newEncashment$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((value) => {
          if (value) {
            this.getData();
          }
        });
    this.initializeEncashmentFilterForm();
    this.getData();
    this.watchRegion();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private getData(): void {
    combineLatest([this.ordersService.getOrders(this.requestParams), this.globalService.regions$])
      .pipe(
        tap(([encashmentsResponse]) => {
          if (encashmentsResponse.code === ResponseCode.FAILED) {
            this.alertifyService.warning(encashmentsResponse.message);
          }
          this.responseSuccessful = true;
        }),
        filter(([, regionsResponse]) => Object.keys(regionsResponse).length > 0),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(([encashmentsResponse, regionsResponse]) => {
        if (encashmentsResponse.code === ResponseCode.SUCCESS) {
          this.encashmentsData = encashmentsResponse.body as PaginationData;
          if ('pagination' in encashmentsResponse.body) {
            this.encashments = Utils.snakeCaseToCamelCase(encashmentsResponse.body?.pagination.data);
          } else {
            this.encashments = Utils.snakeCaseToCamelCase(encashmentsResponse.body?.data);
          }
          this.regions = Utils.snakeCaseToCamelCase(regionsResponse.body?.pagination.data);
          this.encashments = Utils.assignRegionName(this.encashments, this.regions);
        }
      }, () => this.error = true);
  }

  private initializeEncashmentFilterForm() {
    this.encashmentFilterForm = this.formBuilder.group({
                                                         region: [this.regionId]
                                                       });
  }

  loadPaginatedData(pageNumber: string): void {
    this.responseSuccessful = false;
    this.requestParams = { ...this.requestParams, page: pageNumber };
    this.getData();
  }

  private watchRegion(): void {
    this.encashmentFilterForm?.valueChanges.subscribe((value) => {
      this.requestParams.regionId = value.region;
      this.onRefreshList();
    });
  }

  onRefreshList() {
    this.responseSuccessful = false;
    this.getData();
  }
}
