import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbData } from "../shared/components/breadcrumbs/breadcrumbs.component";
import { Router } from "@angular/router";
import { DragulaService } from "ng2-dragula";
import { Utils } from "../shared/utils/utils";
import { LocalStorageKeys } from "../shared/enum/shared-enums";
import { DashboardItem } from "../shared/dataModels/sharedDataModels";
import { takeUntil } from "rxjs/operators";
import { SubscriptionComponent } from "../shared/components/subscription/subscription.component";
import {
  firstColumnComponents,
  referenceComponents,
  secondColumnComponents
} from "../shared/dataModels/dashboardDataModels";

@Component({
             selector: 'app-home-dashboard',
             templateUrl: './home-dashboard.component.html',
             styleUrls: ['./home-dashboard.component.scss']
           })
export class HomeDashboardComponent extends SubscriptionComponent implements OnInit {
  @ViewChild('layout') layout: ElementRef;
  firstColumnComponents = firstColumnComponents;
  secondColumnComponents = secondColumnComponents;
  isDragging = false;

  constructor(private router: Router, private dragulaService: DragulaService) { super() }

  ngOnInit() {
    this.sortItems();
    this.watchDragula();
  }

  private sortItems() {
    let firstColumn = Utils.getItemFromLocalStorage(LocalStorageKeys.FIRST_COLUMN_ITEMS);
    let secondColumn = Utils.getItemFromLocalStorage(LocalStorageKeys.SECOND_COLUMN_ITEMS);
    if (firstColumn) {
      firstColumn = JSON.parse(firstColumn);
      this.assignComponentsToColumns(firstColumn, 'firstColumnComponents');
    }
    if (secondColumn) {
      secondColumn = JSON.parse(secondColumn);
      this.assignComponentsToColumns(secondColumn, 'secondColumnComponents');
    }
  }

  private assignComponentsToColumns(columnValues: { id: number }[], columnComponents: string) {
    const columnIds = columnValues.map((element: { id: number }) => element.id);
    // @ts-ignore
    this[columnComponents] = referenceComponents.filter((element: DashboardItem) => {
      return columnIds.includes(element.id);
    });
    // @ts-ignore
    this[columnComponents].sort((a, b) => {
      return columnIds.indexOf(a.id) - columnIds.indexOf(b.id)
    });
  }

  private watchDragula() {
    this.dragulaService.dropModel('accordions')
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(({ source, target, sourceModel, targetModel }) => {
          if (source.id === 'firstColumn') {
            this.saveColumns(source, target, LocalStorageKeys.FIRST_COLUMN_ITEMS, sourceModel, targetModel);
          } else {
            this.saveColumns(source, target, LocalStorageKeys.SECOND_COLUMN_ITEMS, targetModel, sourceModel);
          }
          let layout = this.layout.nativeElement as HTMLElement;
          layout.classList.remove('cursor-grab');
        });

    this.dragulaService.drag('accordions')
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(({ el }) => {
          let layout = this.layout.nativeElement as HTMLElement;
          layout.classList.add('cursor-grab');
        });
  }

  private saveColumns(source: Element,
                      target: Element,
                      column: LocalStorageKeys,
                      firstModel: any[],
                      secondModel: any[]) {
    if (source.id !== target.id) {
      // an item was moved between columns
      Utils.setItemInLocalStorage(LocalStorageKeys.FIRST_COLUMN_ITEMS, JSON.stringify(firstModel));
      Utils.setItemInLocalStorage(LocalStorageKeys.SECOND_COLUMN_ITEMS, JSON.stringify(secondModel));
    } else {
      // an item was moved inside a column
      Utils.setItemInLocalStorage(column, JSON.stringify(secondModel));
    }
  }

  onStartDrag(): void {
    this.isDragging = true;
    this.setCursor('ew-resize');
  }

  onDrag(event: any): void {
    if (this.isDragging) {
      let page = document.getElementById('page');
      let firstColWidth = event.clientX - 284;
      let secondColWidth;
      if (page) {
        secondColWidth = page.clientWidth - event.clientX + 284;
      }
      let dragbarWidth = 6;
      let columns = [firstColWidth, dragbarWidth, secondColWidth];
      // @ts-ignore
      let newColumnDef = columns.map(column => column.toString() + "px").join(" ");
      if (page) {
        page.style.gridTemplateColumns = newColumnDef;
      }
      event.preventDefault();
    }
  }

  onEndDrag(): void {
    this.isDragging = false;
    this.setCursor('auto');
  }

  private setCursor(cursor: string): void {
    let page = document.getElementById('page');
    if (page) {
      page.style.cursor = cursor;
    }
  }

  navigateTo(breadcrumb: BreadcrumbData) {
    this.router.navigate(['/' + breadcrumb.routerLink]);
  }
}
