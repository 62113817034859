import { Injectable } from '@angular/core';
import * as alertify  from 'alertifyjs';
import { HttpErrorResponse } from "@angular/common/http";
import { Utils } from "../utils/utils";
import { Router } from "@angular/router";
import { ErrorStatus } from "../enum/error-status";
import { AppGlobalService } from "./app-global.service";

@Injectable({
  providedIn: 'root'
})
export class AlertifyService {
  constructor(private router: Router, private globalService: AppGlobalService) {}

  success(message: string) {
    alertify.success(message, 10)
  }

  warning(message: string) {
    alertify.warning(message, 10)
  }

  error(message: string) {
    alertify.error(message, 10)
  }

  handleError(error: HttpErrorResponse) {
    alertify.error(Utils.handleError(error, this.router), 10);
  }
}

