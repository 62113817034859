import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SalesStatisticFilters, StringArrayResponse } from "../../../dataModels/ordersDataModel";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppGlobalService } from "../../../services/app-global.service";
import { dateInputPattern } from "../../filter-container/filter-container.component";
import { OrdersService } from "../../../../services/orders.service";
import { SubscriptionComponent } from "../../subscription/subscription.component";
import { takeUntil } from "rxjs/operators";
import { FilterUtils } from "../../../utils/filter";
import { ProductsService } from "../../../../services/products.service";
import { Status } from "../../../enum/status";
import { AllProductsResponse, Product } from "../../../dataModels/productsDataModel";
import { Utils } from "../../../utils/utils";
import { LocalStorageKeys, ResponseCode } from "../../../enum/shared-enums";
import { ProductVariant } from "../../../enum/products";
import { SalesReportFilters } from "../../../dataModels/reportsDataModel";
import { AllRegionsResponse, Region } from "../../../dataModels/regionDataModels";
import { VouchersService } from "../../../../services/vouchers.service";
import { CardAccessories } from "../../../dataModels/cardDataModel";

@Component({
             selector: 'app-sales-statistic-filters',
             templateUrl: './sales-statistic-filters.component.html',
             styleUrls: ['./sales-statistic-filters.component.scss']
           })
export class SalesStatisticFiltersComponent extends SubscriptionComponent implements OnInit {
  @Output() filterValues = new EventEmitter<SalesReportFilters>();
  salesStatisticsFilterForm: FormGroup;
  CardType = ProductVariant;
  paymentStatusFilters: string[];
  paymentMethodFilters: string[];
  products: Product[] = [];
  voucherAmounts: string[];
  regions: Region[];
  regionId: number;
  statusFilters: string[];
  stateFilters: string[];
  SalesStatisticFilters = SalesStatisticFilters;

  constructor(private formBuilder: FormBuilder,
              private ordersService: OrdersService,
              private productsService: ProductsService,
              private vouchersService: VouchersService,
              private globalService: AppGlobalService) { super() }

  ngOnInit(): void {
    this.regionId = JSON.parse(Utils.getItemFromLocalStorage(LocalStorageKeys.REGION)).id;
    this.getPaymentStatusData();
    this.getPaymentMethodData();
    this.getProductsData();
    this.getStatusesData();
    this.getStatesData();
    this.getVoucherAmount(this.regionId);
    this.getRegions();
    this.onInitializeOrderFilterForm();
    this.watchRegion();
    this.setDurationAndTimeUnit();
  }

  onInitializeOrderFilterForm() {
    this.salesStatisticsFilterForm = this.formBuilder.group({
                                                              regionId: [this.regionId],
                                                              selectedProduct: [null],
                                                              paymentStatus: [null],
                                                              paymentMethod: [null],
                                                              orderType: [null],
                                                              productType: [null],
                                                              voucherAmount: [null],
                                                              orderStatus: [null],
                                                              orderState: [null],
                                                              duration: [null],
                                                              timeUnit: [null],
                                                              orderCreatedFrom: ['', [Validators.pattern(dateInputPattern)]],
                                                              orderCreatedTo: ['', [Validators.pattern(dateInputPattern)]]
                                                            });
  }

  private getPaymentStatusData(): void {
    this.ordersService.getPaymentStatuses()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: StringArrayResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            this.paymentStatusFilters = response.body.data;
          }
        });
  }

  private getPaymentMethodData(): void {
    this.ordersService.getPaymentMethods()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: StringArrayResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            this.paymentMethodFilters = response.body.data;
          }
        });
  }

  private getProductsData(): void {
    this.productsService.getProducts({ status: Status.ACTIVE })
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: AllProductsResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            this.products = Utils.transformProductNames(response.body.pagination.data);
          }
        });
  }

  private getStatusesData(): void {
    this.ordersService.getStatuses()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: StringArrayResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            this.statusFilters = response.body.data;
            this.statusFilters = this.statusFilters.filter(status => status !== 'Widerrufen');
            this.statusFilters = this.statusFilters.filter(status => status !== 'Storniert');
          }
        });
  }

  private getStatesData(): void {
    this.ordersService.getStates()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: StringArrayResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            this.stateFilters = response.body.data;
          }
        });
  }

  private getVoucherAmount(regionId: number): void {
    this.vouchersService.getVoucherAmounts(regionId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: CardAccessories) => {
          if (response.code === ResponseCode.SUCCESS) {
            this.voucherAmounts = response.body.data;
          }
        });
  }

  private getRegions(): void {
    this.globalService.regions$.pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((regionsResponse: AllRegionsResponse) => {
          if (regionsResponse) {
            this.regions = regionsResponse.body?.pagination.data;
          }
        });
  }

  private setDurationAndTimeUnit(): void {
    this.salesStatisticsFilterForm.get('selectedProduct')?.valueChanges.subscribe(value => {
      if (typeof value === 'object' && !this.isNumeric(value)) {
        if (value.duration > 0) {
          this.salesStatisticsFilterForm.get('duration')?.patchValue(value.duration);
          this.salesStatisticsFilterForm.get('timeUnit')?.patchValue(value.timeUnit);
        } else {
          this.salesStatisticsFilterForm.get('duration')?.reset();
          this.salesStatisticsFilterForm.get('timeUnit')?.reset();
        }
        this.salesStatisticsFilterForm.get('productType')?.setValue(value.type.name);
        this.salesStatisticsFilterForm.get('voucherAmount')?.reset();
      } else if (!this.isNumeric(value)) {
        this.salesStatisticsFilterForm.get('productType')?.setValue(value);
        this.salesStatisticsFilterForm.get('duration')?.reset();
        this.salesStatisticsFilterForm.get('timeUnit')?.reset();
        this.salesStatisticsFilterForm.get('voucherAmount')?.reset();
      } else {
        this.salesStatisticsFilterForm.get('voucherAmount')?.setValue(value);
        this.salesStatisticsFilterForm.get('productType')?.reset();
        this.salesStatisticsFilterForm.get('duration')?.reset();
        this.salesStatisticsFilterForm.get('timeUnit')?.reset();
      }
    });
  }

  private watchRegion(): void {
    this.salesStatisticsFilterForm.get('regionId')?.valueChanges.subscribe(value => {
      if (value) {
        this.getVoucherAmount(value);
      }
    });
  }

  onEmitFilters(): void {
    const sanitizedFilters = FilterUtils.sanitizeFilterForm(this.salesStatisticsFilterForm);
    if (sanitizedFilters.regionId === '-1') {
      delete sanitizedFilters.regionId;
    }
    delete sanitizedFilters.selectedProduct;
    // orderCreatedFrom and orderCreatedTo are assigned to the actual properties sent to BE (createdFrom and createdTo).
    // The reason for having orderCreatedFrom and orderCreatedTo is to differentiate them from the values
    // in card statistic filters where there are filters with "createdFrom" and "createdTo"
    // which causes the datepicker in this component not to show.
    if (sanitizedFilters.orderCreatedFrom) {
      sanitizedFilters.createdFrom = sanitizedFilters.orderCreatedFrom;
      delete sanitizedFilters.orderCreatedFrom;
    }
    if (sanitizedFilters.orderCreatedTo) {
      sanitizedFilters.createdTo = sanitizedFilters.orderCreatedTo;
      delete sanitizedFilters.orderCreatedTo;
    }
    this.handleDateFields(sanitizedFilters, ['createdFrom', 'createdTo']);
    this.filterValues.emit(sanitizedFilters);
  }

  private handleDateFields(sanitizedForm: SalesReportFilters, dateFields: string[]): SalesReportFilters {
    dateFields.forEach((dateField: string) => {
      if (dateField in sanitizedForm) {
        // @ts-ignore
        sanitizedForm[dateField] = Utils.convertLocalDateToUTC(sanitizedForm[dateField]);
      }
    });
    return sanitizedForm;
  }

  private isNumeric(str: string): boolean {
    return /^\d*\.?\d+$/.test(str);
  }
}
