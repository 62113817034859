import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { ActionHistory, ActionHistoryResponse } from "../../dataModels/sharedDataModels";
import { ActionHistoryService } from "../../services/action-history.service";
import { ActivatedRoute } from "@angular/router";
import { SubscriptionComponent } from "../subscription/subscription.component";
import { takeUntil } from "rxjs/operators";
import { Utils } from "../../utils/utils";
import { PaginationData } from "../../dataModels/paginationDataModel";
import { ResponseCode } from "../../enum/shared-enums";

@Component({
             selector: 'app-action-history-accordion',
             templateUrl: './action-history-accordion.component.html',
             styleUrls: ['./action-history-accordion.component.scss']
           })
export class ActionHistoryAccordionComponent extends SubscriptionComponent implements OnInit, OnDestroy {
  @Input() historyFor: string;
  actionHistoryData: PaginationData;
  actionHistory: ActionHistory[] = [];
  dataToDisplay: any;
  noHistory = true;
  historyLoaded = false;
  actionChevron = true;

  constructor(private actionHistoryService: ActionHistoryService, private route: ActivatedRoute) { super() }

  ngOnInit(): void {
    const id = +this.route.snapshot.params['id'];
    this.loadActionHistory(id);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  loadActionHistory(id: number) {
    this.actionHistoryService.fetchActionHistory(this.historyFor, id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: ActionHistoryResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            this.actionHistoryData = response.body;
            this.actionHistory = Utils.snakeCaseToCamelCase(response.body?.pagination.data);
            if (response.body.pagination.data.length > 0) {
              this.noHistory = false;
            }
          }
          this.historyLoaded = true;
        }, () => {
          this.historyLoaded = true;
        });
  }

  onDisplayData(data: any): void {
    this.dataToDisplay = data;
  }

  // TODO: later implement pagination
  getPaginatedData(paginatedHistory: any): void {
    this.historyLoaded = false;
    const id = +this.route.snapshot.params['id'];
    this.loadActionHistory(id);
  }
}
