import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss']
})
export class FormModalComponent {
  @Input() modalLabel: string;
  @Input() saveButtonLabel: string;
  @Input() cancelButtonLabel: string;
  @Input() saveButtonCssClass: string;
  @Input() modalHeaderCssClass: string;
  @Input() modalType: string;
  @Input() disableConfirmationButton: boolean | undefined;
  @Input() showSpinner: boolean;
  @Input() set closeModal(value: boolean) {
    this.closeFormModal(value);
  };
  @Output() emitDismiss = new EventEmitter<any>();
  @Output() emitConfirm = new EventEmitter<any>();
  @Output() emitCancel = new EventEmitter<any>();
  @ViewChild('closeButton') closeModalButton: ElementRef;

  constructor() { }

  onDismiss(): void {
    this.emitDismiss.emit(true);
  }

  onConfirm(): void {
    this.emitConfirm.emit(true)
  }

  onCancel(): void {
    this.emitCancel.emit(true);
  }

  private closeFormModal(shouldClose: boolean): void {
    if (shouldClose) {
      let button = this.closeModalButton.nativeElement as HTMLElement;
      button.click();
    }
  }
}
