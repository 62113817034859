import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BlockingTimeSetting, BlockingTimeSettingsResponse } from "../../shared/dataModels/settingsDataModels";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SettingsService } from "../../services/settings.service";
import { AlertifyService } from "../../shared/services/alertify-service.service";
import { SubscriptionComponent } from "../../shared/components/subscription/subscription.component";
import { takeUntil } from "rxjs/operators";
import { ResponseCode } from "../../shared/enum/shared-enums";
import { Utils } from "../../shared/utils/utils";
import { BlockingTimeSettings } from "../../shared/enum/settings";
import { SimpleResponse } from "../../shared/dataModels/sharedDataModels";

type SettingsType = {
  [key: string]: string;
};
@Component({
  selector: 'app-blocking-time-settings',
  templateUrl: './blocking-time-settings.component.html',
  styleUrls: ['./blocking-time-settings.component.scss']
})
export class BlockingTimeSettingsComponent extends SubscriptionComponent  implements OnInit, OnDestroy {

  @ViewChild('confirmModal') confirmationModal: ElementRef;
  btSettings: BlockingTimeSetting[];
  btSettingsForm: FormGroup;
  btLabels: SettingsType;
  btValues: SettingsType;
  btSettingsLoaded = false;

  modalHeader: string;
  modalText: string;

  waitingForResponse = false;
  constructor(private settingsService: SettingsService,
              private formBuilder: FormBuilder,
              private alertifyService: AlertifyService) {super(); }

  ngOnInit(): void {
    this.getBlockingTimeSettings();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private getBlockingTimeSettings(): void {
    this.settingsService.getBlockingTimeSettings()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: BlockingTimeSettingsResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            this.btSettings = response.body.data;
            this.initializeBlockingTimeSettingsForm();
            this.initializeLabels();
            this.btSettingsLoaded = true;
          } else {
            this.alertifyService.warning(response.message);
          }
        });
  }

  private initializeBlockingTimeSettingsForm(): void {
    this.btValues = this.btSettings.reduce((result: SettingsType, setting: BlockingTimeSetting) => {
      result[Utils.transformSnakeCaseToCamelCaseForString(setting.name)] = setting.value;
      return result;
    }, {});
    this.btSettingsForm = this.formBuilder.group({
                                                   blockingTimeText: [this.btValues["blockingTimeText"], [Validators.nullValidator]],
                                                   blockingTimeStart: [this.formatDate(this.btValues["blockingTimeStart"]), [Validators.nullValidator]],
                                                   blockingTimeEnd: [this.formatDate(this.btValues["blockingTimeEnd"]), [Validators.nullValidator]],
                                                   blockingTimePublished: [this.formatBoolean(this.btValues["blockingTimePublished"]), [Validators.nullValidator]],
                                                 });
  }

  private initializeLabels(): void {
    this.btLabels = this.btSettings.reduce((result: SettingsType, setting: BlockingTimeSetting) => {
      result[Utils.transformSnakeCaseToCamelCaseForString(setting.name)] = setting.label;
      return result;
    }, {});
  }

  get blockingTimeText() {
    return this.btSettingsForm.get('blockingTimeText');
  }

  get blockingTimeStart() {
    return this.btSettingsForm.get('blockingTimeStart');
  }

  get blockingTimeEnd() {
    return this.btSettingsForm.get('blockingTimeEnd');
  }

  get blockingTimePublished() {
    return this.btSettingsForm.get('blockingTimePublished');
  }

  onChangeSettings(): void {
    this.modalHeader = 'Sperrzeitraum im Webshop';
    this.modalText = 'Sind Sie sicher, dass Sie die Einstellungen ändern wollen?';
    this.callModal();
  }

  private callModal() {
    const modal = this.confirmationModal.nativeElement as HTMLElement;
    modal.click();
  }

  onConfirmSettingChange(): void {
    let payload: SettingsType = {};
    this.btSettings.forEach((setting: BlockingTimeSetting) => {
      const settingName: string = Utils.transformSnakeCaseToCamelCaseForString(setting.name);
      let value: string = this.btSettingsForm.get(settingName)?.value;
      if(setting.name === BlockingTimeSettings.BLOCKING_TIME_START) {
        value = Utils.formatStartDateToISOString(value);
      } else if(setting.name === BlockingTimeSettings.BLOCKING_TIME_END) {
        value = Utils.formatEndDateToISOString(value);
      }
      payload[setting.name] = value;
    });
    // @ts-ignore
    this.changeBlockingTimeSettings(payload, '\"Sperrzeitraum\" Einstellungen wurden erfolgreich gespeichert');
  }

  onCancel() {
    this.blockingTimeText?.patchValue(this.btValues['blockingTimeText']);
    this.blockingTimeText?.markAsPristine();
    this.blockingTimeStart?.patchValue(this.btValues['blockingTimeStart']);
    this.blockingTimeStart?.markAsPristine();
    this.blockingTimeEnd?.patchValue(this.btValues['blockingTimeEnd']);
    this.blockingTimeEnd?.markAsPristine();
    this.blockingTimePublished?.patchValue(this.btValues['blockingTimePublished']);
    this.blockingTimePublished?.markAsPristine();
  }

  private formatDate(inputDateString: string): string {
    const inputDate = new Date(inputDateString);

    const day = inputDate.getUTCDate();
    const month = inputDate.getUTCMonth() + 1;
    const year = inputDate.getUTCFullYear();

    return `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${year}`;
  }

  private formatBoolean(value: string): boolean {
    return value === "true" ? true : (value !== "false");
  }

  private changeBlockingTimeSettings(payload: SettingsType, successMessage: string): void {
    this.waitingForResponse = true;
    this.settingsService.editBlockingTimeSettings(payload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: SimpleResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            this.waitingForResponse = false;
            this.getBlockingTimeSettings();
            this.alertifyService.success(successMessage);
          } else {
            this.alertifyService.warning(response.message);
          }
        }, () => this.waitingForResponse = false);
  }
}
