<ng-container>
  <div class="mb-1 position-relative" id="{{ 'datepicker-' + formName }}">
    <input type="{{ inputType }}"
           [placeholder]="placeHolder"
           [formGroup]="formGroup"
           [attr.data-date-format]="dateFormat"
           [value]="value"
           data-provide="datepicker"
           #datepicker
           (blur)="formGroup.get(formName)?.patchValue(datepicker?.value)"
           [attr.data-date-container]="'#datepicker-' + formName"
           class="form-control">
  </div>
  <small
    *ngIf="formGroup.get(formName)?.invalid && formGroup.get(formName)?.touched"
    class="error-feedback">
    Ungültiges Datum
  </small>
</ng-container>
