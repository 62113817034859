import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { MainDashboardComponent } from "./main-dashboard.component";
import { AuthenticationGuard } from "../authentication/authentication.guard";
import { HomeDashboardComponent } from "../home-dashboard/home-dashboard.component";
import { PermissionsComponent } from "../users/permissions/permissions.component";
import { VouchersComponent } from "../vouchers/vouchers.component";
import { ErrorMessageComponent } from "../shared/components/error-message/error-message.component";
import { SettingsComponent } from "../settings/settings.component";
import { ExcelExportComponent } from "../shared/components/excel-export/excel-export.component";
import { DeploymentInfoComponent } from "../shared/components/deployment-info/deployment-info.component";
import { ReportingComponent } from "../reporting/reporting.component";


const routes: Routes = [
  {
    path: 'dashboard',
    component: MainDashboardComponent,
    canActivate: [AuthenticationGuard],
    data: { breadcrumb: 'Dashboard' },
    children: [
      {
        path: 'home',
        component: HomeDashboardComponent,
      },
      {
        path: 'customers',
        loadChildren: () => import("../customers/customers.module").then(module => module.CustomersModule)
      },
      {
        path: 'products',
        loadChildren: () => import("../products/products.module").then(module => module.ProductsModule)
      },
      {
        path: 'orders',
        loadChildren: () => import("../orders/orders.module").then(module => module.OrdersModule)
      },
      {
        path: 'coupons',
        loadChildren: () => import("../coupons/coupons.module").then(module => module.CouponsModule)
      },
      {
        path: 'vouchers',
        component: VouchersComponent,
        data: { breadcrumb: 'Gutscheine' }
      },
      {
        path: 'cards',
        loadChildren: () => import("../cards/cards.module").then(module => module.CardsModule)
      },
      {
        path: 'users',
        loadChildren: () => import("../users/users.module").then(module => module.UsersModule)
      },
      {
        path: 'roles',
        loadChildren: () => import("../roles/roles.module").then(module => module.RolesModule)
      },
      {
        path: 'permissions',
        component: PermissionsComponent,
        data: { breadcrumb: 'Berechtigungen' }
      },
      {
        path: 'regions',
        loadChildren: () => import("../regions/regions.module").then(module => module.RegionsModule)
      },
      {
        path: 'memos',
        loadChildren: () => import("../memos/memos.module").then(module => module.MemosModule)
      },
      {
        path: 'settings',
        component: SettingsComponent,
        data: { breadcrumb: 'Einstellungen' },
      },
      {
        path: 'reporting',
        component: ReportingComponent,
        data: { breadcrumb: 'Reporting' }
      },
      {
        path: 'deployment-info',
        component: DeploymentInfoComponent
      }
    ]
  },
  //ToDo Add Auth module
  {
    path: 'logout',
    loadChildren: () => import("../authentication/authentication.module").then(module => module.AuthenticationModule)
  },
  {
    path: 'lock-screen',
    loadChildren: () => import("../authentication/authentication.module").then(module => module.AuthenticationModule)
  },
  {
    path: 'error',
    loadChildren: () => import("../authentication/authentication.module").then(module => module.AuthenticationModule)
  },
  {
    path: '500',
    component: ErrorMessageComponent
  },
  {
    path: 'export',
    component: ExcelExportComponent
  },
  {
    path: '**',
    redirectTo: 'dashboard/home'
  }
];

@NgModule({
            imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
            exports: [RouterModule]
          })
export class DashboardRoutingModule {
}
