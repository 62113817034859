import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { LocalStorageKeys } from "../shared/enum/shared-enums";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (localStorage.getItem(LocalStorageKeys.USER_TOKEN) !== null) {
      return true;
    } else {
      this.router.navigate(['/login'])
      return false;
    }
  }

}
