import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import { PaginationData } from "../../dataModels/paginationDataModel";
import { AllOrdersResponse, Order } from "../../dataModels/ordersDataModel";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { SubscriptionComponent } from "../subscription/subscription.component";
import { AlertifyService } from "../../services/alertify-service.service";
import { OrdersService } from "../../../services/orders.service";
import { FilterUtils } from "../../utils/filter";
import { takeUntil } from "rxjs/operators";
import { ResponseCode } from "../../enum/shared-enums";
import { Utils } from "../../utils/utils";
import { BreadcrumbData } from "../breadcrumbs/breadcrumbs.component";
import { NavigationService } from "../../services/navigation.service";
import { AppGlobalService } from "../../services/app-global.service";

@Component({
             selector: 'app-detailed-orders',
             templateUrl: './detailed-orders.component.html',
             styleUrls: ['./detailed-orders.component.scss']
           })
export class DetailedOrdersComponent extends SubscriptionComponent implements OnInit, OnDestroy {
  ordersData: PaginationData;
  orders: Order[] = [];
  ordersLoaded = false;
  requestParams: Params;
  noOrders = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private ordersService: OrdersService,
              private globalService: AppGlobalService,
              private navigationService: NavigationService,
              private alertifyService: AlertifyService) {super(); }

  ngOnInit(): void {
    this.requestParams = FilterUtils.getQueryParametersFromRoute(this.route);
    this.getOrders();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private getOrders() {
    this.route.params.pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((params: Params) => {
          if (params?.id) {
            this.ordersService.getCustomersOrders(params.id, this.requestParams)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((response: AllOrdersResponse) => {
                  this.ordersLoaded = true;
                  if (response.code === ResponseCode.SUCCESS) {
                    this.orders = Utils.snakeCaseToCamelCase(response.body?.pagination?.data);
                    this.ordersData = response.body;
                    if (this.orders.length === 0) {
                      this.noOrders = true;
                    }
                  } else {
                    this.alertifyService.warning(response.message);
                    this.noOrders = true;
                  }
                });
          } else {
            this.noOrders = true;
          }
        }, () => {
          this.globalService.emitServerError(true);
        });
  }

  loadPaginatedData(pageNumber: string) {
    this.ordersLoaded = false;
    this.requestParams = { ...this.requestParams, page: pageNumber };
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { page: pageNumber },
        queryParamsHandling: 'merge'
      });
    this.getOrders();
  }

  navigateTo(breadcrumb: BreadcrumbData) {
    this.router.navigate(['/' + breadcrumb.routerLink],
                         { queryParams: { 'region': Utils.getRegionFromLocalStorage() } });
  }

  @HostListener('window:popstate', ['$event'])
  goBack(event: Event) {
    this.navigationService.back().subscribe((params: Params) => {
      this.requestParams = params;
      this.getOrders();
    });
  }
}
