<app-breadcrumbs (navigateTo)="navigateTo($event)"></app-breadcrumbs>
<div class="loading-spinner">
  <div class="row">
    <app-filter-container [loadComponent]="VoucherFiltersComponent"
                          [searchTerm]="searchInput"
                          [requestParams]="requestParams"
                          [title]="'Gutscheine'"
                          [exportItemUrl]="'/export'"
                          [queryParams]="{ tableName: 'vouchers' }"
                          [totalItems]="vouchersData?.pagination?.total"
                          placeholder="ID, Code, bestellt in, angewendet auf, angewendet am..."
                          (emitQueryParams)="onSearchAndFilterVouchers($event)"
                          class="col-lg-8">
    </app-filter-container>
    <div class="col-lg-4 d-none d-lg-flex justify-content-end align-items-center mt-auto">
      <div *ngIf="vouchersData?.pagination?.total" class="items-length fw-bold me-3">
        Anzahl: {{ vouchersData?.pagination?.total }}</div>
      <a [routerLink]="['/export']"
         [queryParams]="{ tableName: 'vouchers' }"
         target="_blank"
         queryParamsHandling="merge"
         class="app-primary-button me-1">
        <i class="mdi mdi-download text-white me-1 create-user-button"></i>
        <span>Export</span>
      </a>
    </div>
  </div>
  <div *appLoading="!vouchersLoading">
    <!-- T A B L E -->
    <div *ngIf="searchResults" class="table-responsive mt-3">
      <table class="table table-striped mb-0">
        <thead class="table-light">
        <tr>
          <th class="border-bottom-0 table-cell">ID</th>
          <th class="border-bottom-0 table-cell">Code</th>
          <th class="border-bottom-0 table-cell">Wert</th>
          <th class="border-bottom-0 table-cell">Verkauf</th>
          <th class="border-bottom-0 table-cell">Startdatum</th>
          <th class="border-bottom-0 table-cell">Gültigkeitsende</th>
          <th class="border-bottom-0 table-cell">Einlösung</th>
          <th class="border-bottom-0 table-cell">Einlösedatum</th>
          <th class="border-bottom-0 table-cell">Status</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let voucher of vouchers" class="bg-aqua">
          <td class="table-cell">{{ voucher.id }}</td>
          <td (click)="onCopyCode(voucher.code)" title="Code kopieren" class="cursor-pointer table-cell">
            <span>{{ voucher?.code }}</span>
            <i class="mdi mdi-content-copy ms-2"></i>
          </td>
          <td class="table-cell">{{ voucher?.amount | currency: 'EUR' }}</td>
          <td class="table-cell">
            <a [routerLink]="['/dashboard/orders', voucher.orderId]" queryParamsHandling="merge">
              <span class="me-3">{{ voucher?.orderId }}</span>
            </a>
          </td>
          <td class="table-cell">{{ voucher?.startDate | date }}</td>
          <td class="table-cell">
            {{ Utils.getLocalDateFromUtc(voucher.endDate.toString()) }}
            <i class="mdi mdi-pencil cursor-pointer text-dark-purple ms-1"
               data-bs-toggle="modal"
               data-bs-target="#edit-voucher-header-modal"
               (click)="onPrefillEndDateForm(voucher.endDate, voucher.id)"></i>
          </td>
          <td *ngIf="voucher.applicationOrderId; else notApplied" class="table-cell">
            <a [routerLink]="['/dashboard/orders', voucher.applicationOrderId]" queryParamsHandling="merge">
              <span class="me-3">{{ voucher?.applicationOrderId }}</span>
            </a>
          </td>
          <ng-template #notApplied>
            <td class="table-cell">&#8212;</td>
          </ng-template>
          <td *ngIf="voucher.appliedAt; else notCashedIn" class="table-cell">
            {{ voucher?.appliedAt | date: 'dd.MM.yyyy' }}
          </td>
          <ng-template #notCashedIn>
            <td class="table-cell">&#8212;</td>
          </ng-template>
          <td class="table-cell">
            <div class="form-check form-switch">
              <input type="checkbox"
                     *ngIf="!voucher.applicationOrderId"
                     class="form-check-input"
                     data-bs-toggle="modal"
                     data-bs-target="#vouchers-header-modal"
                     [checked]="voucher.status === Status.ACTIVE"
                     (click)="onPreventSwitchToggle($event, voucher)">
              <label class="form-check-label">{{ voucher.status === Status.ACTIVE ? 'Aktiv' : 'Inaktiv' }}</label>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="!searchResults" class="fw-bold mt-3 fs-3">Keine Ergebnisse gefunden.</div>
    <app-advanced-pagination *ngIf="vouchersData.pagination.total > vouchersData.pagination.per_page"
                             [paginationData]="vouchersData"
                             (emitPaginatedData)="getPaginatedData($event)">
    </app-advanced-pagination>
  </div>
</div>
<app-form-modal
  modalType="edit-voucher"
  modalLabel="Gutscheins Enddatum ändern"
  saveButtonLabel="Speichern"
  cancelButtonLabel="Abbrechen"
  [showSpinner]="pendingResponse"
  [closeModal]="closeModal"
  saveButtonCssClass="btn-info"
  modalHeaderCssClass="bg-info"
  (emitConfirm)="onSetNewEndDate()"
>
  <form [formGroup]="endDateForm" class="mt-3">
    <label for="endDate" class="form-label">Ende *</label>
    <div class="mb-1 position-relative" id="datepicker1">
      <input type="text"
             class="form-control"
             #datepicker
             id="endDate"
             data-date-format="dd.mm.yyyy"
             (blur)="endDateForm.get('endDate')?.patchValue(datepicker?.value)"
             formControlName="endDate"
             data-provide="datepicker"
             data-date-container="#datepicker1">
    </div>
    <small *ngIf="endDateForm.get('endDate')?.errors" class="error-feedback">
      Das Datum darf nicht in der Vergangenheit liegen!
    </small>
  </form>
</app-form-modal>

<app-modal
  modalType="vouchers"
  data-bs-target="modal"
  modalLabel="Gutscheinstatus ändern"
  modalContent="Sind Sie sich sicher, dass Sie den Gutscheinstatus ändern wollen?"
  saveButtonLabel="Ändern"
  cancelButtonLabel="Abbrechen"
  [allowClickElsewhere]="true"
  saveButtonCssClass="btn-warning"
  modalHeaderCssClass="bg-warning"
  (emitConfirm)="onChangeVoucherStatus()"></app-modal>
