import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MessageService } from "../../../services/message.service";
import { MessagePurpose } from "../../../shared/enum/message-purpose";
import { takeUntil } from "rxjs/operators";
import { SubscriptionComponent } from "../../../shared/components/subscription/subscription.component";
import { Message, MessageResponse } from "../../../shared/dataModels/messageDataModel";
import { ResponseCode } from "../../../shared/enum/shared-enums";
import { Utils } from "../../../shared/utils/utils";
import { MessageChannel } from "../../../shared/enum/messageChannel";
import { AlertifyService } from "../../../shared/services/alertify-service.service";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { AppGlobalService } from "../../../shared/services/app-global.service";

@Component({
             selector: 'app-messages-accordion',
             templateUrl: './messages-accordion.component.html',
             styleUrls: ['./messages-accordion.component.scss']
           })
export class MessagesAccordionComponent extends SubscriptionComponent implements OnInit {
  @Input() id: number;
  @Input() purpose: string;
  @ViewChild('confirmMessagesModal') confirmationModal: ElementRef;
  @ViewChild('confirmSharingModal') confirmationSharingModal: ElementRef;

  emailForm: FormGroup;

  messagePurposes: string[] = [];

  messages: Message[] = [];

  messagesChevron: boolean = true;
  messagesLoaded: boolean = false;
  noMessages: boolean = true;

  modalHeader: string;
  modalText: string;
  messageChannel: string;
  messageId: number;
  waitingForResponse = false;
  closeFormModal = false;

  constructor(private messageService: MessageService,
              private alertifyService: AlertifyService,
              private globalService: AppGlobalService,
              private formBuilder: FormBuilder) {
    super();
    this.messagePurposes = Object.values(MessagePurpose);
  }

  ngOnInit(): void {
    this.emailForm = this.formBuilder.group({
                                              email: new FormControl('', [Validators.required, Validators.email])
                                            });
    this.getMessages();
  }

  get email() {
    return this.emailForm?.get('email');
  }

  getMessages(): void {
    this.messageService.getMessages(this.id, (this.messagePurposes.indexOf(this.purpose) > -1) ? this.purpose : '')
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: MessageResponse) => {
          this.messagesLoaded = true;
          if (response.code === ResponseCode.SUCCESS) {
            if (response.message === 'No messages') {
              this.noMessages = true;
              this.messages = [];
            } else {
              this.noMessages = false;
              this.messages = [];
              this.messages = Utils.snakeCaseToCamelCase(response.body?.data);
            }
          } else {
            this.noMessages = true;
          }
        });
  }

  onConfirmMessageSendOrGenerate(): void {
    switch (this.messageChannel) {
      case MessageChannel.EMAIL:
        this.onSend(this.messageId);
        break;
      case MessageChannel.MAIL:
        this.onGenerate(this.messageId);
        break;
      case MessageChannel.EMAIL_FILE:
        this.onGenerate(this.messageId);
        break;
    }
  }

  onSend(id: number): void {
    this.waitingForResponse = true;
    const email = this.email?.value ? this.emailForm?.value : null;
    this.messageService.sendMessage(id, email)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: MessageResponse) => {
          this.closeModal();
          this.emailForm.reset();
          this.handleResponse(response);
        }, () => {
          this.waitingForResponse = false;
        });
  }

  onGenerate(id: number): void {
    this.waitingForResponse = true;
    this.messageService.generateMessage(id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: MessageResponse) => {
          this.handleResponse(response);
        }, () => {
          this.waitingForResponse = false;
        });
  }

  onDownload(fileUrl: string, fileName?: string) {
    if (fileUrl) {
      const errorMessage = this.globalService.downloadFile(
        fileUrl,
        fileName ? fileName : 'File',
        'application/pdf'
      );
      if (errorMessage) {
        this.alertifyService.error(errorMessage);
      }
    }
  }

  onPreview(fileUrl: string) {
    if (fileUrl) {
      window.open(fileUrl, '_blank', 'location=yes,height=990,width=760,scrollbars=yes,status=yes');
    }
  }


  private handleResponse(response: MessageResponse) {
    this.waitingForResponse = false;
    if (response.code === ResponseCode.SUCCESS) {
      this.alertifyService.success(response.message);
      this.getMessages();
    } else {
      this.alertifyService.warning(response.message);
    }
  }

  onChangeEmailMessage(channel: string, id: number): void {
    this.modalHeader = 'Nachricht senden';
    this.modalText = 'Sind Sie sicher, dass Sie die Nachricht erneut senden wollen?';
    this.messageChannel = channel;
    this.messageId = id;
    this.callModal();
  }

  onChangeEmailMessageSharing(channel: string, id: number): void {
    this.modalHeader = 'Nachricht senden';
    this.modalText = '';
    this.messageChannel = channel;
    this.messageId = id;
    this.callSharingModal();
  }

  onChangePdfMessage(channel: string, id: number): void {
    this.modalHeader = 'Dokument generieren';
    this.modalText = 'Sind Sie sicher, dass Sie das Dokument erneut generieren wollen?';
    this.messageChannel = channel;
    this.messageId = id;
    this.callModal();
  }

  private callModal() {
    const modal = this.confirmationModal.nativeElement as HTMLElement;
    modal.click();
  }

  private closeModal() {
    this.waitingForResponse = false;
    this.closeFormModal = true;
  }

  private callSharingModal() {
    const modal = this.confirmationSharingModal.nativeElement as HTMLElement;
    modal.click();
  }

  public getMessageChannel() {
    return MessageChannel;
  }
}
