import {
  ExtensionCardsForEmbossmentComponent
} from "../../home-dashboard/embossments/extension-cards-for-embossment/extension-cards-for-embossment.component";
import {
  ExpirationEmbossmentsComponent
} from "../../home-dashboard/embossments/expiration-embossments/expiration-embossments.component";
import {
  ExpirationCardsForEmbossmentComponent
} from "../../home-dashboard/embossments/expiration-cards-for-embossment/expiration-cards-for-embossment.component";
import { DashboardItem } from "./sharedDataModels";
import {
  ExtensionEmbossmentsComponent
} from "../../home-dashboard/embossments/extension-embossments/extension-embossments.component";
import { DashboardMemosComponent } from "../../memos/dashboard-memos/dashboard-memos.component";
import {
  OrdersForEncashmentComponent
} from "../../home-dashboard/orders-for-encashment/orders-for-encashment.component";
import {
  OrderEmbossmentsComponent
} from "../../home-dashboard/embossments/order-embossments/order-embossments.component";
import {
  OrdersForEmbossmentComponent
} from "../../home-dashboard/embossments/orders-for-embossment/orders-for-embossment.component";
import { TroubleshootingComponent } from "../../home-dashboard/troubleshooting/troubleshooting.component";
import { EncashmentsComponent } from "../../home-dashboard/encashments/encashments.component";
import { CardsForPickUpComponent } from "../../home-dashboard/cards-for-pick-up/cards-for-pick-up.component";

export const referenceComponents: DashboardItem[] = [
     {
      id: 0,
      component: OrdersForEmbossmentComponent
    },
    {
      id: 1,
      component: OrderEmbossmentsComponent,
    },
    {
      id: 2,
      component: ExtensionCardsForEmbossmentComponent
    },
     {
      id: 3,
      component: ExtensionEmbossmentsComponent
    },
    {
      id: 4,
      component: ExpirationCardsForEmbossmentComponent
    },
     {
      id: 5,
      component: ExpirationEmbossmentsComponent
    },
    {
      id: 6,
      component: DashboardMemosComponent
    },
    {
      id: 7,
      component: OrdersForEncashmentComponent
    },
    {
      id: 8,
      component: EncashmentsComponent
    },
    {
      id: 9,
      component: TroubleshootingComponent
    },
  {
    id: 10,
    component: CardsForPickUpComponent
  }
  ];

  export const firstColumnComponents: DashboardItem[] = [
    {
      id: 0,
      component: OrdersForEmbossmentComponent
    },
    {
      id: 1,
      component: OrderEmbossmentsComponent,
    },
    {
      id: 2,
      component: ExtensionCardsForEmbossmentComponent
    },
     {
      id: 3,
      component: ExtensionEmbossmentsComponent
    },
    {
      id: 4,
      component: ExpirationCardsForEmbossmentComponent
    },
    {
      id: 10,
      component: CardsForPickUpComponent
    }
  ];

  export const secondColumnComponents: DashboardItem[] = [
     {
      id: 5,
      component: ExpirationEmbossmentsComponent
    },
    {
      id: 6,
      component: DashboardMemosComponent
    },
    {
      id: 7,
      component: OrdersForEncashmentComponent
    },
    {
      id: 8,
      component: EncashmentsComponent
    },
    {
      id: 9,
      component: TroubleshootingComponent
    },
  ];
