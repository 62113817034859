<div class="authentication-bg" data-layout-config='{"darkMode":false}'>

  <div class="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xxl-4 col-lg-5">
          <div class="card">
            <!-- Logo -->
            <div class="card-header pt-4 pb-4 text-center bg-primary">
              <a>
                <span>
                  <img src="https://www.dresdenforfriends.de/sites/ddff/files/DDFF_Logo450.png" alt="" height="18">
                </span>
              </a>
            </div>

            <div class="card-body p-4">

              <div class="text-center w-75 m-auto">
                <img src="assets/images/users/avatar-1.jpg" height="64" alt="user-image" class="rounded-circle shadow">
                <h4 class="text-dark-50 text-center mt-3 fw-bold">Hi ! Admin </h4>
                <p class="text-muted mb-4">Enter your password to access the admin.</p>
              </div>

              <form action="#">
                <div class="mb-3">
                  <label for="password" class="form-label">Password</label>
                  <input class="form-control" type="password" required="" id="password"
                         placeholder="Enter your password">
                </div>

                <div class="mb-0 text-center">
                  <button class="btn btn-primary" type="submit"
                          routerLink="/login">Log In</button>
                </div>
              </form>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 text-center">
              <p class="text-muted">Not you? return
                <a routerLink="/login" class="text-muted ms-1">
                  <b>Sign In</b>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="footer footer-alt">
    2018 - {{ currentYear }} © DRESDEN FOR FRIENDS
  </footer>
</div>
