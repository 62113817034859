export enum NotificationSettings {
  EXPIRATION = 'expiration_notification_threshold',
  EXTENSION = 'extension_notification_threshold',
  EXTENSION_EXECUTION = 'extension_execution_threshold',
  SUB_CANCELLATION = 'subscription_cancellation_threshold',
  VOUCHER_FEE = 'physical_voucher_fee',
  THIRD_DUNNING = 'dunning_2_to_3_threshold',
  GOOGLE_PLAY_LINK = 'google_play_store_link',
  APPLE_STORE_LINK = 'app_store_link',
  DEFAULT_VAT_PERCENTAGE = 'default_vat_percentage',
  EMAIL_FOR_NOTIFICATIONS = 'email_for_notifications'
}

export enum BannerPurpose {
  ENTRY_ORDER = 'Entry_Order'
}

export enum BlockingTimeSettings {
  BLOCKING_TIME_TEXT = 'blocking_time_text',
  BLOCKING_TIME_START = 'blocking_time_start',
  BLOCKING_TIME_END = 'blocking_time_end',
  BLOCKING_TIME_PUBLISHED = 'blocking_time_published',
}
