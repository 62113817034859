import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { MemosService } from "../../../services/memos.service";
import { takeUntil } from "rxjs/operators";
import { Utils } from "../../../shared/utils/utils";
import { AllMemosResponse, Memo } from "../../../shared/dataModels/memosDataModel";
import { FollowUp } from "../../../shared/enum/memos";
import { SubscriptionComponent } from "../../../shared/components/subscription/subscription.component";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AlertifyService } from "../../../shared/services/alertify-service.service";
import { CRUDMode, ResponseCode } from "../../../shared/enum/shared-enums";

@Component({
             selector: 'app-memos-accordion',
             templateUrl: './memos-accordion.component.html',
             styleUrls: ['./memos-accordion.component.scss']
           })
export class MemosAccordionComponent extends SubscriptionComponent implements OnInit, OnDestroy {
  @Input() customerId: number;
  @Input() orderId: number;
  memos: Memo[] = [];
  FollowUp = FollowUp;
  MemoModes = CRUDMode;
  memosLoaded = false;
  link: string[];
  noMemos = true;
  memosChevron = true;

  constructor(private memosService: MemosService,
              private route: ActivatedRoute,
              private alertifyService: AlertifyService,
              private router: Router) { super() }

  ngOnInit(): void {
    if (this.customerId) {
      this.getCustomerMemos();
      this.link = ['/dashboard/customers/', this.customerId as unknown as string, 'memos'];
    }
    if (this.orderId) {
      this.getOrderMemos();
      this.link = ['/dashboard/orders/', this.orderId as unknown as string, 'memos'];
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  getCustomerMemos(): void {
    this.memosService.getCustomerMemos(this.customerId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: AllMemosResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            if (response.body?.pagination?.data?.length > 0) {
              this.noMemos = false;
            }
           this.memos = Utils.snakeCaseToCamelCase(response.body?.pagination.data);
          } else {
            this.alertifyService.warning(response.message);
          }
          this.memosLoaded = true;
        }, () => {
          this.memosLoaded = true;
        });
  }

  getOrderMemos(): void {
    this.memosService.getOrderMemos(this.orderId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: AllMemosResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            if (response.body?.pagination?.data?.length > 0) {
              this.noMemos = false;
            }
            this.memos = Utils.snakeCaseToCamelCase(response.body?.pagination.data);
          } else {
            this.alertifyService.warning(response.message);
          }
          this.memosLoaded = true;
        }, () => {
          this.memosLoaded = true;
        });
  }

  onNavigateToAllMemos(): void {
    let queryParams: Params = JSON.parse(JSON.stringify(this.route.snapshot.queryParams));
    delete queryParams.page;
    queryParams.origin = this.customerId ? 'customers' : 'orders';
    this.router.navigate(this.link, { queryParams, relativeTo: this.route });
  }
}
