import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {
  @Input() requiredFileType: string[];
  @Input() buttonText: string;
  @Output() file = new EventEmitter<FileList>();

  constructor() { }

  onFileSelected(event: any): void {
    const files: FileList = event.target.files;
    if (files) {
      this.file.emit(files);
    }
  }
}
