<h2 *ngIf="downloadReady" class="d-flex justify-content-center mt-5">Ihr Datenexport steht bereit</h2>
<div class="loader-container">
  <div *ngIf="!downloadReady && !errorCatch" class="loader m-auto"></div>
  <div *ngIf="!downloadReady && !errorCatch" class="d-flex justify-content-center fs-2 mt-4">
        <div>Ihre Datei wird generiert. Bitte warten...</div>
  </div>
  <div *ngIf="errorCatch" class="d-flex justify-content-center fs-2 mt-4">
    <div><i class="mdi mdi-alert text-danger"></i> Ihre Datei kann nicht generiert werden.</div>
  </div>
  <div *ngIf="downloadReady"  class="d-flex justify-content-center">
    <div class="row text-center">
      <div class="col-12">
        <a [href]="exportFile" [download]="file.fileName" class="btn app-primary-button fs-4"><i class="mdi mdi-download"></i> Download</a>
      </div>
    </div>
  </div>
</div>
