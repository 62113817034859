import { NgModule } from "@angular/core";
import { BreadcrumbsComponent } from "./shared/components/breadcrumbs/breadcrumbs.component";
import { AdvancedPaginationComponent } from "./shared/components/advanced-pagination/advanced-pagination.component";
import { CommonModule } from "@angular/common";
import { FilterContainerComponent } from "./shared/components/filter-container/filter-container.component";
import { ContextMenuComponent } from "./shared/components/context-menu/context-menu.component";
import { EditStatusComponent } from "./shared/components/edit-status/edit-status.component";
import { LoadingComponent } from "./shared/components/loading/loading.component";
import { PermissionsComponent } from "./users/permissions/permissions.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FilterComponent } from "./shared/components/filter/filter.component";
import { ComponentInjectorDirective } from "./shared/directives/component-injector.directive";
import { DetailedMemosComponent } from "./shared/components/detailed-memos/detailed-memos.component";
import { RolesComponent } from "./roles/roles.component";
import { LoadingDirective } from "./shared/directives/loading.directive";
import { FileUploadComponent } from "./shared/components/file-upload/file-upload.component";
import { VouchersFiltersComponent } from './shared/components/filter/vouchers-filters/vouchers-filters.component';
import { ModalComponent } from "./shared/components/modal/modal.component";
import { ErrorMessageComponent } from "./shared/components/error-message/error-message.component";
import { MessagesAccordionComponent } from "./customers/customer/messages-accordion/messages-accordion.component";
import { MemosAccordionComponent } from "./customers/customer/memos-accordion/memos-accordion.component";
import { TouchspinComponent } from './shared/components/touchspin/touchspin.component';
import { IbanPipe } from './shared/pipes/iban.pipe';
import { OrdersForEmbossmentComponent } from "./home-dashboard/embossments/orders-for-embossment/orders-for-embossment.component";
import { EmbossmentsComponent } from './home-dashboard/embossments/embossments.component';
import { SubscriptionComponent } from './shared/components/subscription/subscription.component';
import { ExcelExportComponent } from "./shared/components/excel-export/excel-export.component";
import {
  DefaultRegionSelectionModalComponent
} from "./shared/components/modal/default-region-selection-modal/default-region-selection-modal.component";
import { ActionHistoryAccordionComponent } from './shared/components/action-history-accordion/action-history-accordion.component';
import { FormModalComponent } from './shared/components/form-modal/form-modal.component';
import { ExtensionCardsForEmbossmentComponent } from './home-dashboard/embossments/extension-cards-for-embossment/extension-cards-for-embossment.component';
import { ExtensionEmbossmentsComponent } from './home-dashboard/embossments/extension-embossments/extension-embossments.component';
import { OrderEmbossmentsComponent } from './home-dashboard/embossments/order-embossments/order-embossments.component';
import { ExpirationEmbossmentsComponent } from './home-dashboard/embossments/expiration-embossments/expiration-embossments.component';
import { ExpirationCardsForEmbossmentComponent } from './home-dashboard/embossments/expiration-cards-for-embossment/expiration-cards-for-embossment.component';
import { DetailedHistoryComponent } from './shared/components/detailed-history/detailed-history.component';
import { DebounceClickDirective } from './shared/directives/debounce-click.directive';
import { DatePickerComponent } from './shared/components/date-picker/date-picker.component';
import { DetailedCardsComponent } from './shared/components/detailed-cards/detailed-cards.component';
import { LoadingDataFailedComponent } from './shared/components/loading-data-failed/loading-data-failed.component';
import { DetailedOrdersComponent } from './shared/components/detailed-orders/detailed-orders.component';
import { CardsForPickUpComponent } from './home-dashboard/cards-for-pick-up/cards-for-pick-up.component';
import { CardStatisticFiltersComponent } from './shared/components/filter/card-statistic-filters/card-statistic-filters.component';
import { SalesStatisticFiltersComponent } from './shared/components/filter/sales-statistic-filters/sales-statistic-filters.component';

@NgModule({
            declarations: [
              BreadcrumbsComponent,
              AdvancedPaginationComponent,
              RolesComponent,
              FilterContainerComponent,
              DefaultRegionSelectionModalComponent,
              FilterComponent,
              ContextMenuComponent,
              EditStatusComponent,
              PermissionsComponent,
              DetailedMemosComponent,
              ModalComponent,
              LoadingComponent,
              FileUploadComponent,
              LoadingDirective,
              ComponentInjectorDirective,
              VouchersFiltersComponent,
              ErrorMessageComponent,
              MemosAccordionComponent,
              MessagesAccordionComponent,
              TouchspinComponent,
              IbanPipe,
              OrdersForEmbossmentComponent,
              EmbossmentsComponent,
              SubscriptionComponent,
              ExcelExportComponent,
              ActionHistoryAccordionComponent,
              FormModalComponent,
              ExtensionCardsForEmbossmentComponent,
              ExtensionEmbossmentsComponent,
              OrderEmbossmentsComponent,
              ExpirationEmbossmentsComponent,
              ExpirationCardsForEmbossmentComponent,
              DetailedHistoryComponent,
              DebounceClickDirective,
              DatePickerComponent,
              DetailedCardsComponent,
              LoadingDataFailedComponent,
              DetailedOrdersComponent,
              CardsForPickUpComponent,
              CardStatisticFiltersComponent,
              SalesStatisticFiltersComponent
            ],
			  imports: [
				  CommonModule,
				  ReactiveFormsModule,
				  RouterModule,
				  FormsModule
			  ],
            exports: [
              BreadcrumbsComponent,
              AdvancedPaginationComponent,
              RolesComponent,
              FilterContainerComponent,
              DefaultRegionSelectionModalComponent,
              FilterComponent,
              PermissionsComponent,
              DetailedMemosComponent,
              ModalComponent,
              ContextMenuComponent,
              EditStatusComponent,
              FileUploadComponent,
              LoadingComponent,
              LoadingDirective,
              CommonModule,
              ComponentInjectorDirective,
              ErrorMessageComponent,
              MemosAccordionComponent,
              MessagesAccordionComponent,
              TouchspinComponent,
              IbanPipe,
              OrdersForEmbossmentComponent,
              EmbossmentsComponent,
              ExcelExportComponent,
              ActionHistoryAccordionComponent,
              FormModalComponent,
              DatePickerComponent,
              LoadingDataFailedComponent,
              SalesStatisticFiltersComponent,
              CardStatisticFiltersComponent
            ],
            providers: [IbanPipe]
          })
export class SharedModule {
}
