import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Utils } from "../../shared/utils/utils";
import { AllRegionsResponse, Region } from "../../shared/dataModels/regionDataModels";
import { AppGlobalService } from "../../shared/services/app-global.service";
import { LocalStorageKeys, ResponseCode } from "../../shared/enum/shared-enums";
import { takeUntil } from "rxjs/operators";
import { SubscriptionComponent } from "../../shared/components/subscription/subscription.component";
import {
  CardReport,
  CardReportFilters,
  CreateReportResponse, Report,
  ReportResponse
} from "../../shared/dataModels/reportsDataModel";
import { ReportingService } from "../../services/reporting.service";
import { AlertifyService } from "../../shared/services/alertify-service.service";
import {
  CardStatisticFiltersComponent
} from "../../shared/components/filter/card-statistic-filters/card-statistic-filters.component";

@Component({
             selector: 'app-card-statistic',
             templateUrl: './card-statistic.component.html',
             styleUrls: ['./card-statistic.component.scss']
           })
export class CardStatisticComponent extends SubscriptionComponent implements OnInit {
  @Input() statistic: string;
  @Output() deleteReport = new EventEmitter<Report>();
  @ViewChild('filtersComponent') filtersComponent: CardStatisticFiltersComponent;
  cardReports: CardReport[];
  regions: Region[];
  regionForm: FormGroup;
  cardStatisticNameForm: FormGroup;
  Utils = Utils;
  regionId: number;
  initialClick = true;
  chevron = false;
  responseSuccessful = false;
  error = false;

  constructor(private globalService: AppGlobalService,
              private reportingService: ReportingService,
              private alertifyService: AlertifyService,
              private formBuilder: FormBuilder) { super() }

  ngOnInit(): void {
    this.regionId = JSON.parse(Utils.getItemFromLocalStorage(LocalStorageKeys.REGION)).id;
    this.getRegions();
    this.getCardReports();
    this.initializeRegionForm();
    this.initializeCardStatisticNameForm();
    this.watchRegionId();
  }

  getCardReports(): void {
    this.reportingService.getCardReports(this.regionId.toString())
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: ReportResponse) => {
          this.responseSuccessful = true;
          if (response.code === ResponseCode.SUCCESS) {
            this.cardReports = Utils.snakeCaseToCamelCase(response.body.data);
          } else {
            this.alertifyService.warning(response.message);
          }
        }, () => this.responseSuccessful = true);
  }

  private getRegions(): void {
    this.globalService.regions$.pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((regionsResponse: AllRegionsResponse) => {
          if (regionsResponse) {
            this.regions = regionsResponse.body?.pagination.data;
          }
        });
  }

  private initializeRegionForm(): void {
    this.regionForm = this.formBuilder.group({
                                               regionId: [this.regionId],
                                             });
  }

  private initializeCardStatisticNameForm(): void {
    this.cardStatisticNameForm = this.formBuilder.group({
                                                          name: ['', [Validators.required]]
                                                        });
  }

  get regionIdFromForm() {
    return this.regionForm.get('regionId');
  }

  private watchRegionId(): void {
    this.regionIdFromForm?.valueChanges.subscribe((value) => {
      this.responseSuccessful = false;
      this.regionId = value;
      this.getCardReports();
    });
  }

  onReceiveCardFilterData(filterData: CardReportFilters): void {
    let filters: CardReportFilters = {
      ...filterData,
      name: this.cardStatisticNameForm.get('name')?.value
    };
    this.createCardReport(filters);
  }

  private createCardReport(filterData: CardReportFilters): void {
    this.reportingService.createReportForCards(filterData)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: CreateReportResponse) => {
          if (response.code === ResponseCode.SUCCESS) {
            this.getCardReports();
            this.filtersComponent.onInitializeCardStatisticForm();
            this.alertifyService.success(response.message);
          } else {
            this.alertifyService.warning(response.message);
          }
        });
  }

  onDownloadReport(fileUrl: string | null, fileName: string): void {
    if (fileUrl) {
      const errorMessage = this.globalService.downloadFile(
        fileUrl,
        fileName,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      );
      if (errorMessage) {
        this.alertifyService.error(errorMessage);
      }
    }
  }

  onRefreshList(): void {
    this.responseSuccessful = false;
    this.getCardReports();
  }
}
