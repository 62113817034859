export enum CardDeliveryStatus {
  CREATED = 'Neu angelegt',
  EMBOSSED = 'Geprägt',
  PICKUP_READY = 'Zur Abholung bereit',
  DELIVERED = 'Ausgeliefert'
}

export enum CardState {
  INITIATED = 'initiiert',
  ACTIVE = 'aktiv',
  INACTIVE = 'inaktiv'
}

export enum CardSubscriptionStatus {
  ACTIVE_SUBSCRIPTION = 'Aktives Abonnement',
  INACTIVE_SUBSCRIPTION = 'Inaktives Abonnement',
  CANCELED_SUBSCRIPTION = 'Gekündigtes Abonnement',
  NO_SUBSCRIPTION = 'Kein Abonnement'
}

export enum CardCancellationStatus {
  CANCELLABLE = 'Kündbar',
  CANCELLED_BUT_REVOCABLE = 'Kündigung kann zurückgezogen werden'
}

export enum GetCards {
  CUSTOMER,
  LINKED_CARDS,
  ORDERS
}

export enum IsPhysical {
  YES = '1',
  NO = '0'
}
