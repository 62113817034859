import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { ExportResponse } from "../shared/dataModels/exportDataModels";
import { Observable } from "rxjs";
import { apiUrl, Utils } from "../shared/utils/utils";
import { FilterUtils } from "../shared/utils/filter";
import { CustomUrlEncoder } from "../shared/utils/custom-url-encoder";
import { Params } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  constructor(private http: HttpClient) { }

  exportTable(tableName: string, requestParams: Params): Observable<ExportResponse> {
    let queryParams = FilterUtils.trimRequestParams(requestParams);
    const params = new HttpParams({ encoder: new CustomUrlEncoder() })
      .appendAll(Utils.camelCaseToSnakeCase(queryParams));
    return this.http.get<ExportResponse>(apiUrl + 'export/' + tableName, { params });
  }

  downloadTable(fileUuid: string): Observable<ArrayBuffer> {
    return this.http.get(apiUrl + 'secureFiles/' + fileUuid, { responseType: 'arraybuffer' });
  }
}
