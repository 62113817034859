import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { Region } from "../../../shared/dataModels/regionDataModels";
import { Params } from "@angular/router";
import { Card } from "../../../shared/dataModels/cardDataModel";
import { AlertifyService } from "../../../shared/services/alertify-service.service";
import { AppGlobalService } from "../../../shared/services/app-global.service";
import { CardsService } from "../../../services/cards.service";
import { Utils } from "../../../shared/utils/utils";
import { EmbossmentType, LocalStorageKeys, ResponseCode } from "../../../shared/enum/shared-enums";
import { SubscriptionComponent } from "../../../shared/components/subscription/subscription.component";
import { EmbossmentsCreatedResponse } from "../../../shared/dataModels/ordersDataModel";
import { filter, takeUntil, tap } from "rxjs/operators";
import { combineLatest } from "rxjs";

@Component({
             selector: 'app-expiration-cards-for-embossment',
             templateUrl: './expiration-cards-for-embossment.component.html',
             styleUrls: ['./expiration-cards-for-embossment.component.scss']
           })
export class ExpirationCardsForEmbossmentComponent extends SubscriptionComponent implements OnInit, OnDestroy {
  @ViewChild('selectAllCheckbox') headerCheckbox: ElementRef;
  cards: Card[];
  embossmentFilterForm: FormGroup;
  regions: Region[];
  regionId: number;
  requestParams: Params;
  selectedCards: number[] = [];
  allCardsSelected = false;
  waitingForResponse = false;
  responseSuccessful = false;
  perPage = 5;
  error = false;
  chevron = true;

  constructor(private formBuilder: FormBuilder,
              private cardsService: CardsService,
              private globalService: AppGlobalService,
              private alertifyService: AlertifyService) { super() }

  ngOnInit(): void {
    this.regionId = JSON.parse(Utils.getItemFromLocalStorage(LocalStorageKeys.REGION)).id;
    this.requestParams = {
      regionId: this.regionId,
      orderDirection: 'desc',
      orderBy: 'created_at',
      perPage: this.perPage,
    }
    this.initializeFilterForm();
    this.getTableData();
    this.watchRegion();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private initializeFilterForm(): void {
    this.embossmentFilterForm = this.formBuilder.group({
                                                         region: [this.regionId]
                                                       });
  }

  get region() {
    return this.embossmentFilterForm.get('region');
  }

  private getTableData(): void {
    combineLatest([
                    this.cardsService.getAvailableForEmbossment(this.requestParams, EmbossmentType.EXPIRATION),
                    this.globalService.regions$
                  ])
      .pipe(
        tap(([cardsResponse]) => {
          this.responseSuccessful = true;
          if (cardsResponse.code === ResponseCode.FAILED) {
            this.alertifyService.warning(cardsResponse.message);
          }
        }),
        filter(([, regionsResponse]) => Object.keys(regionsResponse).length > 0),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(([cardsResponse, regionsResponse]) => {
        if (cardsResponse.code === ResponseCode.SUCCESS) {
          this.cards = Utils.snakeCaseToCamelCase(cardsResponse.body);
          this.regions = Utils.snakeCaseToCamelCase(regionsResponse.body?.pagination.data);
          this.cards = Utils.assignRegionName(this.cards, this.regions);
          this.checkIfAllCheckboxSelected();
          this.setCheckboxIndeterminateState();
        }
      }, () => this.error = true);
  }

  private watchRegion(): void {
    this.region?.valueChanges.subscribe((value) => {
      this.requestParams.regionId = value;
      this.onRefreshList();
    });
  }

  onCheckAllCards(event: any) {
    if (event.target.checked) {
      // push only those cards that aren't already included
      this.cards.forEach((card: Card) => {
        if (!this.selectedCards.includes(card.id)) {
          this.selectedCards.push(card.id);
        }
        this.allCardsSelected = true;
      });
    } else {
      this.selectedCards = [];
    }
  }

  private checkIfAllCheckboxSelected(): void {
    this.allCardsSelected = Utils.areAllCheckboxesSelected(this.selectedCards, this.cards);
  }

  private setCheckboxIndeterminateState() {
    if (this.headerCheckbox) {
      this.headerCheckbox.nativeElement.indeterminate = Utils.setCheckboxIndeterminateState(
        this.allCardsSelected,
        this.cards,
        this.selectedCards
      );
    }
  }

  onRefreshList() {
    this.responseSuccessful = false;
    this.cards = [];
    this.getTableData();
    this.selectedCards = [];
  }

  onCreateEmbossmentList() {
    this.waitingForResponse = true;
    this.cardsService.createEmbossmentList(this.selectedCards, EmbossmentType.EXPIRATION)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((response: EmbossmentsCreatedResponse) => {
          this.waitingForResponse = false;
          if (response.code === ResponseCode.SUCCESS) {
            this.alertifyService.success(response.message);
            this.cardsService.emitNewEmbossment(EmbossmentType.EXPIRATION);
            this.onRefreshList();
          } else {
            this.alertifyService.warning(response.message);
          }
        }, () => {
          this.waitingForResponse = false;
        });
  }

  onSelectCard(id: number) {
    if (this.selectedCards.includes(id)) {
      const index = this.selectedCards.indexOf(id);
      this.selectedCards.splice(index, 1);
      this.allCardsSelected = false;
      this.setCheckboxIndeterminateState();
    } else {
      this.selectedCards.push(id);
      this.checkIfAllCheckboxSelected();
      this.headerCheckbox.nativeElement.indeterminate = !this.allCardsSelected;
    }
  }
}
