import { Component } from '@angular/core';
import { AppGlobalService } from "./shared/services/app-global.service";
import { SubscriptionComponent } from "./shared/components/subscription/subscription.component";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends SubscriptionComponent {
  title = 'ng-app';
  error: boolean;

  constructor(private globalService: AppGlobalService) {
    super();
    this.globalService._serverError
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((error: boolean) => {
        this.error = error;
      });
  }
}
