<div class="dropdown">
  <button type="button" class="context-menu-button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
      <i class="mdi mdi-dots-vertical mdi-24px icon-color"></i>
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <ng-container *ngFor="let item of items">
      <li *ngIf="item.display" class="cursor-pointer" (mouseover)="hover = !!item.subMenu" (mouseleave)="hover = false">
        <a class="dropdown-item" (click)="onEmitItem(item)">{{ item.label }}  <span *ngIf="item.subMenu"> >> </span></a>
        <ul *ngIf="hover" class="sub-menu">
          <li *ngFor="let subItem of item.subMenu" class="cursor-pointer">
            <a (click)="onDownload(subItem.fileUrl, subItem.textId)" class="dropdown-item" target="_blank">Rechnung #{{ subItem.textId }}</a>
          </li>
        </ul>
      </li>
    </ng-container>
  </ul>
</div>
